import { useContext, useState } from 'react'
import {switchRole, selectProviderLinkedStaff,allLocation,selectLocationProvider } from '../../../Context/api'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import { Notifications } from '../../../App';
import { useNavigate } from 'react-router-dom'
const UseProviderHook = () => {
    const { setUserInfo } = useContext(Notifications)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [branchData, setBranchData] = useState([])
    const [branchShow, setBranchOpen] = useState(false)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const userData = JSON.parse(localStorage.getItem('userData'))
    const navigate=useNavigate()

    const roleswitch = () => {
        const data = timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ?
            {
                timeZone: timeZone,
                mobileNo: userData?.mobileNo,
            }
            :
            {
                timeZone: timeZone,
                email: userData?.email
            }
        axios.post(switchRole(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === 301) {
                setData(resp.data.data)
                setOpen(true)
            }
            else {
                setData([])
                toast.error(resp.data.message)
            }
        })
        .catch(()=>{
            toast.error(commonMessage.networkError)
        })
    }
    const selectMember = (item) => {
        const data = timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ?
            {
                timeZone: timeZone,
                staffId: item.staffId,
                providerId: item._id,
                mobileNo: userData?.mobileNo
            }
            :
            {
                timeZone: timeZone,
                staffId: item.staffId,
                providerId: item._id,
                email: userData?.email
            }
        axios.post(selectProviderLinkedStaff(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.locationScreen) {
                setBranchOpen(true)
                branches(resp?.data?.data?.userId)
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(()=>{})
    }

    const branches = (userId) => {
        axios.post(allLocation(), { userId: userId }).then((resp) => {
            if (resp.data.code ===statusCodes.ok) {
                setBranchData(resp.data.data)
            }
            else {
                setBranchData([])

            }
        }).catch(()=>{})
    }
    const selectLocation = (item) => {
        const data = {
            // locationId:id,
            // loginDevice:'WEB',
            // userId:userData?.userId,
            locationId:item._id,
            loginDevice:'WEB',
            userId:item.userId,
        }
        // setSpinner(true)
        axios.post(selectLocationProvider(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.setItem('userData', JSON.stringify(resp.data.data))
                setUserInfo(resp.data.data)
                toast.success(resp.data.message)
                setBranchOpen(false)
                navigate('/')
                // setSpinner(false)
            }
            else {
                // setSpinner(false)
                toast.error(resp.data.message)
            }
        }).catch(()=>{
            // setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    return {
        data,
        open,
        timeZone,
        userData,
        roleswitch,
        setOpen,
        selectMember,
        setBranchOpen,
        selectLocation,
        branchShow,
        branchData

    }
}

export default UseProviderHook