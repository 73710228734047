import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { comboPackagesTotalEarning, clientEarning, getIndividualClientPackages, imgData, clientListApi } from '../../Context/api'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import moment from 'moment'
import Spinner from '../../Components/spinner/spinner'
import DummyLogo from '../../Assets/images/dummyimg.png'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Offcanvas } from 'react-bootstrap'

const ComboReport = () => {
    const [filter, setFilter] = useState({
        client: '',
        days: "LAST7",
    });
    const navigate = useNavigate()
    const [spinner, setSpinner] = useState(false)
    const [earningData, setEarningData] = useState([])
    const [clientEarningData, setClientEarningData] = useState([])
    const [clientPerPage, setClientPerPage] = useState(1)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [showProfile, setShowProfile] = useState({ open: false, data: '' })



    useEffect(() => {
        getTotalEarning()
        getClientEarning()
    }, [filter])


    const getTotalEarning = () => {
        const data = {
            days: filter.days,
            today: moment(new Date()).format('YYYY-MM-DD'),
            clientId: filter.client
        }
        setSpinner(true)
        axios.post(comboPackagesTotalEarning(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setEarningData(resp.data.data)
                setSpinner(false)
            }
            else {
                setEarningData([])
                setSpinner(false)
            }
        }).catch(() => { setSpinner(false) })
    }
    const getClientEarning = () => {
        const data = {
            days: filter.days,
            today: moment(new Date()).format('YYYY-MM-DD'),
            clientId: filter.client
        }
        setSpinner(true)
        axios.post(clientEarning(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setClientEarningData(resp.data.data)
                setSpinner(false)
            }
            else {
                setClientEarningData([])
                setSpinner(false)
            }
        }).catch(() => { setSpinner(false) })
    }
    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',  // Background color of the control
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'white',  // Background color of the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#f0f0f0' : 'white', // Background color of options
            color: 'black'  // Text color of options
        }),
    };
    const loadClientOptions = async () => {
        const data = {
            userId: userData?.userId,
            isActive: true,
            isAccepted: 'accepted',
            page: clientPerPage,
            isDeleted: false,
            isProviderClient: true,
            count: 10
        }
        const response = await axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }, setClientPerPage(clientPerPage + 1))
        if (response.data.code === 200) {
        }
        return {
            options: response.data.data?.map(item => ({ value: item._id, label: item.name })),
            hasMore: response.data.data.length >= 1
        };

    }
    const getIndividualClient = (Id) => {
        const data = {
            days: filter.days,
            today: moment(new Date()).format('YYYY-MM-DD'),
            clientId: Id
        }
        setSpinner(true)
        axios.post(getIndividualClientPackages(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setShowProfile((pre) => ({ ...pre, open: true, data: resp.data.data }))
                setSpinner(false)
            }
            else {
                setSpinner(false)
                toast.error(resp.data.message)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    console.log(showProfile, 'PPPP')
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className='page-tittle'> <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Combo Reports</h2>
                        <hr />
                    </header>
                    {
                        // intialRole({ permission: "insights", userData: userInfo, section: 'viewTeamReport' }) ?
                        <>
                            <div className="d-flex justify-content-between listpadding">

                                <div className="dropdown" style={{ minWidth: '150px' }}>
                                    <AsyncPaginate
                                        placeholder='Select Client'
                                        styles={customStyles}
                                        loadOptions={loadClientOptions}
                                        onChange={(e) => { setFilter((pre) => ({ ...pre, client: e.value })) }}
                                    />
                                </div>
                                <div>
                                    <form className="cus-form">
                                        <select className='mb-2 form-select' aria-label="Default select example"
                                            name="days"
                                            value={filter.days}
                                            onChange={(e) => { setFilter((pre) => ({ ...pre, days: e.target.value })) }}
                                        >
                                            <option hidden>Select days</option>
                                            <option value="TODAY">Today</option>
                                            <option value="LAST7">Last 7 days</option>
                                            <option value="LAST_MONTH">Last Month</option>
                                            <option value="THIS_MONTH">This Month</option>
                                        </select>
                                    </form>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex justify-content-around my-4">
                                    <div className='mb-4'>
                                        <div className="card1">
                                            <h2>{userData?.currencySymbol} {earningData[0]?.paymentPaid ? earningData[0]?.paymentPaid : 0}</h2>
                                            <p>Payment Paid</p>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="card1">
                                            <h2>{userData?.currencySymbol} {earningData[0]?.paymentPending ? earningData[0]?.paymentPending : 0}</h2>
                                            <p>Payment Pending</p>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="card1">
                                            <h2>{userData?.currencySymbol} {earningData[0]?.totalEarning ? earningData[0]?.totalEarning : 0}</h2>
                                            <p>Total Earning</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive client-scroll">
                                    <table className="table cus-table">
                                        <thead className="text-center tableheader">
                                            <tr>
                                                <th scope="col">Profile</th>
                                                <th scope="col">Client Name</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clientEarningData.length ? (
                                                clientEarningData.map((item, i) => (
                                                    <tr className='cursor' key={i} onClick={() => { getIndividualClient(item._id) }}>
                                                        <td><img alt='img' style={{ borderRadius: '50%' }} src={item.image === '' ? DummyLogo : imgData + item.image} height={40} width={40} /></td>
                                                        <td><b>{item.name}</b></td>
                                                        <td>{userData?.currencySymbol} {item?.totalPrice}</td>
                                                    </tr>
                                                ))
                                            ) :
                                                <tr style={{ background: 'white', boxShadow: 'none' }}>
                                                    <td colSpan="5" className="text-center" >No record found</td>
                                                </tr>
                                            }
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </>
                        // : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                </div>

                {/* Client Report */}
                <Offcanvas show={showProfile.open} onHide={() => setShowProfile({ open: false, data: '' })} placement="end">
                    <div style={{ padding: "10px" }}>
                        <button type="button" className="btn-close" onClick={() => { setShowProfile({ open: false, data: '' }) }}></button>
                        <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">Client Report</h1>
                    </div>
                    <div className="text-center mb-4">
                        <img src={showProfile?.data?.image === '' ? DummyLogo : imgData + showProfile?.data?.image} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                    </div>
                    <div className="mb-1 px-3 cus-form">
                        <label className="form-label">Name</label>
                        <input type="text" className="form-control" name="name" value={showProfile?.data?.name} />
                    </div>
                    <div className="offcanvas-body">
                        <div className='service-scroll'>
                            <table className="table cus-table">
                                <thead className="text-center tableheader">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Validity</th>
                                    </tr>
                                </thead>
                                {
                                    showProfile?.data?.packages?.map((item, i) =>
                                        <React.Fragment key={i}>
                                            <tbody>
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{userData?.currencySymbol} {item.price}</td>
                                                    <td>{item.validity}</td>
                                                </tr>
                                            </tbody>
                                        </React.Fragment>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                </Offcanvas>
            </section>
        </>
    )
}

export default ComboReport
