import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { loginWithEmailValidation } from '../../Context/validation';
import { signInApi } from '../../Context/api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { commonMessage, statusCodes } from '../../Constants/generalConfig';
import { useNavigate } from "react-router-dom";
import { Notifications } from '../../App';

const LoginWithEmailPage = ({ ip, setSpinner, setTab, setUserData }) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [icon, setIcon] = useState(true);
  const navigate = useNavigate()
  const { setUserInfo } = useContext(Notifications)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const login = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginWithEmailValidation,
    onSubmit: (values) => {
      const data = {
        email: values.email,
        password: values.password,
        timeZone: timeZone,
        deviceId: ip,
        loginDevice: 'WEB'

      }
      setSpinner(true)
      axios.post(signInApi(), { ...data }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {

          if (resp.data.data.roleTitle === 'CLIENT') {
            return toast.warn('Client can not login')
          }
          else if (resp.data.data.roleTitle === 'ADMIN') {
            return toast.warn('Admin can not login')
          }
          else {
            setSpinner(false)
            navigate('layout/dashboard')
          }
          setSpinner(false)
          setUserInfo(resp.data.data)
          localStorage.setItem("userData", JSON.stringify(resp.data.data));

        }
        else if (resp.data.code === 411) {
          toast.error(resp.data.message)
          setSpinner(false)
          navigate("/enterOtp/createPin/basicDetailProfile");
          localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
          localStorage.setItem('Mobile', JSON.stringify(resp.data.data.mobileNo))
        }
        else if (resp.data.code === 301) {
          setSpinner(false)
          setTab(2)
          setUserData(resp.data.data)
          toast.success(resp.data.message)
        }
        else {
          setSpinner(false)
          toast.error(resp.data.message);
        }
      })
        .catch(() => {
          setSpinner(false)
          toast.error(commonMessage.networkError);
        });
    },
  })
  return (
    <>
      <form id="signInSignUpForm" className="sign-in-form" onSubmit={login.handleSubmit}>
        <h1 className="text-center heading-color mb-5">Sign in</h1>
        <div className="w-75 input-field">
          <i className="fa fa-envelope" aria-hidden="true"></i>
          <input type="text" placeholder="Enter Email"
            name='email'
            onChange={login.handleChange}
            value={login.values.email} />

          {login.touched.email && login.errors.email ? (
            <div style={{ color: "red", fontSize: "12px", width: '300%' }}>
              {login.errors.email}
            </div>
          ) : null}
        </div>
        <div className="w-75 input-field">
          {icon ? (
            <i className="fa fa-eye" onClick={() => { setPasswordShow(true); setIcon(false) }}></i>
          ) : (
            <i className="fa fa-eye-slash" aria-hidden="true" onClick={() => { setPasswordShow(false); setIcon(true) }}></i>
          )}
          <input
            type={passwordShow ? "text" : "password"}
            placeholder="PIN"
            name="password"
            onChange={login.handleChange}
            onBlur={login.handleBlur}
            value={login.values.password}
            maxLength={4}
            onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
          />
          {login.touched.password && login.errors.password ? (
            <div style={{ color: "red", fontSize: "12px", width: '300%' }}>
              {login.errors.password}
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-end mb-4">
          <p className="forgot-pin" onClick={() => navigate('forgotPin')} style={{ cursor: 'pointer' }}>Forgot PIN?</p>
        </div>
        <input type="submit" value="Sign In" className="btn colorbtun px" />
      </form>
    </>
  )
}

export default LoginWithEmailPage;