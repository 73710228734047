import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const UserApp = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            window.open("https://play.google.com/store/apps/details?id=com.iskedo.iskedo");
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            window.open("https://apps.apple.com/us/app/iskedo-appointment-booking/id6470637085");
        }
        else {
            navigate('app')
        }
    }, []);

    return (
        <>

        </>
    )
}

export default UserApp