import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

const Policies = () => {
  const navigate = useNavigate()

  return (
    <>
      <section>
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className='page-tittle'><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Policies</h2>
            <hr />
          </header>
          <div className="row">
            <div className="col-sm-12 listpadding">
              <ul className="cus-list">
                <li>
                  <NavLink to='bookingPolicies'>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {/* <svg id="Layer_1" data-name="Layer 1" width={50} className='icons_size' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 454 445">
                          <defs>
                          </defs>
                          <polyline className="cls-2" points="117.2 193.47 117.2 7.99 326.94 7.99 447.46 129.29 447.46 436.86 209.55 436.86" />
                          <polyline className="cls-2" points="323.81 12.69 323.81 124.6 441.98 124.6" />
                          <line className="cls-1" x1="159.98" y1="90.95" x2="291.46" y2="90.95" />
                          <line className="cls-1" x1="159.98" y1="127.25" x2="291.46" y2="127.25" />
                          <line className="cls-1" x1="159.98" y1="164.56" x2="401.28" y2="164.56" />
                          <line className="cls-1" x1="188.42" y1="200.73" x2="374.15" y2="200.73" />
                          <circle className="cls-1" cx="122.41" cy="328.77" r="110.04" />
                          <path className="cls-1" d="m175.64,335.76c-1.6,14.83-8.42,28.62-19.23,38.9-25.05,23.81-65.26,22.79-89.07-2.26-23.81-25.05-22.79-65.26,2.26-89.07,21.54-20.48,54.79-23.06,79.23-6.16" />
                          <polyline className="cls-1" points="84 323 110 350 181 281" />
                        </svg> */}
                        <span> Booking Policy</span>
                      </div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='policiesDelete'>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {/* <svg id="Layer_1" data-name="Layer 1" width={50} className='icons_size' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.09 449.86">
                        <defs>
                        </defs>
                        <polyline className="cls-1" points="112.83 196.52 112.83 11.04 322.57 11.04 443.09 132.34 443.09 439.91 205.18 439.91" />
                        <polyline className="cls-2" points="319.44 15.74 319.44 127.65 437.61 127.65" />
                        <line className="cls-1" x1="155.61" y1="94" x2="287.09" y2="94" />
                        <line className="cls-1" x1="155.61" y1="130.3" x2="287.09" y2="130.3" />
                        <line className="cls-1" x1="155.61" y1="167.61" x2="396.91" y2="167.61" />
                        <line className="cls-1" x1="184.05" y1="203.78" x2="369.78" y2="203.78" />
                        <circle className="cls-1" cx="118.04" cy="331.82" r="110.04" />
                        <line className="cls-1" x1="72.63" y1="286.7" x2="162.89" y2="376" />
                        <line className="cls-1" x1="162.89" y1="286.7" x2="72.63" y2="376" />
                      </svg> */}
                       Cancellation Policy</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='reschedulingPolicy'>
                    <div className="d-flex justify-content-between align-items-center">
                      <div> 
                        {/* <svg id="Layer_1" data-name="Layer 1" width={50} className='icons_size' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450.09 445.82">
                        <defs>
                        </defs>
                        <polyline className="cls-2" points="112.83 192.48 112.83 7 322.57 7 443.09 128.3 443.09 435.87 205.18 435.87" />
                        <polyline className="cls-2" points="319.44 11.7 319.44 123.61 437.61 123.61" />
                        <line className="cls-1" x1="155.61" y1="89.96" x2="287.09" y2="89.96" />
                        <line className="cls-1" x1="155.61" y1="126.26" x2="287.09" y2="126.26" />
                        <line className="cls-1" x1="155.61" y1="163.57" x2="396.91" y2="163.57" />
                        <line className="cls-1" x1="184.05" y1="199.74" x2="369.78" y2="199.74" />
                        <path className="cls-1" d="m63.98,355.41c-14.07-26.7-3.04-60.57,24.42-75.04,27.46-14.47,61.64-4.41,75.7,22.29,14.07,26.7,3.04,60.57-24.42,75.04-13.44,7.08-29.1,8.61-43.42,4.24" />
                        <polyline className="cls-2" points="34.51 343.46 67.38 361.46 81.21 329.07" />
                        <circle className="cls-1" cx="118.04" cy="327.78" r="110.04" />
                      </svg> */}
                       Rescheduling Policy</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='refundPolicy'>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {/* <svg id="Layer_1" data-name="Layer 1" width={50} className='icons_size' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 452.66">
                        <defs>
                        </defs>
                        <polyline className="cls-1" points="109.44 194.02 109.44 8.54 319.18 8.54 439.7 129.84 439.7 437.41 201.79 437.41" />
                        <polyline className="cls-2" points="316.05 13.24 316.05 125.15 434.22 125.15" />
                        <line className="cls-1" x1="152.22" y1="91.5" x2="283.7" y2="91.5" />
                        <line className="cls-1" x1="152.22" y1="127.8" x2="283.7" y2="127.8" />
                        <line className="cls-1" x1="152.22" y1="165.11" x2="393.52" y2="165.11" />
                        <line className="cls-1" x1="180.66" y1="201.28" x2="366.39" y2="201.28" />
                        <path className="cls-1" d="m21.63,351.57c13.21,59.72,73.22,98,132.94,84.8,59.72-13.21,98-73.22,84.8-132.94-13.21-59.72-73.22-98-132.94-84.8-26.47,5.85-49.91,21.16-65.92,43.04" />
                        <polyline className="cls-1" points="45.85 213.06 32.39 270 85 270.35" />
                        <text className="cls-3" transform="translate(87.64 378.72)"><tspan x="0" y="0">$</tspan></text>
                      </svg> */}
                      Refund Policy</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>

              </ul>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Policies;