import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { currencyUpdateApi, currencyListApi, logOutApi, getProviderPricePermission, showPriceToClient } from '../../Context/api'
import { useFormik } from 'formik'
import axios from 'axios'
import { statusCodes, commonMessage } from '../../Constants/generalConfig'
import { toast, ToastContainer } from 'react-toastify'
import { useEffect } from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils'
import { GoInfo } from "react-icons/go";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from '../../Components/spinner/spinner'

const Setting = () => {
  const { userInfo } = useContext(Notifications)
  const [currecnyListData, setCurrecnyListData] = useState()
  const [currecnyId, setCurrencyId] = useState([])
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [priceShow, setPriceShow] = useState(false);
  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      currecnyList()
      getPricePermission()
    }
  }, [])

  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }
  const currecnyList = () => {
    axios.post(currencyListApi(), { loggedInUserId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCurrecnyListData(resp.data.data)
        resp.data.data?.map((item) => {
          if (item.isChecked === true) {
            setCurrencyId(item._id)
          }
        })
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      } else {
        toast.error(resp.data.message)
      }
    }).catch(() => {
      toast.error(commonMessage.networkError)
    })
  }
  const formik = useFormik({
    initialValues: {
      currency: '',
    },
    onSubmit: (values) => {
      axios
        .post(currencyUpdateApi(), { currencyId: formik.values.currency, loggedInUserId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
        .then((resp) => {
          if (resp.data.code === statusCodes.ok) {
            toast.success(resp.data.message)
          }
          else if (resp.data.code === statusCodes.tokenExpire) {
            logOutFun()
          }
          else {
            toast.error(resp.data.message)
          }
        })
        .catch(() => {
          toast.error(commonMessage.networkError)
        })
    },
  })
  const getPricePermission = () => {
    axios.get(getProviderPricePermission(), { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setPriceShow(resp.data.data?.showPriceToClient)
      }
      else {
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }
  const showPrice = (status) => {
    setSpinner(true)
    axios.post(showPriceToClient(), { status: status }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        getPricePermission()
        setSpinner(false)
        toast.success(resp.data.message)
        setPriceShow(status)
      }
      else {
        toast.error(resp.data.message)
        setSpinner(false)
      }
    }).catch(() => {
      toast.error(commonMessage.networkError)
      setSpinner(false)
    })
  }
  return (
    <>
      <ToastContainer />
      {spinner && <Spinner />}
      <section>
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className="page-tittle">Settings</h2>
            <hr />
          </header>
          <div className="row">
            <div className="col-sm-12 listpadding">
              <ul className="cus-list">
                {
                  intialRole({ permission: "Policy", userData: userInfo, section: 'view', }) ?
                    <li>
                      <NavLink to="policies">
                        <div className="d-flex justify-content-between">
                          <div>
                            <span>Policies</span>
                          </div>
                          <i className="fa-solid fa-angle-right"></i>
                        </div>
                      </NavLink>
                    </li>
                    : null
                }
                <li>
                  <NavLink to="changePin">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>Change PIN</span>
                      </div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                {
                  // userData?.roleTitle === "SERVICE_PROVIDER" ?

                  intialRole({ permission: "Currency", userData: userInfo, section: 'view', }) ?
                    <li style={{ cursor: 'pointer' }} onClick={() => { formik.setFieldValue("currency", currecnyId); setShow(true) }}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <span style={{ color: 'black' }}>Currency</span>
                        </div>
                        <i className="fa-solid fa-angle-right"></i>
                      </div>
                    </li>
                    : null
                }
                <li>
                  <NavLink to="notification">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span>Notifications</span>
                      </div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="helpSupport">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span> Help Desk</span>
                      </div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                {

                  userData?.roleTitle === "SERVICE_PROVIDER" ?
                    <>
                      <li>
                        <NavLink to="my-plan">
                          <div className="d-flex justify-content-between">
                            <div>
                              <span>My Plan</span>
                            </div>
                            <i className="fa-solid fa-angle-right"></i>
                          </div>
                        </NavLink>
                      </li>
                    </>
                    : null
                }

                {

                  userData?.roleTitle === "SERVICE_PROVIDER" ?
                    <>
                      <li>
                        <div className="d-flex justify-content-between">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">To hide prices from your services</Tooltip>}>
                            <div>
                              <span>Show Prices</span>
                              <GoInfo className='fs-5 ms-2 cursor' />
                            </div>
                          </OverlayTrigger>
                          <div className="form-check form-switch" >
                            <input className="form-check-input cursor" role='switch' type="checkbox" checked={priceShow} onChange={(e) => showPrice(e.target.checked)} />
                          </div>
                        </div>
                      </li>
                    </>
                    : null
                }
              </ul>
            </div>
          </div>
        </div>
        <>
          <Modal show={show} centered onHide={() => setShow(false)}>
            <form className="row g-3 cus-form p-3">
              <div className="mb-3 inputwithicon p-5'">
                <label className="form-label">Select Currency *</label>
                <select
                  className="form-select form-control"
                  placeholder="Select Currency"
                  aria-label="Default select example"
                  name="currency"
                  onChange={(e) => { formik.setFieldValue('currency', e.target.value); formik.handleSubmit() }}
                  value={formik.values.currency}
                >
                  <option hidden>Select Currency</option>
                  {currecnyListData?.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.symbol} {item.code}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </Modal>
        </>
      </section>
    </>
  )
}

export default Setting
