import React from 'react'
import ScrollBars from 'react-scrollbar'
import DummyLogo from '../../Assets/images/dummyimg.png'
import { Modal } from 'react-bootstrap'

const ClientModal = ({ setSearchText, clientFilteredData, imgData, Conversation, clientModalShow, setClientModalShow }) => {
    return (
        <Modal centered show={clientModalShow} onHide={() => setClientModalShow(false)}>
            <div className="modal-content">
                <div className='m-3'>
                    <h1 className="modal-title fs-5 mt-3 heading text-center" id="exampleModalLabel">New Conversation Client</h1>
                    <hr />
                    <div className="form-group has-feedback d-flex">
                        <input id="searchText" type="text" className="form-control m-2" name="searchText" onChange={(e) => setSearchText(e.target.value)} placeholder="Search" style={{ borderRadius: '20px' }} />
                    </div>
                    <ScrollBars horizontal autoHide={false} style={{ height: '400px', width: '100%' }} smoothScrolling={true}>
                        {
                            !clientFilteredData?.length ? <div className='d-flex justify-content-center mt-2'><h3>No record found</h3></div>
                                :
                                clientFilteredData?.map((item, i) =>
                                    <div className="d-flex sideBar-body mb-2 shadow-Box" data-bs-dismiss="modal" aria-label="Close" key={i} onClick={() => Conversation(item)}>
                                        <div className="col-sm-3 col-xs-3 sideBar-avatar">
                                            <div className="avatar-icon">
                                                <img src={item.image === ''||item.image === null ? DummyLogo : imgData + item.image} alt='img'/>
                                            </div>
                                        </div>
                                        <div className="col-sm-9 col-xs-9 sideBar-main">
                                            <div className="row">
                                                <div className="col-sm-8 col-xs-8 sideBar-name">
                                                    <span className="name-meta">{item.name}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                        }

                    </ScrollBars>
                </div>
                <span className='mb-5'></span>
            </div>
        </Modal>
    )
}

export default ClientModal