
import { useNavigate, useParams } from 'react-router-dom'
import { addServiceApi, serviceListApi, imgPath, ServiceViewApi, editServiceApi, changeServiceStatusApi, durationList, currency, logOutApi } from '../../../Context/api'
import { useFormik } from 'formik'
import axios from 'axios'
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig'
import { useContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { addServiceValidation } from '../../../Context/validation';
import { Notifications } from '../../../App'


const UseServiceList = () => {
    const { userInfo } = useContext(Notifications)
    const [image, setImage] = useState('')
    const [listData, setListData] = useState([])
    const [serviceId, setServiceId] = useState('')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [spinner, setSpinner] = useState(false)
    const [showService, setShowService] = useState(false)
    const [show, setShow] = useState(false)
    const [archive, setArchive] = useState(false)
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const { categoryId } = useParams()
    const [duration, setDuration] = useState([])
    const [currencyName, setCurrencyName] = useState([])
    const [isActive, setIsActive] = useState(true)
    const [visit, setVisit] = useState({
        INOFFICE: '',
        HOME: '',
        ONLINE: '',
    })
    const navigate = useNavigate()
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            serviceList()
            currencyLis()

        }
    }, [page, isActive])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }

    const serviceList = (e) => {
        const data = {
            categoryId: categoryId,
            searchText: '' || e,
            isActive: isActive,
            page: page,
            count: PER_PAGE
        }
        setSpinner(true)
        axios.post(serviceListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setListData(resp.data.data)
                setCount(resp.data.totalCount)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setPage(1)
                setListData([])
                setSpinner(false)
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
    }
    const listDuration = () => {
        axios.post(durationList(), { userId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId, count: 50, isDeleted: false, isActive: true, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setDuration(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const serviceView = (id) => {
        axios.post(ServiceViewApi(), { serviceId: id, loggedInUserId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                editService.setValues(resp.data.data)
                setImage(resp.data.data.image)
                setServiceId(id)
                setShowService(true)
                listDuration()
                const visitObject = {
                    INOFFICE: resp.data.data.visitType.includes('INOFFICE') ? 'INOFFICE' : '',
                    HOME: resp.data.data.visitType.includes('HOME') ? 'HOME' : '',
                    ONLINE: resp.data.data.visitType.includes('ONLINE') ? 'ONLINE' : ''
                };
                setVisit(visitObject);
                
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const changeServiceStatus = () => {
        axios.post(changeServiceStatusApi(), { serviceId: serviceId._id, loggedInUserId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId, status: serviceId.isActive }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                serviceList()
                setArchive(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const handleCheckboxChange = (option) => {
        setVisit((prevState) => ({
            ...prevState,
            [option]: prevState[option] ? '' : option
        }));
    };
    const clearAll=()=>{
        setVisit({
            INOFFICE: '',
            HOME: '',
            ONLINE: ''
        });
    }
    const visitArray = Object.values(visit).filter(value => value !== '');
    const addService = useFormik({
        initialValues: {
            name: '',
            fixedAmount: '',
            variableAmount: '',
            description: '',
            duration: '',
        },
        validationSchema: addServiceValidation,
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                fixedAmount: values.fixedAmount,
                variableAmount: values.variableAmount,
                description: values.description.trim(),
                duration: values.duration,
                image: image,
                createdby_id: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                categoryId: categoryId,
                branchId: userData?.locationId,
                visitType: visitArray
            }
            if (!data.visitType.length) return toast.warn('Please select Booking Type')
            if (values.fixedAmount < 0 && values.variableAmount < 0) return toast.warn('Fixed price and variable price should not be nagative')
            if (values.fixedAmount < 0) return toast.warn('Fixed price should not be negative')
            if (values.variableAmount < 0) return toast.warn('Variable price should not be negative')
            // console.log(data, 'data')
            // return
            setSpinner(true)
            axios.post(addServiceApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    addService.resetForm()
                    clearAll()
                    serviceList()
                    setShow(false)
                    setSpinner(false)
                    setImage('')
                }
                else if (resp.data.code === statusCodes.tokenExpire) {   
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const editService = useFormik({
        initialValues: {
            name: '',
            fixedAmount: '',
            variableAmount: '',
            description: '',
            duration: '',
        },
        validationSchema: addServiceValidation,
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                fixedAmount: values.fixedAmount,
                variableAmount: values.variableAmount,
                description: values.description.trim(),
                duration: values.duration,
                image: image,
                loggedInUserId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                serviceId: serviceId,
                visitType: visitArray
            }
            if (values.variableAmount === null) {
                values.variableAmount = ''
            }
            if (!data.visitType.length) return toast.warn('Please select Booking Type')
            if (values.fixedAmount < 0 && values.variableAmount < 0) return toast.warn('Fixed price and variable price should not be nagative')
            if (values.fixedAmount < 0) return toast.warn('Fixed price should not be negative')
            if (values.variableAmount < 0) return toast.warn('Variable price should not be negative')
            setSpinner(true)
            axios.post(editServiceApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    serviceList()
                    setShowService(false)
                    setSpinner(false)
                    setImage('')
                    clearAll()
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            }).catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
        },
    })
    const filterBySearch = (e) => {
        setSpinner(true)
        axios.post(serviceListApi(), { categoryId: categoryId, searchText: e, isActive: true, page: 1, count: PER_PAGE }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setListData(resp.data.data)
                setCount(resp.data.totalCount)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                // toast.error(resp.data.message)
                setSpinner(false)
                setListData([])
            }
        })
    }
    const enterSearch = (event) => {
        if (event.keyCode === 13) {
            filterBySearch(event.target.value)
        }
    }
    const currencyLis = () => {
        axios.post(currency(), { loggedInUserId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setCurrencyName(resp.data.data.symbol)
            }
        }).catch(() => { })
    }
    const ServiceCategory = localStorage.getItem('service')


    return {
        serviceList,
        listDuration,
        serviceView,
        changeServiceStatus,
        enterSearch,
        filterBySearch,
        currencyLis,
        setImage,
        setListData,
        setServiceId,
        setSpinner,
        setShowService,
        setShow,
        setCount,
        setDuration,
        setPage,
        setCurrencyName,
        setIsActive,
        navigate,
        setArchive,
        visit, setVisit,
        clearAll,
        handleCheckboxChange,
        archive,
        isActive,
        currencyName,
        page,
        count,
        show,
        showService,
        spinner,
        serviceId,
        listData,
        editService,
        addService,
        ServiceCategory,
        image,
        userData,
        PER_PAGE,
        categoryId,
        duration,
        imgPath,
        fileInputRef,
        userInfo
    }
}

export default UseServiceList