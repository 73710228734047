import React  from 'react';
import ResetLogo from '../../Assets/images/reset.png';
import OtpInput from "react-otp-input";
import { ToastContainer } from 'react-toastify';
import Spinner from '../../Components/spinner/spinner';
import UseCreatePin  from "./Hooks/useCreatePin";
const CreatePin = () => {
  const { spinner, formik,  }=UseCreatePin()
 
  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="container d-flex justify-content-center align-items-center cusheight">
          <div className="row">
            <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>
              <div className="text-center">
                <img src={ResetLogo} alt='' />
                <h1 className="text-center heading-color mb-3">Create PIN</h1>
                <form className="cus-form" onSubmit={formik.handleSubmit}>
                  <label className="form-label">Create New PIN *</label>
                  <div className="pin-code mb-3">
                    <OtpInput isInputSecure isInputNum value={formik.values.pin} onChange={(e) => { formik.setFieldValue("pin", e) }} numInputs={4} />
                  </div>
                  {formik.touched.pin && formik.errors.pin ? (
                    <div style={{ color: 'red', fontSize: '12px', marginBottom: '5px' }}>{formik.errors.pin}</div>
                  ) : null}
                  <label className="form-label">Confirm New PIN *</label>
                  <div className="pin-code">
                    <OtpInput isInputSecure isInputNum value={formik.values.confirmPin} onChange={(e) => { formik.setFieldValue("confirmPin", e) }} numInputs={4} />
                  </div>
                  {formik.touched.confirmPin && formik.errors.confirmPin ? (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '7px' }}>{formik.errors.confirmPin}</div>
                  ) : null}
                  <div className="d-grid  col-6 mx-auto mb-3">
                    <button type='submit' className="btn colorbtun resetbtn px">Proceed</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreatePin;


