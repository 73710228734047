import React from 'react'
import Loader from '../../Assets/images/spinner.png';
import Backdrop from '@mui/material/Backdrop';
const Spinner = () => {
  return (
    <>
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open >
          <div className="d-flex justify-content-center align-item-center spinner">
            <div className="loading">
              <div className="loading__ring1 ">
                <img src={Loader} className='loaderImg' height="50px" width="50px" alt="" />
              </div>
              <div className="loading__ring2">
                <img src={Loader} className='loaderImg' height="50px" width="50px" alt="" />
              </div>
            </div>
          </div>
        </Backdrop>
      </div>
    </>
  )
}

export default Spinner
