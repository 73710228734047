import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage';
import TimePicker from 'rc-time-picker'
import { Backdrop } from '@mui/material';
function AddTeamAvailability({ showAvailability, setShowAvailability, closeToggleBtn, formik, timeZoneData, defaultFormValue, staffBranchesData, valueChangeToggleSwitch, showAdditionalTimingsBox, hideAdditionalTimingsBox }) {
  return (
    <>
     <Backdrop  open={showAvailability}>
      <Offcanvas show={showAvailability} onHide={() => setShowAvailability(false)} placement="end" backdrop={false}>
        <div style={{ padding: '10px' }}>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { closeToggleBtn(); setShowAvailability(false); formik.resetForm() }}></button>
          <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
            Set Availability
          </h1>
        </div>
        <div className="offcanvas-body">
          <form className="cus-form" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <select className="form-select form-control" aria-label="Default select example" name="timeZone" onChange={formik.handleChange} value={formik.values.timeZone}>
                <option hidden>Select time zone</option>
                {timeZoneData?.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.text}
                  </option>
                ))}
              </select>
              <ValidationMessage formName={formik} name={'timeZone'} />
            </div>
            <div className="mb-3">
              <select className="form-control" aria-label="Default select example" name="branch" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.branch} disabled>
                <option hidden>Select Branch</option>
                {staffBranchesData?.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
              <ValidationMessage formName={formik} name={'branch'} />
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Sunday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[0].isChecked}
                  onChange={() => valueChangeToggleSwitch(0)}
                  role="switch"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseOne" className={defaultFormValue[0].isChecked ? 'collapse show' : 'collapse'} aria-labelledby="flush-headingOne">
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-3">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeSunday"
                    onChange={(e) => formik.setFieldValue('startTimeSunday', e)}
                    value={formik.values.startTimeSunday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeSunday"
                    onChange={(e) => formik.setFieldValue('endTimeSunday', e)}
                    value={formik.values.endTimeSunday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(0)}></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[0].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeSunday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeSunday', e)}
                      value={formik.values.secondStartTimeSunday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeSunday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeSunday', e)}
                      value={formik.values.secondEndTimeSunday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(0, 1)
                          formik.setFieldValue('secondStartTimeSunday', '')
                          formik.setFieldValue('secondEndTimeSunday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {defaultFormValue[0].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeSunday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeSunday', e)}
                      value={formik.values.thirdStartTimeSunday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeSunday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeSunday', e)}
                      value={formik.values.thirdEndTimeSunday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(0, 2)
                          formik.setFieldValue('thirdStartTimeSunday', '')
                          formik.setFieldValue('thirdEndTimeSunday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Monday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[1].isChecked}
                  onChange={() => valueChangeToggleSwitch(1)}
                  role="switch"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseTwo" className={defaultFormValue[1].isChecked ? 'collapse show' : 'collapse'}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-3">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeMonday"
                    onChange={(e) => formik.setFieldValue('startTimeMonday', e)}
                    value={formik.values.startTimeMonday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeMonday"
                    onChange={(e) => formik.setFieldValue('endTimeMonday', e)}
                    value={formik.values.endTimeMonday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(1)}></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[1].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeMonday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeMonday', e)}
                      value={formik.values.secondStartTimeMonday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeMonday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeMonday', e)}
                      value={formik.values.secondEndTimeMonday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(1, 1);
                          formik.setFieldValue('secondStartTimeMonday', '')
                          formik.setFieldValue('secondEndTimeMonday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {defaultFormValue[1].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeMonday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeMonday', e)}
                      value={formik.values.thirdStartTimeMonday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeMonday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeMonday', e)}
                      value={formik.values.thirdEndTimeMonday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(1, 2)
                          formik.setFieldValue('thirdStartTimeMonday', '')
                          formik.setFieldValue('thirdEndTimeMonday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Tuesday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[2].isChecked}
                  onChange={() => valueChangeToggleSwitch(2)}
                  role="switch"
                  id="flexSwitchCheckChecked"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseThree" className={defaultFormValue[2].isChecked ? 'collapse show' : 'collapse'}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-3">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeTuesday"
                    onChange={(e) => formik.setFieldValue('startTimeTuesday', e)}
                    value={formik.values.startTimeTuesday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeTuesday"
                    onChange={(e) => formik.setFieldValue('endTimeTuesday', e)}
                    value={formik.values.endTimeTuesday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(2)}></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[2].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeTuesday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeTuesday', e)}
                      value={formik.values.secondStartTimeTuesday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeTuesday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeTuesday', e)}
                      value={formik.values.secondEndTimeTuesday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(2, 1)
                          formik.setFieldValue('secondStartTimeTuesday', '')
                          formik.setFieldValue('secondEndTimeTuesday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {defaultFormValue[2].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeTuesday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeTuesday', e)}
                      value={formik.values.thirdStartTimeTuesday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeTuesday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeTuesday', e)}
                      value={formik.values.thirdEndTimeTuesday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(2, 2)
                          formik.setFieldValue('thirdStartTimeTuesday', '')
                          formik.setFieldValue('thirdEndTimeTuesday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Wednesday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[3].isChecked}
                  onChange={() => valueChangeToggleSwitch(3)}
                  role="switch"
                  id="flexSwitchCheckChecked"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseFour" className={defaultFormValue[3].isChecked ? 'collapse show' : 'collapse'}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-3">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeWednesday"
                    onChange={(e) => formik.setFieldValue('startTimeWednesday', e)}
                    value={formik.values.startTimeWednesday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeWednesday"
                    onChange={(e) => formik.setFieldValue('endTimeWednesday', e)}
                    value={formik.values.endTimeWednesday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(3)}></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[3].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeWednesday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeWednesday', e)}
                      value={formik.values.secondStartTimeWednesday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeWednesday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeWednesday', e)}
                      value={formik.values.secondEndTimeWednesday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(3, 1)
                          formik.setFieldValue('secondStartTimeWednesday', '')
                          formik.setFieldValue('secondEndTimeWednesday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {defaultFormValue[3].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeWednesday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeWednesday', e)}
                      value={formik.values.thirdStartTimeWednesday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeWednesday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeWednesday', e)}
                      value={formik.values.thirdEndTimeWednesday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                          hideAdditionalTimingsBox(3, 2)
                          formik.setFieldValue('thirdStartTimeWednesday', '')
                          formik.setFieldValue('thirdEndTimeWednesday', '')
                        }}></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Thursday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[4].isChecked}
                  onChange={() => valueChangeToggleSwitch(4)}
                  role="switch"
                  id="flexSwitchCheckChecked"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseFive" className={defaultFormValue[4].isChecked ? 'collapse show' : 'collapse'}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-3">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeThursday"
                    onChange={(e) => formik.setFieldValue('startTimeThursday', e)}
                    value={formik.values.startTimeThursday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeThursday"
                    onChange={(e) => formik.setFieldValue('endTimeThursday', e)}
                    value={formik.values.endTimeThursday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i
                        className="fa-solid fa-plus cus-plus"
                        onClick={() => {
                          showAdditionalTimingsBox(4)
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[4].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeThursday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeThursday', e)}
                      value={formik.values.secondStartTimeThursday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeThursday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeThursday', e)}
                      value={formik.values.secondEndTimeThursday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i
                          className="fa-solid fa-minus cus-minus"
                          onClick={() => {
                            hideAdditionalTimingsBox(4, 1)
                            formik.setFieldValue('secondStartTimeThursday', '')
                            formik.setFieldValue('secondEndTimeThursday', '')
                        }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {defaultFormValue[4].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeThursday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeThursday', e)}
                      value={formik.values.thirdStartTimeThursday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeThursday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeThursday', e)}
                      value={formik.values.thirdEndTimeThursday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i
                          className="fa-solid fa-minus cus-minus"
                          onClick={() => {
                            hideAdditionalTimingsBox(4, 2)
                            formik.setFieldValue('thirdStartTimeThursday', '')
                            formik.setFieldValue('thirdEndTimeThursday', '')
                        }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Friday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[5].isChecked}
                  onChange={() => valueChangeToggleSwitch(5)}
                  role="switch"
                  id="flexSwitchCheckChecked"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseSix" className={defaultFormValue[5].isChecked ? 'collapse show' : 'collapse'}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-3">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeFriday"
                    onChange={(e) => formik.setFieldValue('startTimeFriday', e)}
                    value={formik.values.startTimeFriday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeFriday"
                    onChange={(e) => formik.setFieldValue('endTimeFriday', e)}
                    value={formik.values.endTimeFriday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i
                        className="fa-solid fa-plus cus-plus"
                        onClick={() => {
                          showAdditionalTimingsBox(5)
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[5].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeFriday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeFriday', e)}
                      value={formik.values.secondStartTimeFriday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeFriday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeFriday', e)}
                      value={formik.values.secondEndTimeFriday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i
                          className="fa-solid fa-minus cus-minus"
                          onClick={() => {
                            hideAdditionalTimingsBox(5, 1)
                            formik.setFieldValue('secondStartTimeFriday', '')
                            formik.setFieldValue('secondEndTimeFriday', '')
                        }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : null}
                {defaultFormValue[5].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-3">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeFriday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeFriday', e)}
                      value={formik.values.thirdStartTimeFriday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeFriday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeFriday', e)}
                      value={formik.values.thirdEndTimeFriday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i
                          className="fa-solid fa-minus cus-minus"
                          onClick={() => {
                            hideAdditionalTimingsBox(5, 2)
                            formik.setFieldValue('thirdStartTimeFriday', '')
                            formik.setFieldValue('thirdEndTimeFriday', '')
                        }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between form-control mb-3">
              <h3>Saturday</h3>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={defaultFormValue[6].isChecked}
                  onChange={() => valueChangeToggleSwitch(6)}
                  role="switch"
                  id="flexSwitchCheckChecked"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSeven"
                  aria-expanded="false"
                  aria-controls="flush-collapseSeven"
                />
              </div>
            </div>
            <div className="accordion">
              <div id="flush-collapseSeven" className={defaultFormValue[6].isChecked ? 'collapse show' : 'collapse'}>
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <p>From</p>
                  <p style={{ marginLeft: '140px' }}>To</p>
                </div>
                <div style={{ display: 'flex' }} className="mb-5">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: '20px' }}
                    name="startTimeSaturday"
                    onChange={(e) => formik.setFieldValue('startTimeSaturday', e)}
                    value={formik.values.startTimeSaturday}
                    autoComplete="off"
                  />
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name="endTimeSaturday"
                    onChange={(e) => formik.setFieldValue('endTimeSaturday', e)}
                    value={formik.values.endTimeSaturday}
                    autoComplete="off"
                  />
                  <div className="col-sm-1 offset-sm-1">
                    <div className="d-flex justify-content-end">
                      <i
                        className="fa-solid fa-plus cus-plus"
                        onClick={() => {
                          showAdditionalTimingsBox(6)
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
                {defaultFormValue[6].isAllowedTimes.first ? (
                  <div style={{ display: 'flex' }} className="mb-5">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="secondStartTimeSaturday"
                      onChange={(e) => formik.setFieldValue('secondStartTimeSaturday', e)}
                      value={formik.values.secondStartTimeSaturday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="secondEndTimeSaturday"
                      onChange={(e) => formik.setFieldValue('secondEndTimeSaturday', e)}
                      value={formik.values.secondEndTimeSaturday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i
                          className="fa-solid fa-minus cus-minus"
                          onClick={() => {
                            hideAdditionalTimingsBox(6, 1)
                            formik.setFieldValue('secondStartTimeSaturday', '')
                            formik.setFieldValue('secondEndTimeSaturday', '')
                        }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : null}

                {defaultFormValue[6].isAllowedTimes.second ? (
                  <div style={{ display: 'flex' }} className="mb-5">
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      style={{ marginRight: '20px' }}
                      name="thirdStartTimeSaturday"
                      onChange={(e) => formik.setFieldValue('thirdStartTimeSaturday', e)}
                      value={formik.values.thirdStartTimeSaturday}
                      autoComplete="off"
                    />
                    <TimePicker
                      placeholder="Select Time"
                      use12Hours
                      showSecond={false}
                      focusOnOpen={true}
                      format="hh:mm A"
                      name="thirdEndTimeSaturday"
                      onChange={(e) => formik.setFieldValue('thirdEndTimeSaturday', e)}
                      value={formik.values.thirdEndTimeSaturday}
                      autoComplete="off"
                    />
                    <div className="col-sm-1 offset-sm-1">
                      <div className="d-flex justify-content-end">
                        <i
                          className="fa-solid fa-minus cus-minus"
                          onClick={() => {
                            hideAdditionalTimingsBox(6, 2)
                            formik.setFieldValue('thirdStartTimeSaturday', '')
                            formik.setFieldValue('thirdEndTimeSaturday', '')
                        }}
                        ></i>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-grid  col-6 mx-auto mb-2 mt-1">
              <button className="btn colorbtun popupbtn px" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Offcanvas>
      </Backdrop>
    </>
  )
}

export default AddTeamAvailability