import React, { useEffect, useState } from 'react'
import Master from '../../Assets/images/Mastercard-logo.svg.png';
import Visa from '../../Assets/images/Visa-Symbol.png';
import American from '../../Assets/images/americanExpress.png';
import { checkTstripeToken,stripePayment} from '../../Context/api'
import { paymentValidation } from "../../Context/validation";
import Spinner from '../../Components/spinner/spinner'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import { ToastContainer, toast } from 'react-toastify'
import { useFormik } from 'formik'
import ValidationMessage from '../ValidationMessage/validationMessage';
import { useNavigate, useParams } from 'react-router-dom';


const StripePayment = () => {
    const navigate=useNavigate()
    const [totalAmount, setTotalAmount] = useState('')
    const [spinner, setSpinner] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const {token}=useParams()

 
    useEffect(() => {
        tokenCheck()
      }, [])

    
      const tokenCheck = () => {
        setSpinner(true)
        axios.post(checkTstripeToken(), { token:token}).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
            setTotalAmount(resp.data)
              setSpinner(false)
            } else {
              setSpinner(false)
              navigate('wrong-token')
            }
          })
          .catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
          })
      }
      const amount=useFormik({
        initialValues:{
            card:'',
            expMonth:'',
            expYear:'',
            cvv:'',
        },
        validationSchema:paymentValidation,
        onSubmit:(values)=>{
            const data={
              number:values.card.replace(/ /g, ''),
              exp_year:values.expYear,
              exp_month:values.expMonth,
              cvc:values.cvv,
              token:token
          }
          // console.log(data,'lllllll')
          setSpinner(true)
          axios.post(stripePayment(),data,{ headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
              setSpinner(false)
              toast.success(resp.data.message)
              amount.resetForm()
              setTimeout(() => {
                navigate('/')
              }, 1000);
  
            } else {
              setSpinner(false)
              toast.error(resp.data.message)
            }
          })
          .catch(()=>{
              toast.error(commonMessage.networkError)
          })
        }
      })
      const handleInputChange = (e) => {
        const value = e.target.value.replace(/\s/g, ''); // Remove existing spaces
        const formattedValue = value.replace(/(.{4})/g, '$1 ').trim(); // Add space after every 4 characters
        amount.setFieldValue('card', formattedValue)
    };
  return (
    <div>
        {spinner && <Spinner />}
        <ToastContainer/>
        <div className='payment'>
         <div className="d-flex justify-content-around p-3 _paymentCard">
            <h5 className='fw-bold mt-2'>Price</h5>
            <h5 className='fw-bold mt-2'>{totalAmount?.totalCount} {totalAmount?.data}</h5>
         </div>
          <hr />
         <h4 className='fw-bold text-center'>Card Details</h4>
         <div className='d-flex justify-content-around'>
         <img className='mt-4' src={Master} alt="Master Card" height={45} width={60} />
         <img className='mt-4' src={Visa} alt="Visa Card" height={50} width={60} />
         <img className='mt-1' src={American} alt="American Card" height={80} width={100} />
         </div>
         <div className='mt-5 mb-3'>
         {/* <input type="text" className='form-control mt-2' placeholder='Cardholder name' name="name" value={amount.values.name} onChange={amount.handleChange} id="" />
         <ValidationMessage formName={amount} name={'name'} /> */}

         <input type="text" maxLength={19} className='form-control mt-4' placeholder='Card number' name="card" value={amount.values.card} onChange={handleInputChange} 
        
          />
           <ValidationMessage formName={amount} name={'card'} />
         <div className='row mt-4'>
            <div className="col-sm-4">
            <input type="number" className='form-control' maxLength={2} placeholder="MM" data-slots="my" name='expMonth' value={amount.values.expMonth} onChange={amount.handleChange}
             onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
            <ValidationMessage formName={amount} name={'expMonth'} />
            </div>
            <div className="col-sm-4">
            <input type="number" className='form-control' maxLength={2} placeholder="YY" data-slots="my" name='expYear' value={amount.values.expYear} onChange={amount.handleChange}
             onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
            <ValidationMessage formName={amount} name={'expYear'} />
            </div>
            <div className="col-sm-4">
            <input type="number" className='form-control' placeholder="CVV" data-slots="my" name='cvv'  value={amount.values.cvv} onChange={amount.handleChange}
             onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={4}/>
             <ValidationMessage formName={amount} name={'cvv'} />
            </div>
         </div>
         <div className='d-flex justify-content-center mt-4'>
         <button className='btn btn_Style ps-5 pe-5 mt-4' type='submit' onClick={amount.handleSubmit}>Pay</button>
        </div>
         </div>
        </div>
    </div>
//     <div>
//     <CardElement />
//     <button onClick={generateToken} disabled={!stripe}>
//       Generate Token
//     </button>
//   </div>
  )
}

export default StripePayment