import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { contactUSValidation } from '../../Context/validation';
import ValidationMessage from '../ValidationMessage/validationMessage';
import { countryListApi, customContact } from '../../Context/api';
import axios from "axios";
import { commonMessage, statusCodes } from "../../Constants/generalConfig";
import Spinner from '../../Components/spinner/spinner'
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NumberInput from "../../Components/PhoneInput/phoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";

const PricingPage = () => {
    const [spinner, setSpinner] = useState(false)
    const [country, setcountry] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        countryListData()
    }, [])

    const countryListData = () => {
        const data = {
            isDeleted: true,
            sortValue: 'name',
            sortOrder: -1,
            isActive: true
        }
        axios.post(countryListApi(), { ...data })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setcountry(resp.data.data)
                } else {
                    toast.error(resp.data.message)
                }
            })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }
    const customPlan = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            description: '',
            providerName: '',
            country: ''
        },
        validationSchema: contactUSValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile && isValidPhoneNumber(values?.mobile))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                email: values.email,
                mobileNo: values.mobile.toString(),
                providerName: values.providerName.trim(),
                description: values.description.trim(),
                country: values.country
            }
            // console.log(data,'llllllll')
            setSpinner(true)
            axios.post(customContact(), data).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    customPlan.resetForm()
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                    setSpinner(false)
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            }).catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
        }
    })
    return (
        <>
            {spinner && <Spinner />}
            <section style={{background:"#feeada", height:"100vh"}}>
                <ToastContainer />
                <div className="cus-padd">
                    <header>
                        <div className="d-flex justify-content-between">
                            <div className="">
                            <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>
                            </div>
                            <div className="">
                            <h1 className="page-tittle">Just Write us Here !</h1>
                            </div>
                            <div className=""></div>
                        </div>
                    </header>
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 listpadding">
                            <div className="container">
                                <div className="row mt-5 d-flex justify-content-center shadow pt-5" style={{ borderRadius: '20px', backgroundColor:"#ffffff" }}>
                                    <div className="col-lg-5 mt-4">
                                        <div className="border text-center all_plan_main_border_radius shadow">
                                            <div className="free_custom">
                                                <div className="mt-4" style={{ paddingTop: '30px' }}>
                                                    <h2 className="text-light fw-bold">Customized Plan</h2>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 py-2 free_plan_text mt-4">
                                                <div className="col-lg-12 py-1 list_item_gray_bg">
                                                    <p className="padding_list_item">Email Reminders</p>
                                                </div>
                                                <div className="col-lg-12 py-1">
                                                    <p className="padding_list_item">Ticket Support</p>
                                                </div>
                                                <div className="col-lg-12 py-1 list_item_gray_bg">
                                                    <p className="padding_list_item">Insights</p>
                                                </div>
                                                <div className="col-lg-12 py-1">
                                                    <p className="padding_list_item">
                                                        Multiple Service booking
                                                    </p>
                                                </div>
                                                <div className="col-lg-12 py-1 list_item_gray_bg">
                                                    <p className="padding_list_item">
                                                        Separate Team login
                                                    </p>
                                                </div>
                                                <div className="col-lg-12 py-1">
                                                    <p className="padding_list_item">Chat</p>
                                                </div>
                                                <div className="col-lg-12 py-1 list_item_gray_bg">
                                                    <p className="padding_list_item">Payment Gateway</p>
                                                </div>
                                                <p className="p-4 fw-bold">
                                                    If you want to use this features <br /> then contact us.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="mt-1">
                                            <label
                                                htmlFor="recipient-name"
                                                className="col-form-label"
                                            >
                                                Name *
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="recipient-name"
                                                name="name"
                                                value={customPlan.values.name}
                                                onChange={customPlan.handleChange}
                                            />
                                            <div style={{ height: '12px' }}><ValidationMessage formName={customPlan} name={"name"} /></div>
                                        </div>
                                        <div >
                                            <label htmlFor="recipient-name" className="col-form-label">Business Name *</label>
                                            <input type="text" className="form-control"
                                                id="recipient-name"
                                                name='providerName'
                                                onChange={customPlan.handleChange}
                                                value={customPlan.values.providerName}

                                            />
                                            <div style={{ height: '12px' }}>   <ValidationMessage
                                                formName={customPlan}
                                                name={"providerName"}
                                            />
                                            </div>
                                        </div>
                                        <div >
                                            <label htmlFor="recipient-name" className="col-form-label" >
                                                Email *
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="recipient-name"
                                                name="email"
                                                value={customPlan.values.email}
                                                onChange={customPlan.handleChange}
                                            />
                                            <div style={{ height: '12px' }}>    <ValidationMessage
                                                formName={customPlan}
                                                name={"email"}
                                            />
                                            </div>
                                        </div>
                                        <div >
                                            <label htmlFor="recipient-name" className="col-form-label" >
                                                Mobile Number *
                                            </label>
                                            {/* <input
                                                type="number"
                                                className="form-control"
                                                id="recipient-name"
                                                name="mobile"
                                                value={customPlan.values.mobile}
                                                onChange={customPlan.handleChange}
                                                onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength)
                                                        e.target.value = e.target.value.slice(
                                                            0,
                                                            e.target.maxLength
                                                        );
                                                }}
                                                maxLength={10}
                                            /> */}
                                            <NumberInput
                                                width={false}
                                                Require={false}
                                                Name="mobile"
                                                HandleChange={customPlan}
                                                Value={customPlan.values.mobile}
                                            />
                                            <div style={{ height: '12px' }}> <ValidationMessage formName={customPlan} name={"mobile"} />
                                            </div>
                                        </div>
                                        <div >
                                            <label className="col-form-label" >Country *</label>
                                            <select
                                                className="form-select form-control"
                                                aria-label="Default select example"
                                                name="country"
                                                onChange={(e) => { customPlan.setFieldValue('country', e.target.value) }}
                                                onBlur={customPlan.handleBlur}
                                                value={customPlan.values.country}
                                            >
                                                <option value='' hidden>Select Country</option>
                                                {country.map((item) => (
                                                    <option key={item._id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div style={{ height: '12px' }}> <ValidationMessage formName={customPlan} name={'country'} /></div>
                                        </div>
                                        <div >
                                            <label htmlFor="recipient-name" className="col-form-label">Description *</label>
                                            <textarea
                                                className="form-control"
                                                name="description"
                                                cols="20"
                                                rows="2"
                                                value={customPlan.values.description}
                                                onChange={customPlan.handleChange}
                                            ></textarea>
                                            <div style={{ height: '12px' }}> <ValidationMessage formName={customPlan} name={"description"} /></div>
                                        </div>
                                        <div className="d-flex justify-content-end mb-5 mt-3">
                                            <button className="btn btn_Style px-4" type="submit" onClick={customPlan.handleSubmit}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default PricingPage