import { useContext, useState } from 'react'
import { useFormik } from 'formik'
import { durationValidation } from '../../../Context/validation';
import { durationAdd, durationList, durationDelete, durationStatus, logOutApi } from '../../../Context/api';
import axios from 'axios';
import { roleNames, statusCodes } from '../../../Constants/generalConfig';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notifications } from '../../../App';


const UseServiceDuration = () => {
    const { userInfo} = useContext(Notifications)
    const [show, setShow] = useState(false)
    const [duration, setDuration] = useState([])
    const [durationData, setDurationData] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const userData = JSON.parse(localStorage.getItem('userData'))
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            listDuration()
        }
    }, [page])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        }).catch(()=>{})
    }
    const addDuration = useFormik({
        initialValues: {
            duration: ''
        },
        validationSchema: durationValidation,
        onSubmit: (values) => {
            const data={
                duration: values.duration,
                userId:  userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                branchId:userData?.locationId
            }
            setSpinner(true)
            axios.post(durationAdd(), {...data  }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setShow(false)
                    setSpinner(false)
                    listDuration()
                    addDuration.resetForm()

                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            })
        }
    })
    const listDuration = () => {
        const data={
            userId:  userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
             isDeleted: false,
              page: page, 
              count: PER_PAGE,
              branchId:userData?.locationId

        }
        axios.post(durationList(), {...data  }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setDuration(resp.data.data)
                setCount(resp.data.totalCount)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setDuration([])
            }
        })
    }
    const deleteDuration = () => {
        setSpinner(true)
        axios.post(durationDelete(), { durationId: durationData._id }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                listDuration()
                setSpinner(false)
                setDeleteShow(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
                toast.error(resp.data.message)
            }
        })
    }
    const statusChange = (item) => {
        axios.post(durationStatus(), { id: item._id, isActive: !item.isActive, userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                listDuration()
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    return {
        statusChange,
        deleteDuration,
        listDuration,
        setShow,
        setDuration,
        setDurationData,
        setSpinner,
        setCount,
        setPage,
        navigate,
        deleteShow, setDeleteShow,
        page,
        count,
        spinner,
        durationData,
        addDuration,
        show,
        duration,
        PER_PAGE,
        userData,
        userInfo

    }
}

export default UseServiceDuration