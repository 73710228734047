import React, { useState } from "react";
import ValidationMessage from "../ValidationMessage/validationMessage";
import DummyLogo from "../../Assets/images/dummyimg.png";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import NumberInput from "../../Components/PhoneInput/phoneInput";
import useMuiComponent from "../../Hooks/useMuiComponent";
import UseImage from "../../Hooks/useImage";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer } from "react-toastify";
import Spinner from "../../Components/spinner/spinner";
import UseAddTeam from "./Hooks/useAddTeam";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddTeam = () => {
  const {
    setImageData,
    navigate,
    staffExist,
    handlePermissionChange,
    setPermissionBtn,
    permissionBtn,
    permissions,
    addTeamMember,
    imageData,
    spinner,
    serviceData,
    branchList,
    date,
    exceptThisSymbols,
    timeZone,
    userNumExist,
    imgData,
    roleList,
    handleMarkAll,
    selectAll,
    setSelectAll,
    permissionsList,
    handleTogglePermissions
  } = UseAddTeam();
  const { CustomAutocomplete } = useMuiComponent();
  const { imageDeleteUpload, uploadImage } = UseImage();

  const [open, setOpen] = useState(false);
  const [ServiceOpen, setServiceOpen] = useState(false);
  return (
    <>
      <section>
        <ToastContainer />
        <div
          className="m-t-3 cus-padd cusheight"
          style={{ borderRadius: "15px" }}
        >
          {spinner ? <Spinner /> : null}
          <h2 className="page-tittle">
            <i
              className="fa-solid fa-angle-left cusgap cursor"
              onClick={() => navigate(-1)}
            ></i>
            Add Team
          </h2>
          <hr />

          <div className="text-center mb-4">
            <img
              src={imageData === "" ? DummyLogo : imgData + imageData}
              width={200}
              height={200}
              style={{ borderRadius: "50%" }}
              alt=""
            />
            <svg
              id="imageDelete"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginTop: "150px",
                marginLeft: "-55px",
                fill: "rgb(76 68 68)",
              }}
              width="40"
              height="35"
              className="bi bi-camera"
              viewBox="0 0 16 16"
            >
              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
            </svg>
            <ul className="dropdown-menu" aria-labelledby="imageDelete">
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  uploadImage(e.target.files[0], "PROFILE", setImageData)
                }
              />
              <Button
                variant="text"
                sx={{ color: "black", fontSize: "12px", marginLeft: "10px" }}
                onClick={() =>
                  document.getElementById("icon-button-file").click()
                }
                startIcon={<EditIcon />}
              >
                Edit
              </Button>

              <br />
              {imageData === "" ? null : (
                <Button
                  variant="text"
                  sx={{ color: "black", fontSize: "12px", marginLeft: "10px" }}
                  onClick={() =>
                    imageDeleteUpload(imageData, "PROFILE", setImageData)
                  }
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              )}
            </ul>
          </div>
          <form className="cus-form row" onSubmit={addTeamMember.handleSubmit}>
            <div className="col-sm-6 mb-2">
              <label className="form-label">Name * </label>
              <input
                type="text"
                className="form-control"
                pattern="[A-Za-z ]*"
                title="Please enter only alphabetic characters"
                name="name"
                value={addTeamMember.values.name}
                onChange={addTeamMember.handleChange}
                maxLength={30}
              />
              <ValidationMessage formName={addTeamMember} name={"name"} />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="form-label">
                E-mail Address{" "}
                {timeZone === "Asia/Calcutta" || timeZone === "Asia/Kolkata"
                  ? null
                  : "*"}
              </label>
              <input
                type="email"
                className="form-control"
                placeholder=""
                name="email"
                value={addTeamMember.values.email}
                onChange={addTeamMember.handleChange}
              />
              <ValidationMessage formName={addTeamMember} name={"email"} />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="form-label">DOB </label>
              <input
                type="date"
                className="form-control"
                placeholder=""
                name="dob"
                value={addTeamMember.values.dob}
                onChange={addTeamMember.handleChange}
                max={date}
              />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="form-label">Gender *</label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                name="gender"
                value={addTeamMember.values.gender}
                onChange={addTeamMember.handleChange}
              >
                <option value="" disabled hidden>
                  Select Gender
                </option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
              </select>
              <ValidationMessage formName={addTeamMember} name={"gender"} />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="form-label">Mobile Number * </label>
              <NumberInput
                width={false}
                Require={false}
                Name="mobileNo"
                HandleChange={addTeamMember}
                Value={addTeamMember.values.mobileNo}
                extraFun={staffExist}
              />
              <ValidationMessage formName={addTeamMember} name={"mobileNo"} />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="form-label">Role Title * </label>
              <select className="form-select form-control" name="role" onChange={(e) => { addTeamMember.setFieldValue('role', e.target.value); permissionsList(e.target.value); setSelectAll(true) }}
                value={addTeamMember.values.role}>
                <option value="" hidden>
                  Select Role
                </option>
                {
                  roleList?.map((item, index) =>
                    <option value={item._id} key={index}>{item.title === 'Staff' ? 'Team' : item.title}</option>
                  )
                }
              </select>
              <ValidationMessage formName={addTeamMember} name={"role"} />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="form-label">Assign Services *</label>
              <CustomAutocomplete
                multiple
                className="w-100"
                id="checkboxes-tags-demo"
                options={serviceData}
                onChange={(event, newValue) => {
                  addTeamMember.setFieldValue("services", newValue);
                }}
                open={ServiceOpen}
                onOpen={()=>setServiceOpen(true)}
                onClose={()=>setServiceOpen(false)}
     
                groupBy={(option) => option?.categoryName}
                getOptionLabel={(option) => option?.serviceName}
                getOptionSelected={(option, value) =>
                  option.serviceId === value.serviceId
                }
                name="services"
                value={addTeamMember.values.services}
                size="small"
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.serviceName}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=" "
                    style={{ color: "red" }}
                    InputLabelProps={{ shrink: false }}
                  />
                )}
                popupIcon={
                  <IoIosArrowDown
                    style={{
                      fontSize: "17px",
                      fontWeight: "800",
                      color: "#000",
                    }}
                  />
                } // Custom dropdown arrow icon
                disableClearable
              />
            </div>
            <div className=" col-sm-6">
              <label className="form-label">Branch * </label>
              <CustomAutocomplete
                multiple
                className="w-100"
                id="checkboxes-tags-demo"
                options={branchList}
                onChange={(event, newValue) => {
                  addTeamMember.setFieldValue("branch", newValue);
                }}
                open={open}
                onOpen={()=>setOpen(true)}
                onClose={()=>setOpen(false)}
                getOptionLabel={(option) => option?.name}
                name="branch"
                value={addTeamMember.values.branch}
                size="small"
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=" "
                    style={{ color: "red" }}
                    InputLabelProps={{ shrink: false }}
                  />
                )}
                popupIcon={
                  <IoIosArrowDown
                    style={{
                      fontSize: "17px",
                      fontWeight: "800",
                      color: "#000",
                    }}
                  />
                } // Custom dropdown arrow icon
                disableClearable
              />
            </div>
            <div className="col-sm-6">
              {timeZone === "Asia/Calcutta" || timeZone === "Asia/Kolkata" ? (
                !userNumExist ? (
                  <div>
                    <label className="form-label">PIN * </label>
                    <input
                      autoComplete={false}
                      type="number"
                      className="form-control"
                      name="pin"
                      value={addTeamMember.values.pin}
                      onChange={addTeamMember.handleChange}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      min={0}
                      maxLength={4}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                ) : null
              ) : null}
            </div>
            <div className="mb-2">
              <p className="font12">
                <span className="star_clr">*</span> Note : You must have
                <span className="cursor navi_clr ms-1 me-1" onClick={() => navigate("/layout/service")}  >
                  Services
                </span>
                &
                <span
                  className="cursor navi_clr ms-1 me-1"
                  onClick={() => navigate("/layout/profile")}
                >
                  Branch
                </span>
                to add a team member.
              </p>
            </div>
            <div className="ex2">
              {
                permissionBtn ?
                  <Button size='small' id="togglePermissions" disabled={addTeamMember.values.role ? false : true} style={{ backgroundColor: '#f47d3a' }} variant="contained" onClick={() => { setPermissionBtn(false); handleTogglePermissions() }} endIcon={<ArrowUpwardIcon />}>
                    Permissions
                  </Button>
                  :
                  <Button size='small' id="togglePermissions" disabled={addTeamMember.values.role ? false : true} style={{ backgroundColor: '#f47d3a' }} variant="contained" onClick={() => { setPermissionBtn(true); handleTogglePermissions() }} endIcon={<ArrowDownwardIcon />}>
                    Permissions
                  </Button>
              }
              <input type="checkbox" name="rwo" id="item-2" style={{ display: 'none' }} />
              <div className="inner">
                <div className="hide2">
                  <div className="row my-3">
                    <div className="col-sm-12 d-flex justify-content-end">
                      {
                        selectAll ?
                          <Button size='small' id="togglePermissions" onClick={() => { handleMarkAll(true); setSelectAll(false) }} style={{ color: 'black' }} variant="text">
                            Mark All
                          </Button>
                          :
                          <Button size='small' id="togglePermissions" onClick={() => { handleMarkAll(false); setSelectAll(true) }} style={{ color: 'black' }} variant="text">
                            Unmark All
                          </Button>
                      }
                    </div>
                    {permissions?.map((category) => {
                      return (
                        <span className={"col-3 mt-2"} key={category.title}>
                          <h5>{category.title}</h5>
                          <FormGroup>
                            {category?.permissionData?.map((key, index) => (
                              <React.Fragment key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={key.data.value}
                                      onChange={() =>
                                        handlePermissionChange(category.title, key.title)
                                      }
                                    />

                                  }
                                  label={key.data.title}
                                />
                              </React.Fragment>
                            ))}
                          </FormGroup>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-grid  col-12  mb-3 justify-content-end">
              <button type="submit" className="btn colorbtun  px">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddTeam;
