import React from 'react'
import { ToastContainer } from 'react-toastify'
import DummyLogo from '../../Assets/images/dummyimg.png'
import ValidationMessage from '../ValidationMessage/validationMessage'
import Spinner from '../../Components/spinner/spinner'
import ServiceImg from '../../Assets/images/services-icon.png';
import UseTeamProfile from './Hooks/useTeamProfile'
import moment from 'moment/moment'
import UseImage from '../../Hooks/useImage'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material'
import NumberInput from '../../Components/PhoneInput/phoneInput'
const TeamProfile = () => {
    const {
        setUpdate,
        getBasicProfileDetails,
        setFile,
        basicProfileForm,
        file,
        update,
        currencyName,
        staffbranch,
        spinner,
        service,
        imgData,
        imgPath,
        timeZone,
        userInfo,
        fileInputRef } = UseTeamProfile()
    const { imageDelete, uploadImage } = UseImage()
    return (
        <>
            <ToastContainer />
            {spinner && <Spinner />}
            <section>
                <div className="cus-padd cusheight m-t-3">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className='d-flex justify-content-between my-4'>
                                <div></div>
                                <h5 className='d-flex justify-content-center'>My Profile</h5>
                                <p className='d-flex justify-content-end mb-1' onClick={() => setUpdate(false)}><i className="fa-regular fa-pen-to-square cursor"></i></p>
                            </div>
                            <div className="text-center mb-0">
                                <img src={file === '' ? DummyLogo : imgData + file} width={150} height={150} style={{ borderRadius: '50%' }} alt="" />
                                {
                                    update ? null
                                        :
                                        <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '115px', marginLeft: '-40px', fill: 'rgb(76 68 68)' }} width="40" height="35" class="bi bi-camera" viewBox="0 0 16 16">
                                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                        </svg>
                                }
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <input ref={fileInputRef}
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => uploadImage(e.target.files[0], 'PROFILE', setFile, fileInputRef)}
                                    />
                                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>
                                    <br />

                                    {file === '' ? null : (
                                        <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(userInfo?.userId, file, 'PROFILE', getBasicProfileDetails)} startIcon={<DeleteIcon />}>Delete</Button>

                                    )}
                                </ul>
                            </div>
                            <form className="row g-3 cus-form" onSubmit={basicProfileForm.handleSubmit}>
                                <div className="col-md-12">
                                    <div className="inputwithicon">
                                        <label className="form-label">Name *</label>
                                        <input type="text" className="form-control" disabled={update} name="name" onChange={(e) => { basicProfileForm.setFieldValue('name', e.target.value) }} onBlur={basicProfileForm.handleBlur} value={basicProfileForm.values.name} maxLength={30} />
                                        <ValidationMessage formName={basicProfileForm} name={'name'} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="inputwithicon">
                                        <label className="form-label">E-mail Address {timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? null : '*'}</label>
                                        <input type="text" disabled className="form-control" name="email" onChange={basicProfileForm.handleChange} onBlur={basicProfileForm.handleBlur} value={basicProfileForm.values.email} />
                                        <ValidationMessage formName={basicProfileForm} name={'email'} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="inputwithicon">
                                        <label className="form-label">Mobile Number *</label>
                                        <NumberInput
                                            width={false}
                                            Require={false}
                                            Name="mobileNo"
                                            HandleChange={basicProfileForm}
                                            Value={basicProfileForm.values.mobileNo}
                                            disabledInput={true}
                                        />
                                        <ValidationMessage formName={basicProfileForm} name={'mobileNo'} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="inputwithicon">
                                        <label className="form-label">DOB</label>
                                        <input
                                            disabled={update}
                                            type="date"
                                            className="form-control"
                                            name="DOB"
                                            onChange={basicProfileForm.handleChange}
                                            onBlur={basicProfileForm.handleBlur}
                                            value={moment(basicProfileForm.values.DOB).format('YYYY-MM-DD')}
                                        />
                                    </div>
                                </div>
                                {
                                    !update ?
                                        <div className="d-grid  col-12  mb-3 justify-content-end">
                                            <button className="btn btn_Style" type="submit">
                                                Update
                                            </button>
                                        </div>
                                        : null
                                }
                            </form>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 mt-4 mb-2 div_border" >
                            <h5 className='d-flex justify-content-center'>My Work Stations</h5>
                            <div className='mt-2 wk_Station'>
                                {
                                    staffbranch.map((item) =>
                                        <div className='my_station mt-3'>
                                            <span className='fontSize14'>{item.name}</span>
                                            <br />
                                            <span className='fontSize14'>{item.street} {item.city ? `(${item.city})` : null}</span>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="vlTeam"></div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 mt-4 mb-2 div_border">
                            <h5 className='d-flex justify-content-center'>My Services</h5>
                            <div className="vlTeam"></div>
                            <div className='wk_Station'>
                                {
                                    service.map((item) =>
                                        <div className='my_station mt-3'>
                                            <div className='d-flex justify-content-between'>
                                                <div className="">
                                                    <img className='ms-1' src={item.image === '' ? ServiceImg : imgPath + item.image} alt="image" height={40} width={40} style={{ borderRadius: '50%' }} />
                                                </div>
                                                <div className='mt-2'>
                                                    <span className='fontSize14'>{item.name}</span>
                                                </div>
                                                <div className='fontSize14'>
                                                    {item.duration} (Min.) <br />
                                                    <span>{currencyName} {item.amount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamProfile