import { useState, useEffect, useContext } from 'react';
import { updatePdfAPI, uploadPoliciesApi, getPoliciesApi, pdf, deletePdfApi, logOutApi } from '../../../Context/api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig';
import { useNavigate } from 'react-router-dom';
import { Notifications } from '../../../App';

const border = {
    textAlign: 'center',
    padding: '3%',
    borderStyle: 'dotted',
    borderRadius: '15px'
}

const UseRefundPolicy = () => {
    const { userInfo } = useContext(Notifications)
    const [policiesData, setPoliciesData] = useState();
    const [showPolicy, setShowPolicy] = useState(true)
    const [pdfdData, setPdfData] = useState();
    const [spinner, setSpinner] = useState(false)
    const [showDelete,setShowDelete]=useState(false)

    const userData = JSON.parse(localStorage.getItem('userData'))
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getPolicies()
            
        }
    }, [])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const uploadPdf = (e) => {
        const fd = new FormData();
        const fileTypes = { pdf_File_Type: ['application/pdf'] };
        if (e.size / 1024 / 1024 > 10)
            return toast.warn("Please upload file less than 10 mb");
        if (!e.type.includes(fileTypes.pdf_File_Type))
            return toast.warn("Only PDF format allowed");
        fd.append("file", e);
        fd.append("type", 'PDF');
        axios.post(uploadPoliciesApi(), fd, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setPoliciesData(resp.data.data.db_name)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        });
    }
    const refundPolicy = async () => {
        let data = { loggedInUserId: userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId, pdfName: policiesData, policyType: "REFUND_POLICY", branchId:userData?.locationId }
        await updatePdfAPI(data)
        getPolicies()
    }
    const getPolicies = () => {
        const data={ 
            // loggedInUserId: userData?.userId
            loggedInUserId:userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId,
            branchId:userData?.locationId
    
         }
         if(userData?.roleTitle!==roleNames.SERVICE_PROVIDER){
            data['createdbyId']=userData?.createdbyId
         }
         setSpinner(true)
        axios.post(getPoliciesApi(),data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setShowPolicy(false)
                setSpinner(false)
                if (resp.data.data.refund === '') setShowPolicy(true)
                setPdfData(resp.data.data.refund)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            // else if (resp.data.code === statusCodes.notAuthorized) {
            //     logOutFun()
            // }
            else {
                setSpinner(false)
            }
        })
        .catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const deletePdf = () => {
        let data = { loggedInUserId:userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId, policyType: "REFUND_POLICY", pdfName: policiesData,branchId:userData?.locationId }
        deletePdfApi(data)
        setShowDelete(false)
        setShowPolicy(true)
        setPoliciesData('')
    }
    return {
        uploadPdf,
        refundPolicy,
        deletePdf,
        setPoliciesData,
        setShowPolicy,
        setPdfData,
        setSpinner,
        navigate,
        setShowDelete,
        showDelete,
        spinner,
        pdfdData,
        showPolicy,
        policiesData,
        pdf,
        border,
        userData,
        userInfo
    }
}

export default UseRefundPolicy