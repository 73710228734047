import { useContext, useState } from 'react'
import { getNotificationApi, logOutApi, updateNotificationApi } from '../../../Context/api'
import { toast } from 'react-toastify'
import axios from 'axios'
import { statusCodes, commonMessage } from '../../../Constants/generalConfig'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App';

const UseSettingNotification = () => {
  const { userInfo} = useContext(Notifications)
    const [notificationData, setNotificationData] = useState()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const navigate = useNavigate()

    useEffect(() => {
      if (userData == null) {
        navigate('/')
      }
      else {
        getNotification()
      }
    }, [])
  
    const logOutFun = () => {
      axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          localStorage.removeItem('userData')
          navigate('/')
        }
      })
    }
    const getNotification = () => {
      axios
        .post(getNotificationApi(), { loggedInUserId: userData?.userId,branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
        .then((resp) => {
          if (resp.data.code === statusCodes.ok) {
            setNotificationData(resp.data.data)
          }
          else if (resp.data.code === statusCodes.tokenExpire) {
            logOutFun()
          }
          else if(resp.data.code===statusCodes.notAuthorized){
            logOutFun()
          }
          else {
            toast.error(resp.data.message)
          }
        })
        .catch(() => {
          toast.error(commonMessage.networkError)
        })
    }
    const appointmentEmail = (data) => {
      axios.post(updateNotificationApi(), { ...data, branchId: userData?.locationId}, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          getNotification()
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          toast.error(resp.data.message)
        }
      })
    }
  return {
    appointmentEmail,
    setNotificationData,
    navigate,
    notificationData, 
    userData,
    userInfo
  }
}

export default UseSettingNotification