import React from 'react';
import Download from '../../../Assets/images/download.png';
import { ToastContainer } from 'react-toastify';
import Spinner from '../../../Components/spinner/spinner';
import UseReschedulingPolicy from '../Hooks/useReschedulingPolicy';
import NoRecord from '../../../Components/noRecord';
import { intialRole } from '../../../Utils/Utils';
import { Modal } from 'react-bootstrap';


const ReschedulingPolicy = () => {
  const { updatePolicy,
    deletePdf,
    reschedulingPolicy,
    uploadPdf,
    setShow,
    setHours,
    navigate,
    showDelete,
    setShowDelete,
    hours,
    show,
    pdfdData,
    spinner,
    showPolicy,
    policiesData,
    pdf,
    userData,
    border,
    userInfo
  } = UseReschedulingPolicy()

  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd cusheight">
          {spinner ? <Spinner /> : null}
          <header>
            <h2 className='page-tittle'><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Rescheduling Policy</h2>
            <hr />
          </header>

          {

            // userData?.roleTitle === "SERVICE_PROVIDER" ?
              <>
              {
                 intialRole({ permission: "Policy", userData: userInfo, section: 'add' })?
                 <>
              
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <h6>Allow Client to reschedule appointment?</h6>
                    {show ? <>
                      <h6>Allow rescheduling before
                        <select className='ms-1' value={hours} onChange={(e) => setHours(e.target.value)}>
                          <option hidden>Select hours</option>
                          <option value={24}>24 Hours</option>
                          <option value={48}>48 Hours</option>
                          <option value={72}>72 Hours</option>
                        </select>  of fixed appointment day</h6>
                    </>
                      : null
                    }
                  </div>
                  <div className="form-check form-switch" style={{ marginLeft: '10%' }}>
                    <input className="form-check-input" role='switch' type="checkbox" onChange={(e) => setShow(e.target.checked)} checked={show ? true : false} />
                  </div>
                </div>
                <div className='mb-5 mt-2'>
                  <button className='btn btn-primary btn-sm float-end' onClick={() => updatePolicy()} style={{ cursor: 'pointer', background: '#f47d3a', border: '1px solid #f47d3a' }}>Save</button>
                </div>
                <hr />
                </>
                :null
                }


                {showPolicy ?

                  (policiesData ?
                    <>
                      <div className='mt-2'>
                        <iframe src={pdf + policiesData} title={pdf + policiesData} className='pdf_hw' />
                      </div>
                      <label htmlFor="inputTag" style={{ cursor: 'pointer' }}>
                        <input id="inputTag" type="file" style={{ display: 'none' }} onChange={(e) => { uploadPdf(e.target.files[0]); }} /><br />
                        Upload Policy Again <i class="fa-solid fa-arrow-right"></i>
                        <br />
                      </label>
                    </>
                    :
                    intialRole({ permission: "Policy", userData: userInfo, section: 'add' })?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh'}}>
                      <div>
                        <div className="mb-3" style={border}>
                          <label htmlFor="inputTag" style={{ cursor: 'pointer' }}>
                            <img src={Download} alt="Upload Policy" />
                            <input id="inputTag" type="file" style={{ display: 'none' }} onChange={(e) => uploadPdf(e.target.files[0])} /><br />
                            Upload Policies Here
                            <br />
                            <span id="imageName" style={{ color: 'green' }}></span>
                          </label>
                        </div>
                        <p>Upload only PDF file and the size should be upto 10 mb</p>
                      </div>
                    </div>
                    :<div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    )
                  :
                  <div >
                    <iframe src={pdf + pdfdData} title={pdf + pdfdData} className='pdf_hw' />
                  </div>
                }
                <div className="d-grid  col-12  mb-3 justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                  {policiesData || pdfdData ? (showPolicy ? <button className="btn colorbtun sidebtn px" onClick={() => reschedulingPolicy()}>Save</button>
                    :intialRole({ permission: "Policy", userData: userInfo, section: 'delete' })?
                     <button className="btn colorbtun sidebtn px" onClick={()=>setShowDelete(true)}>Delete</button>:null)
                    : null}
                </div>
              </>
              // :
              // !showPolicy ? <iframe src={pdf + pdfdData} title={pdf + pdfdData} height={500} width={1000} /> :
              // <NoRecord/>
          }
        </div>

        {/* Delete Pdf Start */}
        <Modal centered size='sm' show={showDelete} onHide={() => setShowDelete(false)}>
            <div className="modal-content">
              <div className="modal-body text-center">
                <p>Are You Sure !</p>
                <p>You want to delete this policy</p>
                <button type="button" className="btn nobtn"  onClick={() => deletePdf()}>
                  Yes
                </button>
                <button type="button" className="btn yesbtn" onClick={()=>setShowDelete(false)}>
                  No
                </button>
              </div>
            </div>
        </Modal>
        {/* Delete Pdf End */}
      </section>
    </>
  )
}

export default ReschedulingPolicy;