import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import CardChip from '../../../Assets/images/cardChip.png';
import { useFormik } from 'formik';
import ValidationMessage from '../../ValidationMessage/validationMessage';
import { addCardValidation } from '../../../Context/validation';
import { createCard, cardList, cardDelete, makeCardDefault } from '../../../Context/api';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from '../../../Components/spinner/spinner'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../../Constants/generalConfig'
import NoRecord from '../../../Components/noRecord'

const MyCard = () => {
    const [spinner, setSpinner] = useState(false)
    const [show, setShow] = useState(false)
    const [cardData, setCardData] = useState([])
    const navigate = useNavigate()
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const userData = JSON.parse(localStorage.getItem('userData'))

    const handleInputChange = (e) => {
        const value = e.target.value.replace(/\s/g, ''); // Remove existing spaces
        const formattedValue = value.replace(/(.{4})/g, '$1 ').trim(); // Add space after every 4 characters
        addCard.setFieldValue('cardNumber', formattedValue)
    };


    useEffect(() => {
        list()
    }, [])

    const addCard = useFormik({
        initialValues: {
            cardHolderName: '',
            cardNumber: '',
            expMonth: '',
            expYear: '',
            cvv: '',
        },
        validationSchema: addCardValidation,
        onSubmit: (values) => {
            const data = {
                number: values.cardNumber.replace(/ /g, ''),
                exp_year: values.expYear,
                exp_month: values.expMonth,
                cardHolderName: values.cardHolderName.trim(),
                cvc: values.cvv,
                userId: userData?.userId,
            }
            setSpinner(true)
            axios.post(createCard(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setSpinner(false)
                    toast.success(resp.data.message)
                    setShow(false)
                    addCard.resetForm()
                    list()
                } else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        }
    })

    const list = () => {
        setSpinner(true)
        axios.post(cardList(), { userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setCardData(resp.data.data)
                setSpinner(false)

            } else {
                setSpinner(false)
                setCardData([])
                toast.error(resp.data.message)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const deleteCard = (token) => {
        const data = {
            token: token,
            userId: userData?.userId
        }
        setSpinner(true)
        axios.post(cardDelete(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false)
                toast.success(resp.data.message)
                list()
            } else {
                setSpinner(false)
                toast.error(resp.data.message)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const cardChange = (token) => {
        const data = {
            token: token,
            userId: userData?.userId
        }
        setSpinner(true)
        axios.post(makeCardDefault(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false)
                list()
            } else {
                setSpinner(false)
                toast.error(resp.data.message)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }


    // console.log(addCard.values.cardNumber.replace(/ /g, ''),'llllll')
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className="page-tittle"><i className="fa-solid fa-angle-left cusgap cursor mb-1" onClick={() => navigate(-1)}></i>List</h2>
                        <hr />
                    </header>
                    <div className="row">
                        <div className="col-sm-12 listpadding">
                            <div className='row'>
                                {
                                    cardData?.map((item, index) =>
                                        <div className="height-100 d-flex col-sm-4">
                                            <div className="cards p-3">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <img src={CardChip} width="50" />
                                                    <h2>{item.brand.toUpperCase()}</h2>
                                                </div>
                                                <div className="px-2 number mt-3 d-flex justify-content-center">
                                                    <span>**** **** **** {item.last4}</span>
                                                </div>
                                                <div className="p-4 card-border mt-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span className="cardholder">Card Holder</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span className="name">{item.name}</span>
                                                        <div className='date d-flex'>
                                                            <div className="form-check form-switch me-2">
                                                                <input className="form-check-input" checked={item.isDefault} onChange={() => cardChange(item.token)} type="checkbox" id="flexSwitchCheck" />
                                                            </div>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                <i className="fa-solid fa-trash-can cursor" onClick={() => deleteCard(item.token)}></i>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Modal size="md" centered show={show} onHide={() => setShow(false)}>
                        <div className="p-4">
                            <div className='d-flex justify-content-between '>
                                <h6>Add New Card</h6>
                                <h6 className="cursor" onClick={() => { setShow(false); addCard.resetForm() }}><i className="fa-solid fa-x"></i></h6>
                            </div>
                            <div className="container-fluid p-3 my-3">
                                <div className="height-100 d-flex justify-content-center align-items-center">
                                    <div className="cards p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <img src={CardChip} width="50" />
                                            {/* <h1>VISA</h1> */}
                                        </div>
                                        <div className="px-2 number mt-3 d-flex justify-content-center">
                                            <span>{addCard.values.cardNumber}</span>
                                        </div>
                                        <div className="p-4 card-border mt-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="cardholder">Card Holder</span>
                                                <span className="expires">Expires</span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="name">{addCard.values.cardHolderName}</span>
                                                <span className="date">{addCard.values.expMonth}{addCard?.values?.expMonth ? '/' : null}{addCard.values.expYear}</span>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={addCard.handleSubmit}>
                                <div className="row">
                                    <div className="mb-2">
                                        <label className="form-label">Card Holder Name * </label>
                                        <input type="text" className="form-control" name="cardHolderName" value={addCard.values.cardHolderName} onChange={addCard.handleChange} />
                                        <ValidationMessage formName={addCard} name={'cardHolderName'} />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Card Number * </label>
                                        <input type="text" maxLength={19} min={1} className="form-control"
                                            name="cardNumber" value={addCard.values.cardNumber} onChange={handleInputChange}
                                            onKeyPress={(e) => {
                                                // Allow only numeric characters
                                                if (isNaN(String.fromCharCode(e.charCode))) {
                                                  e.preventDefault();
                                                }
                                              }}/>
                                        <ValidationMessage formName={addCard} name={'cardNumber'} />
                                    </div>
                                    <div className="mb-2 col-sm-6">
                                        <label className="form-label">Expiry Month / Year * </label>
                                        <div className='d-flex'>
                                            <div className="col-sm-6">
                                                <input type="number" min={1} maxLength={2} onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()} className="form-control" name="expMonth"
                                                    value={addCard.values.expMonth} onChange={addCard.handleChange}
                                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                                                <ValidationMessage formName={addCard} name={'expMonth'} />
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="number" min={1} maxLength={2} onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()} className="form-control ms-2" name="expYear"
                                                    value={addCard.values.expYear} onChange={addCard.handleChange}
                                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                                                <ValidationMessage formName={addCard} name={'expYear'} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2 col-sm-6">
                                        <label className="form-label">CVV * </label>
                                        <input type="number" min={1} maxLength={4} onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()} className="form-control" name="cvv"
                                            value={addCard.values.cvv} onChange={addCard.handleChange} onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                                        <ValidationMessage formName={addCard} name={'cvv'} />
                                    </div>
                                    <div className='d-flex justify-content-center mt-5 mb-2'>
                                        <button type="button" className="btn  me-2 nobtn" data-bs-dismiss="modal" onClick={() => { setShow(false); addCard.resetForm() }}>Cancel</button>
                                        <button type="submit" className="btn  yesbtn" data-bs-dismiss="modal">Add</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </Modal>
                    <div className="d-flex justify-content-end">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Card</Tooltip>}>
                            <button onClick={() => setShow(true)} className="btn addbtn2" data-bs-toggle="offcanvas" data-bs-target="#setavail" aria-controls="setavail"
                            >
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyCard