import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../../Assets/images/profile.png";
import DummyLogo from "../../Assets/images/dummyimg.png";
import axios from "axios";
import {
  branchListApi,
  totalBookingRevenue,
  clients,
  imgData,
  currency,
  logOutApi,
  clientsExport,
  clientListApi,
  serviceTakenByClient,
} from "../../Context/api";
import { commonMessage, roleNames, statusCodes } from "../../Constants/generalConfig";
import moment from "moment/moment";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../Components/spinner/spinner"; import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';

const ClientReport = () => {
  const { userInfo } = useContext(Notifications)
  const [branchList, setBranchList] = useState([])
  const [bookingRevenueData, setBookingRevenueData] = useState([])
  const [topClientsData, setTopClientsData] = useState([])
  const [currencyName, setCurrencyName] = useState([])
  const [checked, setChecked] = useState('')
  const [spinner, setSpinner] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [state, setState] = useState({
    branch: userData?.locationId,
    days: "LAST7",
  });
  const [clientId, setClientId] = useState('All')
  const [data, setData] = useState('')
  const [clientList, setClientList] = useState([])
  const [serviceGivenData, setServiceGivenData] = useState([])
  const navigate = useNavigate()


  useEffect(() => {
    if (userData == null) {
      navigate("/");
    } else {
      getBranchList();
      currencyList();
      getClientList();
    }
  }, []);

  useEffect(() => {
    bookingRevenue()
    topClients()
  }, [clientId, state.days, state.branch])



  const logOutFun = () => {
    axios
      .post(
        logOutApi(),
        {
          deviceId: userData?.deviceId,
          userId: userData?.userId,
          type: "WEBPROVIDER",
        },
        { headers: { Authorization: `Bearer ${userData?.token}` } }
      )
      .then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          localStorage.removeItem("userData");
          navigate("/");
        }
      });
  };
  const currencyList = () => {
    axios.post(currency(), { loggedInUserId: userData?.userId }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCurrencyName(resp.data.data.symbol)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
    }).catch(() => { })
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const getBranchList = () => {
    axios
      .post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
      .then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setBranchList(resp.data.data);
        } else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun();
        } else {
          setBranchList([]);
        }
      }).catch(() => { })
  }
  const bookingRevenue = () => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD'),
      clientId: clientId
    }
    setSpinner(true)
    axios.post(totalBookingRevenue(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        resp.data.data?.map((item) => {
          setBookingRevenueData(item)
          setSpinner(false)
        })
        if (resp.data.data.length === 0) {
          setBookingRevenueData([])
          setSpinner(false)
        }
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setBookingRevenueData([])
        setSpinner(false)
      }
    }).catch(() => {
      setSpinner(false)
      toast.error(commonMessage.networkError)
    })
  }
  const topClients = () => {
    const data = {
      sortBy: checked,
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD'),
      clientId: clientId
    }
    setData(data)
    axios.post(clients(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setTopClientsData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {

      }
    }).catch(() => { })
  }
  const topClientsBranchSortBy = (e) => {
    const data = {
      sortBy: e,
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD'),
      clientId: clientId
    }
    axios.post(clients(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setTopClientsData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setTopClientsData([])
      }
    }).catch(() => { })
  }
  const exportClient = () => {
    delete data.sortBy
    setSpinner(true)
    axios.post(clientsExport(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        window.open(resp.data.data)
        setSpinner(false)
      }
      else {
        setSpinner(false)
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }
  const getClientList = () => {
    const data = {
      userId: userData?.userId,
      isActive: true,
      isAccepted: "accepted",
      page: 1,
      count: 100,
      // isProviderClient: true
    }
    if (userData?.roleTitle == roleNames.STAFF) {
      delete data.userId
      data['providerId'] = userData?.createdbyId
    }
    axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setClientList(resp.data.data)
      }
      else {
        setClientList([])
      }
    }).catch(() => { })
  }
  const serviceProvide = (id) => {
    const data = {
      clientId: id,
      today: moment().format("YYYY-MM-DD"),
      days: state.days,
      branchId: state.branch,
    };
    if (!state.branch) {
      delete data.branchId;
    }
    axios.post(serviceTakenByClient(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setServiceGivenData(resp.data.data)
      }
      else {
        setServiceGivenData([])
      }
    }).catch(() => { })
  }
  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className="page-tittle">
              {" "}
              <i
                className="fa-solid fa-angle-left cusgap cursor"
                onClick={() => navigate(-1)}
              ></i>
              Client Reports
            </h2>
            <hr />
          </header>
          {
            intialRole({ permission: "insights", userData: userInfo, section: 'viewClientReport' }) ?
              <>
                <div className="d-flex justify-content-between listpadding">
                  <div className="dropdown">
                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example"
                      name="days" value={state.days}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <option hidden>Select days</option>
                      <option value="TODAY">Today</option>
                      <option value="LAST7">Last 7 days</option>
                      <option value="LAST_MONTH">Last Month</option>
                      <option value="THIS_MONTH">This Month</option>
                    </select>
                  </div>
                  <div className="dropdown">
                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example"
                      name="branch" value={state.branch} disabled
                      onChange={(e) => {
                        handleInputChange(e)
                      }}>
                      <option hidden>All</option>
                      <option value="">All</option>
                      {branchList?.map((item) => (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-6">
                    <div className="d-flex justify-content-sm-center justify-content-md-center justify-content-lg-end mb-4">
                      <div className="card1">
                        <h2>
                          {bookingRevenueData.length === 0
                            ? 0
                            : bookingRevenueData.totalBooking}
                        </h2>
                        <p>Total Bookings</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <div className="card1">
                        <h2>
                          {currencyName}
                          {bookingRevenueData.length === 0
                            ? 0
                            : bookingRevenueData.totalRevenue}
                        </h2>
                        <p>Revenue Earned</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 ">

                  <div className="d-flex justify-content-between ">
                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }}
                      onChange={(e) => setClientId(e.target.value)}>
                      <option hidden>All</option>
                      <option value="All">All</option>
                      {clientList?.map((item) => (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <div className='d-flex'>
                      <button className='btn me-2' style={{ background: '#FDF6F3', color: 'black' }} onClick={() => exportClient()}>Export</button>
                      <div className="dropdown">
                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: '#FDF6F3' }}>
                          Sort by
                        </button>
                        <ul className="dropdown-menu">
                          <li><h6 className="dropdown-item">
                            <input className="form-check-input" type="radio" name="booking" id="flexRadioDefault1"
                              onClick={() => setChecked('BOOKING')}
                              onChange={() => { topClientsBranchSortBy('BOOKING') }}
                              checked={checked === 'BOOKING'}
                            />
                            <label className="form-check-label ms-2" htmlFor="flexRadioDefault1">
                              Bookings
                            </label>
                          </h6></li>
                          <li><h6 className="dropdown-item">
                            <input className="form-check-input" type="radio" name="revenue" id="flexRadioDefault1"
                              onClick={() => setChecked('REVENUE')}
                              onChange={() => { topClientsBranchSortBy('REVENUE') }}
                              checked={checked === 'REVENUE'}
                            />
                            <label className="form-check-label ms-2" htmlFor="flexRadioDefault1">
                              Revenue Earned
                            </label>
                          </h6></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='mt-3 client-scroll'>
                    <div className="table-responsive">
                      <table className="table cus-table">

                        <thead className="text-center tableheader">
                          <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Booking</th>
                            <th scope="col">Visited Time</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            topClientsData.length ?
                              <>
                                {
                                  topClientsData?.map((item, i) =>
                                    <React.Fragment key={i}>
                                      <tr data-bs-toggle="offcanvas" data-bs-target="#client" aria-controls="offcanvasRight" key={item._id} onClick={() => serviceProvide(item._id)}>
                                        <td><img alt='img' src={item.userImage === '' ? Profile : imgData + item.userImage} height={50} width={50} style={{ borderRadius: '50%' }} /></td>
                                        <td><b>{item.userName}</b></td>
                                        <td>  <div className="progress mb-2">
                                          <div className="progress-bar bar1 text-dark" role="progressbar" style={{ width: `${item.acceptedBookingPer}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{item.acceptedBookingPer}%</div>
                                        </div></td>
                                        <td>{item.visitTime}</td>
                                        <td>{currencyName}{item.totalRevenue}</td>
                                      </tr>
                                    </React.Fragment>)
                                }
                              </>
                              :
                              // <div className='position-absolute top-20 start-50 mt-5'><h3>No record found</h3></div>
                              <tr>
                                <td colSpan="5" className="text-center">No record found</td>
                              </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </> : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>

      </section>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="client"
        aria-labelledby="offcanvasRightLabel"
      >
        <div style={{ padding: "10px" }}>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setServiceGivenData([])}
          ></button>
          <h1
            className="popupheader text-center heading-color "
            id="offcanvasRightLabel"
          >
            Client Report
          </h1>
        </div>
        <div className="offcanvas-body">
          <div className="service-scroll">
            <table className="table cus-table">
              <thead className="text-center tableheader">
                <tr>
                  <th scope="col">Service</th>
                  <th scope="col">Price</th>
                  <th scope="col">Visited Time</th>
                </tr>
              </thead>
              {serviceGivenData?.map((item, i) => (
                <React.Fragment key={i}>
                  <tbody>
                    <tr>
                      <td>{item.name}</td>
                      <td>
                        {currencyName}
                        {item.amount}
                      </td>
                      <td>{item.count}</td>
                    </tr>
                  </tbody>
                </React.Fragment>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientReport;
