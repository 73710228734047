import React from 'react'
import Download from '../../../Assets/images/download.png'
import { ToastContainer } from 'react-toastify'
import Spinner from '../../../Components/spinner/spinner'
import UseBookingPolicy from '../Hooks/useBookingPolicy';
import NoRecord from '../../../Components/noRecord'
import { intialRole } from '../../../Utils/Utils';
import { Modal } from 'react-bootstrap';

const BookingPolices = () => {
  const {
    uploadPdf,
    updatePdf,
    deletePdf,
    navigate,
    showDelete,
    setShowDelete,
    userData,
    spinner,
    show,
    pdfdData,
    policiesData,
    border,
    pdf,
    userInfo
  } = UseBookingPolicy()
  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd cusheight">
          {spinner ? <Spinner /> : null}
          <header>
            <h2 className="page-tittle">
              <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Booking Policy
            </h2>
            <hr />
          </header>
          {/* {
            userData?.roleTitle === "SERVICE_PROVIDER" ?
              <> */}
                {show ?
                  (
                    policiesData ?
                      <>
                        <div>
                          <iframe src={pdf + policiesData} title={pdf + policiesData} className='pdf_hw' />
                        </div>
                        <label htmlFor="inputTag" style={{ cursor: 'pointer' }}>
                          <input id="inputTag" type="file" style={{ display: 'none' }} onChange={(e) => { uploadPdf(e.target.files[0]); }} /><br />
                          Upload Policies Again <i class="fa-solid fa-arrow-right"></i>
                          <br />
                        </label>
                      </>
                      :
                      intialRole({ permission: "Policy", userData: userInfo, section: 'add' })?
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                        <div>
                          <div className="mb-3" style={border}>
                            <label htmlFor="inputTag" style={{ cursor: 'pointer' }}>
                              <img src={Download} alt="Upload Policy" />
                              <input id="inputTag" type="file" style={{ display: 'none' }} onChange={(e) => uploadPdf(e.target.files[0])} />
                              <br />
                              Upload Policies Here
                              <br />
                              <span id="imageName" style={{ color: 'green' }}></span>
                            </label>
                          </div>
                          <p>Upload only PDF file and the size should be upto 10 mb</p>
                        </div>
                      </div>
                      :<div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                  )
                  : (
                    intialRole({ permission: "Policy", userData: userInfo, section: 'view' })?
                    <iframe src={pdf + pdfdData} title={pdf + pdfdData} className='pdf_hw' />
                    :<div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                  )}

              {/* </>
              :
              !show ? <iframe src={pdf + pdfdData} title={pdf + pdfdData} height={500} width={1000} /> :
                <NoRecord />
          } */}
          {

            // userData?.roleTitle === "SERVICE_PROVIDER" ?
            intialRole({ permission: "Policy", userData: userInfo, section: 'view' })?
              <>

                <div className="d-grid  col-12  mb-3 justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                  {show ? (
                    intialRole({ permission: "Policy", userData: userInfo, section: 'add' })?
                    <button className="btn colorbtun sidebtn px" onClick={() => updatePdf()}>
                      Save
                    </button>
                    :null
                  ) : 
                  intialRole({ permission: "Policy", userData: userInfo, section: 'delete' })?(
                    <button className="btn colorbtun sidebtn px" onClick={()=>setShowDelete(true)}>
                      Delete
                    </button>
                  ):null
                }
                </div>
              </>
              : null
          }
        </div>
        {/* Delete Pdf Start */}
        <Modal centered size='sm' show={showDelete} onHide={() => setShowDelete(false)}>
            <div className="modal-content">
              <div className="modal-body text-center">
                <p>Are You Sure !</p>
                <p>You want to delete this policy</p>
                <button type="button" className="btn nobtn"  onClick={() => deletePdf()} >
                  Yes
                </button>
                <button type="button" className="btn yesbtn"  onClick={()=>setShowDelete(false)}>
                  No
                </button>
              </div>
            </div>
        </Modal>
        {/* Delete Pdf End */}
      </section>


    </>
  )
}

export default BookingPolices
