import React from 'react'
import logo from '../../Assets/images/logo white color.png'
import { useNavigate } from 'react-router-dom'

const WrongToken = () => {
    const navigate = useNavigate()
    return (
        <section className="bgImg">
            <nav className="navbar navbar-expand-lg pb-2 pb-mg-0 pb-lg-0">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} height="50px" className="img-fluid my-3" onClick={() => navigate('/')}
                            style={{ margin: '0px !important', maxWidth: '100%', padding: '0px' }} width="60px" alt="" />
                    </a>
                </div>
            </nav>

            <div className="d-flex justify-content-around padd">
                <div className=" my-5">
                    <h1>Contact to iskedo for resend invitation</h1>
                </div>
                <div className=""></div>
                <div className=""></div>
            </div>

        </section>
    )
}

export default WrongToken