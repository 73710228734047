import { useState, useContext, useRef } from 'react'
import { useFormik } from 'formik'
import { basicProfileValidation, businessDetailsValidation, addBranchValidation, editBranchValidation } from '../../../Context/validation'
import { toast } from 'react-toastify'
import { statusCodes, commonMessage } from '../../../Constants/generalConfig'
import axios from 'axios'
import {
    getBasicProfileDetailsApi,
    updateProfileApi,
    createBranchApi,
    countryListApi,
    stateListApi,
    branchListApi,
    branchDeleteApi,
    businessDetailsApi,
    getBusinessDetailsApi,
    imgData,
    branchUpdateApi,
    getBranchDetails,
    qrCodeApi,
    staffServices,
    staffBranches,
    changeEmail,
    changeEmailVerify,
    branchImgPath
} from '../../../Context/api'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App';
import { isValidPhoneNumber } from 'react-phone-number-input'

const UseProfile = () => {
    const [file, setFile] = useState('')
    const [country, setcountry] = useState([])
    const [stateList, setStateList] = useState([])
    const [branchListArr, setBranchListArr] = useState([])
    const [branchId, setBranchId] = useState('')
    const [qrCodeData, setQrCodeData] = useState('')
    const [showBranch, setShowBranch] = useState(false)
    const [show, setShow] = useState(false)
    const [disableBranch, setDisableBranch] = useState('')
    const [tab, setTab] = useState(1)
    const [spinner, setSpinner] = useState(false)
    const [service, setService] = useState([])
    const [showQr, setShowQr] = useState(false)
    const [staffbranch, setStaffbranch] = useState([])
    const [deleteBranch, setDeleteBranch] = useState(false)
    const [changeEmailShow, setChangeEmailShow] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [branchImg, setBranchImg] = useState('')
    const [otp, setOtp] = useState('')
    const { userInfo, setUserInfo } = useContext(Notifications)
    const navigate = useNavigate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const fileInputRef = useRef(null);
    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getBasicProfileDetails()
            // getBusinessProfileDetails()
            if (userData?.roleTitle === 'STAFF') {
                staffService()
                getStaffBranchList()

            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let listArr
    const userData = JSON.parse(localStorage.getItem('userData'))

    const qrCode = () => {
        axios.post(qrCodeApi(), { userId: userData?.userId }).then((resp) => {
            setQrCodeData(resp.data)
        }).catch(() => { })
    }
    const countryListData = () => {
        const data = {
            id: userData?.userId,
            isDeleted: true,
            sortValue: 'name',
            sortOrder: -1,
            isActive: true
        }
        axios.post(countryListApi(), { ...data })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setcountry(resp.data.data)
                } else {
                    toast.error(resp.data.message)
                }
            })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }
    const stateListData = (id) => {
        axios.post(stateListApi(), { countryId: id, sortValue: 'name', sortOrder: -1, page: 1, count: 200 }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setStateList(resp.data.data)
            }
        }).catch(() => { })
    }
    const getBranchList = () => {
        axios.post(branchListApi(), { userId: userData?.userId, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setBranchListArr(resp.data.data)
            }
        }).catch(() => { })
    }
    const branchDelete = () => {
        const data = {
            loggedInUserId: userData?.userId,
            branchId: branchId,
            status: true,
        }
        axios.post(branchDeleteApi(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                listArr = branchListArr
                listArr = listArr.filter((branch) => branch._id !== branchId)
                setBranchListArr(listArr)
                setDeleteBranch(false)
            }
            else{
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const getBranchListDetails = (id) => {
        const data = {
            loggedInUserId: userData?.userId,
            branchId: id,
        }
        axios.post(getBranchDetails(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                stateListData(resp.data.data.countryId)
                setBranchId(data.branchId)
                setDisableBranch(resp.data.data.isActive)
                for (let item in EditBranchForm.initialValues) {
                    EditBranchForm.setFieldValue(item, resp.data.data[item])
                }
                setBranchImg(resp.data.data.image)
            }
        }).catch(() => { })
    }
    const getBasicProfileDetails = () => {
        setSpinner(true)
        axios.post(getBasicProfileDetailsApi(), { userId: userData?.userId })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setSpinner(false)
                    setFile(resp.data.data.image)
                    for (let item in basicProfileForm.initialValues) {
                        basicProfileForm.setFieldValue(item, resp.data.data[item])
                    }
                    let data = JSON.parse(localStorage.getItem('userData'))
                    let updateData = { ...data }
                    updateData.image = resp.data.data.image
                    localStorage.setItem("userData", JSON.stringify(updateData))
                    setUserInfo(JSON.parse(localStorage.getItem('userData')))
                }
                else {
                    setSpinner(false)
                }
            }).catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const getBusinessProfileDetails = () => {
        setSpinner(true)
        axios.post(getBusinessDetailsApi(), { userId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false)
                for (let item in businessProfileForm.initialValues) {
                    businessProfileForm.setFieldValue(item, resp.data.data[item])
                }
            }
            else {
                setSpinner(false)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const basicProfileForm = useFormik({
        initialValues: {
            name: '',
            mobileNo: '',
            email: '',
        },
        validationSchema: basicProfileValidation,
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                email: values.email,
                mobileNo: values.mobileNo,
                userId: userData?.userId,
                image: file,
                timeZone: timeZone,
                roleTitle: userData?.roleTitle
            }
            axios
                .post(updateProfileApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        getBasicProfileDetails()
                        let data = JSON.parse(localStorage.getItem('userData'))
                        let updateData = { ...data }
                        updateData.image = file
                        localStorage.setItem("userData", JSON.stringify(updateData))
                        setUserInfo(JSON.parse(localStorage.getItem('userData')))
                    } else {
                        toast.error(resp.data.message)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const businessProfileForm = useFormik({
        initialValues: {
            businessName: '',
            businessCategory: '',
            about: '',
        },
        validationSchema: businessDetailsValidation,
        onSubmit: (values) => {
            const data = {
                businessName: values.businessName.trim(),
                businessCategory: values.businessCategory.trim(),
                about: values.about,
                userId: userData?.userId,
                timeZone: timeZone
            }
            setSpinner(true)
            axios
                .post(businessDetailsApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        getBusinessProfileDetails()
                        let data = JSON.parse(localStorage.getItem('userData'))
                        let updateData = { ...data }
                        updateData.businessName = values.businessName
                        updateData.image = file
                        localStorage.setItem("userData", JSON.stringify(updateData))
                        setUserInfo(JSON.parse(localStorage.getItem('userData')))
                        setSpinner(false)
                        // window.location.reload()
                    } else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const addBranchForm = useFormik({
        initialValues: {
            name: '',
            building: '',
            street: '',
            city: '',
            country: '',
            state: '',
            zipCode: '',
            email: '',
            mobileNo: '',
            alternateMobileNo:null
        },
        validationSchema: addBranchValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                building: values.building.trim(),
                street: values.street.trim(),
                city: values.city.trim(),
                country: values.country,
                state: values.state,
                zipCode: values.zipCode.trim(),
                email: values.email.trim(),
                mobileNumber: `${values.mobileNo}`,
                alternateMobileNo:values.alternateMobileNo,
                createdby_id: userData?.userId,
                image: branchImg,

            }

            setSpinner(true)
            axios
                .post(createBranchApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        localStorage.setItem('loggedInUserId', JSON.stringify(resp.data.data.createdby_id))
                        addBranchForm.resetForm()
                        getBranchList()
                        setShowBranch(false)
                        setSpinner(false)
                        setBranchImg('')
                    } else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const EditBranchForm = useFormik({
        initialValues: {
            name: '',
            building: '',
            street: '',
            city: '',
            countryId: '',
            stateId: '',
            zipCode: '',
            email: '',
            mobileNumber: '',
            alternateMobileNo:null,
        },
        validationSchema: editBranchValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNumber?.length > 0 && (values?.mobileNumber && isValidPhoneNumber(values?.mobileNumber))) {
                delete errors.mobileNumber
            } else {
                errors.mobileNumber = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                building: values.building.trim(),
                street: values.street.trim(),
                city: values.city.trim(),
                country: values.countryId,
                state: values.stateId,
                zipCode: values.zipCode.trim(),
                email: values.email,
                mobileNumber: `${values.mobileNumber}`,
                alternateMobileNo:values.alternateMobileNo,
                loggedInUserId: userData?.userId,
                branchId: branchId,
                isActive: disableBranch,
                image: branchImg
            }
            setSpinner(true)
            axios
                .post(branchUpdateApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        getBranchList()
                        setShow(false)
                        setSpinner(false)
                        setBranchImg('')
                    } else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const staffService = () => {
        axios.post(staffServices(), { userId: userData?.userId, providerId: userData?.createdbyId, count: 100, page: 1, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setService(resp.data.data)
            }
            else {
                setService([])
            }
        }).catch(() => { })
    }
    const getStaffBranchList = () => {
        axios.post(staffBranches(), { userId: userData?.userId, providerId: userData?.createdbyId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setStaffbranch(resp.data.data)
                }
                else {
                    setStaffbranch([])
                }
            }).catch(() => { })
    }
    const updateEmail = () => {
        setSpinner(true)
        axios.post(changeEmail(), { oldEmail: userInfo?.email.trim(), email: basicProfileForm.values.email.trim() }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setChangeEmailShow(true)
                setSpinner(false)
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const emailVerify = () => {
        if (!otp) return toast.warn('Please enter OTP')
        const data = {
            oldEmail: userInfo?.email.trim(),
            email: basicProfileForm.values.email.trim(),
            otp: otp
        }
        setSpinner(true)
        axios.post(changeEmailVerify(), { ...data })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setChangeEmailShow(false)
                    setSpinner(false)
                    getBasicProfileDetails()
                    setOtp('')
                    setDisabled(false)
                    let data = JSON.parse(localStorage.getItem('userData'))
                    let updateData = { ...data }
                    updateData.email = basicProfileForm.values.email
                    // console.log(basicProfileForm.values.email, 'basicProfileForm.values.email')
                    localStorage.setItem("userData", JSON.stringify(updateData))
                    setUserInfo(JSON.parse(localStorage.getItem('userData')))
                }
                else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            }).catch(() => { })
    }
    return {
        qrCode,
        countryListData,
        stateListData,
        getBranchList,
        branchDelete,
        getBranchListDetails,
        getStaffBranchList,
        staffService,
        getBusinessProfileDetails,
        getBasicProfileDetails,
        navigate,
        setFile,
        setcountry,
        setStateList,
        setBranchListArr,
        setBranchId,
        setQrCodeData,
        setShowBranch,
        setShow,
        setDisableBranch,
        setTab,
        setSpinner,
        setService,
        setStaffbranch,
        setChangeEmailShow,
        updateEmail,
        setDisabled,
        setOtp,
        emailVerify,
        showQr, setShowQr,
        deleteBranch, setDeleteBranch,
        branchImg, setBranchImg,
        branchImgPath,
        otp,
        disabled,
        changeEmailShow,
        EditBranchForm,
        addBranchForm,
        businessProfileForm,
        basicProfileForm,
        userData,
        file,
        country,
        branchId,
        qrCodeData,
        showBranch,
        show,
        stateList,
        branchListArr,
        disableBranch,
        tab,
        spinner,
        service,
        staffbranch,
        setUserInfo,
        timeZone,
        imgData,
        userInfo,
        fileInputRef
    }
}

export default UseProfile