import React from 'react'
import OtpInput from "react-otp-input";
import ResetLogo from '../../Assets/images/reset.png';
import axios from 'axios';
import { acceptInvitation, checkTokensExpire } from '../../Context/api';
import { statusCodes, commonMessage } from '../../Constants/generalConfig';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPinValidation } from '../../Context/validation';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Spinner from '../../Components/spinner/spinner';


const CreatePin = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const [spinner, setSpinner] = useState(false)

    useEffect(() => {
        tokenCheck()
    }, [])


    const tokenCheck = () => {
        axios.post(checkTokensExpire(), { token: token }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
            }
            else {
                toast.error(resp.data.message)
                setTimeout(() => {
                    navigate(`/`)
                }, 1000);
            }
        })
    }
    const pinCreate = useFormik({
        initialValues: {
            pin: '',
            confirmPin: '',
        },
        validationSchema: resetPinValidation,
        onSubmit: values => {
            const data = {
                token: token,
                password: values.confirmPin
            }
            setSpinner(true)
            axios.post(acceptInvitation(), { ...data }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    navigate('/')
                    pinCreate.resetForm()
                    setSpinner(false)
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)

                })
        },
    });
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="container d-flex justify-content-center align-items-center cusheight">
                    <div className="row">
                        <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>
                            <div className="text-center">
                                <img src={ResetLogo} alt='' />
                                <h1 className="text-center heading-color mb-3">Create PIN</h1>
                                <form className="cus-form" onSubmit={pinCreate.handleSubmit}>
                                    <label className="form-label">Create New PIN *</label>
                                    <div className="pin-code mb-3">
                                        <OtpInput isInputNum isInputSecure numInputs={4} value={pinCreate.values.pin} onChange={(e) => { pinCreate.setFieldValue("pin", e) }} />
                                    </div>
                                    {pinCreate.touched.pin && pinCreate.errors.pin ? (
                                        <div className='err_msg'>{pinCreate.errors.pin}</div>
                                    ) : null}
                                    <label className="form-label">Confirm New PIN *</label>
                                    <div className="pin-code">
                                        <OtpInput isInputNum isInputSecure numInputs={4} value={pinCreate.values.confirmPin} onChange={(e) => { pinCreate.setFieldValue("confirmPin", e) }} />
                                    </div>
                                    {pinCreate.touched.confirmPin && pinCreate.errors.confirmPin ? (
                                        <div className='err_msg'>{pinCreate.errors.confirmPin}</div>
                                    ) : null}
                                    <div className="d-grid  col-6 mx-auto mb-3">
                                        <button type='submit' className="btn colorbtun resetbtn px">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreatePin
