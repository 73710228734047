import React, { useContext, useEffect } from 'react'
import logo from '../../Assets/images/logo white color.png'
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { imgData } from '../../Context/api'
import DummyLogo from '../../Assets/images/dummyimg.png'
import './style.css'
import { logOutApi } from '../../Context/api';
import axios from 'axios'
import { Notifications } from '../../App';
import io from "socket.io-client";
import { environment } from '../../Context/environment'
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { notificationList } from '../../Context/api';
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify'
import Backdrop from '@mui/material/Backdrop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import UseProviderHook from './Hooks/useProviderHook'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const ProviderNav = () => {
    const {
        data,
        open,
        roleswitch,
        setOpen,
        selectMember,
        setBranchOpen,
        branchShow,
        branchData,
        selectLocation
    } = UseProviderHook()

    const userData = JSON.parse(localStorage.getItem('userData'))
    const navigate = useNavigate()
    const { count, setCount, userInfo, setSideShow } = useContext(Notifications)
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");

    if (splitLocation[2] !== "appointmentList") {
        localStorage.removeItem('status')
    }
    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            listNotifications()
        }
    }, [])

    useEffect(() => {
        const socket = io(environment.baseUrl)
        // Notification.requestPermission()
        socket.on('notification', ((data) => { showNotification(data); listNotifications() }))
        socket.emit("add-user", { user_Id: userData?.userId, tokenID: userData?.token })
        if ('Notification' in window) {
            Notification.requestPermission();
        }
        // else if (navigator.platform === 'iPhone' || navigator.platform === 'iPad' || navigator.platform === 'iPod') {
        //     alert("To enable notifications, please navigate to Settings > Safari > Notifications and enable notifications for this website.");
        // }
        // else if (/android/i.test(navigator.userAgent)) {
        //     alert("To enable notifications, please allow notifications when prompted.")
        // }
    }, [])
    const showNotification = (message) => {
        if ('Notification' in window) {
            if (Notification.permission === 'granted') {
                sendNotification(message);
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        sendNotification(message);
                    }
                });
            }
        }
    };
    const sendNotification = (message) => {
        const notification = new Notification(message?.title);

        notification.onclick = function (event) {
            event.preventDefault();
            if (message.type === 'BOOKAPPOINTMENT' || message.type === 'CANCELAPPOINTMENT' || message.type === 'RESCHEDULEDAPPOINTMENT' || message.type === 'DELETAPPOINTMENT') {
                navigate(`appointmentList/appointmentView/${message.type_id}`);
            }
        };
    };

    const listNotifications = () => {
        const data = {
            receiver: userData?.userId,
        }
        axios.post(notificationList(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setCount({ totalCount: resp.data.unreadedCount })

            }
            else {
                setCount({})
            }
        }).catch(() => { })
    }
    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.clear()
                navigate('/')
            }
            else {
                toast.error(resp.data.message)
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }

    let checkPlanDetails = Array.isArray(userData?.planDetails)
    if (checkPlanDetails) {
        localStorage.clear();
        navigate('/')
    }

    return (
        <>
            <aside>
                <span className='phonehide d-flex justify-content-center align-items-center'> <img src={logo} alt="" width={100} height={100} /></span>
                <div className='menu-list'>
                    <NavLink to="dashboard" activeclassname="active" onClick={() => setSideShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" width="45" className="icons-size">
                            <path d="M22.417 15.208V5.792h11.791v9.416ZM5.833 20.542V5.792H18.25v14.75Zm16.584 13.625v-14.75h11.791v14.75Zm-16.584 0v-9.459H18.25v9.459Zm2.125-15.75h8.167v-10.5H7.958Zm16.584 13.625h7.541v-10.5h-7.541Zm0-18.959h7.541V7.917h-7.541ZM7.958 32.042h8.167v-5.167H7.958Zm8.167-13.625Zm8.417-5.334Zm0 8.459Zm-8.417 5.333Z" />
                        </svg>
                        Dashboard
                    </NavLink>
                    <NavLink to="appointmentList" activeclassname="active" onClick={() => setSideShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" className="icons-size">
                            <path d="m18.208 29.875-5.291-5.25 1.5-1.458 3.791 3.791 7.375-7.416 1.5 1.458Zm-9.291 5.958q-1.292 0-2.209-.916-.916-.917-.916-2.25V10.292q0-1.292.916-2.23.917-.937 2.209-.937h2.416V3.792h2.292v3.333H26.5V3.792h2.208v3.333h2.375q1.292 0 2.209.937.916.938.916 2.23v22.375q0 1.333-.916 2.25-.917.916-2.209.916Zm0-2.125h22.166q.375 0 .688-.333.312-.333.312-.708V17H7.917v15.667q0 .375.312.708.313.333.688.333Zm-1-18.833h24.166v-4.583q0-.375-.312-.709-.313-.333-.688-.333H8.917q-.375 0-.688.333-.312.334-.312.709Zm0 0V9.25v5.625Z" />
                        </svg>
                        <span className='ms-1'>Appointment List</span>
                    </NavLink>
                    <NavLink to="availability" activeclassname="active" onClick={() => setSideShow(false)}>
                        <i className="fa-sharp fa-regular fa-clock fs-5" style={{ padding: '8px 0px', marginLeft: '10px' }}></i>
                        <span style={{ marginLeft: '10px' }}>Availability</span>
                    </NavLink>
                    <NavLink to="waitingList" activeclassname="active" onClick={() => setSideShow(false)}>

                        <svg xmlns="http://www.w3.org/2000/svg" height="35" width="45" className="icons-size">
                            <path d="M13.292 28.042q.625 0 1.062-.417.438-.417.438-1.083 0-.625-.438-1.063-.437-.437-1.104-.437-.625 0-1.062.437-.438.438-.438 1.063 0 .666.438 1.083.437.417 1.104.417Zm0-6.542q.625 0 1.062-.438.438-.437.438-1.062 0-.667-.438-1.104-.437-.438-1.104-.438-.625 0-1.062.459-.438.458-.438 1.083 0 .667.438 1.083.437.417 1.104.417Zm0-6.625q.625 0 1.062-.417.438-.416.438-1.083 0-.625-.438-1.063-.437-.437-1.104-.437-.625 0-1.062.437-.438.438-.438 1.063 0 .667.438 1.083.437.417 1.104.417Zm5.083 12.75h9.583V25.5h-9.583Zm0-6.583h9.583v-2.125h-9.583Zm0-6.542h9.583v-2.125h-9.583ZM9.083 34.042q-1.291 0-2.208-.917-.917-.917-.917-2.208V9.083q0-1.291.917-2.208.917-.917 2.208-.917h21.834q1.291 0 2.208.917.917.917.917 2.208v21.834q0 1.291-.917 2.208-.917.917-2.208.917Zm0-2.125h21.834q.375 0 .687-.313.313-.312.313-.687V9.083q0-.375-.313-.687-.312-.313-.687-.313H9.083q-.375 0-.687.313-.313.312-.313.687v21.834q0 .375.313.687.312.313.687.313Zm-1-23.834v23.834V8.083Z" />
                        </svg>
                        Waiting List
                    </NavLink>
                    <NavLink to="client" activeclassname="active" onClick={() => setSideShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="46" className="icons-size">
                            <path d="M1.9 40v-4.7q0-1.75.9-3.175Q3.7 30.7 5.3 30q3.65-1.6 6.575-2.3Q14.8 27 17.9 27q3.1 0 6 .7t6.55 2.3q1.6.7 2.525 2.125.925 1.425.925 3.175V40Zm35 0v-4.7q0-3.15-1.6-5.175t-4.2-3.275q3.45.4 6.5 1.175t4.95 1.775q1.65.95 2.6 2.35.95 1.4.95 3.15V40Zm-19-16.05q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1Zm18-7.5q0 3.3-2.1 5.4-2.1 2.1-5.4 2.1-.55 0-1.225-.075T25.95 23.6q1.2-1.25 1.825-3.075.625-1.825.625-4.075t-.625-3.975Q27.15 10.75 25.95 9.3q.55-.15 1.225-.25t1.225-.1q3.3 0 5.4 2.1 2.1 2.1 2.1 5.4ZM4.9 37h26v-1.7q0-.8-.475-1.55T29.25 32.7q-3.6-1.6-6.05-2.15-2.45-.55-5.3-.55-2.85 0-5.325.55T6.5 32.7q-.7.3-1.15 1.05-.45.75-.45 1.55Zm13-16.05q1.95 0 3.225-1.275Q22.4 18.4 22.4 16.45q0-1.95-1.275-3.225Q19.85 11.95 17.9 11.95q-1.95 0-3.225 1.275Q13.4 14.5 13.4 16.45q0 1.95 1.275 3.225Q15.95 20.95 17.9 20.95Zm0 16.05Zm0-20.55Z" />
                        </svg>
                        Clients
                    </NavLink>
                    <NavLink to="team" activeclassname="active" onClick={() => setSideShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="46" className="icons-size">
                            <path d="M1.9 40v-4.7q0-1.75.9-3.175Q3.7 30.7 5.3 30q3.65-1.6 6.575-2.3Q14.8 27 17.9 27q3.1 0 6 .7t6.55 2.3q1.6.7 2.525 2.125.925 1.425.925 3.175V40Zm35 0v-4.7q0-3.15-1.6-5.175t-4.2-3.275q3.45.4 6.5 1.175t4.95 1.775q1.65.95 2.6 2.35.95 1.4.95 3.15V40Zm-19-16.05q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1Zm18-7.5q0 3.3-2.1 5.4-2.1 2.1-5.4 2.1-.55 0-1.225-.075T25.95 23.6q1.2-1.25 1.825-3.075.625-1.825.625-4.075t-.625-3.975Q27.15 10.75 25.95 9.3q.55-.15 1.225-.25t1.225-.1q3.3 0 5.4 2.1 2.1 2.1 2.1 5.4ZM4.9 37h26v-1.7q0-.8-.475-1.55T29.25 32.7q-3.6-1.6-6.05-2.15-2.45-.55-5.3-.55-2.85 0-5.325.55T6.5 32.7q-.7.3-1.15 1.05-.45.75-.45 1.55Zm13-16.05q1.95 0 3.225-1.275Q22.4 18.4 22.4 16.45q0-1.95-1.275-3.225Q19.85 11.95 17.9 11.95q-1.95 0-3.225 1.275Q13.4 14.5 13.4 16.45q0 1.95 1.275 3.225Q15.95 20.95 17.9 20.95Zm0 16.05Zm0-20.55Z" />
                        </svg>
                        Teams
                    </NavLink>
                    <NavLink to="service" activeclassname="active" onClick={() => setSideShow(false)}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="35" width="45" style={{ transform: 'scale(0.7)' }}>
                                <path d="M26.375 19.25q-3.875-3.375-6.625-6.375T17 7.292q0-2 1.417-3.417 1.416-1.417 3.458-1.417 1.208 0 2.375.667t2.125 1.917q1-1.25 2.187-1.917 1.188-.667 2.396-.667 2.042 0 3.417 1.417t1.375 3.417q0 2.583-2.729 5.583t-6.646 6.375Zm0-2.917q2.792-2.458 5.021-4.895Q33.625 9 33.625 7.292q0-1.209-.729-1.959T31 4.583q-.708 0-1.458.375t-1.584 1.459l-1.583 1.916-1.583-1.916q-.834-1.084-1.542-1.459-.708-.375-1.375-.375-1.208 0-1.958.75t-.75 1.959q0 1.708 2.229 4.146 2.229 2.437 4.979 4.895Zm0-5.875ZM23 35.875l-12.792-3.708v2.291H2.333V19.25h11.792L24.5 23.125q1.167.417 1.812 1.187.646.771.646 2.605h4.834q1.708 0 2.687 1.208.979 1.208.979 3.125V32ZM4.458 32.333h3.584V21.375H4.458Zm18.417 1.292 10.417-3.208q-.084-.625-.438-.979-.354-.355-1.062-.355H23.5q-1.042 0-2.146-.166-1.104-.167-1.812-.417l-3.625-1.125.791-2.083 2.792 1q1.083.375 2.083.5 1 .125 3.292.125 0-.75-.25-1.167-.25-.417-.833-.625l-10.042-3.75h-3.542v8.542Zm-14.833-6.75Zm16.833.042Zm-16.833-.042Zm2.166 0Z" />
                            </svg>
                        </span>
                        Services
                    </NavLink>
                    {
                        userData?.planDetails?.addons?.length && userData.planDetails.addons.find((item) => item.keyName === 'chat')?.keyValue ?
                            <NavLink to="chat" activeclassname="active" style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={() => setSideShow(false)}>
                                <span>
                                    <i className="fs-5 fa-regular fa-comment ms-2" style={{ paddingRight: '11px' }}></i>
                                </span>
                                Chat
                            </NavLink>
                            :
                            <a style={{ paddingTop: '10px', paddingBottom: '10px', color: '#98969682' }}>
                                <span>
                                    <i className="fs-5 fa-regular fa-comment ms-2" style={{ paddingRight: '11px', color: '#98969682' }}></i>
                                </span>
                                Chat
                            </a>
                    }

                    <NavLink to="offer" activeclassname="active" onClick={() => setSideShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" width="43" className="icons-size">
                            <path d="M21.458 35.875q-.416 0-.812-.146-.396-.146-.729-.479L4.75 20.125q-.375-.333-.5-.75t-.125-.833V6.25q0-.875.625-1.5t1.5-.625h12.292q.416 0 .812.146.396.146.771.479L35.25 19.792q.667.708.688 1.604.02.896-.688 1.562L22.958 35.25q-.291.292-.687.458-.396.167-.813.167Zm.042-2.083 12.333-12.334L18.583 6.25H6.25v12.292ZM10.542 12.417q.791 0 1.354-.563.562-.562.562-1.354 0-.833-.562-1.375-.563-.542-1.354-.542-.834 0-1.375.542-.542.542-.542 1.333 0 .792.542 1.375.541.584 1.375.584ZM6.25 6.25Z" />
                        </svg>
                        Offers
                    </NavLink>
                    {
                        userData?.planDetails?.addons?.length && userData.planDetails.addons.find((item) => item.keyName === 'rewards' || item.keyName === 'Rewards')?.keyValue ?
                            <NavLink to="reward" activeclassname="active">
                                <svg height="35" width="43" style={{ verticalAlign: 'middle', overflow: 'hidden', transform: 'scale(0.8)' }} fill='black' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1011 630.6L834.8 464.5c-3.9-3.6-8.2-6.4-12.9-8.3 7.5-27.5 11.3-56 11.3-84.9 0-86-33.5-166.8-94.3-227.6-60.8-60.8-141.6-94.3-227.6-94.3s-166.8 33.5-227.6 94.3c-60.8 60.8-94.3 141.6-94.3 227.6 0 29.8 4.1 59.2 12.1 87.6-3.2 1.9-6.1 4.2-8.7 6.9-31.1 32-62.7 64.2-93.2 95.3-29.6 30.1-60.2 61.2-90.4 92.3-8.2 8.4-10.7 20.5-6.5 31.5 4.2 11 14.1 18.3 25.8 19.1 36.6 2.6 90.9 12.7 142.2 46.3 46.8 31.2 71.7 79.3 80.3 98.5 4 9.1 12.3 15.5 22 17.3 1.8 0.3 3.5 0.5 5.3 0.5 8 0 15.7-3.2 21.3-9l164-167.9c15.8 2.3 31.7 3.5 47.7 3.5 18 0 36.1-1.5 53.8-4.5 27.8 26.4 56.1 52.8 83.5 78.4 32.6 30.4 66.3 61.8 99 93.1 5.6 5.4 13 8.3 20.6 8.3 2.3 0 4.7-0.3 7-0.8 9.9-2.4 17.9-9.6 21.2-19.2 10.1-29 32.2-70.4 80.7-103.3 36.3-24.2 81.7-36.9 113.4-43.3 15.3-3.1 27.5-14.4 31.8-29.4 4.4-15.1 0-31.1-11.3-41.9zM281.2 819.1c-14.8-29.1-42.5-71.7-88.4-102.2l-0.1-0.1c-49.2-32.2-100.5-45.5-140.2-50.6 25.3-26 50.8-51.9 75.5-77.1 28.8-29.3 58.5-59.5 87.8-89.8 37.6 86.7 112 152.7 201.9 180L281.2 819.1z m179-170.6c-98.2-18-180.3-87.6-214.2-181.8l-18.8 6.8 18.8-6.8c-11-30.6-16.6-62.7-16.6-95.5 0-155.4 126.5-281.9 281.9-281.9s281.9 126.5 281.9 281.9c0 33.9-6 67-17.7 98.5-33.8 90.5-113.5 158.7-208 177.8-35 7.2-72 7.5-107.3 1zM984 661.4c-0.3 1-0.9 1.1-1.3 1.2-35.3 7.1-86.1 21.5-127.8 49.3l-0.1 0.1c-50 33.9-76.5 75.7-90.5 108.8-29.4-28-59.3-55.9-88.4-83-21.3-19.8-43-40.1-64.6-60.5 40.1-13.1 77.5-34.1 109.7-61.8 38.2-32.9 68.3-74.4 87.6-120.7l174.9 164.9c0.3 0.3 0.8 0.7 0.5 1.7z" /><path d="M709.7 260c0-5.4-2-10.6-5.6-14.1-3.5-3.5-8.7-5.6-14.1-5.6s-10.6 2-14.1 5.6l-0.1 0.1-181.7 191.7-90.6-85.8c-7.4-7.3-20.8-7.2-28.2 0.1-7.4 7.4-7.4 20.9 0 28.3l100.9 95.6c3.2 3.1 7.7 5.1 12.5 5.4 2.7 1.2 5.6 1.8 8.6 1.8 5.3 0 10.5-1.9 14.2-5.6L704.2 274c3.5-3.5 5.5-8.6 5.5-14z" /></svg>
                                <span className='ms-2'>Rewards </span>
                            </NavLink>
                            :
                            <a style={{ paddingTop: '10px', paddingBottom: '10px', color: '#98969682' }}>
                                <span>
                                    <svg height="35" width="43" style={{ verticalAlign: 'middle', overflow: 'hidden', transform: 'scale(0.8)' }} fill='#98969682' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1011 630.6L834.8 464.5c-3.9-3.6-8.2-6.4-12.9-8.3 7.5-27.5 11.3-56 11.3-84.9 0-86-33.5-166.8-94.3-227.6-60.8-60.8-141.6-94.3-227.6-94.3s-166.8 33.5-227.6 94.3c-60.8 60.8-94.3 141.6-94.3 227.6 0 29.8 4.1 59.2 12.1 87.6-3.2 1.9-6.1 4.2-8.7 6.9-31.1 32-62.7 64.2-93.2 95.3-29.6 30.1-60.2 61.2-90.4 92.3-8.2 8.4-10.7 20.5-6.5 31.5 4.2 11 14.1 18.3 25.8 19.1 36.6 2.6 90.9 12.7 142.2 46.3 46.8 31.2 71.7 79.3 80.3 98.5 4 9.1 12.3 15.5 22 17.3 1.8 0.3 3.5 0.5 5.3 0.5 8 0 15.7-3.2 21.3-9l164-167.9c15.8 2.3 31.7 3.5 47.7 3.5 18 0 36.1-1.5 53.8-4.5 27.8 26.4 56.1 52.8 83.5 78.4 32.6 30.4 66.3 61.8 99 93.1 5.6 5.4 13 8.3 20.6 8.3 2.3 0 4.7-0.3 7-0.8 9.9-2.4 17.9-9.6 21.2-19.2 10.1-29 32.2-70.4 80.7-103.3 36.3-24.2 81.7-36.9 113.4-43.3 15.3-3.1 27.5-14.4 31.8-29.4 4.4-15.1 0-31.1-11.3-41.9zM281.2 819.1c-14.8-29.1-42.5-71.7-88.4-102.2l-0.1-0.1c-49.2-32.2-100.5-45.5-140.2-50.6 25.3-26 50.8-51.9 75.5-77.1 28.8-29.3 58.5-59.5 87.8-89.8 37.6 86.7 112 152.7 201.9 180L281.2 819.1z m179-170.6c-98.2-18-180.3-87.6-214.2-181.8l-18.8 6.8 18.8-6.8c-11-30.6-16.6-62.7-16.6-95.5 0-155.4 126.5-281.9 281.9-281.9s281.9 126.5 281.9 281.9c0 33.9-6 67-17.7 98.5-33.8 90.5-113.5 158.7-208 177.8-35 7.2-72 7.5-107.3 1zM984 661.4c-0.3 1-0.9 1.1-1.3 1.2-35.3 7.1-86.1 21.5-127.8 49.3l-0.1 0.1c-50 33.9-76.5 75.7-90.5 108.8-29.4-28-59.3-55.9-88.4-83-21.3-19.8-43-40.1-64.6-60.5 40.1-13.1 77.5-34.1 109.7-61.8 38.2-32.9 68.3-74.4 87.6-120.7l174.9 164.9c0.3 0.3 0.8 0.7 0.5 1.7z" /><path d="M709.7 260c0-5.4-2-10.6-5.6-14.1-3.5-3.5-8.7-5.6-14.1-5.6s-10.6 2-14.1 5.6l-0.1 0.1-181.7 191.7-90.6-85.8c-7.4-7.3-20.8-7.2-28.2 0.1-7.4 7.4-7.4 20.9 0 28.3l100.9 95.6c3.2 3.1 7.7 5.1 12.5 5.4 2.7 1.2 5.6 1.8 8.6 1.8 5.3 0 10.5-1.9 14.2-5.6L704.2 274c3.5-3.5 5.5-8.6 5.5-14z" /></svg>
                                </span>
                                Rewards
                            </a>
                    }
                    {
                        userData?.planDetails?.addons?.length && userData?.planDetails?.addons?.find((item) => item.keyName == "insight")?.keyValue ?

                            <NavLink to="insightList" activeclassname="active" onClick={() => setSideShow(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="37" width="43" className="icons-size">
                                    <path d="M4.667 32.917q-1.084 0-1.813-.75-.729-.75-.729-1.834 0-1.083.75-1.791.75-.709 1.75-.709.292 0 .583.042.292.042.584.167l8.583-8.542q-.167-.292-.229-.542-.063-.25-.063-.583 0-1.042.729-1.771.73-.729 1.813-.729t1.813.729q.729.729.729 1.813 0 .208-.25 1.041L24 24.5q.292-.125.542-.188.25-.062.5-.062.291 0 .562.062.271.063.521.23l6.917-6.917q-.167-.25-.209-.521-.041-.271-.041-.562 0-1.084.75-1.813.75-.729 1.833-.729t1.792.729q.708.729.708 1.813 0 1.083-.729 1.812-.729.729-1.771.729-.292 0-.563-.041-.27-.042-.52-.167l-6.917 6.917q.125.25.167.5.041.25.041.583 0 1.042-.708 1.771-.708.729-1.833.729-1.042 0-1.792-.729t-.75-1.771q0-.333.062-.604.063-.271.23-.563l-5.042-5.041q-.292.166-.562.229-.271.062-.563.062-.208 0-1.083-.25l-8.5 8.584q.083.291.125.541.041.25.041.542 0 1.042-.729 1.792t-1.812.75Zm2-17.167-.834-1.792-1.791-.833 1.791-.833.834-1.834.875 1.834 1.791.833-1.791.833ZM25 13.708 23.75 11l-2.708-1.25 2.708-1.292L25 5.75l1.25 2.708L29 9.75 26.25 11Z" />
                                </svg>
                                Insights
                            </NavLink>
                            :
                            <a style={{ color: '#98969682' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#98969682' height="37" width="43" className="icons-size">
                                    <path d="M4.667 32.917q-1.084 0-1.813-.75-.729-.75-.729-1.834 0-1.083.75-1.791.75-.709 1.75-.709.292 0 .583.042.292.042.584.167l8.583-8.542q-.167-.292-.229-.542-.063-.25-.063-.583 0-1.042.729-1.771.73-.729 1.813-.729t1.813.729q.729.729.729 1.813 0 .208-.25 1.041L24 24.5q.292-.125.542-.188.25-.062.5-.062.291 0 .562.062.271.063.521.23l6.917-6.917q-.167-.25-.209-.521-.041-.271-.041-.562 0-1.084.75-1.813.75-.729 1.833-.729t1.792.729q.708.729.708 1.813 0 1.083-.729 1.812-.729.729-1.771.729-.292 0-.563-.041-.27-.042-.52-.167l-6.917 6.917q.125.25.167.5.041.25.041.583 0 1.042-.708 1.771-.708.729-1.833.729-1.042 0-1.792-.729t-.75-1.771q0-.333.062-.604.063-.271.23-.563l-5.042-5.041q-.292.166-.562.229-.271.062-.563.062-.208 0-1.083-.25l-8.5 8.584q.083.291.125.541.041.25.041.542 0 1.042-.729 1.792t-1.812.75Zm2-17.167-.834-1.792-1.791-.833 1.791-.833.834-1.834.875 1.834 1.791.833-1.791.833ZM25 13.708 23.75 11l-2.708-1.25 2.708-1.292L25 5.75l1.25 2.708L29 9.75 26.25 11Z" />
                                </svg>
                                Insights
                            </a>
                    }
                    <NavLink to="notification" activeclassname="active" onClick={() => setSideShow(false)}>
                        <Badge style={{ marginLeft: '5px' }} badgeContent={count.totalCount} color="error">
                            <NotificationsNoneIcon sx={{ fontSize: '20px' }} />
                        </Badge>
                        <span style={{ marginLeft: '10px', padding: '5px 0px' }}>Notifications</span>
                    </NavLink>
                    <NavLink to="setting" activeclassname="active" onClick={() => setSideShow(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" width="40" className="icons-size">
                            <path d="m16.5 35.875-.708-5q-.709-.208-1.667-.729-.958-.521-1.583-1.104l-4.625 2.041-3.542-6.333 4-3q-.042-.417-.104-.896-.063-.479-.063-.937 0-.375.063-.813.062-.437.104-.979l-4-3 3.542-6.167 4.5 1.959q.708-.584 1.625-1.104.916-.521 1.708-.813l.75-4.958h7.042l.666 5q.875.333 1.709.812.833.479 1.541 1.063l4.709-1.959 3.458 6.167-4.167 3.083q.084.5.146.959.063.458.063.833 0 .333-.063.792-.062.458-.104 1l4.042 2.958-3.5 6.333-4.709-2.041q-.75.583-1.541 1.083-.792.5-1.542.708l-.708 5.042Zm3.333-11.125q2.042 0 3.438-1.396 1.396-1.396 1.396-3.396 0-2.041-1.396-3.437t-3.438-1.396q-2 0-3.395 1.396-1.396 1.396-1.396 3.437 0 2 1.396 3.396 1.395 1.396 3.395 1.396Zm0-2.125q-1.125 0-1.895-.792-.771-.791-.771-1.875 0-1.125.791-1.916.792-.792 1.875-.792 1.125 0 1.917.792.792.791.792 1.916t-.792 1.896q-.792.771-1.917.771ZM20 19.958ZM18.292 33.75h3.375l.625-4.625q1.291-.333 2.375-.958 1.083-.625 2.208-1.667l4.208 1.833 1.667-2.916-3.667-2.75q.167-.75.271-1.417.104-.667.104-1.292 0-.708-.104-1.291-.104-.584-.229-1.375l3.708-2.792-1.625-2.875-4.333 1.792q-.792-.917-2.104-1.709-1.313-.791-2.521-.958l-.5-4.542h-3.5l-.5 4.5q-1.417.292-2.562.938-1.146.646-2.188 1.729l-4.167-1.75L7.125 14.5l3.667 2.75q-.209.708-.292 1.375-.083.667-.083 1.333 0 .625.083 1.271.083.646.25 1.438l-3.625 2.75 1.708 2.916L13 26.542q1.125 1.083 2.25 1.708t2.5.958Z" />
                        </svg>
                        Settings
                    </NavLink>
                    <a className={`${splitLocation[2] == 'profile' ? 'active' : null} cursor`}>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{userInfo?.businessName}</Tooltip>}>
                            <div className='userData-menu' onClick={() => setSideShow(false)}>
                                <img src={userInfo?.image === '' ? DummyLogo : imgData + userInfo?.image} alt="" style={{ borderRadius: '50%' }} onClick={() => navigate('/layout/profile')} />
                                <span style={{ padding: '5px 0px' }} onClick={() => navigate('/layout/profile')}>{userInfo?.businessName?.slice(0, 10) + (userInfo?.businessName?.length > 10 ? "..." : "")}</span>
                            </div>
                        </OverlayTrigger>
                        {
                            userData?.isMultiprofile ?
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Profile Switch</Tooltip>}>
                                    <AutorenewIcon className='me-2' onClick={() => { roleswitch(); }} />
                                </OverlayTrigger>
                                : null
                        }
                    </a>
                    <a onClick={() => { logOutFun() }} style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" className="icons-size" >
                            <path d="M18.958 21.625V5.542h2.125v16.083ZM20 34.208q-2.958 0-5.563-1.125-2.604-1.125-4.52-3.041Q8 28.125 6.896 25.521 5.792 22.917 5.792 20q0-3.208 1.333-6.042 1.333-2.833 3.75-4.833l1.417 1.417q-2.084 1.708-3.23 4.187-1.145 2.479-1.145 5.229 0 5 3.541 8.563Q15 32.083 20 32.083q5.042 0 8.562-3.562 3.521-3.563 3.521-8.563 0-2.75-1.146-5.229-1.145-2.479-3.229-4.187l1.417-1.417q2.458 2.042 3.771 4.854 1.312 2.813 1.312 6.021 0 2.917-1.083 5.521t-3.042 4.521q-1.958 1.916-4.562 3.041T20 34.208Z" />
                        </svg>
                        Logout
                    </a>
                    <Outlet />

                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                        <section>
                            <div style={{ float: 'right', marginTop: '-15px' }}>
                                <IconButton className="close-button" onClick={() => setOpen(false)}>
                                    <CloseIcon sx={{ color: 'white' }} />
                                </IconButton>
                            </div>
                            <div className='_card_Main' >
                                {
                                    data?.map((item, index) =>
                                        <div className='cursor _card mt-2 text-dark'
                                            onClick={() => {
                                                selectMember(item);
                                                setOpen(false);
                                            }}

                                            key={index}>
                                            <div className="top-container">
                                                {item?.image ? <img src={imgData + item?.image} alt="" width='70' style={{ borderRadius: '5px' }} /> :
                                                    <img src={require("../../Assets/images/dummyimg.png")} width='70' />}
                                                <div className="flex-row" >
                                                    <h5 className="ms-2">{item.name}</h5>
                                                    <span className="ms-2">({item?.roleTitle == 'SERVICE_PROVIDER' ? 'Service Provider' : item?.roleTitle == 'STAFF' ? 'Team' : 'Front Desk'})</span>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </section>
                    </Backdrop>

                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={branchShow}>
                        <section>
                            <div style={{ float: 'right', marginTop: '-15px' }}>
                                <IconButton className="close-button" onClick={() => setBranchOpen(false)}>
                                    <CloseIcon sx={{ color: 'white' }} />
                                </IconButton>
                            </div>
                            <div className='_card_Main' >
                                {
                                    !branchData?.length ?
                                        <div className='No_Location_card  text-dark'>
                                            <h5>No location assigned</h5>
                                        </div>
                                        :
                                        branchData?.map((item, index) =>

                                            <div className={`${item?._id === userInfo?.locationId && item?.userId === userInfo?.userId ? '_card2' : 'cursor _card'} mt-2 text-dark`}
                                                onClick={() => {
                                                    if (!(item?._id === userInfo?.locationId && item?.userId === userInfo?.userId)) {
                                                        selectLocation(item)
                                                    }
                                                }}

                                                key={index}>
                                                <div className="top-container">
                                                    <div className="flex-row" >
                                                        <h5 className="ms-2">{item?.name}</h5>
                                                        <span className="ms-2">({item?.city})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>

                        </section>
                    </Backdrop>




                </div>
            </aside>
        </>
    )
}

export default ProviderNav