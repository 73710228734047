/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { payment } from '../../Context/api'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import { useNavigate} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

const PaymentStatus = () => {

    const [paymentDetails, setPaymentDetails] = useState({
        razorpay_payment_id: '',
        razorpay_payment_link_id: '',
        razorpay_payment_link_reference_id: '',
        razorpay_payment_link_status: '',
        razorpay_signature: '',
    });
    const navigate = useNavigate()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const razorpay_payment_id = params.get('razorpay_payment_id');
        const razorpay_payment_link_id = params.get('razorpay_payment_link_id');
        const razorpay_payment_link_reference_id = params.get('razorpay_payment_link_reference_id');
        const razorpay_payment_link_status = params.get('razorpay_payment_link_status');
        const razorpay_signature = params.get('razorpay_signature');
        setPaymentDetails({
            razorpay_payment_id: razorpay_payment_id,
            razorpay_payment_link_id: razorpay_payment_link_id,
            razorpay_payment_link_reference_id: razorpay_payment_link_reference_id,
            razorpay_payment_link_status: razorpay_payment_link_status,
            razorpay_signature: razorpay_signature,
        });
    }, []);

    useEffect(() => {
        if (Object.keys(paymentDetails).length > 0) {
            const timer = setTimeout(() => {
                paymentSuccess();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [paymentDetails]);

    const paymentSuccess = () => {
        axios.post(payment(), paymentDetails).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTimeout(() => {
                    navigate('/')
                }, 5000);
            }
            else {

            }
        }).catch(() => {toast.error(commonMessage.networkError) })
    }
    if(paymentDetails.razorpay_payment_link_status!=='paid'){
        navigate('/payment-failed')
    }
    return (
        <div className="container d-flex justify-content-center align-items-center cusheight card_Main">
            <ToastContainer/>
            <div className="Success_Card">
                <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
                    <i className="checkmark text-center">✓</i>
                </div>
                <h1 className='text-center'>Success</h1>
                <p>We received your purchase payment<br /> You can use your assign features.</p>
            </div>
        </div>
    )
}

export default PaymentStatus
