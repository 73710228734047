import React from 'react'
import { ToastContainer } from 'react-toastify'
import UseSettingNotification from './Hooks/useSettingNotification'
import { intialRole } from '../../Utils/Utils';
const SettingNotification = () => {
  const { appointmentEmail,
    navigate,
    notificationData,
    userData,
    userInfo
  } = UseSettingNotification()
  // console.log(notificationData, 'notificationData')
  const reminderPermission=userData?.planDetails?.addons?.length && userData?.planDetails?.addons?.find((item) => item.keyName == 'chat')?.keyValue ?false:true

  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className="page-tittle">
              <i
                className="fa-solid fa-angle-left cusgap cursor"
                onClick={() => navigate(-1)}
              ></i>
              Notifications
            </h2>
            <hr />
          </header>
          {
            intialRole({ permission: "Notification Settings", userData: userInfo, section: 'view' }) ?
              <div className="container-fluid">
                <div className="row mt-2">
                  <div className="col-lg-2 boxx ms-0 me-4 my-2">
                    <div className="text-center pt-2">
                      <h6>Appointments</h6>
                    </div>
                    <hr className="m-0 p-0" />
                    <div className="mt-3 mb-4">
                      <div className="row">
                        <div className="col-lg-5 d-flex align-items-end pb-1">
                          <b className="ms-2 toggle_btn_font">Booked</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="ms-2 toggle_btn_font">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2 ms-2">
                                <input
                                  className="form-check-input"
                                  value={
                                    notificationData?.appointmentsBooked?.email
                                  }
                                  checked={
                                    notificationData?.appointmentsBooked?.email
                                  }
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.appointmentsBooked
                                          ?.email,
                                      notificationType: "APPOINTMENTBOOKED_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.appointmentsBooked?.push}
                                  checked={
                                    notificationData?.appointmentsBooked?.push
                                  }
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.appointmentsBooked?.push,
                                      notificationType: "APPOINTMENTBOOKED_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className="row">
                        <div className="col-lg-5">
                          <b className="ms-2 toggle_btn_font">Cancelled</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12 webhide">
                              <b className="toggle_btn_font ms-2">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch ms-2">
                                <input
                                  className="form-check-input"
                                  value={
                                    notificationData?.appointmentsCancel?.email
                                  }
                                  checked={
                                    notificationData?.appointmentsCancel?.email
                                  }
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.appointmentsCancel
                                          ?.email,
                                      notificationType: "APPOINTMENTCANCEL_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12 webhide">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.appointmentsCancel?.push}
                                  checked={
                                    notificationData?.appointmentsCancel?.push
                                  }
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.appointmentsCancel?.push,
                                      notificationType: "APPOINTMENTCANCEL_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className="row">
                        <div className="col-lg-5">
                          <b className="ms-2 toggle_btn_font">Reschedule</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12 webhide">
                              <b className="toggle_btn_font ms-2">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch ms-2">
                                <input
                                  className="form-check-input"
                                  value={
                                    notificationData?.appointmentsReschedule?.email
                                  }
                                  checked={
                                    notificationData?.appointmentsReschedule?.email
                                  }
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.appointmentsReschedule
                                          ?.email,
                                      notificationType:
                                        "APPOINTMENTRESCHEDULE_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12 webhide">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  value={
                                    notificationData?.appointmentsReschedule?.push
                                  }
                                  checked={
                                    notificationData?.appointmentsReschedule?.push
                                  }
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.appointmentsReschedule
                                          ?.push,
                                      notificationType:
                                        "APPOINTMENTRESCHEDULE_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleServices"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="text-center modal-title"
                            id="exampleModalLabel"
                          >
                            <b>Services</b>
                          </h5>
                        </div>
                        <div className="modal-body">
                          <div className="tab-content py-3" id="">
                            <div
                              className="tab-pane ms-4 fade show active newtabs"
                              id=""
                              role="tabpanel"
                              aria-labelledby=""
                            >
                              <div>
                                <ul>
                                  <li>
                                    <div className="d-flex">
                                      <div>Email</div> &nbsp; &nbsp; &nbsp;{" "}
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          value={notificationData?.services?.email}
                                          checked={
                                            notificationData?.services?.email
                                          }
                                          onChange={() =>
                                            appointmentEmail({
                                              loggedInUserId: userData?.userId,
                                              status:
                                                !notificationData?.services?.email,
                                              notificationType: "SERVICES_EMAIL",
                                            })
                                          }
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckDefault"
                                        />
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <div className="d-flex">
                                      <div>Push</div> &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          value={notificationData?.services?.push}
                                          checked={notificationData?.services?.push}
                                          onChange={() =>
                                            appointmentEmail({
                                              loggedInUserId: userData?.userId,
                                              status:
                                                !notificationData?.services?.push,
                                              notificationType: "SERVICES_PUSH",
                                            })
                                          }
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckDefault"
                                        />
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="Clients2"
                              role="tabpanel"
                              aria-labelledby="Clients2-tab"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 boxx ms-0 me-4 my-2">
                    <div className="text-center pt-2">
                      <h6>Availability</h6>
                    </div>
                    <hr className="m-0 p-0" />
                    <div className="mt-3 mb-4">
                      <div className="row">
                        <div className="col-lg-5 d-flex align-items-end pb-1">
                          <b className="ms-2 toggle_btn_font">Status</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font ms-2">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2 ms-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.availability?.email}
                                  checked={notificationData?.availability?.email}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.availability?.email,
                                      notificationType: "AVAILABILITY_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.availability?.push}
                                  checked={notificationData?.availability?.push}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status: !notificationData?.availability?.push,
                                      notificationType: "AVAILABILITY_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 boxx ms-0 me-4 my-2">
                    <div className="text-center pt-2">
                      <h6>Un-Availability</h6>
                    </div>
                    <hr className="m-0 p-0" />
                    <div className="mt-3 mb-4">
                      <div className="row">
                        <div className="col-lg-5 d-flex align-items-end pb-1">
                          <b className="ms-2 toggle_btn_font">Status</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font ms-2">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2 ms-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.unavailability?.email}
                                  checked={notificationData?.unavailability?.email}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.unavailability?.email,
                                      notificationType: "UNAVAILABILITY_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.unavailability?.push}
                                  checked={notificationData?.unavailability?.push}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.unavailability?.push,
                                      notificationType: "UNAVAILABILITY_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 boxx ms-0 me-4 my-2">
                    <div className="text-center pt-2">
                      <h6>Clients</h6>
                    </div>
                    <hr className="m-0 p-0" />
                    <div className="mt-3 mb-4">
                      <div className="row">
                        <div className="col-lg-5 d-flex align-items-end pb-1">
                          <b className="ms-2 toggle_btn_font">Accept</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font ms-2">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2 ms-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.clientAccept?.email}
                                  checked={notificationData?.clientAccept?.email}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.clientAccept?.email,
                                      notificationType: "CLIENTS_ACCEPT_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch mt-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.clientAccept?.push}
                                  checked={notificationData?.clientAccept?.push}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status: !notificationData?.clientAccept?.push,
                                      notificationType: "CLIENTS_ACCEPT_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-4">
                      <div className="row">
                        <div className="col-lg-5">
                          <b className="ms-2 toggle_btn_font">Reject</b>
                        </div>
                        <div className="col-lg-7 d-flex justify-content-around">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12 webhide">
                              <b className="toggle_btn_font ms-2">Email</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch ms-2">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.clientReject?.email}
                                  checked={notificationData?.clientReject?.email}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status:
                                        !notificationData?.clientReject?.email,
                                      notificationType: "CLIENTS_REJECT_EMAIL",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2"></div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                            <div className="col-lg-12 webhide">
                              <b className="toggle_btn_font">Push</b>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  value={notificationData?.clientReject?.push}
                                  checked={notificationData?.clientReject?.push}
                                  onChange={() =>
                                    appointmentEmail({
                                      loggedInUserId: userData?.userId,
                                      status: !notificationData?.clientReject?.push,
                                      notificationType: "CLIENTS_REJECT_PUSH",
                                    })
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row"> */}
                    <div className="col-lg-2 boxx ms-0 me-4 my-2">
                      <div className="text-center pt-2">
                        <h6>Reminder</h6>
                      </div>
                      <hr className="m-0 p-0" />
                      <div className="mt-3 mb-4">
                        <div className="row">
                          <div className="col-lg-5 d-flex align-items-end pb-1">
                            <b className="ms-2 toggle_btn_font">Email</b>
                          </div>
                          <div className="col-lg-7 d-flex justify-content-around">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">

                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2 ms-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.reminder?.email}
                                    checked={notificationData?.reminder?.email}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.reminder?.email,
                                        notificationType: "REMINDER",
                                      })
                                    }
                                    disabled={reminderPermission}
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  {/* </div> */}
                  {userData?.roleTitle === "STAFF" ? (
                    <div className="col-lg-2 boxx ms-0 me-4 my-2">
                      <div className="text-center pt-2">
                        <h6>Branch</h6>
                      </div>
                      <hr className="m-0 p-0" />
                      <div className="mt-3 mb-4">
                        <div className="row">
                          <div className="col-lg-5 d-flex align-items-end pb-1">
                            <b className="ms-2 toggle_btn_font">Status</b>
                          </div>
                          <div className="col-lg-7 d-flex justify-content-around">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12">
                                <b className="toggle_btn_font ms-2">Email</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2 ms-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.branches?.email}
                                    checked={notificationData?.branches?.email}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.branches?.email,
                                        notificationType: "BRANCHES_EMAIL",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12">
                                <b className="toggle_btn_font ms-2">Push</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2 ms-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.branches?.push}
                                    checked={notificationData?.branches?.push}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.branches?.push,
                                        notificationType: "BRANCHES_PUSH",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-2 boxx ms-0 me-4 my-2">
                      <div className="text-center pt-2">
                        <h6>Teams</h6>
                      </div>
                      <hr className="m-0 p-0" />
                      <div className="mt-3 mb-4">
                        <div className="row">
                          <div className="col-lg-5 d-flex align-items-end pb-1">
                            <b className="ms-2 toggle_btn_font">Accept</b>
                          </div>
                          <div className="col-lg-7 d-flex justify-content-around">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12">
                                <b className="toggle_btn_font ms-2">Email</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2 ms-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.teamAccept?.email}
                                    checked={notificationData?.teamAccept?.email}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status:
                                          !notificationData?.teamAccept?.email,
                                        notificationType: "TEAMS_ACCEPT_EMAIL",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12">
                                <b className="toggle_btn_font">Push</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.teamAccept?.push}
                                    checked={notificationData?.teamAccept?.push}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.teamAccept?.push,
                                        notificationType: "TEAMS_ACCEPT_PUSH",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <div className="row">
                          <div className="col-lg-5">
                            <b className="ms-2 toggle_btn_font">Reject</b>
                          </div>
                          <div className="col-lg-7 d-flex justify-content-around">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12 webhide">
                                <b className="toggle_btn_font ms-2">Email</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch ms-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.teamReject?.email}
                                    checked={notificationData?.teamReject?.email}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status:
                                          !notificationData?.teamReject?.email,
                                        notificationType: "TEAMS_REJECT_EMAIL",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12 webhide">
                                <b className="toggle_btn_font">Push</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.teamReject?.push}
                                    checked={notificationData?.teamReject?.push}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.teamReject?.push,
                                        notificationType: "TEAMS_REJECT_PUSH",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {userData?.roleTitle === "STAFF" ? (
                  // <div className="row">
                    <div className="col-lg-2 boxx ms-0 me-4 my-2">
                      <div className="text-center pt-2">
                        <h6>Services</h6>
                      </div>
                      <hr className="m-0 p-0" />
                      <div className="mt-3 mb-4">
                        <div className="row">
                          <div className="col-lg-5 d-flex align-items-end pb-1">
                            <b className="ms-2 toggle_btn_font">Status</b>
                          </div>
                          <div className="col-lg-7 d-flex justify-content-around">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12">
                                <b className="toggle_btn_font ms-2">Email</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2 ms-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.services?.email}
                                    checked={notificationData?.services?.email}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.services?.email,
                                        notificationType: "SERVICES_EMAIL",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5">
                              <div className="col-lg-12">
                                <b className="toggle_btn_font">Push</b>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-check form-switch mt-2">
                                  <input
                                    className="form-check-input"
                                    value={notificationData?.services?.push}
                                    checked={notificationData?.services?.push}
                                    onChange={() =>
                                      appointmentEmail({
                                        loggedInUserId: userData?.userId,
                                        status: !notificationData?.services?.push,
                                        notificationType: "SERVICES_PUSH",
                                      })
                                    }
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  // </div>
                ) : null}
                </div>
              </div>
              : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>
      </section>
    </>
  );
};

export default SettingNotification;
