import React from 'react'
import NoRecordImg from '../Assets/images/no record found 2.1.png'
const NoRecord = () => {
    return (
        <>
            <div className=' notfound_image_position text-center '>
                <img className='' src={NoRecordImg} alt="no_record" height={400} width={500} />
                <h3 className='mt-2'>No records found</h3>
            </div>
        </>
    )
}

export default NoRecord