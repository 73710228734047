import { Autocomplete} from '@mui/material'
import { alpha, styled } from "@mui/material/styles";
const useMuiComponent = () => {
    const CustomAutocomplete = styled(Autocomplete)({
        width: "100%",
        borderRadius: "4px",
        // border: "2px solid #f0f0f0",
        boxShadow: '0px 1px 3px #fbbf9e !important',
        minHeight: "40px",
        fontWeight: "400",
        color: "#8D8B8B",
        fontSize: "12px ",
        padding: "0",
        backgroundColor: "#fcf6f3 ",
        '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'none',
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderStyle: 'none',
            },
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderStyle: 'none',
            },
        },
        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            // padding: "2px 0px 2px 0px",
            fontSize: "12px",
        },
        " &:hover": {
            borderColor: "none",
        },
        "&.Mui-focused": {
            borderColor: "#5c6bc0",
        },
       
        
 
    })
  return {
    CustomAutocomplete
  }
}

export default useMuiComponent