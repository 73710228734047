
import { useState, useContext } from 'react'
import { useEffect } from 'react'
import { useFormik } from 'formik'
import { loginValidation, signUpValidation } from '../../../Context/validation'
import { useNavigate } from 'react-router-dom'
import { signUpApi, signInApi, selectProviderLinkedStaff, allLocation, selectLocationProvider, imgData, branchImgPath } from '../../../Context/api'
import { toast } from 'react-toastify'
import { statusCodes, commonMessage, roleNames } from '../../../Constants/generalConfig'
import axios from 'axios'
import { Notifications } from '../../../App';
import { isValidPhoneNumber } from 'react-phone-number-input'



const UseLoginSignUpPage = () => {
    const [passwordShow, setPasswordShow] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [icon, setIcon] = useState(true)
    const navigate = useNavigate()
    const userDatas = JSON.parse(localStorage.getItem('userData'))
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const [ip, setIP] = useState('');
    const { setUserInfo } = useContext(Notifications)
    const [tab, setTab] = useState(1)
    const [disabled, setDisabled] = useState(true)
    const [branchData, setBranchData] = useState([])

    const [userData, setUserData] = useState([])
    const [userInfoData, setUserInfoData] = useState([])
    const [active, SetActive] = useState('')
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    useEffect(() => {
        const sign_in_btn = document.querySelector('#sign-in-btn')
        const sign_up_btn = document.querySelector('#sign-up-btn')
        const container2 = document.querySelector('.container2')

        sign_up_btn.addEventListener('click', () => {
            container2.classList.add('sign-up-mode')
        })

        sign_in_btn.addEventListener('click', () => {
            container2.classList.remove('sign-up-mode')
        })
        if (userDatas) {
            navigate('layout/dashboard')
        }
        getData()

        // const disableBackNavigation = (event) => {
        //     event.preventDefault();
        //     window.history.forward();
        // };
        // window.history.pushState(null, null, window.location.pathname);
        // window.addEventListener('popstate', disableBackNavigation);
        // return () => {
        //     window.removeEventListener('popstate', disableBackNavigation);
        // };
    }, [])

    const signUpForm = useFormik({
        initialValues: {
            mobileNo: '',
        },
        validationSchema: signUpValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                mobileNo: values.mobileNo,
                roleTitle: roleNames.SERVICE_PROVIDER,
                timeZone: 'Asia/Kolkata',

            }
            // console.log(data,'llllllll')
            setSpinner(true)
            axios.post(signUpApi(), { ...data }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setSpinner(false)
                    signUpForm.resetForm()
                } else if (resp.data.code === 410) {
                    toast.success(resp.data.message)
                    localStorage.setItem('Mobile', JSON.stringify(resp.data.data.mobileNo))
                    localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))

                    setTimeout(() => {
                        setSpinner(false)
                        navigate('/enterOtp')
                    }, 100)
                } else if (resp.data.code === 412) {
                    toast.error(resp.data.message)
                    setTimeout(() => {
                        navigate(`/createPin/${resp.data.data.token}`)
                        setSpinner(false)
                    }, 100)
                }
                else if (resp.data.code === 411) {
                    setSpinner(false)
                    navigate('/enterOtp/createPin/basicDetailProfile')
                    localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
                    localStorage.setItem('Mobile', JSON.stringify(resp.data.data.mobileNo))
                }

                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const signInForm = useFormik({
        initialValues: {
            mobileNo: '',
            password: '',
        },
        validationSchema: loginValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            values.mobileNo = values.mobileNo
            const data = {
                mobileNo: values.mobileNo,
                password: values.password,
                timeZone: 'Asia/Kolkata',
                deviceId: ip,
                loginDevice: 'WEB'
            }
            setSpinner(true)
            axios
                .post(signInApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        if (resp.data.data.roleTitle === 'CLIENT') {
                            setSpinner(false)
                            return toast.warn('Client can not login')
                        }
                        else if (resp.data.data.roleTitle === 'ADMIN') {
                            setSpinner(false)
                            return toast.warn('Admin can not login')
                        }
                        else {
                            navigate('layout/dashboard')
                            setSpinner(false)
                        }
                        setUserInfo(resp.data.data)
                        localStorage.setItem('userData', JSON.stringify(resp.data.data))
                        // localStorage.setItem('image', json.stringify(resp.data.data.image))
                        // localStorage.setItem('subcriptionData', JSON.stringify(resp.data.data))

                    } else if (resp.data.code === 411) {
                        navigate('/enterOtp/createPin/basicDetailProfile')
                        localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
                        localStorage.setItem('Mobile', JSON.stringify(resp.data.data.mobileNo))
                    } else if (resp.data.code === 301) {
                        // navigate(`multiple-login`)
                        // localStorage.setItem('Mobile', JSON.stringify(values.mobileNo))
                        // toast.success(resp.data.message)
                        setUserData(resp.data.data)
                        setTab(2)
                        setSpinner(false)
                        signInForm.resetForm()
                    }
                    else if (resp.data.code === 1005) {
                        navigate(`team-change-pin`)
                        localStorage.setItem('Mobile', JSON.stringify(resp.data.data))
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },

    })
    const hidePassword = () => {
        setPasswordShow(true)
        setIcon(false)
    }
    const showPassword = () => {
        setPasswordShow(false)
        setIcon(true)
    }

    const selectMember = () => {
        const data = timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ?
            {
                timeZone: timeZone,
                staffId: userInfoData.staffId,
                providerId: userInfoData._id,
                mobileNo: userInfoData?.mobileNumber
            }
            :
            {
                timeZone: timeZone,
                staffId: userInfoData.staffId,
                providerId: userInfoData._id,
                email: userInfoData?.email
            }
        setSpinner(true)
        axios.post(selectProviderLinkedStaff(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.locationScreen) {
                setSpinner(false)
                branches(resp?.data?.data?.userId)
                setTab(3)
                setDisabled(true)
                SetActive('')
                setUserInfoData('')
            }
            else if (resp.data.code === 411) {
                navigate('/enterOtp/createPin/basicDetailProfile')
                localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
                localStorage.setItem('Mobile', JSON.stringify(resp.data.data.mobileNo))
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    const branches = (id) => {
        axios.post(allLocation(), { userId: id }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setBranchData(resp.data.data)
            }
            else {
                setBranchData([])

            }
        }).catch(() => { })
    }
    const selectLocation = () => {
        const data = {
            locationId: userInfoData._id,
            loginDevice: 'WEB',
            userId: userInfoData.userId,
        }
        setSpinner(true)
        axios.post(selectLocationProvider(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.setItem('userData', JSON.stringify(resp.data.data))
                setUserInfo(resp.data.data)
                toast.success(resp.data.message)
                setTimeout(() => {
                    navigate('/layout/dashboard')
                }, 1000);
                setSpinner(false)
                // localStorage.removeItem('userId')
                // localStorage.removeItem(timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile' : 'Email')
            }
            else {
                setSpinner(false)
                toast.error(resp.data.message)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    return {
        showPassword,
        hidePassword,
        getData,
        setPasswordShow,
        setIcon,
        navigate,
        setIP,
        spinner,
        setSpinner,
        ip,
        icon,
        passwordShow,
        signInForm,
        signUpForm,
        timeZone,
        exceptThisSymbols,
        setUserInfo,
        tab, setTab,
        imgData,
        disabled, setDisabled,
        userData, setUserData,
        active, SetActive,
        selectMember,
        setUserInfoData,
        branchData,
        selectLocation,
        branchImgPath

    }
}

export default UseLoginSignUpPage