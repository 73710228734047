import React from 'react'
import { ToastContainer } from 'react-toastify'
import moment from 'moment/moment'
import Spinner from '../../Components/spinner/spinner'
import Paginations from '../../Components/Pagination/pagination'
import Offer from '../../Assets/images/offers.jpg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord';
import AddOffer from './addOffer'
import EditOffer from './editOffer'
import UseOffer from './Hooks/useOffer'
import UseImage from '../../Hooks/useImage'
import useMuiComponent from '../../Hooks/useMuiComponent'
import { intialRole } from '../../Utils/Utils'
import { Modal } from 'react-bootstrap'


const OfferList = () => {
  const {
    valuetext,
    handleChange,
    offerList,
    offerView,
    servicesList,
    offerDelete,
    setImageData,
    setInActive,
    setOfferId,
    setShow,
    setShowOffer,
    setFilter,
    setPage,
    navigate,
    setMale,
    setFemale,
    setOther,
    setShowDelete,
    showDelete,
    setRewardOffer,
    rewardOffer,
    setZeroOffer,
    zeroOffer,
    createOffer,
    editOffer,
    value,
    imageData,
    serviceList,
    inActive,
    offerListData,
    selectedService,
    show,
    showOffer,
    spinner,
    filter,
    count,
    page,
    PER_PAGE,
    male,
    female,
    other,
    date,
    imgFolder,
    offerId,
    fileInputRef,
    userInfo
  } = UseOffer()
  const { imageDeleteUpload, imageDelete, uploadImage } = UseImage()
  const { CustomAutocomplete } = useMuiComponent()
  const Edit_Permission = intialRole({ permission: "offers", userData: userInfo, section: 'edit' })

  const handleClick = (id, offerType) => {
    if (Edit_Permission) {
      offerView(id);
      setRewardOffer(offerType === 'REWARDS');
    }
  };
// console.log(filter,'filter')
  return (
    <>
      <ToastContainer />
      <section className="position-relative">
        <div className="m-t-3 cus-padd cusheight">
          {spinner && <Spinner />}
          <header>
            <h2 className="page-tittle">Offers</h2>
            <hr />
          </header>
          {
            intialRole({ permission: "offers", userData: userInfo, section: 'view' }) ?
              <div className="row">
                <div className="col-sm-12 listpadding">
                  <div className="d-flex justify-content-end">
                    <div className='me-2'>
                      <select className='form-select' name="offerFilter"  value={filter?.offerFilter} onChange={(e) => setFilter((pre)=>({...pre,offerFilter:e.target.value}))}>
                        <option value="ALL">All</option>
                        <option value="OFFER">Offer</option>
                        <option value="REWARDS">Rewards</option>
                      </select>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by name"
                        onChange={(e) => setFilter((pre)=>({...pre,searchText:e.target.value}))}
                      />
                    </div>
                    <button className='btn ms-2 mb-3 btn_Style' type='submit' onClick={() => offerList()}>Search</button>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset</Tooltip>}>
                      <i className="fa fa-refresh ms-3 mt-2 me-1" aria-hidden="true" onClick={() => filter?.searchText?.length ? window.location.reload() : null} style={{ marginRight: '-10px', cursor: 'pointer' }}></i>
                    </OverlayTrigger>
                  </div>
                  {
                    !offerListData?.length ? <NoRecord /> :

                      <div className="table-responsive">
                        <table className="table cus-table">
                          <thead className="text-center tableheader">
                            <tr>
                              <th scope="col">Image</th>
                              <th scope="col">Name</th>
                              <th scope="col">Service</th>
                              <th scope="col">Audience</th>
                              <th scope="col">Type</th>
                              <th scope="col">Date Range</th>
                              {intialRole({ permission: "offers", userData: userInfo, section: 'delete' }) ? <th scope="col">Action</th> : null}
                            </tr>
                          </thead>

                          <tbody>
                            {offerListData?.map((item, index) => (
                              <tr className={Edit_Permission ? 'cursor' : null} key={index}>
                                <td style={{ padding: '0.3rem .5rem' }} onClick={() => handleClick(item._id, item.offerType)}>
                                  <img src={item.image === '' ? Offer : imgFolder + item.image} alt="" height={30} width={30} style={{ borderRadius: '50%' }} />
                                </td>
                                <td onClick={() => handleClick(item._id, item.offerType)}>{item.name}</td>
                                <td onClick={() => handleClick(item._id, item.offerType)}>
                                  {item.serviceData?.map((data, i) =>
                                    <b key={i}>{i === 0 ? data.name.slice(0, 10) + (data.name.length > 10 ? "..." : "") : null}</b>
                                  )}
                                </td>
                                <td onClick={() => handleClick(item._id, item.offerType)}>{`${item.audience == null || !item?.audience?.gender?.length ? '--' : item.audience.gender}`}</td>
                                <td onClick={() => handleClick(item._id, item.offerType)}>{item?.offerType}</td>
                                <td onClick={() => handleClick(item._id, item.offerType)}>
                                  {moment(item.startDate).format('DD-MM-YYYY')} To {moment(item.endDate).format('DD-MM-YYYY')}
                                </td>
                                {
                                  intialRole({ permission: "offers", userData: userInfo, section: 'delete' }) ?
                                    <td>
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">Delete</Tooltip>
                                        }
                                      >
                                        <i
                                          className="fa-solid fa-trash-can text-danger"
                                          onClick={() => { setShowDelete(true); setOfferId(item._id) }}
                                          style={{ cursor: "pointer", fill: "red" }}
                                        ></i>
                                      </OverlayTrigger>
                                    </td>
                                    : null
                                }
                              </tr>
                            ))}
                          </tbody>

                        </table>
                        <div className="w-100 d-flex justify-content-center">
                          {count > 10 && (
                            <Paginations
                              setpage={setPage}
                              count={Math.ceil(count / PER_PAGE)}
                              page={page}
                            />
                          )}
                        </div>
                      </div>
                  }
                </div>
              </div>
              :
              <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>
        {
          intialRole({ permission: "offers", userData: userInfo, section: 'create' }) ?
            <div className="d-flex justify-content-end">
              {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Create Offer</Tooltip>}>
                <p className="btn addbtn2">
                  <i className="fa-solid fa-plus" onClick={() => { setShowOffer(true); servicesList(); setZeroOffer(false) }}></i>
                </p>
              </OverlayTrigger> */}
              {/* <ul className="navi"> */}
              {/* <li> */}
              <div className="dropdown">
                <p className="mb-4 me-3  addbtn2" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Create Offer</Tooltip>}>
                    <i className="fa-solid fa-plus"></i>
                  </OverlayTrigger>
                </p>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li className="dropdown-item cursor" onClick={() => { setShowOffer(true); servicesList(); setZeroOffer(false); setRewardOffer(false) }}>
                   Create Offer
                  </li>
                  <li className="dropdown-item cursor" onClick={() => { setShowOffer(true); servicesList(); setZeroOffer(false); setRewardOffer(true) }}>
                    Create Reward
                  </li>
                </ul>
              </div>
              {/* </li> */}
              {/* </ul> */}
            </div>
            : null
        }


      </section>

      <AddOffer rewardOffer={rewardOffer} zeroOffer={zeroOffer} setZeroOffer={setZeroOffer} fileInputRef={fileInputRef} imageDeleteUpload={imageDeleteUpload} showOffer={showOffer} setShowOffer={setShowOffer} createOffer={createOffer} setImageData={setImageData}
        setMale={setMale} setFemale={setFemale} setOther={setOther} imageUpload={uploadImage} CustomAutocomplete={CustomAutocomplete}
        imageData={imageData} male={male} female={female} other={other} date={date} valuetext={valuetext} navigate={navigate} serviceList={serviceList} handleChange={handleChange} value={value}
        imgFolder={imgFolder} setInActive={setInActive} />

      <EditOffer rewardOffer={rewardOffer} zeroOffer={zeroOffer} setZeroOffer={setZeroOffer} fileInputRef={fileInputRef} offerId={offerId} imageDelete={imageDelete} show={show} setShow={setShow} setImageData={setImageData} male={male} setMale={setMale} female={female}
        setFemale={setFemale} other={other} setOther={setOther} imageData={imageData} CustomAutocomplete={CustomAutocomplete}
        imageUpload={uploadImage} editOffer={editOffer} value={value} serviceList={serviceList} moment={moment} handleChange={handleChange} imgFolder={imgFolder} valuetext={valuetext} setInActive={setInActive}
        inActive={inActive} selectedService={selectedService} />

      {/* Offer Delete*/}
      <>
        <Modal
          show={showDelete}
          size="sm"
          centered
          onHide={() => setShowDelete(false)}
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p>Are you sure !</p>
              <p>You want to delete this offer</p>
              <button
                type="button"
                className="btn yesbtn"
                data-bs-dismiss="modal"
                onClick={() => offerDelete()}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn nobtn ms-1"
                data-bs-dismiss="modal"
                onClick={() => setShowDelete(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default OfferList;
