import React from 'react'
import { ToastContainer } from 'react-toastify'
import moment from 'moment/moment'
import Spinner from '../../Components/spinner/spinner'
import Paginations from '../../Components/Pagination/pagination'
import ValidationMessage from '../ValidationMessage/validationMessage'
import Offcanvas from 'react-bootstrap/Offcanvas'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import Calendars from 'react-calendar'
import ScrollBars from 'react-scrollbar'
import NoRecord from '../../Components/noRecord'
import UseAppointmentList from './Hooks/useAppointmentList'
import { intialRole } from '../../Utils/Utils'
import { Backdrop } from '@mui/material'
import ServiceImg from '../../Assets/images/services-icon.png';

const AppointmentList = () => {
    const { staffService,
        getBranchList,
        appointmentDelete,
        appointmentReschedule,
        timesSlot,
        clearAllFilter,
        setStatus,
        setService,
        setClient,
        setBranches,
        handleChange,
        handleInputChange,
        getAppointmentData,
        setModalData,
        setPage,
        setShow,
        setShowModal,
        setSlotTime,
        setSlots,
        setShowFilter,
        setDateFilter,
        navigate,
        getStaffList,
        setShowDelete,
        setTab,
        clientCombo,
        setCount,
        tab,
        comboImgPath,
        clientComboData,
        showDelete,
        statusNames,
        PER_PAGE,
        show,
        showModal,
        slotTime,
        slots,
        val,
        cancelAppointment,
        appointmentListData,
        modalData,
        spinner,
        page,
        count,
        showFilter,
        branchList,
        clientList,
        serviceList,
        dateFilter,
        filterInput,
        userInfo } = UseAppointmentList()

    return (
        <>
            <ToastContainer />
            <section>
                <div className="m-t-3 cus-padd cusheight position-relative">
                    {spinner ? <Spinner /> : null}
                    <header>
                        <div className='d-flex justify-content-between'>
                            <h2 className="page-tittle">Appointment List</h2>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Calendar</Tooltip>}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" className="icons-size" onClick={() => navigate('calendar')} style={{ cursor: 'pointer' }} >
                                    <path d="m18.208 29.875-5.291-5.25 1.5-1.458 3.791 3.791 7.375-7.416 1.5 1.458Zm-9.291 5.958q-1.292 0-2.209-.916-.916-.917-.916-2.25V10.292q0-1.292.916-2.23.917-.937 2.209-.937h2.416V3.792h2.292v3.333H26.5V3.792h2.208v3.333h2.375q1.292 0 2.209.937.916.938.916 2.23v22.375q0 1.333-.916 2.25-.917.916-2.209.916Zm0-2.125h22.166q.375 0 .688-.333.312-.333.312-.708V17H7.917v15.667q0 .375.312.708.313.333.688.333Zm-1-18.833h24.166v-4.583q0-.375-.312-.709-.313-.333-.688-.333H8.917q-.375 0-.688.333-.312.334-.312.709Zm0 0V9.25v5.625Z" />
                                </svg>
                            </OverlayTrigger>
                        </div>
                        <hr />
                        <nav className="mb-3">
                            <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
                                <button className={` ${tab === 1 ? 'activeNav' : 'unActiveNav'} border-0`} onClick={() => { setTab(1); setPage(1); setCount(1) }} id="basicdetails" type="button">
                                    Appointments List
                                </button>
                                <button className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`} type="button" onClick={() => { setTab(2); clientCombo(); setPage(1); setCount(1) }} >
                                    Combo & Packages
                                </button>
                            </div>
                        </nav>
                    </header>
                    {
                        tab === 1 &&
                        <div className='scroll'>
                            <div className="row">
                                {
                                    intialRole({ permission: "appointment", userData: userInfo, section: 'ViewAppointmentList' }) ?
                                        <div className="col-sm-12 listpadding">
                                            {appointmentListData.length === 0 ? null : <p className='d-flex float-start'>Total Appointments : {appointmentListData.length === 0 ? 0 : appointmentListData.totalCount}</p>}
                                            <dl className="d-flex justify-content-end">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Filter</Tooltip>}>
                                                    <ul className="navicon cursor">
                                                        <li>
                                                            <i className="fa-solid fa-sliders" onClick={() => { setShowFilter(true); getBranchList(); staffService() }}></i>
                                                        </li>
                                                    </ul>
                                                </OverlayTrigger>
                                            </dl>
                                            {/* Apppointment List Start */}
                                            {
                                                appointmentListData.length === 0 ?
                                                    <NoRecord />
                                                    :
                                                    <div className="table-responsive">
                                                        <table className="table cus-table">
                                                            <thead className="text-center tableheader">
                                                                <tr>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Time</th>
                                                                    <th scope="col">Appointment ID</th>
                                                                    {/* <th scope="col">Team</th> */}
                                                                    <th scope="col">Branch</th>
                                                                    {/* <th scope="col">Service</th> */}
                                                                    <th scope="col">Client</th>
                                                                    <th scope="col">Status</th>
                                                                    {/* <th scope="col">Action</th> */}
                                                                    {
                                                                        !intialRole({ permission: "appointment", userData: userInfo, section: 'reschedule' }) && !intialRole({ permission: "appointment", userData: userInfo, section: 'delete' })
                                                                            && !intialRole({ permission: "appointment", userData: userInfo, section: 'cancel' }) ? null :

                                                                            // intialRole({ permission: "appointment", userData: userInfo, section: 'action' }) ? <th scope="col">Action</th> : null
                                                                            <th scope="col">Action</th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            {appointmentListData.length === 0 ? <div className='position-absolute top-20 start-50 mt-5'><h3>No record found</h3></div> :
                                                                <tbody>
                                                                    {appointmentListData.data?.map((item) => (
                                                                        <tr className='cursor' key={item._id}>
                                                                            <td onClick={() => navigate(`appointmentView/${item._id}`)}>
                                                                                {moment(item.startDateTime).format('DD-MM-YYYY')}
                                                                            </td>
                                                                            <td onClick={() => navigate(`appointmentView/${item._id}`)}>{moment(item.startDateTime).format('LT')} - {moment(item.endDateTime).format('LT')}</td>
                                                                            <td onClick={() => navigate(`appointmentView/${item._id}`)}>{item.appointment_number}</td>
                                                                            {/* <td onClick={() => navigate(`appointmentView/${item._id}`)}>{item.staffName}
                                                                            </td> */}
                                                                            <td>{item.branchName}</td>
                                                                            {/* <td onClick={() => navigate(`appointmentView/${item._id}`)}>{item.serviceName}</td> */}
                                                                            <td onClick={() => navigate(`appointmentView/${item._id}`)}>{item.clientName}</td>
                                                                            <td onClick={() => navigate(`appointmentView/${item._id}`)}>{item.isRescheduled === true && item.status === 'CANCELLED' ? item.status : item.status === 'PENDING' ? item.status : item.status === 'DECLINED' ? item.status : item.isRescheduled === true ? 'RESCHEDULE' : item.status}</td>
                                                                            {
                                                                                !intialRole({ permission: "appointment", userData: userInfo, section: 'reschedule' }) && !intialRole({ permission: "appointment", userData: userInfo, section: 'delete' })
                                                                                    && !intialRole({ permission: "appointment", userData: userInfo, section: 'cancel' }) ? null
                                                                                    :
                                                                                    <td>
                                                                                        <ul className="iconul" style={{ marginBottom: '-1px' }}>
                                                                                            {
                                                                                                intialRole({ permission: "appointment", userData: userInfo, section: 'delete' }) ?
                                                                                                    <li>
                                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                                                            <i className="fa-solid fa-trash-can" onClick={() => { setModalData(item); setShowDelete(true) }}></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </li>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                intialRole({ permission: "appointment", userData: userInfo, section: 'cancel' }) ?
                                                                                                    <li>
                                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cancel</Tooltip>}>
                                                                                                            <i
                                                                                                                className="fa-solid fa-ban disabled"
                                                                                                                onClick={() => {
                                                                                                                    setModalData(item);
                                                                                                                    if (item.status !== 'CANCELLED') {
                                                                                                                        setShowModal(true);
                                                                                                                    }
                                                                                                                }}
                                                                                                            ></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </li>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                intialRole({ permission: "appointment", userData: userInfo, section: 'reschedule' }) ?
                                                                                                !item?.isComboPackage&&
                                                                                                    <li>
                                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reschedule</Tooltip>}>
                                                                                                            <i
                                                                                                                className="fa-solid fa-calendar"
                                                                                                                onClick={() => {
                                                                                                                    setModalData(item);
                                                                                                                    if (!(item.isRescheduled === true || item.status === 'CANCELLED')) {
                                                                                                                        setShow(true);
                                                                                                                        getStaffList(item);
                                                                                                                    }
                                                                                                                }}
                                                                                                            ></i>
                                                                                                        </OverlayTrigger>
                                                                                                    </li>
                                                                                                    : null
                                                                                            }
                                                                                        </ul>
                                                                                    </td>
                                                                            }
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            }
                                                        </table>
                                                    </div>
                                            }
                                            <div className='w-100 d-flex justify-content-center'>
                                                {
                                                    count > 10 &&
                                                    <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                                                }
                                            </div>
                                            {/* Apppointment List End */}
                                        </div>
                                        : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                                }
                            </div>
                        </div>
                    }
                    {
                        tab === 2 &&
                        <>
                            {
                                !clientComboData.length ? <NoRecord />
                                    :
                                    <div className="table-responsive">
                                        <table className="table cus-table">
                                            <thead className="text-center tableheader">
                                                <tr>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Client Name</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Validity(In Days)</th>
                                                </tr>
                                            </thead>
                                            {clientComboData?.length === 0 ? <div className='position-absolute top-20 start-50 mt-5'><h3>No record found</h3></div> :
                                                <tbody>
                                                    {clientComboData?.map((item) => (<React.Fragment key={item._id}>
                                                        <tr className='cursor' onClick={() => {navigate(`combo-view/${item._id}`) }}>
                                                            <td key={item._id}><img src={item.image === '' ? ServiceImg : comboImgPath + item.image} alt="" height={30} width={30} style={{ borderRadius: '50%' }} /></td>
                                                            <td>{item.clientName}</td>
                                                            <td>{item.name}</td>
                                                            <td>{userInfo?.currencySymbol} {item.price}</td>
                                                            <td>{item.validity}</td>
                                                        </tr>
                                                    </React.Fragment>))}
                                                </tbody>
                                            }
                                        </table>
                                        <div className='w-100 d-flex justify-content-center'>
                                            {clientComboData.length === 0 ? null :
                                                count > 3 &&
                                                <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                                            }
                                        </div>
                                    </div>
                            }
                        </>
                    }
                </div>

            </section>

            {/* Appointment Cancel Start  */}
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <div className="modal-body">
                    <p className="text-center">Are You Sure !</p>
                    <p className="text-center">You want to cancel this appointment</p>
                    <div className="mb-3">
                        <label className="form-label">Reason *</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name='reason' value={cancelAppointment.values.reason} onChange={cancelAppointment.handleChange}></textarea>
                        <ValidationMessage formName={cancelAppointment} name={'reason'} />
                    </div>
                    <button
                        type="submit"
                        className="btn nobtn"
                        onClick={cancelAppointment.handleSubmit}
                        style={{ marginLeft: '20%' }}
                    >
                        Yes
                    </button>
                    <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => setShowModal(false)}>
                        No
                    </button>
                </div>
            </Modal>
            {/* Appointment Cancel End */}

            {/*Appointment Reschedule  Start */}
            <Backdrop open={show}>
                <Offcanvas show={show} onHide={() => setShow(false)} placement="end" backdrop={false}>
                    <div style={{ padding: '10px' }}>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShow(false); setSlots([]) }}></button>
                        <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                            Reschedule Appointment
                        </h1>
                    </div>
                    <div className="offcanvas-body">
                        <div className="cus-form">
                            <div className="mb-3">
                                <label className="form-label">Client Name *</label>
                                <input type="text" className="form-control" value={modalData.clientName} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Booking Type *</label>
                                <select className="form-control" name="visitType" value={modalData.visitType} disabled>
                                    <option hidden>Select Booking Type</option>
                                    <option value='INOFFICE'>In-Office</option>
                                    <option value='HOME'>Home</option>
                                    <option value='ONLINE'>Online</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Service *</label>
                                <input type="text" className="form-control" value={modalData.serviceName} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Price</label>
                                <input type="number" className="form-control" disabled name="price" value={modalData.amount || modalData.servicePrice} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Branch *</label>
                                <input type="text" className="form-control" value={modalData.branchName} />
                            </div>
                            <Calendars onChange={(e) => { timesSlot(e); setSlotTime([]) }} />
                            <ScrollBars horizontal style={{ height: '200px', width: '100%', marginTop: '10px' }} smoothScrolling={true}>
                                <div className="form-check mt-2">
                                    {slots.length ? null : <h4 className='text-center'>No Slots Available</h4>}
                                    {slots?.map((item, index) => (
                                        <>
                                            <div className='row'>
                                                <button
                                                    type="button"
                                                    style={{ backgroundColor: (item.isActive ? '#f47d3a' : '#fff'), color: (item.status === 'Booked' || item.status === 'Unavailable' ? 'grey' : (item.isActive ? '#fff' : '#f47d3a')), width: '300px', marginLeft: '2px', border: (item.status === 'Booked' || item.status === 'Unavailable' ? '1px solid grey' : '1px solid #f47d3a') }}
                                                    className="btn me-5 mb-2 col-sm-12"
                                                    disabled={item.status === 'Booked' || item.status === 'Unavailable' ? true : false}
                                                    onClick={() => {
                                                        setSlots((prevState) => {
                                                            const newData = prevState.map((el, i) => {
                                                                if (i === index) return { ...el, isActive: true }
                                                                else return { ...el, isActive: false }
                                                            })
                                                            return [...newData]
                                                        })
                                                        setSlotTime(item)
                                                    }}
                                                >
                                                    {moment(item.startTime).format('LT')} - {moment(item.endTime).format('LT')} {(item.status === 'Booked') ? 'Booked' : (item.status === 'Unavailable') ? 'Unavailable' : null}
                                                </button>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </ScrollBars>
                            <div className="d-grid  col-6 mx-auto mb-4">
                                <button disabled={slotTime.length === 0 ? true : false} className="btn colorbtun popupbtn px" onClick={() => appointmentReschedule()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Offcanvas>
            </Backdrop>
            {/*Appointment Reschedule End */}

            {/* Filter Start */}
            <Offcanvas show={showFilter} onHide={() => setShowFilter(false)} placement="end" style={{ height: '100vh' }}>
                <div style={{ padding: '10px' }}>
                    <button type="button" className="btn-close mb-3" onClick={() => setShowFilter(false)}></button>
                    <div className="d-flex justify-content-between">
                        <h3 className="heading-color " id="offcanvasRightLabel" >
                            Filters
                        </h3>
                        <h3 className=" heading-color" id="offcanvasRightLabel" onClick={() => clearAllFilter()} style={{ cursor: 'pointer' }}>
                            Clear All
                        </h3>
                    </div>
                </div>
                <hr style={{ margin: '0em' }} />
                <div className="">
                    <div className="d-flex align-items-start  sidenav">
                        <div className="col-sm-5 sidenavlink" style={{ background: '#EAEAEA', height: '100vh' }}>
                            <div className="nav flex-column nav-pills ms-2 me-2 mt-1" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                                <button className="nav-link active text-black" id="daterange" data-bs-toggle="pill" data-bs-target="#datepill" type="button" role="tab" aria-controls="datepill" aria-selected="true">
                                    Date Range
                                </button>
                                <button className="nav-link text-black" id="branch" data-bs-toggle="pill" data-bs-target="#branchpill" type="button" role="tab" aria-controls="branchpill" aria-selected="false">
                                    Branch
                                </button>
                                <button className="nav-link text-black" id="client" data-bs-toggle="pill" data-bs-target="#clientpill" type="button" role="tab" aria-controls="clientpill" aria-selected="false">
                                    Client
                                </button>
                                <button className="nav-link text-black" id="service" data-bs-toggle="pill" data-bs-target="#servicepill" type="button" role="tab" aria-controls="servicepill" aria-selected="false">
                                    Service
                                </button>
                                <button className="nav-link text-black" id="status" data-bs-toggle="pill" data-bs-target="#statuspill" type="button" role="tab" aria-controls="statuspill" aria-selected="false">
                                    Status
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-7 ps-1 pe-1 mt-1 me-1">
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* Date Range Start */}
                                <div className="tab-pane fade show active" id="datepill" role="tabpanel" aria-labelledby="daterange" tabIndex="0">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="today"
                                            name="flexRadioDefault"
                                            onClick={() => {
                                                setDateFilter('TODAY')
                                            }}
                                            checked={dateFilter === 'TODAY'}
                                        />
                                        <label className="form-check-label" htmlFor="today">
                                            Today's
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="last7"
                                            name="flexRadioDefault"
                                            onClick={() => {
                                                setDateFilter('LAST7')
                                            }}
                                            checked={dateFilter === 'LAST7'}
                                        />
                                        <label className="form-check-label" htmlFor="last7">
                                            Last 7 Days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="last30"
                                            name="flexRadioDefault"
                                            onClick={() => {
                                                setDateFilter('LAST30')
                                            }}
                                            checked={dateFilter === 'LAST30'}
                                        />
                                        <label className="form-check-label" htmlFor="last30">
                                            Last 30 Days
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="last30"
                                            name="flexRadioDefault"

                                            onClick={() => {
                                                setDateFilter('CUSTOM')
                                            }}
                                            checked={dateFilter === 'CUSTOM'}
                                        />
                                        <label className="form-check-label" htmlFor="last30">
                                            Custom
                                        </label>
                                    </div>
                                    {
                                        dateFilter === 'CUSTOM' ?
                                            <div className="d-flex flex-column align-items-center">
                                                <div >
                                                    <input type="date" className="form-control" name="startDate" value={filterInput.startDate} onChange={handleInputChange} />
                                                </div>
                                                <div className="col-auto">-</div>
                                                <div >
                                                    <input type="date" className="form-control" name="endDate" value={filterInput.endDate} onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                                {/* Date Range End */}

                                {/* Branch Start */}
                                <div className="tab-pane fade" id="branchpill" role="tabpanel" aria-labelledby="branch" tabIndex="0">
                                    <input type="search" className="form-control" placeholder="Search Branch......" onChange={handleChange} name="branchFil" />
                                    <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '700px' }} smoothScrolling={true} >
                                        {branchList?.filter((value) => value.name.toLowerCase().includes(val.branchFil.toLowerCase()))
                                            .map((branchData, i) => (
                                                <div className="form-check" key={branchData._id}>
                                                    <label className="form-check-label" htmlFor={branchData.name}>
                                                        <input className="form-check-input" type="checkbox" id={branchData._id}
                                                            checked={branchData.isChecked}
                                                            value={branchData._id} name="branch" onChange={(e) => { setBranches(e, branchData._id, i) }} />
                                                        {branchData.name}
                                                    </label>
                                                </div>
                                            ))}
                                    </ScrollBars>
                                </div>
                                {/* Branch End */}

                                {/* Team End */}

                                {/* Client Start */}
                                <div className="tab-pane fade" id="clientpill" role="tabpanel" aria-labelledby="client" tabIndex="0">
                                    <input type="search" className="form-control" placeholder="Search Client......" onChange={handleChange} name="clientFil" />
                                    <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '700px' }} smoothScrolling={true} >
                                        {clientList
                                            ?.filter((value) => value.name.toLowerCase().includes(val.clientFil.toLowerCase()))
                                            ?.map((data, i) => (
                                                <div className="form-check" key={data._id}>
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" name="client" checked={data.isChecked} value={data._id} onChange={(e) => setClient(e, data._id, i)} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        {data.name}
                                                    </label>
                                                </div>
                                            ))}
                                    </ScrollBars>
                                </div>
                                {/* Client End */}

                                {/* Services Start */}
                                <div className="tab-pane fade" id="servicepill" role="tabpanel" aria-labelledby="service" tabIndex="0">
                                    <input type="search" className="form-control" placeholder="Search Service......" onChange={handleChange} name="serviceFil" />
                                    <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '700px' }} smoothScrolling={true} >
                                        {serviceList?.filter((value) => value.name.toLowerCase().includes(val.serviceFil.toLowerCase()))?.map((serviceData, i) => (
                                            <div className="form-check" key={serviceData._id}>
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={serviceData.isChecked} value={serviceData._id} name="service" onChange={(e) => setService(e, serviceData._id, i)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {serviceData.name}
                                                </label>
                                            </div>
                                        ))}
                                    </ScrollBars>
                                </div>
                                {/* Services End */}

                                {/* Status Start */}
                                <div className="tab-pane fade" id="statuspill" role="tabpanel" aria-labelledby="status" tabIndex="0">
                                    {statusNames?.map((item, i) => (
                                        <div className="form-check" key={i}>
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" name="status" checked={item.isChecked} value={item.value} onChange={(e) => setStatus(e, item.value, i)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {/* Status End */}
                            </div>
                        </div>
                    </div>
                    <div className="d-grid  col-6 mx-auto mb-3">
                        <button className="btn colorbtun popupbtn px" type="submit" onClick={() => getAppointmentData()} style={{ marginLeft: '17%', marginBottom: '30px' }}>
                            Search
                        </button>
                    </div>
                </div>
            </Offcanvas>
            {/* Filter End */}

            {/* Appointment Delete Start */}
            <Modal centered size='sm' show={showDelete} onHide={() => setShowDelete(false)}>
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <p>Are you sure !</p>
                        <p>You want to delete this appointment
                        </p>
                        <button type="button" className="btn nobtn" data-bs-dismiss="modal" onClick={() => appointmentDelete()}>Yes</button>
                        <button type="button" className="btn  ms-1 yesbtn" data-bs-dismiss="modal" onClick={() => setShowDelete(false)}>No</button>
                    </div>
                </div>
            </Modal>
            {/* Appointment Delete End */}
        </>
    )
}

export default AppointmentList