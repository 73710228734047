import React from 'react'
import Logo from '../../Assets/images/logo white color.png'
import android from '../../Assets/images/apple.png'
import iphone from '../../Assets/images/google.png'
import { useLocation } from 'react-router-dom'


const AppnotFound = () => {
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    return (
        <>
            <body className='appnotfound_bg'>
                <div className='d-flex align-items-center h-100'>
                    <div className='appnotfound_mainbg text-center mx-auto py-5' >
                        <div style={{ marginBottom: '100px' }}>
                            <a href="https://iskedo.com/"><img src={Logo} alt="" width={150} height={150} /></a>
                        </div>
                        {
                            splitLocation[1] == 'provider-app' ?
                                <>
                                    <div className='mb-3' >

                                        <a href="https://play.google.com/store/apps/details?id=com.project.iskedo.iskedo_rpm"><img src={iphone} width={250} height={70} alt="android" /></a>
                                    </div>
                                    <div className='' >
                                        <a href="https://apps.apple.com/in/app/iskedo-provider/id6470967699"><img src={android} width={250} height={70} alt="ios" /></a>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='mb-3' >

                                        <a href="https://play.google.com/store/apps/details?id=com.iskedo.iskedo"><img src={iphone} width={250} height={70} alt="android" /></a>
                                    </div>
                                    <div className='' >
                                        <a href="https://apps.apple.com/us/app/iskedo-appointment-booking/id6470637085"><img src={android} width={250} height={70} alt="ios" /></a>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </body>
        </>
    )
}

export default AppnotFound
