import { toast } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';
import { updatePdfAPI, uploadPoliciesApi, getPoliciesApi, pdf, deletePdfApi, updateCancelPolicy, getPolicie, logOutApi } from '../../../Context/api';
import axios from 'axios';
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig';
import { useNavigate } from 'react-router-dom';
import { Notifications } from '../../../App';

const border = {
  textAlign: 'center',
  padding: '3%',
  borderStyle: 'dotted',
  borderRadius: '15px'
}

const UseCancellation = () => {
  const { userInfo } = useContext(Notifications)
    const [pdfdData, setPdfData] = useState();
    const [policiesData, setPoliciesData] = useState();
    const [showPolicy, setShowPolicy] = useState(true)
    const [spinner, setSpinner] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [show, setShow] = useState(false)
    const [hours, setHours] = useState('')
    const navigate = useNavigate()
    const [showDelete,setShowDelete]=useState(false)

    useEffect(() => {
      if (userData == null) {
        navigate('/')
      }
      else{
        getPolicies()
        setSpinner(true)
        policy()
      }
    }, [])
  
    const logOutFun = () => {
      axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          localStorage.removeItem('userData')
          navigate('/')
        }
      })
    }
    const uploadPdf = (e) => {
      const fd = new FormData();
      const fileTypes = { pdf_File_Type: ['application/pdf'] };
      if (e.size / 1024 / 1024 > 10)
        return toast.warn("Please upload file less than 10 mb");
      if (!e.type.includes(fileTypes.pdf_File_Type))
        return toast.warn("Only PDF format allowed");
      fd.append("file", e);
      fd.append("type", 'PDF');
      axios.post(uploadPoliciesApi(), fd, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          // toast.success(resp.data.message)
          setPoliciesData(resp.data.data.db_name)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          toast.error(resp.data.message)
        }
      });
    }
    const getPolicies = () => {
      const data={ 
        // loggedInUserId: userData?.userId
        loggedInUserId:userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId,
        branchId:userData?.locationId

     }
     if(userData?.roleTitle!==roleNames.SERVICE_PROVIDER){
        data['createdbyId']=userData?.createdbyId
     }
      axios.post(getPoliciesApi(),data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setSpinner(false)
          setShowPolicy(false)
          if (resp.data.data.cancellation === '') setShowPolicy(true)
          setPdfData(resp.data.data.cancellation)
          setPoliciesData('')
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        // else if(resp.data.code===statusCodes.notAuthorized){
        //   logOutFun()
        // }
        else {
          setSpinner(false)
        }
      })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
    })
    }
    const updatePdf = async () => {
      let data = { loggedInUserId: userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId, 
        pdfName: policiesData,
         policyType: "CANCELLATION_POLICY",
         branchId:userData?.locationId
         }
      if (data.pdfName) {
        await updatePdfAPI(data)
        getPolicies()
        setSpinner(false)
        setPoliciesData('')
      }
      else {
        setSpinner(false)
      }
    }
    const deletePdf = () => {
      let data = { loggedInUserId: userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId, policyType: "CANCELLATION_POLICY", pdfName: pdfdData,branchId:userData?.locationId }
      deletePdfApi(data)
      setShowPolicy(true)
      setShowDelete(false)
      setPoliciesData('')
      setPdfData('')
  
    }
    const updatePolicy = () => {
      const data = {
        branchId:userData?.locationId,
        updateType: "CANCELLATION",
        policy: {
          hours:show?hours:0,
          isAllowed: show
        }
      }
      axios.post(updateCancelPolicy(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          toast.error(resp.data.message)
        }
      })
    }
    const policy = () => {
      axios.post(getPolicie(), {branchId:userData?.locationId,}, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setHours(resp.data.data.cancel.hours)
          setShow(resp.data.data.cancel.isAllowed)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
  
        }
      }).catch(()=>{})
    }
  return {
    uploadPdf,
    updatePdf,
    deletePdf,
    updatePolicy,
    setPdfData,
    setPoliciesData,
    setShowPolicy,
    setSpinner,
    setShow,
    setHours,
    navigate,
    setShowDelete,
    showDelete,
    hours, 
    show, 
    spinner, 
    showPolicy, 
    policiesData, 
    pdfdData, 
    border,
    userData,
    pdf,
    userInfo
  }
}

export default UseCancellation