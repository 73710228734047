import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { clientViewApi, logOutApi, updateClientApi, imgData, clientMember, appointmentHistory, clientPurchaseList, comboImgPath } from '../../../Context/api';
import { toast } from 'react-toastify';
import { statusCodes, roleNames, commonMessage } from '../../../Constants/generalConfig';
import { addClientValidation, addClientValidationIndia } from '../../../Context/validation';
import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';



const UseClientView = () => {
    const { id } = useParams()
    const [spinner, setSpinner] = useState(false)
    const [imageData, setImageData] = useState('')
    const [clientMembers, setClientMembers] = useState([])
    const [appointmentData, setAppointmentData] = useState([])
    const [tab, setTab] = useState(1)
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const fileInputRef = useRef(null);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    useEffect(() => {
        clientView()
        members()

    }, [])

    useEffect(() => {
        if (tab == 2) {
            history()
        }
        if (tab == 3) {
            clientCombo()
        }
    }, [page])
    console.log(appointmentData, 'appointmentData')

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const members = () => {
        axios.post(clientMember(), { clientId: id, isClientMemberProvider: true }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code == statusCodes.ok) {
                setClientMembers(resp.data.data)
            }
            else {
                setClientMembers([])
            }
        }).catch(() => { })
    }
    const clientView = () => {
        setSpinner(true)
        axios.post(clientViewApi(), { clientId: id }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setImageData(resp.data.data.image)
                editClient.setValues(resp.data.data)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const editClient = useFormik({
        initialValues: {
            name: '',
            email: '',
            DOB: '',
            mobileNo: '',
            gender: '',
        },
        validationSchema: timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? addClientValidationIndia : addClientValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            let data = {
                name: values.name.trim(),
                email: values.email == null ? '' : values.email.trim(),
                DOB: values.DOB,
                gender: values.gender,
                mobileNo: values.mobileNo,
                image: imageData,
                clientId: id,
                timeZone: timeZone
            }
            setSpinner(true)
            axios.post(updateClientApi(), { ...data }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setSpinner(false)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
        },
    })
    const history = () => {
        const data =
        {
            clientId: id,
            // providerId: userData?.roleTitle === roleNames.STAFF ? userData?.createdbyId : userData?.userId,
            providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
            page: page,
            count: PER_PAGE,
            branchId: userData?.locationId
        }

        // if (userData.roleTitle === roleNames.STAFF) {
        //     data.staffId = userData?.userId
        // }
        if (userData?.roleTitle !== roleNames.SERVICE_PROVIDER) {
            data.staffId = userData?.userId
        }
        setSpinner(true)
        axios.post(appointmentHistory(), { ...data }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentData(resp.data.data)
                setCount(resp.data.totalCount)
                setSpinner(false)
            }
            else {
                setAppointmentData([])
                setSpinner(false)
            }
        })
    }
    const clientCombo = () => {
        setSpinner(true)
        axios.post(clientPurchaseList(), { page: page, count: PER_PAGE,clientId:id }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentData(resp.data.data)
                setCount(resp.data.totalCount)
                setSpinner(false)
            }
            else {
                setAppointmentData([])
                setSpinner(false)
            }
        })
    }
    return {

        setPage,
        setSpinner,
        setImageData,
        setClientMembers,
        navigate,
        logOutFun,
        members,
        clientView,
        setTab,
        setCount,
        history,
        clientCombo,
        setAppointmentData,
        comboImgPath,
        id,
        spinner,
        imageData,
        clientMembers,
        userData,
        fileInputRef,
        editClient,
        timeZone,
        imgData,
        tab,
        appointmentData,
        count,
        page,
        PER_PAGE

    }
}

export default UseClientView