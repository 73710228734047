
import { useState, useContext, useRef } from 'react'
import { useFormik } from 'formik'
import { basicProfileValidation, businessDetailsValidation, addBranchValidation, editBranchValidation } from '../../../Context/validation'
import axios from 'axios'
import {
    getBasicProfileDetailsApi,
    updateProfileApi,
    createBranchApi,
    countryListApi,
    stateListApi,
    branchListDataApi,
    branchDeleteApi,
    businessDetailsApi,
    imageUploadApi,
    imgData,
    branchUpdateApi,
    getBranchDetails,
    branchImgPath
} from '../../../Context/api'
import { toast } from 'react-toastify'
import { statusCodes, commonMessage, roleNames } from '../../../Constants/generalConfig'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App';
import { isValidPhoneNumber } from 'react-phone-number-input'

const UseBasicDetailProfile = () => {
    const [country, setcountry] = useState([])
    const [stateList, setStateList] = useState([])
    const [businessCategorys, setBusinessCategorys] = useState([])
    const [branchListArr, setBranchListArr] = useState([])
    const [file, setFile] = useState('')
    const [showBranch, setShowBranch] = useState(false)
    const [branchId, setBranchId] = useState('')
    const [tab, setTab] = useState(1)
    const [show, setShow] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [branchImg, setBranchImg] = useState('')
    const navigate = useNavigate()
    const { setUserInfo } = useContext(Notifications)
    let listArr
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const userId = JSON.parse(localStorage.getItem('userId'))
    const fileInputRef = useRef(null);
    useEffect(() => {
        getBasicProfileDetails()
    }, [])


    const uploadImageProfile = (e) => {
        const fd = new FormData()
        fd.append('file', e)
        fd.append('type', 'PROFILE')
        axios.post(imageUploadApi(), fd).then((res) => {
            if (res.data.code === 200) {
                setFile(res.data.data.imagePath)
            }
        })
    }
    const countryListData = () => {
        axios
            .post(countryListApi(), { id: userId, isActive: true, isDeleted: true, sortValue: 'name', sortOrder: -1 })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setcountry(resp.data.data)
                }
            })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }
    const stateListData = (id) => {
        axios
            .post(stateListApi(), { countryId: id, sortValue: 'name', sortOrder: -1, page: 1, count: 200 })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setStateList(resp.data.data)
                }
            }).catch(()=>{})
    }
    const branchDelete = () => {
        const data = {
            loggedInUserId: userId,
            branchId: branchId,
            status: true,
        }
        axios
            .post(branchDeleteApi(), { ...data })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    listArr = branchListArr
                    listArr = listArr.filter((branch) => branch._id != branchId)
                    setBranchListArr(listArr)
                } else {
                    toast.error(resp.data.message)
                }
            })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }
    const getBranchList = () => {
        axios
            .post(branchListDataApi(), { userId: userId, isActive: true, page: 1, count: 100 })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setBranchListArr(resp.data.data)
                }
            }).catch(()=>{})
    }
    const getBasicProfileDetails = () => {
        axios
            .post(getBasicProfileDetailsApi(), { userId: userId })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setFile(resp.data.data.image)
                    basicProfileForm.setValues(resp.data.data)
                    if (resp.data.data.name) {
                        setTab(2)
                    }
                }
            }).catch(()=>{})

    }
    const basicProfileForm = useFormik({
        initialValues: {
            name: '',
            mobileNo: '',
            email: '',
        },
        validationSchema: basicProfileValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data =
                timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata'
                    ? { name: values.name.trim(), mobileNo: `${values.mobileNo}`, email: values.email, userId: userId, image: file, timeZone: timeZone, roleTitle: roleNames.SERVICE_PROVIDER }
                    : { name: values.name.trim(), mobileNo: `${values.mobileNo}`, email: values.email, userId: userId, image: file, timeZone: timeZone, roleTitle: roleNames.SERVICE_PROVIDER }
                    setSpinner(true)
            axios
                .post(updateProfileApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        // toast.success(resp.data.message)
                        setTab(2)
                        // businessCategoryListData();
                        getBranchList();
                        setSpinner(false)
                    } else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const businessProfileForm = useFormik({
        initialValues: {
            businessName: '',
            businessCategory: '',
            about: '',
        },
        validationSchema: businessDetailsValidation,
        onSubmit: (values) => {
            if(!branchListArr.length) return toast.warn('Please add branch')
            setSpinner(true)
            axios.post(businessDetailsApi(), { businessName: values.businessName.trim(), businessCategory: values.businessCategory.trim(), about: values.about.trim(), userId: userId, loginDevice: "WEB" })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        setSpinner(false)
                        // navigate('/layout/dashboard')      // shivam kashyap changes on 5 dec
                        // localStorage.setItem('userData', JSON.stringify(resp.data.data))
                        // localStorage.setItem('userId', JSON.stringify(resp.data.data))
                        // setUserInfo(resp.data.data)
                    }
                    if(resp.data.code==statusCodes.locationScreen){
                        localStorage.setItem('userId', JSON.stringify(resp.data.data?.userId))
                        navigate('/branches')
                        setSpinner(false)
                    }
                    else {
                        toast.error(resp.data.message)
                        setSpinner(false)
                    }
                })
                .catch(() => {
                    setSpinner(false)
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const addBranchForm = useFormik({
        initialValues: {
            name: '',
            building: '',
            street: '',
            city: '',
            country: '',
            state: '',
            zipCode: '',
            email: '',
            mobileNo: '',
            alternateMobileNo:null
        },
        validationSchema: addBranchValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                building: values.building.trim(),
                street: values.street.trim(),
                city: values.city.trim(),
                country: values.country,
                state: values.state,
                zipCode: values.zipCode.trim(),
                email: values.email,
                mobileNumber: `${values.mobileNo}`,
                alternateMobileNo:values.alternateMobileNo,
                createdby_id: userId,
                image:branchImg
            }
            axios.post(createBranchApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        addBranchForm.resetForm()
                        getBranchList()
                        setShowBranch(false)
                        setBranchImg('')
                    } else {
                        toast.error(resp.data.message)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const getBranchListDetails = (id) => {
        const data = {
            loggedInUserId: userId,
            branchId: id,
        }
        axios.post(getBranchDetails(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                stateListData(resp.data.data.countryId)
                setBranchId(data.branchId)
                for (let item in EditBranchForm.initialValues) {
                    EditBranchForm.setFieldValue(item, resp.data.data[item])
                }
                setBranchImg(resp.data.data?.image)
            }
        }).catch(()=>{})
    }
    const EditBranchForm = useFormik({
        initialValues: {
            name: '',
            building: '',
            street: '',
            city: '',
            countryId: '',
            stateId: '',
            zipCode: '',
            email: '',
            mobileNumber: '',
            alternateMobileNo:null,
        },
        validationSchema: editBranchValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNumber?.length > 0 && (values?.mobileNumber && isValidPhoneNumber(values?.mobileNumber))) {
                delete errors.mobileNumber
            } else {
                errors.mobileNumber = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                building: values.building.trim(),
                street: values.street.trim(),
                city: values.city.trim(),
                country: values.countryId,
                state: values.stateId,
                zipCode: values.zipCode.trim(),
                email: values.email,
                mobileNumber: `${values.mobileNumber}`,
                alternateMobileNo:values.alternateMobileNo,
                loggedInUserId: userId,
                branchId: branchId,
                // isActive: disableBranch,
                image:branchImg
            }
            axios
                .post(branchUpdateApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        getBranchList()
                        setShow(false)
                        setBranchImg('')
                    } else {
                        toast.error(resp.data.message)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    return {
        uploadImageProfile,
        countryListData,
        stateListData,
        branchDelete,
        getBranchList,
        getBasicProfileDetails,
        setcountry,
        setStateList,
        setBusinessCategorys,
        setBranchListArr,
        setFile,
        setShowBranch,
        setBranchId,
        setTab,
        navigate,
        setUserInfo,
        tab,
        branchId,
        showBranch,
        file,
        branchListArr,
        businessCategorys,
        stateList,
        basicProfileForm,
        businessProfileForm,
        addBranchForm,
        country,
        listArr,
        timeZone,
        userId,
        imgData,
        getBranchListDetails,
        setShow,
        EditBranchForm,
        show,
        spinner,
        branchImg, setBranchImg,
        branchImgPath,
        fileInputRef,
        branchId
    }
}

export default UseBasicDetailProfile