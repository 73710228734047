import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useFormik } from 'formik'
import { invitationReSendValidation } from '../../../Context/validation'
import {
    staffListApi, imgData, changeStaffStatusApi, logOutApi,
    reSendInvitation, listRole
} from '../../../Context/api'
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { Notifications } from '../../../App'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
const UseTeamList = () => {
    const { userInfo } = useContext(Notifications)
    const [staffList, setStaffList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [modalArchive, setModalArchive] = useState(false)
    const [invitationShow, setInvitationShow] = useState(false)
    const [count, setCount] = useState(1)
    const [filter, setFilter] = useState({
        status: '',
        search: '',
        role: 'ALL'
    })
    const [roleList, setRoleList] = useState([])
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const [staffId, setStaffId] = useState('')
    const [isActive, setIsActive] = useState(true)
    const userData = JSON.parse(localStorage.getItem('userData'))

    const navigate = useNavigate()
    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getStaffList()
            list()
        }
    }, [page, isActive])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const changeStaffStatus = () => {
        const data = {
            userId: staffId._id,
            // loggedInUserId: userData?.userId,
            loggedInUserId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
            status: staffId.isActive,
            statusDeleted: staffId.isDeleted
        }
        axios.post(changeStaffStatusApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                getStaffList()
                setModalArchive(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const getStaffList = () => {
        const data = {
            // userId: userData?.userId,
            userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
            isActive: isActive,
            page: page,
            count: PER_PAGE,
            isAccepted: filter.status,
            searchText: filter.search,
            roleFilter: filter.role,
            branchId: userData?.locationId,
            typeList: "LISTDATA"
        }
        setSpinner(true)
        axios.post(staffListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setStaffList(resp.data.data)
                setSpinner(false)
                setCount(resp.data.totalCount)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setPage(1)
                setSpinner(false)
                setStaffList([])
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFilter((prevProps) => ({
            ...prevProps,
            [name]: value
        }))
    }
    const invitationReSend = useFormik({
        initialValues: {
            oldEmail: '',
            email: ''
        },
        validationSchema: invitationReSendValidation,
        onSubmit: (values) => {
            const data = { old_email: values.oldEmail, new_email: values.email, roleTitle: 'STAFF', userId: staffId._id }
            setSpinner(true)
            axios.post(reSendInvitation(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    invitationReSend.resetForm()
                    setInvitationShow(false)
                    setSpinner(false)
                    getStaffList()
                }
                else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        }
    })
    const list = () => {
        setSpinner(true)
        axios.post(listRole(), {},).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setRoleList(resp.data.data)
            }
            else {
                setRoleList([])
            }
        }).catch(() => { })
    }

    return {
        changeStaffStatus,
        setInvitationShow,
        setPage,
        setStaffId,
        setIsActive,
        getStaffList,
        navigate,
        handleInputChange,
        setModalArchive,
        modalArchive,
        invitationReSend,
        staffList,
        spinner,
        userInfo,
        invitationShow,
        count,
        filter,
        page,
        PER_PAGE,
        staffId,
        imgData,
        roleList
    }
}

export default UseTeamList