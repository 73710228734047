import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Spinner from '../../Components/spinner/spinner'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Paginations from '../../Components/Pagination/pagination'
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord';
import UseServiceDuration from './Hooks/useServiceDuration';
import { ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { intialRole } from '../../Utils/Utils';
import { Backdrop } from '@mui/material';


const ServiceDuration = () => {
    const { statusChange,
        deleteDuration,
        setShow,
        setDurationData,
        setPage,
        deleteShow, setDeleteShow,
        page,
        count, navigate,
        spinner,
        addDuration,
        show,
        duration,
        PER_PAGE,
        userInfo
    } = UseServiceDuration()
    const durations = [10, 15, 20, 30, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120, 150, 180, 210, 240, 270, 300];

    return (
        <>
            <ToastContainer />
            <section>
                <div className="m-t-3 cus-padd cusheight">
                    {spinner ? <Spinner /> : null}
                    <header>
                        <h2 className="page-tittle"><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Service Durations</h2>
                        <hr />
                    </header>
                    {
                        intialRole({ permission: "Service Duration", userData: userInfo, section: 'view', }) ?
                            !duration?.length ? <NoRecord /> :
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table cus-table">
                                            <thead className="text-center tableheader">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Durations(Minutes)</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    duration?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td style={{ padding: '0.3rem .5rem' }}>{PER_PAGE * (page - 1) + index + 1}</td>
                                                            <td>{item.duration}</td>
                                                            <td> <div className="form-check form-switch" style={{ marginLeft: '44%' }}>
                                                                <input className="form-check-input" role='switch' type="checkbox" checked={item.isActive} onChange={() => statusChange(item)} />
                                                            </div></td>
                                                            <td onClick={() => { setDurationData(item); setDeleteShow(true) }} style={{ cursor: "pointer", marginTop: '7px' }}>

                                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                    <i className="fa-solid fa-trash-can text-danger"></i>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                    {
                        intialRole({ permission: "Service Duration", userData: userInfo, section: 'add', }) ?
                            <div className="d-flex justify-content-end">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Service Duration</Tooltip>}>
                                    <p className="btn addbtn2">
                                        <i className="fa-solid fa-plus" onClick={() => setShow(true)}></i>
                                    </p>
                                </OverlayTrigger>
                            </div>
                            : null
                    }
                    <div className='w-100 d-flex justify-content-center'>
                        {count > 10 &&
                            <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                        }
                    </div>
                </div>
            </section>
            {/* Duration Add Start */}
            <Backdrop open={show}>
                <Offcanvas show={show} onHide={() => setShow(false)} placement="end" backdrop={false}>
                    <div style={{ padding: '10px' }}>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShow(false); addDuration.resetForm() }}></button>
                        <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                            Add Service Duration
                        </h1>
                    </div>
                    <div className="offcanvas-body">
                        <form className="cus-form" onSubmit={addDuration.handleSubmit}>
                            <div className="mb-2">
                                <label className="form-label">Duration *</label>
                                <select className='form-select form-control' name="duration" value={addDuration.values.duration} onChange={addDuration.handleChange}>
                                    <option hidden>Select duration(In minutes)</option>
                                    {durations.map((duration) => (
                                        <option key={duration} value={duration}>
                                            {duration} Minutes
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <ValidationMessage formName={addDuration} name={'duration'} />
                            <div className="d-grid  col-6 mx-auto mb-3">
                                <button className="btn colorbtun popupbtn px" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas>
            </Backdrop>
            {/* Duration Add End */}


            {/* Duration Delete Start */}
            <Modal centered show={deleteShow} size='sm' onHide={() => setDeleteShow(false)}>
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <p>Are you sure !</p>
                        <p>You want to delete this duration</p>
                        <button type="button" className="btn nobtn" onClick={() => setDeleteShow(false)}>
                            No
                        </button>
                        <button type="button" className="btn yesbtn" onClick={() => deleteDuration()}>
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
            {/* Duration Delete End */}
        </>
    )
}

export default ServiceDuration;