import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { demographics, branchListApi, logOutApi } from '../../Context/api';
import { commonMessage, statusCodes } from '../../Constants/generalConfig';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Spinner from '../../Components/spinner/spinner'
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';

const DemoGraphics = () => {
  const { userInfo } = useContext(Notifications)
  const [branchList, setBranchList] = useState([])
  const [demographicsData, setDemographics] = useState([])
  const [spinner, setSpinner] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [state, setState] = useState({
    branch: userData?.locationId,
    days: "LAST7"
  });
  const navigate = useNavigate()

  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      getBranchList()
      demographicsDays()
    }
  }, [state.days, state.branch])

  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  const getBranchList = () => {
    axios
      .post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
      .then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setBranchList(resp.data.data)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          setBranchList([])
        }
      }).catch(() => { })
  }
  const demographicsDays = () => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }
    if (state.branch === '') {
      delete data.branchId
    }
    setSpinner(true)
    axios.post(demographics(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setDemographics(resp.data.data)
        setSpinner(false)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setSpinner(false)
        setDemographics([])
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }

  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className='page-tittle'> <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Demographics</h2>
            <hr />
          </header>
          {
            intialRole({ permission: "insights", userData: userInfo, section: 'viewDemographics' }) ?
              <>
                <div className="d-flex justify-content-between listpadding mb-3">
                  <div className="dropdown">
                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example" name="days" value={state.days}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <option hidden>Select days</option>
                      <option value="TODAY">Today</option>
                      <option value="LAST7">Last 7 days</option>
                      <option value="LAST_MONTH">Last Month</option>
                      <option value="THIS_MONTH">This Month</option>
                    </select>
                  </div>
                  <div className="dropdown">
                    <select className='mb-2' disabled style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example" name="branch" value={state.branch}
                      onChange={(e) => {
                        handleInputChange(e);
                        // demographicsBranch(e.target.value)
                      }}>
                      <option hidden>All</option>
                      <option value="">All</option>
                      {branchList?.map((item) => (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-sm-12 d-flex justify-content-center mt-5'>

                  <BarChart
                    width={800}
                    height={500}
                    data={demographicsData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="branchId" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Male" fill="#FF7777" />
                    <Bar dataKey="Female" fill="#4C9C9F" />
                    <Bar dataKey="Other" fill="#3396CD" />
                  </BarChart>
                </div>
              </>
              : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>
      </section>
    </>
  )
}

export default DemoGraphics