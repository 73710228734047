import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { otpValidation } from '../../../Context/validation';
import { toast } from 'react-toastify'
import axios from 'axios';
import { statusCodes, commonMessage } from '../../../Constants/generalConfig'
import { enterOtpApi, resendOTPApi } from '../../../Context/api'
import { useNavigate } from 'react-router-dom'

const UseVerifyOtp = () => {
    const navigate = useNavigate();
  const [timer, setTimer] = useState(60)
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), [])
  const mobileNumber = JSON.parse(localStorage.getItem('MobileNo'))
  const email = JSON.parse(localStorage.getItem('email'))
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000)
  }, [timer, timeOutCallback])

  const resetTimer = () => {
    setTimer(60)
  }
  const resendOtp = () => {
    const data = timeZone === 'Asia/Calcutta'||timeZone==='Asia/Kolkata' ? { mobileNo: mobileNumber, timeZone: timeZone,type:'FORGOT' } : { email: email, timeZone: timeZone,type:'FORGOT' }
    axios.post(resendOTPApi(), { ...data }).then((resp) => {
      if (resp.data.code === statusCodes.ok || resp.data.code === statusCodes.token) {
        toast.success(resp.data.message)
        resetTimer()
      }
      else if (resp.data.code === 410) {
        toast.success(resp.data.message)
      }
      else {
        toast.error(resp.data.message)
      }
    })
      .catch(() => {
        toast.error(commonMessage.networkError)
      })
  }

  const formik = useFormik({
    initialValues: {
      number: ''
    },
    validationSchema: otpValidation,
    onSubmit: values => {
      values = `${values.number}`
      const data = timeZone === 'Asia/Calcutta'||timeZone ==='Asia/Kolkata' ? { otp: +values, mobileNo: mobileNumber, timeZone: 'Asia/Kolkata' }
        : { otp: +values, email: email, timeZone: timeZone }
      axios.post(enterOtpApi(), { ...data }).then((resp) => {
        if (
          resp.data.code === statusCodes.ok
        ) {
          navigate('/verifyOtp/resetPin/');
          toast.success(resp.data.message)
          localStorage.setItem('Token', JSON.stringify(resp.data.data.token))
        } else {
          toast.error(resp.data.message)
        }
      })
        .catch(() => {
          toast.error(commonMessage.networkError)
        })
    },
  });
  return {
    navigate,
    resendOtp,
    resetTimer,
    timeOutCallback,
    setTimer,
    timer, 
    formik,
    timeZone,
    email,
    mobileNumber
  }
}

export default UseVerifyOtp