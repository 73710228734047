import React from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { signUpWithEmailValidation } from '../../Context/validation'
import { signUpApi } from '../../Context/api'
import { toast } from 'react-toastify'
import { statusCodes, roleNames } from '../../Constants/generalConfig'
import { useNavigate } from 'react-router-dom'

const SignUpWithEmail = ({ setSpinner }) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const navigate = useNavigate()

  const signUp = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: signUpWithEmailValidation,
    onSubmit: (values) => {
      signUp.resetForm()
      const data = {
        email: values.email,
        roleTitle: roleNames.SERVICE_PROVIDER,
        timeZone: timeZone
      }
      setSpinner(true)
      axios.post(signUpApi(), { ...data }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          setSpinner(false)
        } else if (resp.data.code === 410) {
          toast.success(resp.data.message)
          localStorage.setItem('email', JSON.stringify(resp.data.data.email))
          localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
          setTimeout(() => {
            navigate('/enterOtp')
            setSpinner(false)
          }, 100)
        } else if (resp.data.code === 412) {
          toast.error(resp.data.message)
          setSpinner(false)
          navigate(`/createPin/${resp.data.data.token}`)
        }
        else if (resp.data.code === 411) {
          navigate('/enterOtp/createPin/basicDetailProfile')
          localStorage.setItem('email', JSON.stringify(resp.data.data.email))
          localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
          setSpinner(false)
        }
        else {
          toast.error(resp.data.message)
          setSpinner(false)
        }
      })
    },
  })
  return (
    <>
      <form id="signInSignUpForm" className="sign-up-form" onSubmit={signUp.handleSubmit}>
        <h1 className="text-center heading-color mb-5">Sign up</h1>
        <div className="w-75 input-field">
          <i className="fa fa-envelope" aria-hidden="true"></i>
          <input type="text" placeholder="Enter Email" name="email" onChange={signUp.handleChange} value={signUp.values.email} />
        </div>
        {signUp.touched.email && signUp.errors.email ? <div className='text-start w-100' style={{ color: 'red', fontSize: '12px', width: '200%', marginLeft: '120px' }}>{signUp.errors.email}</div> : null}
        <input type="submit" className="btn colorbtun px" value="Sign up" />
      </form>
    </>
  )
}

export default SignUpWithEmail
