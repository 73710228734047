import { useFormik } from 'formik'
import { otpValidation } from '../../Context/validation'
import { verifyOtpByToken, checkTokenProvider } from '../../Context/api'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { statusCodes, commonMessage } from '../../Constants/generalConfig'
import OtpLogo from '../../Assets/images/enter0tp.png'
import { ToastContainer } from 'react-toastify'
import OtpInput from 'react-otp-input'
import { useEffect } from 'react'

const EnterOtp = () => {

  const navigate = useNavigate()
  const email = JSON.parse(localStorage.getItem('email'))
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { token } = useParams()

  useEffect(() => {
    tokenCheck()
  }, [])


  const tokenCheck = () => {
    axios.post(checkTokenProvider(), { tokens: token }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
      }
      else {
        navigate('wrong-token')
      }
    })
  }

  const otpForm = useFormik({
    initialValues: {
      number: '',
    },
    validationSchema: otpValidation,
    onSubmit: (values) => {
      otpForm.resetForm()
      axios
        .post(verifyOtpByToken(), { tokens: token, otp: values.number })
        .then((resp) => {
          if (resp.data.code === statusCodes.ok || resp.data.code === statusCodes.token) {
            navigate(`/enterOtp/createPin/${resp.data.data.token}`)
            toast.success(resp.data.message)
            localStorage.setItem('Token', JSON.stringify(resp.data.data.token))
            localStorage.setItem(timeZone == 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile' : email, JSON.stringify(timeZone == 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? resp.data.data.mobileNo : resp.data.data.email))
          } else {
            toast.error(resp.data.message)
          }
        })
        .catch(() => {
          toast.error(commonMessage.networkError)
        })
    },
  })
  return (
    <>
      <ToastContainer />
      <section>
        <div className="container d-flex justify-content-center align-items-center cusheight">
          <div className="row">
            <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>
              <div className="text-center">
                <img className="mb-2" src={OtpLogo} alt="" />
                <h1 className="text-center heading-color mb-3">Enter OTP</h1>
                <p>Enter OTP has been sent to your registered email.</p>
                <form className="cus-form" onSubmit={otpForm.handleSubmit}>
                  <div className="pin-code mb-3">
                    <OtpInput
                      isInputNum
                      value={otpForm.values.number}
                      onChange={(e) => {
                        otpForm.setFieldValue('number', e)
                      }}
                      numInputs={4}
                    />
                  </div>
                  {otpForm.touched.number && otpForm.errors.number ? <div className='err_msg'>{otpForm.errors.number}</div> : null}
                  <div className="d-grid  col-6 mx-auto mb-3">
                    <button type="submit" className="btn colorbtun px1">
                      Verify
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EnterOtp
