/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import ResetLogo from '../../Assets/images/reset.png';
import { resetPinValidation } from '../../Context/validation';
import { useFormik } from 'formik';
import OtpInput from "react-otp-input";
import axios from 'axios';
import { createPinApi } from '../../Context/api';
import { statusCodes, commonMessage } from '../../Constants/generalConfig';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/spinner/spinner';

const ResetPin = () => {
  const [spinner, setSpinner] = useState(false)
  const navigate = useNavigate();
  const mobileNumber = JSON.parse(localStorage.getItem('MobileNo'))
  const token = JSON.parse(localStorage.getItem('Token'));
  const email = JSON.parse(localStorage.getItem('email'))
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formik = useFormik({
    initialValues: {
      pin: '',
      confirmPin: '',
    },
    validationSchema: resetPinValidation,
    onSubmit: values => {
        const data = timeZone === 'Asia/Calcutta'||timeZone ==='Asia/Kolkata' ? { confirmPin:values.confirmPin, mobileNo: mobileNumber, token: token, timeZone: timeZone }
        : { confirmPin:values.confirmPin, email: email, token: token, timeZone: timeZone }
        setSpinner(true)
      axios.post(createPinApi(), { ...data }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          {
            setTimeout(() => {
              formik.resetForm();
              setSpinner(false)
              navigate('/')
            },100);
          }
        }
        else {
          setSpinner(false)
          toast.error(resp.data.message)
        }
      })
        .catch(() => {
          toast.error(commonMessage.networkError)
          setSpinner(false)

        })
    },
  });
  return (
    <>
    {spinner && <Spinner/>}
      <ToastContainer />
      <section>
        <div className="container d-flex justify-content-center align-items-center cusheight">
          <div className="row">
            <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>
              <div className="text-center">
                <img src={ResetLogo} alt='' />
                <h1 className="text-center heading-color mb-3">Reset PIN</h1>
                <form className="cus-form" onSubmit={formik.handleSubmit}>
                  <label className="form-label">Create New PIN *</label>
                  <div className="pin-code mb-3">
                    <OtpInput isInputSecure isInputNum value={formik.values.pin} onChange={(e) => { formik.setFieldValue("pin", e) }} numInputs={4} />
                  </div>
                  {formik.touched.pin && formik.errors.pin ? (
                    <div className='err_msg'>{formik.errors.pin}</div>
                  ) : null}
                  <label className="form-label">Confirm New PIN *</label>
                  <div className="pin-code">
                    <OtpInput isInputSecure isInputNum value={formik.values.confirmPin} onChange={(e) => { formik.setFieldValue("confirmPin", e) }} numInputs={4} />
                  </div>
                  {formik.touched.confirmPin && formik.errors.confirmPin ? (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '8px' }}>{formik.errors.confirmPin}</div>
                  ) : null}
                  <div className="d-grid  col-6 mx-auto mb-3">
                    <button type='submit' className="btn colorbtun resetbtn px">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ResetPin