import React from 'react'
import { Modal } from 'react-bootstrap';
import ScrollBars from 'react-scrollbar'
import DummyLogo from '../../Assets/images/dummyimg.png'
import { toast } from 'react-toastify';

const GroupModal = ({ showModal, setShowModal, file, Group, imgData, uploadImage, setGroupName, setSearchText, teamFilteredData, handleCheckboxChange, groupName, Conversation, selectedValues }) => {
    return (
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
            <div className='m-3'>
                <h1 className="modal-title fs-5 mt-3 heading text-center" id="exampleModalLabel">Create Group</h1>
                <hr />
                <div className='d-flex'>
                    <div>
                        <img src={file === '' ? Group : imgData + file} width={80} height={80} style={{ borderRadius: '50%' }} alt="" />
                        <label htmlFor="icon-button-file" className="icon">
                            <input accept="image/*" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={(event) => uploadImage(event.target.files[0])} />
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '50px', marginLeft: '-25px', fill: 'rgb(76 68 68)' }} width="25" height="25" className="bi bi-camera" viewBox="0 0 16 16">
                                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                        </label>
                    </div>
                    <input className='form-control mt-3 ms-3 me-4' type="text" placeholder='Group Name' style={{ height: "50px" }} onChange={(e) => setGroupName(e.target.value)} />

                </div>
                <div className="form-group has-feedback d-flex mt-3 ms-1 me-3">
                    <input id="searchText" type="text" className="form-control m-2" name="searchText" placeholder="Search" onChange={(e) => setSearchText(e.target.value)} />
                </div>
                <ScrollBars horizontal autoHide={false} style={{ height: '400px', width: '100%' }} smoothScrolling={true}>
                    {
                        !teamFilteredData?.length ? <div className='d-flex justify-content-center mt-2'><h3>No record found</h3></div>
                            :
                            teamFilteredData.map((item, index) =>
                                <div key={index}>
                                    <div className="d-flex sideBar-body  shadow-Box mb-2">
                                        <div className="col-sm-1 d-flex align-items-center">
                                            <input className='cursor' type="checkbox" value={item._id} checked={selectedValues.includes(item._id)} onChange={handleCheckboxChange} />
                                        </div>
                                        <div className="col-sm-2 col-xs-3 sideBar-avatar">
                                            <div className="avatar-icon">
                                                <img src={item.image === '' ? DummyLogo : imgData + item.image} alt='img'/>
                                            </div>
                                        </div>
                                        <div className="col-sm-9 col-xs-9 sideBar-main">
                                            <div className="row">
                                                <div className="col-sm-8 col-xs-8 sideBar-name">
                                                    <span className="name-meta">{item.name}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </ScrollBars>

            </div>
            <div className='d-flex justify-content-evenly mt-3 mb-3'>
                <button className='btn ps-5 pe-5 cancel_btn' onClick={() => setShowModal(false)}>Cancel</button>
                <button className='btn ps-5 pe-5 btn_Style' onClick={() => { !groupName ? toast.warn('Please enter group name') : (!selectedValues.length) ? toast.warn('Please select member') : Conversation(selectedValues) }}>Create</button>
            </div>
        </Modal>
    )
}

export default GroupModal