import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProviderApp = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            window.open("https://play.google.com/store/apps/details?id=com.project.iskedo.iskedo");
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            window.open("https://apps.apple.com/in/app/iskedo-provider/id6470967699");
        }
        else {
            navigate('app')
        }
    }, []);

    return (
        <>

        </>
    )
}

export default ProviderApp

