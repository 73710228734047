import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ticketAttachment, imgData } from '../../../Context/api';
import { ToastContainer } from 'react-toastify';
import moment from 'moment/moment';
import Spinner from '../../../Components/spinner/spinner'
import NoRecord from '../../../Components/noRecord'
import Paginations from '../../../Components/Pagination/pagination'
import useTicket from './hooks/useTicket';
import ViewTicket from './viewTicket';
import CreateTicket from './createTicket';

const HelpSupport = () => {
  const {
    ticketView,
    setTicketId,
    navigate,
    setTicketDetailShow,
    setTicketShow,
    setImageFile,
    setPage,
    getFileExtensionFromUrl,
    handleFileChange,
    DeleteAttachmentFile,
    setShowMore,
    handleInputChange,
    list,
    filter,
    showMore,
    PER_PAGE,
    page,
    count,
    spinner,
    listData,
    ticketShow,
    addTicket,
    imageFile,
    ticketDetail,
    attachments,
    replyMsg,
    ticketDetailShow,
    userData,
    status
  } = useTicket()
  // console.log(listData, 'listData')
  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className='page-tittle'><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>My Tickets</h2>
            <hr />
          </header>
          <div className="row">
            <div className="col-sm-12 listpadding">
              <div className="d-flex justify-content-end">
                <>
                  <div>
                    <input type="search" placeholder='Search by ticket id/subject' className='form-control ms-3'
                      name='search'
                      value={filter.search}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  {
                    userData?.roleTitle == "SERVICE_PROVIDER" ?
                      <div className='me-2'>
                        <select className='form-select ms-4 me-2' name='list' value={filter.list} onChange={(e) => handleInputChange(e)}>
                          <option hidden>List</option>
                          <option value="MYTICKETS">My List</option>
                          <option value="STAFFTICKETS">My Team List</option>
                        </select>
                      </div>
                      : null
                  }

                  <button className='btn ms-4 mb-3 btn_Style' type='submit' onClick={() => filter.status === '' && filter.search === '' ? null : list()}>Search</button>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset</Tooltip>}>
                    <button className="btn mb-2 ms-2">
                      <i className="fa fa-refresh cursor" aria-hidden="true" onClick={() => window.location.reload()}></i>
                    </button>
                  </OverlayTrigger>

                </>

              </div>
              {
                !listData.length ? <NoRecord />
                  :
                  <div className="table-responsive">
                    <table className="table cus-table">
                      <thead className="text-center tableheader">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Ticket ID</th>
                          <th scope="col">Subject</th>
                          {/* <th scope="col">Project</th> */}
                          <th scope="col">Date</th>
                          <th scope="col">Created By</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          listData?.map((item, index) =>
                            <tr className='cursor' key={index} onClick={() => { ticketView(item?._id); setTicketId(item); setTicketDetailShow(true) }}>
                              <td>{PER_PAGE * (page - 1) + index + 1}</td>
                              <td>{item.ticketId}</td>
                              <td>{item.subject}</td>
                              {/* <td>{item.project}</td> */}
                              <td>{moment(item.reportDate).format('DD-MM-YYYY')}</td>
                              <td>{item.createdby}</td>
                              <td>{item.status}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                    <div className='w-100 d-flex justify-content-center'>
                      {
                        count > 10 &&
                        <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                      }
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end position-relative">
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Ticket</Tooltip>}>
            <p className="btn addbtn2">
              <i className="fa-solid fa-plus" onClick={() => { setTicketShow(true); setImageFile([]) }}></i>
            </p>
          </OverlayTrigger>
        </div>
      </section>
      <CreateTicket ticketShow={ticketShow} handleFileChange={handleFileChange} DeleteAttachmentFile={DeleteAttachmentFile} setTicketShow={setTicketShow} addTicket={addTicket} imageFile={imageFile} setImageFile={setImageFile} userData={userData} />
      <ViewTicket ticketDetailShow={ticketDetailShow} setTicketDetailShow={setTicketDetailShow} ticketDetail={ticketDetail} getFileExtensionFromUrl={getFileExtensionFromUrl} replyMsg={replyMsg} moment={moment}
        attachments={attachments} ticketAttachment={ticketAttachment} imgData={imgData} userData={userData} status={status} setShowMore={setShowMore} showMore={showMore}
      />
    </>
  )
}

export default HelpSupport;