import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import EnterOtp from './Pages/Credentials/enterOtp'
import CreatePin from './Pages/Credentials/createPin'
import BasicDetailProfile from './Pages/Credentials/basicDetailProfile'
import ForgotPin from './Pages/Credentials/forgotPin'
import ResetPin from './Pages/Credentials/resetPin'
import VerifyOtp from './Pages/Credentials/verifyOtp'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import Layout from './Components/Layout/layout'
import LoginSignUpPage from './Pages/Credentials/loginSignUpPage'
// import Home from './Pages/Home Page/home'
import CreatePassword from './Pages/AcceptInvitaion/createPin';
import Rejectpage from './Pages/RejectInvitation/rejectpage'
import { createContext } from 'react';
import PageNoFoundHome from './Components/Error/pageNoFoundHome'
import ContactUs from './Pages/Subscription/contact-us'

// import Contact from './Pages/Subscription/contact'
import MultipleLogin from './Pages/Multiple Login/multipleLogin'
import Otp from './Pages/AcceptInvitaion/otp';
import WrongToken from './Pages/AcceptInvitaion/wrongToken'
import StripePayment from './Pages/AcceptInvitaion/stripePayment'
import TeamChangePin from './Pages/Credentials/teamChangePin'
import UserApp from './Pages/User App/userApp'
import Location from './Pages/Location/location'
import ProviderApp from './Pages/User App/providerApp'
import AppnotFound from './Pages/User App/appnotFound'
import PaymentStatus from './Pages/AcceptInvitaion/paymentStatus'
import FailedStatus from './Pages/AcceptInvitaion/failedStatus'



export const Notifications = createContext({})

const App = () => {
  const [count, setCount] = useState({ totalCount: 0 })
  const [receiveData, setReceiveData] = useState({})
  const [socket, setSocket] = useState(null);
  const [sideShow, setSideShow] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [userInfo, setUserInfo] = useState(userData)

  return (
    <>
      <Notifications.Provider value={{ count, setCount, receiveData, setReceiveData, socket, setSocket, userInfo, setUserInfo, sideShow, setSideShow }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginSignUpPage />} />
            <Route path="/enterOtp" element={<EnterOtp />} />
            <Route path="/createPin/:token" element={<CreatePin />} />
            <Route path="/enterOtp/createPin/:token" element={<CreatePin />} />
            <Route path="/enterOtp/createPin/basicDetailProfile" element={<BasicDetailProfile />} />
            <Route path="/forgotPin" element={<ForgotPin />} />
            <Route path="/verifyOtp/resetPin/" element={<ResetPin />} />
            <Route path="/verifyOtp" element={<VerifyOtp />} />
            <Route path="/acceptInvitation/:token" element={<CreatePassword />} />
            <Route path="/declineInvitation/:token" element={<Rejectpage />} />
            <Route path="/acceptInvitation/:token/declineInvitation" element={<Rejectpage />} />
            <Route path="/layout/*" element={<Layout />} />
            <Route path="/team-change-pin/*" element={<TeamChangePin />} />


            <Route path="*" element={<PageNoFoundHome />} />

            <Route path="contact-us" element={<ContactUs />} />
            <Route path="multiple-login" element={<MultipleLogin />} />
            <Route path="branches" element={<Location/>} />

            <Route path="/acceptInvitationProvider/:token" element={<Otp />} />
            <Route path="/acceptInvitationProvider/:token/wrong-token" element={<WrongToken />} />
            <Route path="/stripePayment/:token" element={<StripePayment />} />
            <Route path="/stripePayment/:token/wrong-token" element={<WrongToken />} />
            <Route path="/paymentStatus" element={<PaymentStatus />} />
            <Route path="/payment-failed" element={<FailedStatus />} />

            
            <Route path="/user-app" element={<UserApp />} />
            <Route path="/provider-app" element={<ProviderApp />} />
            <Route path="/user-app/app" element={<AppnotFound />} />
            <Route path="/provider-app/app" element={<AppnotFound />} />
          </Routes>
        </BrowserRouter>
      </Notifications.Provider>
    </>
  )
}

export default App;