/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { staffListApi, clientListApi, imgData, dashboardAppointment, dashboardRevenue, rushHours, logOutApi, checkPlanExpiredDays, allLocation } from '../../../Context/api';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { commonMessage, statusCodes, roleNames } from '../../../Constants/generalConfig';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { Notifications } from '../../../App';

const UseDashboard = () => {
    const { userInfo, setSideShow } = useContext(Notifications)
    const [team, setTeam] = useState([]);
    const [client, setClient] = useState([])
    const [branchList, setBranchList] = useState([])
    const [appointServiceData, setAppointServiceData] = useState([])
    const [revenueData, setRevenueData] = useState([])
    const [rushHourData, setRushHourData] = useState([])
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [btn, setBtn] = useState('DAILY')
    const [branch, setBranch] = useState('')
    const [page, setPage] = useState(1)
    const [spinner, setSpinner] = useState(false)
    const [apptBtn, setApptBtn] = useState('TODAY')
    const [serviceBtn, setServiceBtn] = useState('TODAY')
    const [planExpMsg, setPlanExpMsg] = useState('')
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData'))
    let appointments;

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        if (userData?.payment?.paymentType === 'STRIPE') {
            navigate(`/stripePayment/${userData?.payment?.paymentToken}`)
            localStorage.clear()
        }
        if (userData?.payment?.paymentType === 'RAZORPAY') {
            window.open(userData?.payment?.redirectUrl)
            localStorage.clear()
        }
        else {
            appointmentAndService()
            revenue()
            rushHour()
            planExpiredDays()
            setSideShow(false)
        }
    }, [page, branch])
    useEffect(() => {
        getTeamList()
        getClientList()
        getBranchList()
    }, [page])

    useEffect(() => {
        const disableBackNavigation = (event) => {
            event.preventDefault();
            window.history.forward();
        };
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', disableBackNavigation);
        return () => {
            window.removeEventListener('popstate', disableBackNavigation);
        };
    }, [])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        }).catch(() => { })
    }

    const getBranchList = () => {
        axios.post(allLocation(), { userId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setBranchList(resp.data.data)
                setBranch(userData?.locationId)
            }
            else {
                setBranchList([])

            }
        }).catch(() => { })
    }
    const getTeamList = () => {
        const data = {
            userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            isActive: true,
            count: 100,
            isAccepted: 'accepted',
            branchId: userData?.locationId
        }
        axios.post(staffListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {

            if (resp.data.code === statusCodes.ok) {
                setTeam(resp.data.data)
            }
            else {
                setTeam([])
            }
        }).catch(() => { })
    }
    const getClientList = () => {
        const data = {
            userId: userData?.userId,
            isActive: true,
            isAccepted: 'accepted',
            page: page,
            count: 100,
            isProviderClient: true
        }
        if (userData?.roleTitle === roleNames.STAFF || userData?.roleTitle === roleNames.FRONTDESK) {
            delete data.userId
            data['providerId'] = userData?.createdbyId
        }
        axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setClient((pre) => [...pre, ...resp.data.data])
            }
            else {
                setClient(client)
            }
        }).catch(() => { })
    }
    const fetchMoreData = () => setPage((prev) => prev + 1);

    const appointmentAndService = (e) => {
        const data = {
            branchId: branch,
            days: e || apptBtn,
            today: moment(new Date()).format('YYYY-MM-DD'),
        }
        if (branch.length === 0) {
            delete data.branchId
        }
        if (userData?.roleTitle === roleNames.STAFF) {
            data['createdbyId'] = userData?.createdbyId
        }
        axios.post(dashboardAppointment(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointServiceData(resp.data.data)
            }
            else {
                setAppointServiceData([])
            }
        }).catch(() => { })
    }

    appointServiceData?.forEach((item) => {
        item.Appointments?.forEach((data, i) => {
            appointments = data;
        });
    });
 
    const revenue = (e) => {
        const data = {
            days: "THIS_MONTH",
            today: moment(new Date()).format('YYYY-MM-DD'),
            sortBy: e || btn,
            branchId: branch
        }
        if (branch.length === 0) {
            delete data.branchId
        }
        if (userData?.roleTitle === roleNames.STAFF) {
            data['createdbyId'] = userData?.createdbyId
        }
        setSpinner(true)
        axios.post(dashboardRevenue(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setRevenueData(resp.data.data)
                setSpinner(false)

            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setRevenueData([])
                setSpinner(false)
            }
        }).catch(() => {
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    let newData = [];
    revenueData.forEach((element) => {
        btn === 'YEARLY' ? newData.push({ _id: moment(element._id).format("YYYY"), count: element.count, Total_Revenue: element.totalRevenue }) : btn === 'DAILY' ?
            newData.push({ _id: moment(element._id).format("MMM Do"), count: element.count, Total_Revenue: element.totalRevenue }) : newData.push({
                _id: moment(element._id).format("MMM  YY"),
                count: element.count, Total_Revenue: element.totalRevenue
            })
    });

    const rushHour = (e) => {
        const data = {
            today: e || date,
            branchId: branch
        }
        if (branch.length === 0) {
            delete data.branchId
        }
        if (userData?.roleTitle === roleNames.STAFF) {
            data['createdbyId'] = userData?.createdbyId
        }
        axios.post(rushHours(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setRushHourData(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
            }
        }).catch(() => { })
    }
    let rush = [];
    rushHourData?.forEach((item, i) => {
        rush.push({ _id: moment(item._id).format('LT'), Total_Visit: item.totalVisit });
    });


    const planExpiredDays = () => {
        axios.post(checkPlanExpiredDays(), { userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setPlanExpMsg(resp.data.message)
            }
            else {
                setPlanExpMsg('')
            }
        }).catch(() => { })
    }
    return {
        logOutFun,
        getBranchList,
        getTeamList,
        getClientList,
        fetchMoreData,
        appointmentAndService,
        revenue,
        rushHour,
        navigate,
        setTeam,
        setClient,
        setBranchList,
        setAppointServiceData,
        setRevenueData,
        setRushHourData,
        setDate,
        setBtn,
        setBranch,
        setPage,
        setApptBtn,
        setServiceBtn,
        appointments,
        team,
        client,
        branchList,
        appointServiceData,
        revenueData,
        rushHourData,
        date,
        btn,
        branch,
        page,
        spinner,
        apptBtn,
        serviceBtn,
        userData,
        imgData,
        newData,
        rush,
        planExpMsg,
        userInfo

    }
}

export default UseDashboard