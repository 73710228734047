import React, { useState } from 'react'
import Reset from '../../Assets/images/reset.png';
import { changePinApi } from '../../Context/api';
import { useFormik } from 'formik';
import OtpInput from "react-otp-input";
import axios from 'axios';
import { statusCodes, commonMessage } from '../../Constants/generalConfig';
import { toast, ToastContainer } from 'react-toastify';
import { changePinValidation } from '../../Context/validation';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/spinner/spinner';


const TeamChangePin = () => {
    const [spinner, setSpinner] = useState(false)
    const mobile = JSON.parse(localStorage.getItem('Mobile'))
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            oldPin: '',
            newPin: '',
            confirmNewPin: '',
        },
        validationSchema: changePinValidation,
        onSubmit: values => {
            const data = {
                mobileNo: mobile,
                oldPassword: values.oldPin,
                newPassword: values.newPin,
                timeZone: 'Asia/Kolkata'
            }
            setSpinner(true)
            axios.post(changePinApi(), { ...data }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    navigate('/')
                    formik.resetForm();
                    setSpinner(false)

                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)

                })
        },
    });
    return (
        <>
        <ToastContainer/>
            <section>
                {spinner && <Spinner />}
                <div className="container d-flex justify-content-center align-items-center cusheight">
                    <div className="row">
                        <div className="col-sm-12 bg-white shadow mt-2" style={{ padding: '60px' }}>
                            <div className="text-center">
                                <img src={Reset} alt='' />
                                <h1 className="text-center heading-color mb-3">Change PIN</h1>
                                <form className="cus-form" onSubmit={formik.handleSubmit}>
                                    <label className="form-label">Enter Old PIN *</label>
                                    <div className="pin-code mb-3">
                                        <OtpInput isInputSecure isInputNum value={formik.values.oldPin} onChange={(e) => { formik.setFieldValue("oldPin", e) }} numInputs={4} />
                                    </div>
                                    {formik.touched.oldPin && formik.errors.oldPin ? (
                                        <div className='err_msg'>{formik.errors.oldPin}</div>
                                    ) : null}
                                    <label className="form-label">Enter New PIN *</label>
                                    <div className="pin-code mb-3">
                                        <OtpInput isInputSecure isInputNum value={formik.values.newPin} onChange={(e) => { formik.setFieldValue("newPin", e) }} numInputs={4} />
                                    </div>
                                    {formik.touched.newPin && formik.errors.newPin ? (
                                        <div className='err_msg'>{formik.errors.newPin}</div>
                                    ) : null}
                                    <label className="form-label mb-3">Confirm New PIN *</label>
                                    <div className="pin-code">
                                        <OtpInput isInputSecure isInputNum value={formik.values.confirmNewPin} onChange={(e) => { formik.setFieldValue("confirmNewPin", e) }} numInputs={4} />
                                    </div>
                                    {formik.touched.confirmNewPin && formik.errors.confirmNewPin ? (
                                        <div className='err_msg'>{formik.errors.confirmNewPin}</div>
                                    ) : null}

                                    <div className="d-grid  col-12  mb-3 justify-content-center mt-5">
                                        <button type='submit' className="btn colorbtun sidebtn">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamChangePin