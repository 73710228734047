import React from 'react'
import { ToastContainer } from 'react-toastify';
import ValidationMessage from '../ValidationMessage/validationMessage';
import Spinner from '../../Components/spinner/spinner'
import DummyLogo from '../../Assets/images/dummyimg.png'
import moment from 'moment/moment';
import UseClientView from './Hooks/useClientView';
import NoRecord from '../../Components/noRecord'
import Paginations from '../../Components/Pagination/pagination'
import UseImage from '../../Hooks/useImage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import NumberInput from '../../Components/PhoneInput/phoneInput';
import ServiceImg from '../../Assets/images/services-icon.png';
const ClientView = () => {
    const {
        clientView,
        history,
        setAppointmentData,
        setCount,
        navigate,
        setImageData,
        setTab,
        setPage,
        clientCombo,
        id,
        spinner,
        imageData,
        comboImgPath,
        userData,
        editClient,
        imgData, tab,
        appointmentData,
        count,
        timeZone,
        page,
        PER_PAGE, fileInputRef } = UseClientView()
    const { imageDelete, uploadImage } = UseImage()
    return (
        <>
            <ToastContainer />
            <section>
                <div className="m-t-3 cus-padd cusheight">
                    {spinner ? <Spinner /> : null}
                    <h2 className="page-tittle">
                        <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Edit Client
                    </h2>
                    <hr />
                    <nav className="mb-3">
                        <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
                            <button className={` ${tab === 1 ? 'activeNav' : 'unActiveNav'} border-0`} onClick={() => setTab(1)} id="basicdetails" type="button">
                                Profile
                            </button>
                            <button
                                className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`}
                                type="button"
                                onClick={() => { setTab(2); history(); setAppointmentData([]); setPage(1); setCount(1) }}
                            >
                                Appointment History
                            </button>
                            <button className={` ${tab === 3 ? 'activeNav' : 'unActiveNav'} border-0`} type="button" onClick={() => { setTab(3); setAppointmentData([]); clientCombo(); setPage(1); setCount(1) }} >
                                Combo & Packages
                            </button>
                        </div>
                    </nav>
                    {
                        tab === 1 &&

                        <div className="tab-content" id="nav-tabContent">
                            <div className="text-center mb-4">
                                <img src={imageData === '' || imageData === null || imageData === 'null' ? DummyLogo : imgData + imageData} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                                <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                </svg>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <input ref={fileInputRef}
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => uploadImage(e.target.files[0], 'PROFILE', setImageData, fileInputRef)}
                                    />
                                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                                    <br />
                                    {imageData === '' || imageData === null || imageData === 'null' ? null : (
                                        <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(id, imageData, 'PROFILE', clientView)} startIcon={<DeleteIcon />}>Delete</Button>
                                    )}
                                </ul>
                            </div>
                            <form className="row g-3 cus-form" onSubmit={editClient.handleSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Name *</label>
                                    <input type="text" className="form-control" pattern="[A-Za-z ]*" title="Please enter only alphabetic characters" name="name" value={editClient.values.name} onChange={editClient.handleChange} />
                                    <ValidationMessage formName={editClient} name={'name'} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">E-mail Address {timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? null : '*'}</label>
                                    <input type="email" className="form-control" name="email" value={editClient.values.email} onChange={editClient.handleChange} disabled />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">DOB</label>
                                    <input type="date" className="form-control" name="DOB" value={moment(editClient.values.DOB).format('YYYY-MM-DD')} onChange={editClient.handleChange}
                                        min='1899-01-01' />
                                    <ValidationMessage formName={editClient} name={'DOB'} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Mobile Number *</label>
                                    {/* <input type="text" className="form-control" name="mobileNo" disabled value={editClient.values.mobileNo} onChange={editClient.handleChange} /> */}
                                    <NumberInput
                                        width={false}
                                        Require={false}
                                        Name="mobileNo"
                                        HandleChange={editClient}
                                        Value={editClient.values.mobileNo}
                                        disabledInput={true}
                                    />
                                    <ValidationMessage formName={editClient} name={'mobileNo'} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Gender *</label>
                                    <select className="form-select form-control" aria-label="Default select example" name="gender" value={editClient.values.gender} onChange={editClient.handleChange}>
                                        <option value='' hidden disabled>Select Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                        <option value="OTHER">Other</option>
                                    </select>
                                    <ValidationMessage formName={editClient} name={'gender'} />
                                </div>
                                <div className="col-md-6"></div>
                                <div className="d-grid  col-12  mb-3 justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                                    <button type="submit" className="btn colorbtun sidebtn px">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        tab === 2 &&

                        <>
                            {
                                !appointmentData.length ? <NoRecord />
                                    :
                                    <div className="table-responsive">
                                        <table className="table cus-table">
                                            <thead className="text-center tableheader">
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Time</th>
                                                    <th scope="col">Appointment ID</th>
                                                    <th scope="col">Branch</th>
                                                    <th scope="col">Team</th>
                                                    <th scope="col">Service</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            {appointmentData?.length === 0 ? <div className='position-absolute top-20 start-50 mt-5'><h3>No record found</h3></div> :
                                                <tbody>
                                                    {appointmentData?.map((item) => (<React.Fragment key={item._id}>
                                                        <tr className='cursor'>
                                                            <td key={item._id} onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)} >{moment(item.startDateTime).format('DD-MM-YYYY')}</td>
                                                            <td onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)}>{moment(item.startDateTime).format('LT')} - {moment(item.endDateTime).format('LT')}</td>
                                                            <td onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)}>{item.appointment_number}</td>
                                                            <td onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)}>{item.branchName}</td>
                                                            <td onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)}>{item.staffName}</td>
                                                            <td onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)}>{item.serviceName}</td>
                                                            <td onClick={() => navigate(`/layout/appointmentList/appointmentView/${item._id}`)}>{item.status}</td>

                                                        </tr>
                                                    </React.Fragment>))}
                                                </tbody>
                                            }
                                        </table>
                                        <div className='w-100 d-flex justify-content-center'>
                                            {appointmentData.length === 0 ? null :
                                                count > 10 &&
                                                <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                                            }
                                        </div>
                                    </div>
                            }</>

                    }
                    {
                        tab === 3 &&

                        <>
                            {
                                !appointmentData.length ? <NoRecord />
                                    :
                                    <div className="table-responsive">
                                        <table className="table cus-table">
                                            <thead className="text-center tableheader">
                                                <tr>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Validity(In Days)</th>
                                                </tr>
                                            </thead>
                                            {appointmentData?.length === 0 ? <div className='position-absolute top-20 start-50 mt-5'><h3>No record found</h3></div> :
                                                <tbody>
                                                    {appointmentData?.map((item) => (<React.Fragment key={item._id}>
                                                        <tr>
                                                            <td key={item._id}><img src={item.image === '' ? ServiceImg : comboImgPath + item.image} alt="" height={30} width={30} style={{ borderRadius: '50%' }} /></td>
                                                            <td>{item.name}</td>
                                                            <td>{userData?.currencySymbol} {item.price}</td>
                                                            <td>{item.validity}</td>
                                                        </tr>
                                                    </React.Fragment>))}
                                                </tbody>
                                            }
                                        </table>
                                        <div className='w-100 d-flex justify-content-center'>
                                            {appointmentData.length === 0 ? null :
                                                count > 10 &&
                                                <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />     
                                            }
                                        </div> 
                                    </div>
                            }</>

                    }
                </div>
            </section>
        </>
    )
}

export default ClientView