/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { statusCodes, roleNames, commonMessage } from '../../../Constants/generalConfig';
import { appointmentViewApi, cancelappointmnetApi, acceptedBooking, logOutApi, checkout, followUpList } from '../../../Context/api';
import { toast } from 'react-toastify'
import { cancelAppointmentValidation } from '../../../Context/validation'
import { Notifications } from '../../../App';


const UseAppointmentView = () => {
    const { userInfo } = useContext(Notifications)
    const [appointmentViewData, setAppointmentViewData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [vistModal, setVisitModal] = useState(false)
    const [showVistModal, setShowVisitModal] = useState(false)
    const [daysData, setDaysData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [visitData, setVisitData] = useState({
        remark: '',
        days: '',
    })
    const userData = JSON.parse(localStorage.getItem('userData'))
    const { id } = useParams()
    const timeZoneData = Intl.DateTimeFormat().resolvedOptions().timeZone
    const navigate = useNavigate()
    useEffect(() => {
        appointmentView()
    }, [])

    const logOutFun = () => {

        axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData.userId, type: userData.roleTitle === roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const appointmentView = () => {
        setSpinner(true)
        axios.post(appointmentViewApi(), { loggedInUserId: userData.userId, appointmentId: id }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setAppointmentViewData(resp.data.data)
                setSpinner(false)

            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setAppointmentViewData([])
                navigate('*')
                setSpinner(false)
            }
        }).catch(() => {
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    const days = () => {
        axios.post(followUpList(), {}, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setDaysData(resp.data.data)

            }
            else {
                setDaysData([])
            }
        }).catch(() => { })
    }
    const cancelAppointment = useFormik({
        initialValues: { reason: '' },
        validationSchema: cancelAppointmentValidation,
        onSubmit: (values) => {
            const data = {
                appointmentId: appointmentViewData._id,
                timezone: timeZoneData,
                startDateTime: appointmentViewData.startDateTime,
                endDateTime: appointmentViewData.endDateTime,
                clientId: appointmentViewData.clientId,
                staffId: appointmentViewData.staffId,
                reason: values.reason,
                status: 'DECLINED'
            }
            axios.post(cancelappointmnetApi(), { ...data }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    appointmentView()
                    setShowModal(false)
                    cancelAppointment.resetForm()
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    appointmentView()
                }
            }).catch(() => { toast.error(commonMessage.networkError) })
        }

    })
    const accepted = () => {
        axios.post(acceptedBooking(), { appointmentId: appointmentViewData._id, timezone: timeZoneData }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setShowModal(false)
                appointmentView()
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                appointmentView()
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const appointmentCheckout = (type) => {
        const data = {
            appointmentId: appointmentViewData._id,
            type: type,
            remark: visitData?.remark,
            followUpDays: +visitData?.days
        }
        if (type) {
            if (!visitData?.days) return toast.warn('Please select days')
            if (!visitData?.remark) return toast.warn('Please enter remark')
        }
        if (!type) {
            if (!visitData.remark) return toast.warn('Please enter remark')
            delete data.followUpDays
        }

        setSpinner(true)
        axios.post(checkout(), { ...data }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                appointmentView()
                setShowVisitModal(false)
                setVisitData({ days: '', remark: '' })
                setSpinner(false)
                setVisitModal(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
             toast.error(commonMessage.networkError)
             setSpinner(false)
             })
    }
    return {
        logOutFun,
        appointmentView,
        accepted,
        setAppointmentViewData,
        setShowModal,
        navigate,
        days,
        appointmentCheckout,
        showVistModal, setShowVisitModal,
        vistModal, setVisitModal,
        cancelAppointment,
        appointmentViewData,
        showModal,
        userData,
        id,
        timeZoneData,
        userInfo,
        visitData,
        daysData,
        spinner,
        setVisitData
    }
}

export default UseAppointmentView