import { useFormik } from 'formik';
import { forgotPinValidation, forgotPinValidationWithEmail } from '../../../Context/validation';
import { forgotPasswordApi } from '../../../Context/api';
import axios from 'axios';
import { toast } from "react-toastify";
import { statusCodes, commonMessage, roleNames } from "../../../Constants/generalConfig";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

const UseForgotPin = () => {
    const navigate = useNavigate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [spinner, setSpinner] = useState(false)

    const formik = useFormik({
        initialValues: {
            mobileNo: '',
        },
        validationSchema: forgotPinValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: values => {
            setSpinner(true)
            axios.post(forgotPasswordApi(), { ...values, timeZone:timeZone === 'Asia/Calcutta' ||timeZone ==='Asia/Kolkata'?'Asia/Kolkata':timeZone, roleTitle: roleNames.SERVICE_PROVIDER }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    localStorage.setItem("MobileNo", JSON.stringify(resp.data.data.mobileNo));
                    toast.success(resp.data.message)
                    setTimeout(() => {
                        navigate('/verifyOtp')
                        setSpinner(false)
                    },100)
                }
                else if (resp.data.code === 410) {
                    localStorage.setItem("MobileNo", JSON.stringify(values.email));
                    toast.success(resp.data.message)
                    setTimeout(() => {
                        navigate('/verifyOtp')
                        setSpinner(false)
                    },100)
                }
                else {
                    toast.error(resp.data.message)
                    setTimeout(() => {
                        setSpinner(false)
                    },100);
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    });
    const forgotPinWithEmail = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: forgotPinValidationWithEmail,
        onSubmit: values => {
            setSpinner(true)
            axios.post(forgotPasswordApi(), { ...values, timeZone: timeZone, roleTitle: roleNames.SERVICE_PROVIDER }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    navigate('/verifyOtp')
                    setSpinner(false)
                }
                else if (resp.data.code === 410) {
                    localStorage.setItem("email", JSON.stringify(values.email));
                    toast.success(resp.data.message)
                    navigate('/verifyOtp')
                    setSpinner(false)
                }

                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    });
    return {
        navigate,
        timeZone,
        formik,
        forgotPinWithEmail,
        spinner
    }
}

export default UseForgotPin