import React from 'react'
import { faqCreateApi, faqListApi, faqApi, faqUpdateApi,logOutApi } from '../../Context/api';
import { useFormik } from 'formik';
import axios from 'axios';
import { statusCodes, commonMessage } from '../../Constants/generalConfig';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/spinner/spinner';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { addFaqValidation } from '../../Context/validation';
import ValidationMessage from '../ValidationMessage/validationMessage';
import Paginations from '../../Components/Pagination/pagination'

const Faq = () => {
  const [faqListData, setFaqListData] = useState();
  const [openDialogType, updateOpenType] = useState('')
  const [spinner, setSpinner] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [faqId, setFaqId] = useState();
  const [modal, setModal] = useState(false)
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const PER_PAGE = 10
  const navigate = useNavigate()

  useEffect(() => {
    faqList()
    setSpinner(true)
  }, [page])

  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData.deviceId,userId:userData.userId, type: userData.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }

  const dialog = (openType) => {
    updateOpenType(openType)
  }
  const faqList = () => {
    axios.post(faqListApi(), { userId: userData.userId,page,count:PER_PAGE },{headers:{Authorization: `Bearer ${userData.token}` }}).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setFaqListData(resp.data.data);
        setSpinner(false)
        setCount(resp.data.totalCount)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setSpinner(false)
      }
    })
      .catch(() => {
        toast.error(commonMessage.networkError)

      })
  }
  const faqView = (id) => {
    axios.post(faqApi(), { faqId: id, loggedInUserId: userData.userId },{headers:{Authorization: `Bearer ${userData.token}` }}).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        formik.setValues(resp.data.data)
        setFaqId(id)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
    })
  }
  const faqUpdate = () => {
    setSpinner(true)
    axios.post(faqUpdateApi(), { question: formik.values.question, answer: formik.values.answer, loggedInUserId: userData.userId, faqId: faqId },{headers:{Authorization: `Bearer ${userData.token}` }}).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        toast.success(resp.data.message)
        formik.resetForm()
        faqList()
        setSpinner(false)
        setModal(false)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
        setSpinner(false)
      }
    })
  }
  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
    },
    validationSchema: addFaqValidation,
    onSubmit: values => {
      formik.resetForm()
      setSpinner(true)
      axios.post(faqCreateApi(), { question: values.question.trim(), answer: values.answer.trim(), createdby_id: userData.userId },{headers:{Authorization: `Bearer ${userData.token}` }}).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          faqList()
          setSpinner(false)
          setModal(false)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          toast.error(resp.data.message)
          setSpinner(false)
        }
      })
        .catch(() => {
          toast.error(commonMessage.networkError)

        })
    },
  });
  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd " style={{height: '90vh' }}>
          {spinner ? <Spinner /> : null}
          <header>
            <h2 className='page-tittle'><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Frequently Asked Questions</h2>
            <hr />
          </header>
          <div className="row">
            <div className="col-sm-12 listpadding">
              {
                faqListData?.map((item) =>
                  <div className="accordion accordion-flush" id="accordionFlushExample" key={item._id}>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + item._id} aria-expanded="false" aria-controls="flush-collapseOne">
                          {item.question}
                        </button>
                      </h2>
                      <div id={"flush-collapseOne" + item._id} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample" >
                        <div className="accordion-body">
                          {item.answer}
                          <p className='edit-btn' onClick={() => { dialog('EDIT'); faqView(item._id); setModal(true) }} data-bs-toggle="offcanvas" data-bs-target="#addQuestion" aria-controls="addQuestion">Edit</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className='w-100 d-flex justify-content-center'>
              {
                count > 10 &&
                <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
              }
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn" onClick={() => { dialog('ADD'); setModal(true) }} data-bs-toggle="offcanvas" data-bs-target="#addQuestion" aria-controls="addQuestion"><i className="fa-solid fa-plus"></i></button>
        </div>
        {/* Question Answer ADD Start */}
        <Offcanvas show={modal} onHide={() => setModal(false)} placement="end">
          <div style={{ padding: "10px" }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setModal(false)}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">{openDialogType === 'ADD' ? 'Add Question & Answer' : 'Edit Question & Answer'}</h1>
          </div>
          <div className="offcanvas-body">
            <form className="cus-form" onSubmit={formik.handleSubmit}>
              <div className="mb-2">
                <label className="form-label" >Question</label>
                <input type="text" className="form-control" placeholder="" name='question' onChange={formik.handleChange} value={formik.values.question} />
                <ValidationMessage formName={formik} name={'question'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Answer</label>
                <input type="text" className="form-control" placeholder="" name='answer' onChange={formik.handleChange} value={formik.values.answer} />
                <ValidationMessage formName={formik} name={'answer'} />
              </div>
              <div className="d-grid  col-6 mx-auto mb-3">
                {openDialogType === 'ADD' ? <button className="btn colorbtun popupbtn px" type='submit' >Submit</button>
                  : null
                }
              </div>
            </form>
            {openDialogType === 'EDIT' ? <button type='submit' className="btn colorbtun popupbtn px" onClick={() => faqUpdate()}>update</button>
              : null}
          </div>
        </Offcanvas>
        {/* Question Answer ADD End */}
      </section>
    </>
  )
}

export default Faq;