import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Availability from '../../Pages/Availability/availability'
import WaitingList from '../../Pages/WaitingList/waitingList'
import Calendar from '../../Pages/Appointment/calendar'
import AppointmentList from '../../Pages/Appointment/appointmentList'
import AppointmentView from '../../Pages/Appointment/appointmentView'

import ClientList from '../../Pages/Client/clientList'
import ClientView from '../../Pages/Client/clientView'
import TeamList from '../../Pages/Team/teamList'
import TeamProfile from '../../Pages/Team/teamProfile'
import ServiceList from '../../Pages/Service/serviceList'
import ServiceCategory from '../../Pages/Service/serviceCategory'
import ServiceDuration from '../../Pages/Service/serviceDuration'
import OfferList from '../../Pages/Offer/offerList'
import ReadNotification from '../../Pages/Notifications/notification'
import Setting from '../../Pages/Setting/setting'
import Policies from '../../Pages/Setting/Policy/policies'
import ChangePin from '../../Pages/Setting/changePin'
import HelpSupport from '../../Pages/Setting/Ticket/ticketList'
import SettingNotification from '../../Pages/Setting/settingNotification'
import BookingPolices from '../../Pages/Setting/Policy/bookingPolices'
import CancellationPolicies from '../../Pages/Setting/Policy/cancellattionPolicy'
import Faq from '../../Pages/Setting/faq'
import Profile from '../../Pages/Profile/profile'
import Dashboard from '../../Pages/Dashboard/dashboard'
import ReschedulingPolicy from '../../Pages/Setting/Policy/reschedulingPolicy'
import RefundPolicy from '../../Pages/Setting/Policy/refundPolicy'
import StaffCalendar from '../../Pages/AppointmentStaff/calendar';
import StaffAppointmentList from '../../Pages/AppointmentStaff/appointmentList';

import InsightList from '../../Pages/Insight/insightList'
import BookingDetails from '../../Pages/Insight/bookingDetails'
import ClientReport from '../../Pages/Insight/clientReport'
import TeamReport from '../../Pages/Insight/teamReport';
import DemoGraphics from '../../Pages/Insight/demoGraphics'
import ServiceReport from '../../Pages/Insight/serviceReport'
import FeedBack from '../../Pages/Insight/feedBack'
import RushHours from '../../Pages/Insight/rushHours'
import PageNoFound from '../Error/pageNoFound'
import Chat from '../../Pages/Chat/chat'
import ProfileTeam from '../../Pages/Profile/teamProfile';
import MyPlan from '../../Pages/Setting/My Plan/myPlan'
import MyPlanHistory from '../../Pages/Setting/My Plan/myPlanHistory'
import MyCard from '../../Pages/Setting/My Plan/myCard'
import ChatMobile from '../../Pages/Chat/chatMobile'
import AddTeam from '../../Pages/Team/addTeam'
import Reward from '../../Pages/Reward/reward'
import ComboView from '../../Pages/Appointment/comboView'
import ComboReport from '../../Pages/Insight/comboReport'
import ComboPacakgeReport from '../../Pages/Insight/comboPacakgeReport'

// import MultipleLogin from '../../Pages/Multiple Login/multipleLogin'


const Main = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  return (
    <div className='dashboard_main'>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="availability" element={<Availability />} />
        <Route path="appointmentList/calendar" element={userData?.roleTitle === 'STAFF' ? <StaffCalendar /> : <Calendar />} />
        <Route path="appointmentList" element={userData?.roleTitle === 'STAFF' ? <StaffAppointmentList /> : <AppointmentList />} />
        {/* <Route path="appointmentList/calendar" element={ <Calendar />} />
        <Route path="appointmentList" element={<AppointmentList />} /> */}
        <Route path="appointmentList/appointmentView/:id" element={<AppointmentView />} />
        <Route path="appointmentList/combo-view/:id" element={<ComboView />} />

        <Route path="waitingList" element={<WaitingList />} />
        <Route path="client" element={<ClientList />} />
        <Route path="client/clientView/:id" element={<ClientView />} />

        <Route path="team" element={<TeamList />} />
        <Route path="team/teamProfile/:staffId" element={<TeamProfile />} />
        <Route path="teamProfile/:staffId" element={<TeamProfile />} />
        <Route path="team/add-team" element={<AddTeam />} />

        <Route path="service" element={<ServiceCategory />} />
        <Route path="service/serviceList/:categoryId" element={<ServiceList />} />
        <Route path="service/serviceDuration" element={<ServiceDuration />} />

        <Route path="offer" element={<OfferList />} />
        {
          userData?.planDetails?.addons?.length && userData?.planDetails?.addons?.find((item) => item.keyName === "Rewards")?.keyValue ?
        <Route path="reward" element={<Reward />} />
        :null
        }

        <Route path="notification" element={<ReadNotification />} />

        <Route path="setting" element={<Setting />} />
        <Route path="setting/policies" element={<Policies />} />
        <Route path="setting/changePin" element={<ChangePin />} />
        <Route path="setting/helpSupport" element={<HelpSupport />} />
        <Route path="setting/helpSupport/faq" element={<Faq />} />
        <Route path="setting/notification" element={<SettingNotification />} />
        <Route path="setting/policies/bookingPolicies" element={<BookingPolices />} />
        <Route path="setting/policies/policiesDelete" element={<CancellationPolicies />} />
        <Route path="setting/policies/reschedulingPolicy" element={<ReschedulingPolicy />} />
        <Route path="setting/policies/refundPolicy" element={<RefundPolicy />} />
        <Route path="setting/my-plan" element={<MyPlan />} />
        <Route path="setting/my-plan/my-plan-history/:currency" element={<MyPlanHistory />} />
        <Route path="setting/my-card" element={<MyCard />} />

        <Route path="profile" element={userData?.roleTitle === 'SERVICE_PROVIDER' ? <Profile /> : <ProfileTeam />} />

        {
          userData?.planDetails?.addons?.length && userData?.planDetails?.addons?.find((item) => item.keyName === "insight")?.keyValue ?
            <>
              <Route path="insightList" element={<InsightList />} />
              <Route path="insightList/bookingDetails" element={<BookingDetails />} />
              <Route path="insightList/clientReport" element={<ClientReport />} />
              <Route path="insightList/teamReport" element={<TeamReport />} />
              <Route path="insightList/demoGraphics" element={<DemoGraphics />} />
              <Route path="insightList/serviceReport" element={<ServiceReport />} />
              <Route path="insightList/feedBack" element={<FeedBack />} />
              <Route path="insightList/rushHours" element={<RushHours />} />
              <Route path="insightList/combo-report" element={<ComboReport />} />
              <Route path="insightList/combo-package-report" element={<ComboPacakgeReport />} />
            </>
            : null
        }

        {
          userData?.planDetails?.addons?.length && userData.planDetails.addons.find((item) => item.keyName === 'chat')?.keyValue ?
            <>
              <Route path="chat" element={<Chat />} />
              <Route path="chat-mobile" element={<ChatMobile />} />
            </>
            : null
        }

        {/* <Route path="chat" element={<Chat />} /> */}
        {/* <Route path="chat-mobile" element={<ChatMobile />} /> */}
        {/* <Route path="multiple-login/:id" element={<MultipleLogin />} /> */}



        <Route path="*" element={<PageNoFound />} />
      </Routes>
    </div>
  )
}

export default Main
