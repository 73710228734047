import React, { useContext, useEffect, useState } from 'react'
import Profile from '../../Assets/images/profile.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    branchListApi, teamBookingRevenue, appointmentBook, teamRevenue, currency, teamReport, imgData, logOutApi,
    teamExport, staffListApi, serviceGivenByTeam
} from '../../Context/api';
import { commonMessage, statusCodes } from '../../Constants/generalConfig';
import moment from 'moment/moment';
import ScrollBars from 'react-scrollbar'
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../../Components/spinner/spinner'
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';
import { Offcanvas } from 'react-bootstrap';

const TeamReport = () => {
    const { userInfo } = useContext(Notifications)
    const [show, setShow] = useState(true)
    const [branchList, setBranchList] = useState([])
    const [booked, setBooked] = useState([])
    const [branchBook, setBranchBook] = useState([])
    const [revenue, setRevenue] = useState([])
    const [currencyName, setCurrencyName] = useState([])
    const [teamMember, setTeamMember] = useState([])
    const [spinner, setSpinner] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [state, setState] = useState({
        branch: userData?.locationId,
        days: "LAST7",
    });
    const [teamId, setTeamId] = useState('')
    const [teamSelectData, setTeamSelectData] = useState([])
    const [data, setData] = useState('')
    const [teamList, setTeamList] = useState([])
    const [serviceProvideData, setServiceProvideData] = useState([])
    const [showProfile, setShowProfile] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getBranchList()
            bookAppointment()
            totalRevenue()
            currencyList()
            getTeamList()
        }
    }, [])

    useEffect(() => {
        totalBooking()
        topTenTeamMember()
    }, [state.days, state.branch, teamId])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const currencyList = () => {
        axios.post(currency(), { loggedInUserId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setCurrencyName(resp.data.data.symbol)
            }
        }).catch(() => { })
    }
    const getBranchList = () => {
        axios
            .post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setBranchList(resp.data.data)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setBranchList([])
                }
            }).catch(() => { })
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };
    const totalBooking = () => {
        const data = {
            days: state.days,
            branchId: state.branch,
            today: moment(new Date()).format('YYYY-MM-DD'),
            userId: teamId
        }
        if (!state.branch) {
            delete data.userId
        }
        setSpinner(true)
        axios.post(teamBookingRevenue(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setBooked(resp.data.data[0])
                // if(!resp.data.data){
                //     setBooked([])
                // }
                setSpinner(false)
                if (state.branch) {
                    return setShow(false)
                }
                else if (!state.branch) {
                    return setShow(true)
                }
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
                setBooked([])
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
    }
    const bookAppointment = (e) => {
        const data = {
            days: e || state.days,
            branchId: state.branch,
            today: moment(new Date()).format('YYYY-MM-DD')
        }
        if (state.branch === '') {
            delete data.branchId
        }
        axios.post(appointmentBook(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setBranchBook(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setBranchBook([])
            }
        }).catch(() => { })
    }
    const totalRevenue = (e) => {
        const data = {
            days: e || state.days,
            branchId: state.branch,
            today: moment(new Date()).format('YYYY-MM-DD')
        }
        axios.post(teamRevenue(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                resp.data.data?.map((item) => {
                    setRevenue(item)
                })
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setRevenue([])
            }
        }).catch(() => { })
    }
    const topTenTeamMember = () => {
        const data = {
            days: state.days,
            branchId: state.branch,
            today: moment(new Date()).format('YYYY-MM-DD'),
            staffId: teamId
        }
        if (!state.branch) {
            delete data.staffId
        }
        setData(data)
        axios.post(teamReport(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTeamMember(resp.data.data)
                resp.data.data?.map((item) =>
                    setTeamSelectData([...teamSelectData, { userName: item.userName, userId: item.userId }])
                )

            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setTeamMember([])
                setTeamSelectData([])
            }
        }).catch(() => { })
    }
    const exportTeam = () => {
        setSpinner(true)
        axios.post(teamExport(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                window.open(resp.data.data)
                setSpinner(false)
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => { })
    }
    const getTeamList = () => {
        const data = {
            userId: userData?.userId,
            isActive: true,
            count: 1000,
            isAccepted: 'accepted',
            branchId: userData?.locationId
        }
        axios.post(staffListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {

            if (resp.data.code === statusCodes.ok) {
                setTeamList(resp.data.data)
            }
            else {
                setTeamList([])
            }
        }).catch(() => { })
    }
    const serviceProvide = (item) => {
        const data = {
            staffId: item._id,
            today: moment().format('YYYY-MM-DD'),
            days: state.days,
            branchId: state.branch
        }
        axios.post(serviceGivenByTeam(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setServiceProvideData(resp.data.data)
            }
            else {
                setServiceProvideData([])
            }
        }).catch(() => { })
    }
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className='page-tittle'> <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Team Reports</h2>
                        <hr />
                    </header>
                    {
                        intialRole({ permission: "insights", userData: userInfo, section: 'viewTeamReport' }) ?
                            <>
                                <div className="d-flex justify-content-between listpadding">
                                    <div className="dropdown">
                                        <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example"
                                            name="days" value={state.days}
                                            onChange={(e) => {
                                                handleInputChange(e)
                                                bookAppointment(e.target.value)
                                                totalRevenue(e.target.value)
                                            }}
                                        >
                                            <option hidden>Select days</option>
                                            <option value="TODAY">Today</option>
                                            <option value="LAST7">Last 7 days</option>
                                            <option value="LAST_MONTH">Last Month</option>
                                            <option value="THIS_MONTH">This Month</option>
                                        </select>
                                    </div>
                                    <div className="dropdown">
                                        <select className='mb-2' disabled style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example"
                                            name="branch" value={state.branch}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                if (!e.target.value) {
                                                    setTeamId('')
                                                }
                                            }}
                                        >
                                            <option hidden>All</option>
                                            <option value="">All</option>
                                            {branchList?.map((item) => (
                                                <option value={item._id} key={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='d-flex justify-content-end'>
                                            <button className='btn' style={{ background: '#FDF6F3', color: 'black', fontSize: '15px' }} onClick={() => exportTeam()}>Export</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="row my-4">
                                        <div className='col-lg-6'>
                                            <div className='d-flex justify-content-sm-center justify-content-md-center justify-content-lg-end mb-4'>
                                                <div className="card1">
                                                    <h2>{booked?.totalBooking ? booked?.totalBooking : 0}</h2>
                                                    <p>Total Bookings</p>
                                                </div></div></div>
                                        {
                                            show ? null :

                                                <div className='col-lg-6'>
                                                    <div className='mb-4'>
                                                        <div className="card1">
                                                            <h2>{currencyName}{booked?.totalRevenue ? booked?.totalRevenue : 0}</h2>
                                                            <p>Revenue Earned</p>
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                    {
                                        show ?
                                            <>
                                                <div className="col-sm-6 ">
                                                    <h3>Total Bookings</h3>
                                                    <hr className="innerhr" />
                                                    <div className="card listpadding2">
                                                        <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '228px' }} smoothScrolling={true}>
                                                            {
                                                                branchBook.map((item, i) => (
                                                                    <React.Fragment key={i}>
                                                                        <div className="d-flex justify-content-between">
                                                                            <h5>{item.name}</h5>
                                                                            <h5>{item.branchPercentage ? item.branchPercentage : 0}%</h5>
                                                                        </div>
                                                                        <div className="progress mb-2">
                                                                            <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.branchPercentage ? item.branchPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>

                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </ScrollBars>
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 ">
                                                    <div className='d-flex justify-content-between'>
                                                        <h3>Revenue Earned</h3>
                                                        {
                                                            revenue.totalRevenew?.map((item) =>
                                                                <h5 className='me-5 pe-4' key={item._id}>{currencyName}{item.amount}</h5>
                                                            )
                                                        }
                                                    </div>
                                                    <hr className="innerhr" />
                                                    <div className="card listpadding2">
                                                        <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '228px' }} >
                                                            {
                                                                revenue.branches?.map((item) =>
                                                                    <React.Fragment key={item._id}>
                                                                        <div className="d-flex justify-content-between" >
                                                                            <h5>{item.name}</h5>
                                                                            <h5>{currencyName}{item.amount}</h5>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </ScrollBars>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="col-sm-12 ">
                                                <div className="d-flex justify-content-between ">
                                                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }}
                                                        onChange={(e) => setTeamId(e.target.value)}>
                                                        <option hidden>All</option>
                                                        <option value="">All</option>
                                                        {teamList?.map((item) => (
                                                            <option value={item._id} key={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div>
                                                        <button className='btn' style={{ background: '#FDF6F3', color: 'black', fontSize: '15px' }} onClick={() => exportTeam()}>Export</button>
                                                    </div>
                                                </div>
                                                <div className="table-responsive client-scroll">
                                                    <table className="table cus-table">
                                                        <thead className="text-center tableheader">
                                                            <tr>
                                                                <th scope="col">Profile</th>
                                                                <th scope="col">Team Name</th>
                                                                <th scope="col">Booking</th>
                                                                <th scope="col">Service Provided</th>
                                                                <th scope="col">Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {teamMember.length ? (
                                                                teamMember.map((item, i) => (
                                                                    <tr className='cursor' key={i} onClick={() => {serviceProvide(item);setShowProfile(true)}}>
                                                                        <td><img alt='img' style={{borderRadius:'50%'}} src={item.userImage === '' ? Profile : imgData + item.userImage} height={60} width={60} /></td>
                                                                        <td><b>{item.userName}</b></td>
                                                                        <td>
                                                                            <div className="progress mb-2">
                                                                                <div className="progress-bar bar1 text-dark" role="progressbar" style={{ width: `${item.acceptedBookingServicePer}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{item.acceptedBookingServicePer}%</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.serviceAcceptedGiven}</td>
                                                                        <td>{currencyName}{item.totalRevenue}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="5" className="text-center">No record found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </>
                            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                </div>
            </section>

            {/* Service Provide */}
            <Offcanvas show={showProfile} onHide={() => setShowProfile(false)} placement="end">
                <div style={{ padding: "10px" }}>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {setServiceProvideData([]);setShowProfile(false)}}></button>
                    <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">Team Report</h1>
                </div>
                <div className="offcanvas-body">
                    <div className='service-scroll'>
                        <table className="table cus-table">
                            <thead className="text-center tableheader">
                                <tr>
                                    <th scope="col">Service</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Service Provided</th>
                                </tr>
                            </thead>
                            {
                                serviceProvideData?.map((item, i) =>
                                    <React.Fragment key={i}>
                                        <tbody>
                                            <tr>
                                                <td>{item.name}</td>
                                                <td>{currencyName}{item.amount}</td>
                                                <td>{item.count}</td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                                )
                            }
                        </table>
                    </div>
                </div>
                {/* </div> */}
            </Offcanvas>
        </>
    )
}

export default TeamReport