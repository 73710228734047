/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap';
import ScrollBars from 'react-scrollbar'
import DummyLogo from '../../Assets/images/dummyimg.png'

const GroupInfo = ({ showGroupInfo, setShowGroupInfo, setGroupUpdateSettingShow, setAddMember, addMember, teamMemberData, groupDetailData, imgData, disableAdmin, memberAddInGroup,
    groupSetting, updateBtn, memberRemoveInGroup,userData,file,Group,teamMember,groupUpdate,moment,checked,setChecked,uploadImage,groupName,setGroupName,setUpdateBtn }) => {
    return (

        <Modal size="lg" centered show={showGroupInfo} onHide={() => setShowGroupInfo(false)}>
            <div className='m-3 modal-lg'>
                <div className='row'>
                    <div className="col-sm-7">
                        <h1 className="modal-title fs-5 mt-2 float-end heading text-center" id="exampleModalLabel">Group Info</h1>
                    </div>
                    <div className='col-sm-5'>
                        <button className='btn float-end' onClick={() => { setShowGroupInfo(false); setGroupUpdateSettingShow(false) }} style={{ marginTop: '5px' }}>X</button>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    {
                        addMember ?
                            <div className="col-sm-6">
                                <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => setAddMember(false)}></i>
                                <ScrollBars horizontal autoHide={false} style={{ height: '400px', width: '100%' }} smoothScrolling={true}>
                                    {
                                        teamMemberData.map((item, index) =>
                                            <div className='d-flex' key={index}>
                                                <div className="row sideBar-body  shadow-Box mb-2">
                                                    <div className="col-sm-3 col-xs-3 sideBar-avatar">
                                                        <div className="avatar-icon">
                                                            <img src={item.image === '' ? DummyLogo : imgData + item.image} alt='img'/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-9 sideBar-main">
                                                        <div className="row">
                                                            <div className="col-sm-9 col-xs-8 sideBar-name">
                                                                <span className="name-meta">{item.name}
                                                                </span>
                                                                <br />
                                                                {
                                                                    groupDetailData.membersData.find((obj) => obj.user_id === item._id) ? <p style={{ fontSize: '12px' }}>Already member of group</p> : null
                                                                }
                                                            </div>

                                                            <div className='col-sm-2 ms-2 mt-2'>
                                                                {
                                                                    groupDetailData.membersData.find((obj) => obj.user_id === item._id) ?
                                                                        null
                                                                        :

                                                                        disableAdmin ?
                                                                            <button className='btn btn-sm btn_Style' onClick={() => memberAddInGroup(item)}>Add</button>
                                                                            :
                                                                            <button className='btn btn-sm btn_Style' disabled={groupDetailData.onlyAdminCanAddOrRemove} onClick={() => memberAddInGroup(item)}>Add</button>

                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </ScrollBars>
                            </div>
                            :
                            <div className="col-sm-6">
                                <h6 className='group_text cursor' onClick={() => { setAddMember(true); teamMember() }}>+ Add Participant</h6>
                                <ScrollBars horizontal autoHide={false} style={{ height: '400px', width: '100%' }} smoothScrolling={true}>
                                    {

                                        groupDetailData.membersData?.map((data, index) =>
                                            <div className='d-flex' key={index}>
                                                <div className="row sideBar-body  shadow-Box mb-2">
                                                    <div className="col-sm-3 col-xs-3 sideBar-avatar">
                                                        <div className="avatar-icon">
                                                            <img src={data.image === '' ? DummyLogo : imgData + data.image} alt='img'/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-9 sideBar-main">
                                                        <div className="row">
                                                            <div className="col-sm-8 col-xs-8 sideBar-name">
                                                                <span className="name-meta">{data.name}
                                                                </span>
                                                            </div>
                                                            <div className='col-sm-4'>
                                                                <h6 className={`${data.isAdmin ? 'admin-text' : null}`}>{data.isAdmin ? 'Admin' : null}</h6>
                                                                {
                                                                    disableAdmin ?
                                                                        <>
                                                                            <i className="ms-3 fa-solid fa-chevron-down" id="dropdownMenuGroup" data-bs-toggle="dropdown"></i>
                                                                            <ul className="dropdown-menu position-absolute" style={{ zIndex: "2" }} aria-labelledby="dropdownMenuGroup">
                                                                                <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#active" onClick={() => memberRemoveInGroup(data.user_id)}>Remove group</a>
                                                                                {data.user_id === userData?.userId ? null : <a className="dropdown-item" onClick={() => groupSetting(data.user_id, data.isAdmin)}>{data.isAdmin ? 'Dismiss as admin' : 'Make group admin'}</a>}
                                                                            </ul>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {data.user_id === userData?.userId ? <i className="ms-3 fa-solid fa-chevron-down" id="dropdownMenuGroup" data-bs-toggle="dropdown"></i> : null}
                                                                            <ul className="dropdown-menu position-absolute" style={{ zIndex: "2" }} aria-labelledby="dropdownMenuGroup">
                                                                                <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#active" onClick={() => memberRemoveInGroup(data.user_id)}>Remove group</a>
                                                                            </ul>
                                                                        </>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </ScrollBars>
                            </div>
                    }

                    <div className="col-sm-6">
                        <div className='d-flex'>
                            <div>
                                <img src={file === '' ? Group : imgData + file} width={80} height={80} style={{ borderRadius: '50%' }} alt="" />
                                <label htmlFor="icon-button-file" className="icon">
                                    <input accept="image/*" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={(event) => uploadImage(event.target.files[0])} />
                                    {

                                        disableAdmin ?
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '50px', marginLeft: '-25px', fill: 'rgb(76 68 68)' }} width="25" height="25" className="bi bi-camera" viewBox="0 0 16 16">
                                                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                            </svg>
                                            :
                                            groupDetailData.onlyAdminUpdateInfo ? null :
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '50px', marginLeft: '-25px', fill: 'rgb(76 68 68)' }} width="25" height="25" className="bi bi-camera" viewBox="0 0 16 16">
                                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                </svg>
                                    }
                                </label>
                            </div>
                            {
                                disableAdmin ?
                                    <input className='form-control mt-3 ms-3 me-4' type="text" placeholder='Group Name' style={{ height: "50px", }} value={groupName} onChange={(e) => { setGroupName(e.target.value); setUpdateBtn(true) }} />
                                    :
                                    <input className='form-control mt-3 ms-3 me-4' disabled={groupDetailData.onlyAdminUpdateInfo} type="text" placeholder='Group Name' style={{ height: "50px", }} value={groupName} onChange={(e) => { setGroupName(e.target.value); setUpdateBtn(true) }} />

                            }
                        </div>
                        {updateBtn ? <div className='mb-5 me-4'><button className='btn btn_Style float-end' onClick={groupUpdate}>Save</button></div> : null}
                        <hr className='mt-3' />
                        <h6 className='group_text cursor mt-3 ps-3'>Participant <span className='float-end pe-2'>{groupDetailData?.membersData?.length}</span></h6>
                        <h6 className='group_text cursor mt-3 ps-3'>Created by <span className='float-end pe-2'>{groupDetailData?.createdBy?.name}</span></h6>
                        <h6 className='group_text cursor mt-3 ps-3'>Created date <span className='float-end pe-2'>{moment(groupDetailData?.createdBy?.dateTime).format('lll')}</span></h6>
                        <h6 className='group_text cursor mt-3 ps-3'>Only admins can message<span className='float-end pe-2'>
                            <div className="form-check form-switch">
                                <input className="form-check-input" checked={checked.onlyAdminCanMessage}
                                    disabled={!disableAdmin}
                                    onChange={(e) => { setChecked((pre) => ({ ...pre, onlyAdminCanMessage: e.target.checked, type: 'onlyAdminCanMessage' })); setGroupUpdateSettingShow(true) }} type="checkbox" />
                            </div>
                        </span></h6>
                        <h6 className='group_text cursor mt-3 ps-3'>Only admins can edit group info<span className='float-end pe-2'>
                            <div className="form-check form-switch">
                                <input className="form-check-input" disabled={!disableAdmin} type="checkbox" checked={checked.onlyAdminUpdateInfo} onChange={(e) => { setChecked((pre) => ({ ...pre, onlyAdminUpdateInfo: e.target.checked, type: 'onlyAdminUpdateInfo' })); setGroupUpdateSettingShow(true) }} />
                            </div>
                        </span></h6>
                        <h6 className='group_text cursor mt-3 ps-3'>Only admins can add/remove members <span className='float-end pe-2'>
                            <div className="form-check form-switch">
                                <input className="form-check-input" disabled={!disableAdmin} type="checkbox" checked={checked.onlyAdminCanAddOrRemove} onChange={(e) => { setChecked((pre) => ({ ...pre, onlyAdminCanAddOrRemove: e.target.checked, type: 'onlyAdminCanAddOrRemove' })); setGroupUpdateSettingShow(true) }} />
                            </div>
                        </span></h6>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default GroupInfo