import { useState, useContext, useRef } from 'react'
import { useFormik } from 'formik'
import { basicProfileValidation,basicTeamProfileValidation } from '../../../Context/validation'
import { toast } from 'react-toastify'
import { statusCodes, commonMessage } from '../../../Constants/generalConfig'
import axios from 'axios'
import {
    getBasicProfileDetailsApi,
    updateProfileApi,
    imgData,
    currency,
    staffServices,
    staffBranches,
    imgPath
} from '../../../Context/api'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App';

const UseTeamProfile = () => {
    const [file, setFile] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [service, setService] = useState([])
    const [staffbranch, setStaffbranch] = useState([])
    const [currencyName, setCurrencyName] = useState([])
    const [update, setUpdate] = useState(true)
    const { userInfo, setUserInfo } = useContext(Notifications)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const fileInputRef = useRef(null);

    const navigate = useNavigate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getBasicProfileDetails()
            staffService()
            getStaffBranchList()
            currencyList()
        }
    }, [])

    const getBasicProfileDetails = () => {
        setSpinner(true)
        axios.post(getBasicProfileDetailsApi(), { userId: userData?.userId })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setSpinner(false)
                    setFile(resp.data.data.image)
                    for (let item in basicProfileForm.initialValues) {
                        basicProfileForm.setFieldValue(item, resp.data.data[item])
                    }
                }
                else {
                    setSpinner(false)
                }
            })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }

    const basicProfileForm = useFormik({
        initialValues: {
            name: '',
            mobileNo: '',
            email: '',
            DOB:'',
        },
        validationSchema:timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata'?basicTeamProfileValidation: basicProfileValidation,
        onSubmit: (values) => {
            const data={
                name:values.name.trim(),
                mobileNo:values.mobileNo,
                email:values.email,
                DOB:values.DOB,
                userId: userData?.userId, 
                image: file, 
                timeZone: timeZone, 
                roleTitle: userData?.roleTitle
            }
            axios
                .post(updateProfileApi(), { ...data, })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok) {
                        toast.success(resp.data.message)
                        getBasicProfileDetails()
                        let data = JSON.parse(localStorage.getItem('userData'))
                        let updateData = { ...data }
                        updateData.name = values.name
                        updateData.image = file
                        localStorage.setItem("userData", JSON.stringify(updateData))
                        setUserInfo(JSON.parse(localStorage.getItem('userData')))
                        setUpdate(true)
                    } else {
                        toast.error(resp.data.message)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const staffService = () => {
        axios.post(staffServices(), { userId: userData?.userId,providerId:userData?.createdbyId ,count: 100, page: 1,branchId:userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setService(resp.data.data)
            }
            else {
                setService([])
            }
        })
    }
    const getStaffBranchList = () => {
        axios
            .post(staffBranches(), { userId: userData?.userId,providerId:userData?.createdbyId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setStaffbranch(resp.data.data)
                }
                else {
                    setStaffbranch([])
                }
            })
    }
    const currencyList = () => {
        axios.post(currency(), { loggedInUserId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setCurrencyName(resp.data.data.symbol)
            }
        })
    }
    return {
        navigate,
        currencyList,
        getStaffBranchList,
        getBasicProfileDetails,
        staffService,
        setFile,
        setSpinner,
        setService,
        setStaffbranch,
        setCurrencyName,
        setUpdate,
        setUserInfo,
        basicProfileForm,
        timeZone,
        userData,
        file,
        userInfo,
        update,
        currencyName,
        staffbranch,
        spinner,
        service,
        imgData,
        imgPath,
        timeZone,
        fileInputRef
    }
}

export default UseTeamProfile