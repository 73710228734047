import React, { useEffect, useState } from 'react'
import { myPlan, logOutApi, checkRefundPrice, CancelPlan, CancelPlanStripe, currency } from '../../../Context/api'
import axios from 'axios'
import { commonMessage, statusCodes, roleNames } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../Components/spinner/spinner'
import NoRecord from '../../../Components/noRecord'
import { FaLocationDot } from "react-icons/fa6";
import { MdGroup } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import { Modal } from 'react-bootstrap'
import { FaCalendarAlt } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
const MyTicket = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [currentPlanData, setCurrentPlanData] = useState([])
  const [cancelPrice, setCancelPrice] = useState('')
  const [currencyName, setCurrencyName] = useState('')
  const [cancelShow, setCancelShow] = useState(false)


  // console.log(cancelPrice, 'cancelPrice')
  useEffect(() => {
    getPlan()
    currencyList()
  }, [])
  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    }).catch(() => { })
  }
  const getPlan = () => {
    setSpinner(true)
    axios.post(myPlan(), { providerId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCurrentPlanData(resp.data.data)
        setSpinner(false)

      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }

      else {
        setSpinner(false)
        setCurrentPlanData([])
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const checkRefund = () => {
    setSpinner(true)
    axios.post(checkRefundPrice(), { userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCancelPrice(resp.data.data)
        setSpinner(false)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }

      else {
        setSpinner(false)
        setCancelPrice('')
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const cancelPlans = () => {
    setSpinner(true)
    axios.post(currentPlanData?.paymentMode == 'STRIPE' ? CancelPlanStripe() : CancelPlan(), { userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        toast.success(resp.data.message)
        setSpinner(false)
        setShow(false)
        setCancelShow(false)
        getPlan()
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }

      else {
        setSpinner(false)
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const currencyList = () => {
    axios.post(currency(), { loggedInUserId: userData?.userId }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCurrencyName(resp.data.data.symbol)
      }
    }).catch(() => { })
  }
  // console.log(currentPlanData?.paymentMode,'currentPlanData?.paymentMode')
  return (
    <>
      <section>
        <div className="m-t-3 cus-padd cusheight">
          {spinner && <Spinner />}
          <header>
            <h2 className="page-tittle"><i className="fa-solid fa-angle-left cusgap cursor mb-1" onClick={() => navigate(-1)}></i>Current Plan</h2>

            <hr />
          </header>
          <div className="">
            {
              currentPlanData?.length == 0 ? <NoRecord />
                :
                <div className='col-sm-10 shadow my-5 pb-5 mx-auto' style={{ height: 'auto', borderRadius: '10px' }}>
                  <div className="row">
                    <div className="col-sm-4 mt-5">
                      <img className='ms-4' src={require('../../../Assets/images/appointment-schedule-calendar.jpg')} alt="" style={{ height: '15vh', borderRadius: '10px', width: '90%' }} />
                      <h6 className='fw-bold mt-5 ms-4'>Thanks for using ISKEDO.COM</h6>
                      <p className='ms-4 text_Grey' >
                        Thanks for trusting and using ISKEDO we promise to provide you with fulfilling and satisfactory services shortly too. We assure you of better services and commitment
                      </p>
                    </div>
                    <div className="col-sm-4 mt-4">
                      <div className='mt-4 ms-5 me-5'>
                        <h5 className='fw-bold '>Current Plan ({currentPlanData?.paymentMode==='FREE'?'Free':'Custom'})</h5>
                        <div className="d-flex justify-content-between mt-5">
                          <div className='d-flex me-3'>
                            <FaLocationDot className='_icon' />
                            <p className='fw-bold'>Locations</p>
                          </div>
                          <p>{currentPlanData?.features?.[1].keyValue}</p>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <div className='d-flex me-3'>
                            <FaHandHoldingHeart className='_icon' />
                            <p className='fw-bold'>Services</p>
                          </div>
                          <p>{currentPlanData?.features?.[0].keyValue}</p>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <div className='d-flex me-3'>
                            <MdGroup className='_icon' />
                            <p className='fw-bold'>Teams</p>
                          </div>
                          <p>{currentPlanData?.features?.[2].keyValue}</p>
                        </div>
                        <hr />
                        <button className='btn btn-sm px-3 plan_Btn mt-5' onClick={() => setShow(true)}>Manage Plan</button>
                      </div>
                    </div>
                    <div className="col-sm-4 mt-5">
                      <div className='ms-5 me-5'>
                        <h5 className='fw-bold'>Billing and Payment</h5>
                        <div className='d-flex mt-5'>
                          <FaCalendarAlt className='mt-1 text_Grey ' style={{ fontSize: '20px' }} />
                          <p className='ms-1 mt-1 text-wrap text_Grey'> {currencyName} {currentPlanData?.paidAmount}/{currentPlanData?.validity} next payment on {moment(currentPlanData?.endDate
                          ).format('lll')}</p>
                        </div>
                        <u className='text-primary cursor pt-5' onClick={() => navigate(`my-plan-history/${currencyName}`)}>View billing history</u>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
        <Modal centered show={show} size='lg' onHide={() => setShow(false)}>
          <div className="modal-body px-4">
            <div className='d-flex justify-content-between mt-2'>
              <h4 className='fw-bold'>Manage Plan</h4>
              <i className="fa-solid fa-xmark cursor" onClick={() => { setShow(false); setCancelShow(false) }}></i>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-5 mt-4">
                <h6 style={{ fontWeight: 'bolder' }}>Your Current Plan Includes : </h6>
                <div className="d-flex justify-content-between mt-4 ms-2 me-5">
                  <div className='col-6 d-flex me-3'>
                    <FaLocationDot className='_icon' />
                    <p className='fw-bold'>Locations</p>
                  </div>
                  <div className='col-4'><p>{currentPlanData?.features?.[1].keyValue}</p></div>
                  <div className='col-2'></div>

                </div>
                <div className="d-flex justify-content-between ms-2 me-5">
                  <div className='col-6 d-flex me-3'>
                    <FaHandHoldingHeart className='_icon' />
                    <p className='fw-bold'>Services</p>
                  </div>
                  <div className='col-4'><p>{currentPlanData?.features?.[0].keyValue}</p></div>
                  <div className='col-2'></div>
                </div>
                <div className="d-flex justify-content-between ms-2 me-5">
                  <div className='col-6 d-flex me-3'>
                    <MdGroup className='_icon' />
                    <p className='fw-bold'>Teams</p>
                  </div>
                  <div className='col-4'><p>{currentPlanData?.features?.[2].keyValue}</p></div>
                  <div className='col-2'></div>
                </div>
                {
                  !currentPlanData?.addons?.[0].keyValue && !currentPlanData?.addons?.[1].keyValue
                    && !currentPlanData?.addons?.[2].keyValue && !currentPlanData?.addons?.[3].keyValue ? null
                    :
                    <>
                      <h6 className='fw-bold mt-2 mb-3'>Add Ons</h6>
                      {
                        currentPlanData?.addons?.[0] && currentPlanData?.addons?.[0].keyValue ?
                          <>
                            <div className="d-flex justify-content-between ms-1">
                              <div className='d-flex me-3'>
                                {currentPlanData?.addons?.[0] && currentPlanData?.addons?.[0].keyValue ? <img src={require('../../../Assets/icon/check-mark (1).png')} width={20} height={20} alt="" /> : <img src={require('../../../Assets/icon/delete.webp')} width={20} height={20} alt="" />}
                                <p className='fw-bold text_Grey'>Chat</p>
                              </div>
                            </div>
                          </>
                          : null
                      }
                      {
                        currentPlanData?.addons?.[1] && currentPlanData?.addons?.[1].keyValue ?
                          <>
                            <div className="d-flex justify-content-between ms-1">
                              <div className='d-flex me-3'>
                                {currentPlanData?.addons?.[1] && currentPlanData?.addons?.[1].keyValue ? <img src={require('../../../Assets/icon/check-mark (1).png')} width={20} height={20} alt="" /> : <img src={require('../../../Assets/icon/delete.webp')} width={20} height={20} alt="" />}
                                <p className='fw-bold text_Grey'>Insight</p>
                              </div>
                            </div>
                          </>
                          : null
                      }
                      {
                        currentPlanData?.addons?.[2] && currentPlanData?.addons?.[2].keyValue ?
                          <>
                            <div className="d-flex justify-content-between ms-1">
                              <div className='d-flex me-3'>
                                {currentPlanData?.addons?.[2] && currentPlanData?.addons?.[2].keyValue ? <img src={require('../../../Assets/icon/check-mark (1).png')} width={20} height={20} alt="" /> : <img src={require('../../../Assets/icon/delete.webp')} width={20} height={20} alt="" />}
                                <p className='fw-bold text_Grey'>Team Login</p>
                              </div>
                            </div>
                          </>
                          : null
                      }
                      {
                        currentPlanData?.addons?.[3] && currentPlanData?.addons?.[3].keyValue ?
                          <>
                            <div className="d-flex justify-content-between ms-1">
                              <div className='d-flex me-3'>
                                {currentPlanData?.addons?.[3] && currentPlanData?.addons?.[3].keyValue ? <img src={require('../../../Assets/icon/check-mark (1).png')} width={20} height={20} alt="" /> : <img src={require('../../../Assets/icon/delete.webp')} width={20} height={20} alt="" />}
                                <p className='fw-bold text_Grey'>Email Reminders</p>
                              </div>
                            </div>
                          </>
                          : null
                      }
                    </>
                }
              </div>
              <div className="col-sm-7 mt-4">
                {/* <div className="col-lg-12">
                  <h6 style={{ fontWeight: 'bolder' }}>Available Actions : </h6>
                  <div className='d-flex mt-4'>
                    <img src={require('../../../Assets/icon/Add ons.png')} alt="icon" height={25} />
                    <h6 className='fw-bold mt-1 ms-1'>Add Ons</h6>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex">
                      <ul className='p-0 ms-5 text_Grey font13' style={{ flexWrap: 'wrap' }} >
                        <li>Chat</li>
                        <li>Insights</li>
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <ul className='p-0 ms-5 text_Grey font13' style={{ flexWrap: 'wrap' }} >
                        <li>E-mail Reminders</li>
                        <li>Team Login</li>
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4  text-sm-start text-md-start text-lg-center">
                        <a href="https://iskedo.com/contact-us/">
                      <button className='btn  btn-sm px-3 plan_Btn'>
                        Upgrade Plan
                      </button>
                      </a>
                    </div>
                  </div>
                </div> */}
               

                <div className="col-lg-12">
                  <div className='d-flex'>
                    <FaCircleXmark className='_icon' />
                    <h6 className='mt-1 fw-bold'>End Your Service</h6>
                  </div>
                  <p className='text_Grey ms-4 mt-2'>You can drop your subscription anytime.</p>
                  {/* <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex">
                      <p className='text_Grey ms-4 mt-2'>You can drop your subscription anytime.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 text-sm-start text-md-start text-lg-center">
                      <button className='btn  btn-sm px-3 mt-2 plan_Btn' disabled={cancelShow} onClick={() => { setCancelShow(true); checkRefund() }}>Cancel Plan</button>
                    </div>
                  </div> */}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4  text-sm-start text-md-start text-lg-center">
                        <a href="https://iskedo.com/contact-us/">
                      <button className='btn  btn-sm px-3 plan_Btn'>
                        Upgrade Plan
                      </button>
                      </a>
                    </div>
                <div className="col-sm-12 col-md-6 col-lg-4 text-sm-start text-md-start text-lg-center">
                      <button className='btn  btn-sm px-3 mt-3 plan_Btn' disabled={cancelShow} onClick={() => { setCancelShow(true); checkRefund() }}>Cancel Plan</button>
                    </div>
                <div className="col-sm-12">
                  <div className="d-flex">
                    {/* <div className="col-4 d-flex">
                    </div> */}
                    <div className="col-4 d-flex ">
                        <div className={`d-flex text-center justify-content-between mt-1 ms-3 text-center ${cancelShow ? 'show_Cancel' : 'hidden_Cancel'}`}>
                          <img className='cursor me-2' style={{ marginTop: '3px' }} src={require('../../../Assets/images/Red.png')} alt="" height={20} width={20} onClick={() => setCancelShow(false)} />
                          <img className='cursor' src={require('../../../Assets/images/green.png')} alt="" height={25} width={25} onClick={() => cancelPlans()} />
                      </div>
                    </div>
                    <div className="col-6 mt-1">
                      <p className={`text-center ${cancelShow ? 'show_Cancel' : 'hidden_Cancel'}`} style={{ background: '#eea8a8', color: '#bb3d3d', fontSize: '10px' }}>Are you sure you want to cancel your plan <br />
                        If You cancel this plan then you will recieve {currencyName} {cancelPrice}</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </section>
    </>
  )
}

export default MyTicket