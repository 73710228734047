import React from 'react'
import DummyLogo from '../../Assets/images/dummyimg.png'
import { ToastContainer } from 'react-toastify'
import ScrollBars from 'react-scrollbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UseBasicDetailProfile from './Hooks/useBasicDetailProfile'
import NumberInput from '../../Components/PhoneInput/phoneInput'
import Spinner from '../../Components/spinner/spinner'
import { Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UseImage from '../../Hooks/useImage'

const BasicDetailProfile = () => {
  const {
    uploadImageProfile,
    countryListData,
    stateListData,
    branchDelete,
    getBranchList,
    setShowBranch,
    setBranchId,
    setTab,
    getBranchListDetails,
    setShow,
    EditBranchForm,
    show,
    tab,
    showBranch,
    file,
    branchListArr,
    stateList,
    basicProfileForm,
    businessProfileForm,
    addBranchForm,
    country,
    imgData,
    timeZone,
    spinner,
    branchImg, setBranchImg,
    branchImgPath,
    fileInputRef,
    branchId
  } = UseBasicDetailProfile()
  const { imageDelete, uploadImage, imageDeleteUpload } = UseImage()
  return (
    <>
      <ToastContainer />
      {spinner&&<Spinner/>}
      <section>
        <div className="container mt-3 shadow cus-padd cusheight">
          <hr />
          <nav className="mb-3">
            <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
              <button onClick={() => setTab(1)} className={` ${tab === 1 ? 'activeNav' : 'unActiveNav'} border-0`} id="basicdetails"  type="button" role="tab" aria-controls="basic" aria-selected="true">
                Basic Details
              </button>
              <button onClick={() => {getBranchList(); }} className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`} id="businessdetails"  type="button" role="tab" aria-controls="business" aria-selected="false">
                Business Details
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            {/* Basic Profile Start */}

            {
              tab === 1 && <>
                <div className="text-center mb-4">
                  <img src={file === '' ? DummyLogo : imgData + file} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                  <label htmlFor="icon-button-file" className="icon">
                    <input accept="image/*" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={(event) => uploadImageProfile(event.target.files[0])} />
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                      <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                      <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                    </svg>
                  </label>
                </div>

                <form className="row g-3 cus-form" onSubmit={basicProfileForm.handleSubmit}>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Name *</label>
                      <input
                        maxLength={30}
                        type="text"
                        pattern="[A-Za-z ]*" title="Please enter only alphabetic characters"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        onChange={basicProfileForm.handleChange}
                        onBlur={basicProfileForm.handleBlur}
                        value={basicProfileForm.values.name}
                      />
                      <i className="fa-solid fa-user"></i>
                      <ValidationMessage formName={basicProfileForm} name={'name'} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">E-mail Address *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="E-mail"
                        name="email"
                        onChange={basicProfileForm.handleChange}
                        onBlur={basicProfileForm.handleBlur}
                        value={basicProfileForm.values.email}
                      />
                      <i className="fa-solid fa-envelope"></i>
                      <ValidationMessage formName={basicProfileForm} name={'email'} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Mobile Number *</label>
                      <NumberInput
                        width={false}
                        Require={false}
                        Name="mobileNo"
                        HandleChange={basicProfileForm}
                        Value={basicProfileForm.values.mobileNo}
                        // countrySelectComponent="null"
                        disabledInput={timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? true : false}
                      />
                      <i className="fa-solid fa-phone-flip"></i>
                      <ValidationMessage formName={basicProfileForm} name={'mobileNo'} />
                    </div>
                  </div>
                  <div className="d-grid  col-12  mb-3 justify-content-end">
                    <button
                      style={{ marginLeft: '10px' }}
                      type="submit"
                      className="btn colorbtun sidebtn px"
                      id="businessdetails"
                    >
                      Next
                    </button>
                  </div>
                </form>

              </>
            }
            {/* Basic Profile End */}


            {/* Business Profile Start */}
            {
              tab === 2 &&
              <>
                <form className="row g-3 cus-form" onSubmit={businessProfileForm.handleSubmit}>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Business Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Business Name"
                        name="businessName"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.businessName}
                        maxLength={50}
                      />
                      <ValidationMessage formName={businessProfileForm} name={'businessName'} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Business Category *</label>
                      <input type="text" className="form-control"
                        aria-label="Default select example"
                        name="businessCategory"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.businessCategory} />
                      <ValidationMessage formName={businessProfileForm} name={'businessCategory'} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">About *</label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        name="about"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.about}
                      ></textarea>
                      <ValidationMessage formName={businessProfileForm} name={'about'} />
                      <p className="text-end tinytext">0-300</p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <label className="form-label" style={{ marginTop: '6px' }}>
                      Add Branch
                    </label>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Branch</Tooltip>}>
                      <p
                        className="btn addbtn "
                        name="addBranch"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.addBranch}
                        onClick={() => { countryListData(); setShowBranch(true) }}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </p>
                    </OverlayTrigger>
                  </div>
                  <hr />
                  <ScrollBars horizontal autoHide={false} style={{ height: '250px', width: '100%' }}>
                    {branchListArr?.map((item, i) => (
                      <div className="col-md-12" key={i}>
                        <p className={`form-control ${item.isActive === false ? `bg-dark bg-opacity-10 shadow-none` : null}`}>
                          <i className="fa-solid fa-location-dot"></i> &nbsp; <b>{item.name}</b>
                          <br />
                          {item.city}
                          <p style={{ float: 'right', fontSize: '18px', cursor: 'pointer' }} onClick={() => setBranchId(item._id)} data-bs-toggle="modal" data-bs-target="#branchDelete">
                            Delete
                          </p>
                          <p
                            style={{ float: 'right', fontSize: '18px', cursor: 'pointer', marginRight: '20px' }}
                            onClick={() => {
                              getBranchListDetails(item._id)
                              countryListData()
                              setShow(true)

                            }}
                          >
                            Edit
                          </p>
                        </p>
                      </div>
                    ))}
                  </ScrollBars>
                  <div className="d-grid  col-12  mb-3 justify-content-end">
                    <button type="submit" className="btn colorbtun sidebtn px" style={{ marginLeft: '10px' }}>
                      Submit
                    </button>
                  </div>
                </form>

              </>
            }

            {/* Business Profile End */}
          </div>
        </div>
        {/* Add Branch Start */}
        <Offcanvas show={showBranch} onHide={() => setShowBranch(false)} placement="end">
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowBranch(false); addBranchForm.resetForm();setBranchImg('') }}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Add Branch
            </h1>
          </div>
          <div className="text-center mb-4">
            <img src={branchImg === '' ? DummyLogo : branchImgPath + branchImg} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
            <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
            </svg>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => uploadImage(e.target.files[0], 'BRANCH', setBranchImg, fileInputRef)}
              />
              <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

              <br />
              {branchImg === '' ? null : (
                <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(branchImg, 'BRANCH', setBranchImg)} startIcon={<DeleteIcon />}>Delete</Button>
              )}
            </ul>
          </div>
          <div className="offcanvas-body">
            <form className="cus-form" onSubmit={addBranchForm.handleSubmit}>
              <div className="mb-2">
                <label className="form-label">Branch Name *</label>
                <input type="text" className="form-control" placeholder="" name="name" onChange={addBranchForm.handleChange} onBlur={addBranchForm.handleBlur} value={addBranchForm.values.name} />
                <ValidationMessage formName={addBranchForm} name={'name'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Building *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="building"
                  onChange={addBranchForm.handleChange}
                  onBlur={addBranchForm.handleBlur}
                  value={addBranchForm.values.building}
                />
                <ValidationMessage formName={addBranchForm} name={'building'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Street *</label>
                <input type="text" className="form-control" placeholder="" name="street" onChange={addBranchForm.handleChange} onBlur={addBranchForm.handleBlur} value={addBranchForm.values.street} />
                <ValidationMessage formName={addBranchForm} name={'street'} />
              </div>
              <div className="mb-2">
                <label className="form-label">City *</label>
                <input type="text" className="form-control" placeholder="" name="city" onChange={addBranchForm.handleChange} onBlur={addBranchForm.handleBlur} value={addBranchForm.values.city} />
                <ValidationMessage formName={addBranchForm} name={'city'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Country *</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="country"
                  onChange={(e) => { addBranchForm.setFieldValue('country', e.target.value); stateListData(e.target.value) }}
                  onBlur={addBranchForm.handleBlur}
                  value={addBranchForm.values.country}
                >
                  <option>Select Country</option>
                  {country.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage formName={addBranchForm} name={'country'} />
              </div>
              <div className="mb-2">
                <label className="form-label">State *</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="state"
                  onChange={addBranchForm.handleChange}
                  onBlur={addBranchForm.handleBlur}
                  value={addBranchForm.values.state}
                >
                  <option>Select State</option>
                  {stateList.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
                <ValidationMessage formName={addBranchForm} name={'state'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Zip Code *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="zipCode"
                  onChange={addBranchForm.handleChange}
                  onBlur={addBranchForm.handleBlur}
                  value={addBranchForm.values.zipCode}
                  maxLength={10}
                />
                <ValidationMessage formName={addBranchForm} name={'zipCode'} />
              </div>
              <div className="mb-2">
                <label className="form-label">E-mail Address *</label>
                <input type="text" className="form-control" placeholder="" name="email" onChange={addBranchForm.handleChange} onBlur={addBranchForm.handleBlur} value={addBranchForm.values.email} />
                <ValidationMessage formName={addBranchForm} name={'email'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Mobile Number *</label>
                <NumberInput
                  width={false}
                  Require={false}
                  Name="mobileNo"
                  HandleChange={addBranchForm}
                  Value={addBranchForm.values.mobileNo}
                />
                <ValidationMessage formName={addBranchForm} name={'mobileNo'} />
              </div>
              <div className="mb-5">
                  <label className="form-label">Alternate Mobile Number</label>
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="alternateMobileNo"
                    HandleChange={addBranchForm}
                    Value={addBranchForm.values.alternateMobileNo}
                  />
                </div>

              <div className="d-grid  col-6 mx-auto mb-2">
                <button type="submit" className="btn colorbtun popupbtn px " data-bs-dismiss="offcanvas" aria-label="Close">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
        {/* Add Branch End */}

        {/* Edit Branch Start */}
        <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" onClick={() => { setShow(false); EditBranchForm.resetForm();setBranchImg('') }} data-bs-dismiss="offcanvas" aria-label="Close"></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Edit Branch
            </h1>
          </div>
          <div className="text-center mb-4">
            <img src={branchImg === '' || branchImg === null||branchImg === undefined ? DummyLogo : branchImgPath + branchImg} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
            <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
              <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
              <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
            </svg>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => uploadImage(e.target.files[0], 'BRANCH', setBranchImg, fileInputRef)}
              />
              <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

              <br />
              {branchImg === '' || branchImg === null||branchImg === undefined ? null : (
                <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(branchId,branchImg, 'BRANCH', setBranchImg)} startIcon={<DeleteIcon />}>Delete</Button>
              )}
            </ul>
          </div>
          <div className="offcanvas-body">
            <form className="cus-form" onSubmit={EditBranchForm.handleSubmit}>
              <div className="mb-2">
                <label className="form-label">Branch Name *</label>
                <input type="text" className="form-control" name="name" maxLength={30} onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.name} />
                <ValidationMessage formName={EditBranchForm} name={'name'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Building *</label>
                <input type="text" className="form-control" name="building" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.building} />
              </div>
              <ValidationMessage formName={EditBranchForm} name={'building'} />
              <div className="mb-2">
                <label className="form-label">Street *</label>
                <input type="text" className="form-control" name="street" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.street} />
                <ValidationMessage formName={EditBranchForm} name={'street'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Country *</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="countryId"
                  onChange={(e) => { EditBranchForm.setFieldValue('countryId', e.target.value); stateListData(e.target.value) }}
                  onBlur={EditBranchForm.handleBlur}
                  value={EditBranchForm.values.countryId}
                >
                  <option value="" disabled selected hidden>Select Country</option>
                  {country.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage formName={EditBranchForm} name={'countryId'} />
              </div>
              <div className="mb-2">
                <label className="form-label">State *</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="stateId"
                  onChange={EditBranchForm.handleChange}
                  onBlur={EditBranchForm.handleBlur}
                  value={EditBranchForm.values.stateId}
                >
                  <option value="" disabled selected hidden>Select State</option>
                  {stateList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage formName={EditBranchForm} name={'stateId'} />
              </div>
              <div className="mb-2">
                <label className="form-label">City *</label>
                <input type="text" className="form-control" name="city" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.city} />
                <ValidationMessage formName={EditBranchForm} name={'city'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Zip Code *</label>
                <input type="text" className="form-control" name="zipCode" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.zipCode}
                  maxlength={10}
                />
                <ValidationMessage formName={EditBranchForm} name={'zipCode'} />
              </div>
              <div className="mb-2">
                <label className="form-label">E-mail Address *</label>
                <input type="text" className="form-control" placeholder="" name="email" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.email} />
                <ValidationMessage formName={EditBranchForm} name={'email'} />
              </div>
              <div className="mb-2">
                <label className="form-label">Mobile Number *</label>
                <NumberInput
                  width={false}
                  Require={false}
                  Name="mobileNumber"
                  HandleChange={EditBranchForm}
                  Value={EditBranchForm.values.mobileNumber}
                />
                <ValidationMessage formName={EditBranchForm} name={'mobileNumber'} />
              </div>
              <div className="mb-5">
                  <label className="form-label">Alternate Mobile Number</label>
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="alternateMobileNo"
                    HandleChange={EditBranchForm}
                    Value={EditBranchForm.values.alternateMobileNo}
                  />
                </div>
              <div className="d-grid  col-6 mx-auto mb-2">
                <button className="btn colorbtun popupbtn px" data-bs-dismiss="offcanvas" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
        {/* Edit Branch End */}0

        {/* Branch Delete Start */}
        <div className="modal fade" id="branchDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body text-center">
                <p>Are You Sure !</p>
                <p>You Want to Delete Your Branch</p>
                <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => branchDelete()}>
                  Yes
                </button>
                <button type="button" className="btn nobtn ms-1" data-bs-dismiss="modal">
                  No
                </button>

              </div>
            </div>
          </div>
        </div>
        {/* Branch Delete End */}
      </section>
    </>
  )
}

export default BasicDetailProfile
