/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ToastContainer } from 'react-toastify'
import Spinner from '../../Components/spinner/spinner'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Paginations from '../../Components/Pagination/pagination'
import DummyLogo from '../../Assets/images/dummyimg.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap';
import NoRecord from '../../Components/noRecord'
import AddClient from './addClient'
import UseClient from './Hooks/useClientList'
import { Button } from '@mui/material';
import UseImage from '../../Hooks/useImage';
import { intialRole } from '../../Utils/Utils';
const ClientList = () => {
  const {
    handleInputChange,
    changeClientStatus,
    navigate,
    getClientList,
    setImageData,
    setClientDetail,
    setIsActive,
    setInvitationShow,
    setDeletedAccountShow,
    setShowClient,
    setPage,
    setClientArchive,
    clientArchive,
    timeZone,
    invitationReSend,
    addClient,
    date,
    imageData,
    listData,
    spinner,
    showClient,
    filter,
    count,
    page,
    PER_PAGE,
    invitationShow,
    deletedAccountShow,
    userInfo,
    imgData,
    clientDetail,

  } = UseClient()
  const { imageDeleteUpload, uploadImage } = UseImage()

  return (
    <>
      {/* <PhoneInput/> */}
      <ToastContainer />
      <section className='position-relative'>
        <div className="m-t-3 cus-padd cusheight">
          {spinner && <Spinner />}
          <header>
            <h2 className="page-tittle">Clients</h2>
            <hr />
          </header>
          <div className="row">
            {
              intialRole({ permission: "client", userData: userInfo, section: 'view' }) ?
                <div className="col-sm-12 listpadding">
                  <div className="d-flex justify-content-end">
                    <>
                      <div style={{ width: '260px' }}>
                        <select className='form-select' name='status' value={filter.status} onChange={(e) => handleInputChange(e)}>
                          <option hidden>Status</option>
                          <option value="accepted">Accepted</option>
                          <option value="pending">Pending</option>
                          <option value="declined">Declined</option>
                        </select>
                      </div>
                      <div>
                        <input type="search" placeholder='Search by name' className='form-control ms-3'
                          name='search'
                          value={filter.search}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                      <button className='btn ms-4 mb-3 btn_Style' type='submit' onClick={() => filter.status === '' && filter.search === '' ? null : getClientList()}>Search</button>
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset</Tooltip>}>
                        <button className="btn mb-2 ms-2">
                          <i className="fa fa-refresh" aria-hidden="true" onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}></i>
                        </button>
                      </OverlayTrigger>
                    </>
                    <ul className="navi">
                      <li>
                        <div className="dropdown">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Filter</Tooltip>}>
                            <i className="fa-solid fa-sliders" style={{ marginTop: '11px' }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></i>
                          </OverlayTrigger>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item cursor" onClick={() => { setIsActive(true); setPage(1) }}>
                              Un-Archive
                            </li>
                            <li className="dropdown-item cursor" onClick={() => { setIsActive(false); setPage(1) }} >
                              Archive
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>

                  </div>
                  {
                    !listData.length ? <NoRecord />
                      :
                      <div className="table-responsive">
                        <table className="table cus-table">
                          <thead className="text-center tableheader">
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Client</th>
                              <th scope="col">E-mail ID</th>
                              <th scope="col">Mobile Number</th>
                              <th scope="col">Invitation Status</th>
                              {intialRole({ permission: "client", userData: userInfo, section: 'action' }) ? <th scope="col">Action</th> : null}
                            </tr>
                          </thead>
                          {listData?.length === 0 ? <div className='position-absolute top-20 start-50 mt-5'><h3>No record found</h3></div> :
                            <tbody>
                              {listData?.map((item) => (<React.Fragment key={item._id}>
                                <tr className={intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : 'cursor' : null} style={{ background: item?.isDeleted ? "rgb(248 255 159 / 55%)" : "#fcf6f3" }}>
                                  <td style={{ padding: '0.3rem .5rem' }} onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : navigate(`clientView/${item._id}`) : null}>
                                    <span onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : navigate(`clientView/${item._id}`) : null}>
                                      <img alt="" src={(item.image === '' || item.image === null||item.image === 'null') ? DummyLogo : imgData + item.image} height={30} width={30} style={{ borderRadius: '50%' }} />
                                    </span>
                                  </td>
                                  <td onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : navigate(`clientView/${item._id}`) : null}>{item.name}</td>
                                  <td onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : navigate(`clientView/${item._id}`) : null}>{item.email}</td>
                                  <td onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : navigate(`clientView/${item._id}`) : null}>{item.mobileNo}</td>
                                  <td onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? item?.isDeleted ? null : navigate(`clientView/${item._id}`) : null}>{item.isAccepted?.charAt(0).toUpperCase() + item.isAccepted?.slice(1)}</td>
                                  {
                                    intialRole({ permission: "client", userData: userInfo, section: 'action' }) ?
                                      // !item?.isDeleted && userData.roleTitle == roleNames.SERVICE_PROVIDER || !item?.isDeleted && userData.roleTitle == roleNames.STAFF ?
                                      !item?.isDeleted ?
                                        <td>
                                          <Button variant="text" sx={{ color: 'black', fontSize: '12px' }} id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa-solid fa-ellipsis-vertical cursor"></i>
                                          </Button>
                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {item.isActive ?
                                              <a className="dropdown-item cursor" onClick={() => { setClientDetail(item); setClientArchive(true) }}>Archive</a>
                                              :
                                              <a className="dropdown-item cursor" onClick={() => { setClientDetail(item); setClientArchive(true) }}>Un-Archive</a>
                                            }
                                            {item.isAccepted === 'Accepted' ? null : <a className="dropdown-item cursor" onClick={() => { setClientDetail(item); invitationReSend.setFieldValue('oldEmail', item.email); invitationReSend.setFieldValue('email', ''); setInvitationShow(true) }}>Resend Invitation</a>}
                                          </ul>
                                        </td>
                                        : <td>
                                          <i className="fa-solid fa-circle-exclamation cursor" onClick={() => { setDeletedAccountShow(true) }}></i>
                                        </td>
                                      : null
                                  }

                                </tr>
                              </React.Fragment>))}
                            </tbody>
                          }
                        </table>
                        <div className='w-100 d-flex justify-content-center'>
                          {listData.length === 0 ? null :
                            count > 10 &&
                            <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                          }
                        </div>
                      </div>
                  }
                </div>
                :
                <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
            }
          </div>

        </div>
        {
          intialRole({ permission: "client", userData: userInfo, section: 'add' }) ?
            <div className="">
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Client</Tooltip>}>
                <p className="btn addbtn2">
                  <i className="fa-solid fa-plus" onClick={() => setShowClient(true)}></i>
                </p>
              </OverlayTrigger>
            </div>
            : null
        }
      </section>

      {/* Client Archive and Un-Archive Start */}
      {/* <div className="modal fade" id="active" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content"> */}
      <Modal show={clientArchive} size='sm' centered onHide={() => setClientArchive(false)}>
        <div className="modal-content">
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            <p>You want to {clientDetail?.isActive ? 'Archive' : 'Un-Archive'} this client</p>
            <button type="button" className="btn nobtn" data-bs-dismiss="modal" onClick={() => changeClientStatus()}>
              Yes
            </button>
            <button type="button" className="btn yesbtn" data-bs-dismiss="modal">
              No
            </button>
          </div>
        </div>
      </Modal>
      {/* </div>
        </div>
      </div> */}
      {/* Client Archive and Un-Archive End */}

      <AddClient timeZone={timeZone} imageDeleteUpload={imageDeleteUpload} showClient={showClient} setShowClient={setShowClient} addClient={addClient} imageData={imageData} uploadImage={uploadImage} ValidationMessage={ValidationMessage}
        date={date} setImageData={setImageData} imgData={imgData} DummyLogo={DummyLogo} />

      {/* Invitation  */}
      <Modal centered show={invitationShow} onHide={() => setInvitationShow(false)}>
        <div className="modal-body">
          <h5 className='text-center mt-2 '>Resend Invitation</h5>
          <div>
            <label className="form-label">Old Email *</label>
            <input type="text" className='form-control' disabled name='oldEmail' value={invitationReSend.values.oldEmail} onChange={invitationReSend.handleChange} />
          </div>
          <div className='mt-3'>
            <label className="form-label">New Email *</label>
            <input type="text" className='form-control' name='email' value={invitationReSend.values.email} onChange={invitationReSend.handleChange} />
            <ValidationMessage formName={invitationReSend} name={'email'} />
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <button type='button' className='btn yesbtn' style={{ color: 'white' }} onClick={invitationReSend.handleSubmit}>Submit</button>
          </div>
        </div>
      </Modal>

      {/* Deleted Account Model */}
      <Modal centered show={deletedAccountShow} onHide={() => setDeletedAccountShow(false)}>
        <div className="modal-body">
          <h5 className='text-left mt-2 mb-4' style={{ fontSize: "25px" }}><i className="fa-solid fa-triangle-exclamation me-2" style={{ color: "#ffb727", fontSize: "30px" }}></i> Account Status</h5>
          <span >We regret to inform you that this user has left Iskedo family and is no longer available on our platform.</span>
          <div className='d-flex justify-content-end mt-5'>
            <button type='button' className='btn yesbtn' style={{ background: '#f47d3a', color: 'white' }} onClick={() => setDeletedAccountShow(false)}>Close</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ClientList
