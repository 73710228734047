import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import DummyLogo from "../../Assets/images/dummyimg.png";
import Group from "../../Assets/images/group.png";
import moment from "moment/moment";
import { ToastContainer } from "react-toastify";
import logo from "../../Assets/images/logo white color.png";
import Spinner from "../../Components/spinner/spinner";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Picker from "emoji-picker-react";
import UseChat from "./Hooks/useChat";
import GroupInfo from "./groupInfo";
import GroupModal from "./groupModal";
import DirectModal from "./directModal";
import ClientModal from "./clientModal";
import { Modal } from "react-bootstrap";

const ChatMobile = () => {

    const {
        handleSendMessage,
        handleEditMessage,
        handleDeleteMessage,
        enterSendMessage,
        teamMember,
        Conversation,
        ConversationList,
        ConversationDelete,
        ConversationBlock,
        enterSearch,
        selectConversation,
        getClientList,
        uploadImage,
        handleCheckboxChange,
        groupDetail,
        memberAddInGroup,
        memberRemoveInGroup,
        groupSetting,
        handleScroll,
        matchTime,
        onEmojiClickEdit,
        onEmojiClick,
        pdfDownload,
        deleteDocument,
        uploadPdf,
        groupUpdate,
        setProfile,
        setSearchText,
        setSendMessage,
        setType,
        setGroupName,
        setShowModal,
        setShowGroupInfo,
        setAddMember,
        setUpdateBtn,
        setChecked,
        setMsgId,
        setEditMsg,
        setUploadData,
        setGroupUpdateSettingShow,
        setIndex,
        setShowPicker,
        setShowEditPicker,
        setConversationType,
        teamFilteredData,
        clientFilteredData,
        teamMemberData,
        conversationData,
        profile,
        spinner,
        userData,
        sendMessage,
        receiveMessages,
        type,
        file,
        groupName,
        selectedValues,
        showModal,
        showGroupInfo,
        addMember,
        groupDetailData,
        updateBtn,
        checked,
        msgId,
        editMsg,
        uploadData,
        index,
        messageEl,
        fileInputRef,
        disableAdmin,
        showPicker,
        showEditPicker,
        imgData,
        conversationType,
    } = UseChat();

    return (
        <>
            <section className='position-relative'>
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className="page-tittle">Chat Mobile</h2>
                        <hr />
                    </header>
                    <div className="row">
                        <div className="col-sm-12 listpadding">
                            <div className="conversation">
                                {!profile.length ? (
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src={logo}
                                            alt=""
                                            style={{ marginTop: "30%" }}
                                            width={150}
                                            height={150}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div
                                            className="row main-heading"
                                            onClick={() => {
                                                type == "GROUP"
                                                    ? groupDetail(profile[0].conversation_id)
                                                    : setShowGroupInfo(false);
                                            }}
                                        >
                                            <div className="col-sm-12 d-flex">
                                                <img
                                                    className="heading-avatar-icon"
                                                    src={
                                                        profile[0].image == ""
                                                            ? DummyLogo
                                                            : imgData + profile[0].image
                                                    }
                                                />
                                                <p className="heading-name-meta ms-1 mt-1">
                                                    {profile[0].name}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className="row message"
                                            id="conversation"
                                            style={{ width: "100%" }}
                                            ref={messageEl}
                                            onScroll={handleScroll}
                                        >
                                            <div className="col-sm-12 mt-5 d-flex flex-column width-100">
                                                {receiveMessages?.messagesData?.allMessages?.map(
                                                    (item, i) => (
                                                        <div key={i}>
                                                            <span className="d-flex justify-content-center">
                                                                {matchTime(
                                                                    item.msgTime,
                                                                    receiveMessages?.messagesData?.allMessages[
                                                                        i - 1
                                                                    ]?.msgTime
                                                                )}
                                                            </span>
                                                            <div className="d-flex d-flex flex-column">
                                                                <div
                                                                    className={`${item.sender_id !== userData?.userId
                                                                        ? "d-flex  justify-content-between"
                                                                        : "d-flex justify-content-end"
                                                                        } msg_send`}
                                                                >
                                                                    <div className="d-flex-justify-content-start">
                                                                        {type == "GROUP" ? (
                                                                            <span className="chat_Time me-1">
                                                                                {item.userData?.name}
                                                                            </span>
                                                                        ) : null}
                                                                        <span className="chat_Time">
                                                                            {moment(item.msgTime).format("LT")}
                                                                        </span>
                                                                        {item.isEdited ? (
                                                                            <span className="chat_Time ms-1">
                                                                                Edited
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                    <div>
                                                                        {item?.sender_id == userData?.userId ? (
                                                                            !item.isDeleted ? (
                                                                                <span className="overlay_chat_send">
                                                                                    <MoreHorizIcon
                                                                                        className="cursor float-end"
                                                                                        id="chatLink"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false"
                                                                                    />
                                                                                    <ul
                                                                                        className="dropdown-menu position-absolute"
                                                                                        style={{ zIndex: "2" }}
                                                                                        aria-labelledby="chatLink"
                                                                                    >
                                                                                        {item.document.length ? null : (
                                                                                            <a
                                                                                                className="dropdown-item cursor"
                                                                                                onClick={() => {
                                                                                                    setMsgId(item.message_id);
                                                                                                    setEditMsg(item.message);
                                                                                                }}
                                                                                            >
                                                                                                Edit
                                                                                            </a>
                                                                                        )}
                                                                                        <a
                                                                                            className="dropdown-item cursor"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#active"
                                                                                            onClick={() => {
                                                                                                handleDeleteMessage(
                                                                                                    item?.message_id
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Delete
                                                                                        </a>
                                                                                    </ul>
                                                                                </span>
                                                                            ) : null
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    key={i}
                                                                    className={`${item?.sender_id !== userData?.userId
                                                                        ? "receive_message_box"
                                                                        : "send_message_box"
                                                                        } d-flex w-100`}
                                                                >
                                                                    {msgId == item.message_id ? (
                                                                        <div>
                                                                            <div className="d-flex col-sm-6 float-end">
                                                                                {item.sender_id == userData?.userId ? (
                                                                                    <div className="w-100">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name=""
                                                                                            id=""
                                                                                            value={editMsg}
                                                                                            onChange={(e) =>
                                                                                                setEditMsg(e.target.value)
                                                                                            }
                                                                                        />
                                                                                        <div className="me-2 mt-2">
                                                                                            <SentimentSatisfiedAltIcon
                                                                                                className="cursor mb-2"
                                                                                                onClick={() =>
                                                                                                    setShowEditPicker(
                                                                                                        (val) => !val
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <i
                                                                                                className="fa-solid fa-check float-end cursor ms-3"
                                                                                                onClick={() =>
                                                                                                    handleEditMessage()
                                                                                                }
                                                                                            ></i>
                                                                                            <i
                                                                                                className="fa-solid fa-xmark float-end cursor"
                                                                                                onClick={() => setMsgId("")}
                                                                                            ></i>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                            {/* <div>
                                                                                        {showEditPicker && (
                                                                                            <Picker pickerStyle={{ width: "100%" }} onEmojiClick={onEmojiClickEdit} />
                                                                                        )}
                                                                                    </div> */}
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <div
                                                                                className={`${item.sender_id !== userData?.userId
                                                                                    ? "receive_message"
                                                                                    : "send_message"
                                                                                    } `}
                                                                            >
                                                                                {item.document.length ? (
                                                                                    <div
                                                                                        className="chat-doc-box"
                                                                                        onClick={() =>
                                                                                            item.isDeleted
                                                                                                ? null
                                                                                                : pdfDownload(item.document)
                                                                                        }
                                                                                    >
                                                                                        {item.document}
                                                                                        <i className="fa-solid fa-download ms-2"></i>
                                                                                    </div>
                                                                                ) : null}
                                                                                {item.message}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {/* <div>
                                                    {showPicker && (
                                                        <Picker pickerStyle={{ width: "100%" }} onEmojiClick={onEmojiClick} onClickOutside={() => {
                                                            setShowPicker(false);
                                                        }} />
                                                    )}
                                                </div> */}
                                        </div>
                                        {uploadData ? (
                                            <div className="d-flex justify-content-center">
                                                <div className="document_box">
                                                    {uploadData}
                                                    <span className="cursor" onClick={deleteDocument}>
                                                        X
                                                    </span>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* send Message Start*/}
                                        <div className="row reply">
                                            {profile[0].isBlocked ? (
                                                <div className="d-flex justify-content-evenly">
                                                    <p
                                                        className="mt-1"
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Open Sans",
                                                        }}
                                                    >
                                                        You have blocked this user
                                                    </p>
                                                    <button
                                                        className="btn btn_Style btn-sm"
                                                        onClick={() => ConversationBlock(profile[0])}
                                                    >
                                                        Unblock
                                                    </button>
                                                </div>
                                            ) : type == "GROUP" ? (
                                                receiveMessages.isAdmin ? (
                                                    <>
                                                        <div className="col-sm-1 col-xs-1 reply-emojis">
                                                            <label htmlFor="inputTag" className="">
                                                                <input
                                                                    id="inputTag"
                                                                    type="file"
                                                                    style={{ display: "none" }}
                                                                    ref={fileInputRef}
                                                                    onChange={(e) => uploadPdf(e.target.files[0])}
                                                                />
                                                                <AttachFileIcon className="mt-1 heading-color cursor" />
                                                            </label>
                                                            <SentimentSatisfiedAltIcon
                                                                className="mt-1  heading-color cursor"
                                                                onClick={() => {
                                                                    setShowPicker((val) => !val);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-10 col-xs-10 reply-main mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={sendMessage}
                                                                onKeyDown={(e) => enterSendMessage(e)}
                                                                onChange={(e) => setSendMessage(e.target.value)}
                                                                placeholder="Type a message"
                                                            />
                                                        </div>
                                                        <div className="col-sm-1 col-xs-1 reply-recording">
                                                            <div
                                                                style={{
                                                                    width: "35px",
                                                                    height: "33px",
                                                                    background: "#f47d3a",
                                                                    borderRadius: "50%",
                                                                }}
                                                            >
                                                                <SendIcon
                                                                    className="mt-1 ms-2 text-light cursor"
                                                                    onClick={() =>
                                                                        sendMessage.length || uploadData.length
                                                                            ? handleSendMessage()
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : !receiveMessages.onlyAdminCanMessage ? (
                                                    <>
                                                        <div className="col-sm-1 col-xs-1 reply-emojis">
                                                            <label htmlFor="inputTag" className="">
                                                                <input
                                                                    id="inputTag"
                                                                    type="file"
                                                                    style={{ display: "none" }}
                                                                    ref={fileInputRef}
                                                                    onChange={(e) => uploadPdf(e.target.files[0])}
                                                                />
                                                                <AttachFileIcon className="mt-1 heading-color cursor" />
                                                            </label>
                                                            <SentimentSatisfiedAltIcon
                                                                className="mt-1  heading-color cursor"
                                                                onClick={() => {
                                                                    setShowPicker((val) => !val);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-10 col-xs-10 reply-main mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={sendMessage}
                                                                onKeyDown={(e) => enterSendMessage(e)}
                                                                onChange={(e) => setSendMessage(e.target.value)}
                                                                placeholder="Type a message"
                                                            />
                                                        </div>
                                                        <div className="col-sm-1 col-xs-1 reply-recording">
                                                            <div
                                                                style={{
                                                                    width: "35px",
                                                                    height: "33px",
                                                                    background: "#f47d3a",
                                                                    borderRadius: "50%",
                                                                }}
                                                            >
                                                                <SendIcon
                                                                    className="mt-1 ms-2 text-light cursor"
                                                                    onClick={() =>
                                                                        sendMessage.length || uploadData.length
                                                                            ? handleSendMessage()
                                                                            : null
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <p
                                                        className="d-flex justify-content-center  mt-1"
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Open Sans",
                                                        }}
                                                    >
                                                        Only admins can message
                                                    </p>
                                                )
                                            ) : (
                                                <>
                                                    <div className="col-sm-1 col-xs-1 reply-emojis float-end">
                                                        <label htmlFor="inputTag" className="">
                                                            <input
                                                                id="inputTag"
                                                                type="file"
                                                                style={{ display: "none" }}
                                                                ref={fileInputRef}
                                                                onChange={(e) => {
                                                                    uploadPdf(e.target.files[0]);
                                                                }}
                                                            />
                                                            <AttachFileIcon className="mt-1 heading-color cursor" />
                                                        </label>
                                                        <SentimentSatisfiedAltIcon
                                                            className="heading-color cursor"
                                                            onClick={() => {
                                                                setShowPicker((val) => !val);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-10 col-xs-10 reply-main mb-2">
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            value={sendMessage}
                                                            onKeyDown={(e) => enterSendMessage(e)}
                                                            onChange={(e) => setSendMessage(e.target.value)}
                                                            placeholder="Type a message"
                                                        />
                                                    </div>
                                                    <div className="col-sm-1 col-xs-1 reply-recording">
                                                        <div
                                                            style={{
                                                                width: "35px",
                                                                height: "33px",
                                                                background: "#f47d3a",
                                                                borderRadius: "50%",
                                                            }}
                                                        >
                                                            <SendIcon
                                                                className="mt-1 ms-2 text-light cursor"
                                                                onClick={() =>
                                                                    sendMessage.length || uploadData.length
                                                                        ? handleSendMessage()
                                                                        : null
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {/* Emoji  */}
                                        <Modal
                                            centered
                                            show={showPicker}
                                            size="sm"
                                            onHide={() => setShowPicker(false)}
                                        >
                                            <div className="d-flex justify-content-end me-3 mt-3">
                                                <i
                                                    className="fa-solid fa-x cursor"
                                                    onClick={() => setShowPicker(false)}
                                                ></i>
                                            </div>
                                            <Picker
                                                onEmojiClick={onEmojiClick}
                                                onClickOutside={() => {
                                                    setShowPicker(false);
                                                }}
                                            />
                                        </Modal>
                                        {/* Emoji  */}

                                        {/* Emoji  Edit*/}
                                        <Modal
                                            centered
                                            show={showEditPicker}
                                            size="sm"
                                            onHide={() => setShowEditPicker(false)}
                                        >
                                            <div className="d-flex justify-content-end me-3 mt-3">
                                                <i
                                                    className="fa-solid fa-x cursor"
                                                    onClick={() => setShowEditPicker(false)}
                                                ></i>
                                            </div>
                                            <Picker
                                                pickerStyle={{ width: "100%" }}
                                                onEmojiClick={onEmojiClickEdit}
                                            />
                                        </Modal>
                                        {/* Emoji Edit */}

                                        {/* send Message End*/}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ChatMobile
