import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Download from '../../Assets/images/download.png'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, Button } from '@mui/material';
import NumberInput from '../../Components/PhoneInput/phoneInput';

const AddClient = ({ showClient, setShowClient, addClient, imageData, uploadImage, ValidationMessage, date, setImageData, imgData, DummyLogo, imageDeleteUpload, timeZone }) => {
    return (
        <>
        <Backdrop  open={showClient}>     
            <Offcanvas show={showClient} onHide={() => setShowClient(false)} placement="end" backdrop={false} >
                <div style={{ padding: '10px' }}>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowClient(false); addClient.resetForm(); setImageData('') }}></button>
                    <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                        Add Client
                    </h1>
                </div>
                <div className="offcanvas-body">
                    {imageData === '' ?
                        <div className="col-6 mx-auto">
                            <div className="bodrerer mb-4 ms-5">
                                <label htmlFor="inputTag">
                                    <img src={Download} className='cursor' alt='img'/>
                                    <input id="inputTag" type="file" style={{ display: 'none' }} accept="image/*" onChange={(e) => uploadImage(e.target.files[0], 'PROFILE', setImageData,)} />
                                    <span id="imageName"></span>
                                    <p>upload image here</p>
                                </label>
                            </div>
                        </div>
                        :
                        <div className="text-center mb-4">
                            <img src={imageData === '' ? DummyLogo : imgData + imageData} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                            <svg id="imageDelete" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" class="bi bi-camera" viewBox="0 0 16 16">
                                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                            </svg>
                            <ul className="dropdown-menu" aria-labelledby="imageDelete">
                                <input
                                    accept="image/*"
                                    id="icon-button-file"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => uploadImage(e.target.files[0], 'PROFILE', setImageData,)}
                                />
                                <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                                <br />
                                {imageData === '' ? null : (
                                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(imageData, 'PROFILE', setImageData)} startIcon={<DeleteIcon />}>Delete</Button>
                                )}
                            </ul>
                        </div>
                    }
                    <form className="cus-form" onSubmit={addClient.handleSubmit}>
                        <div className="mb-2">
                            <label className="form-label">Name * </label>
                            <input type="text" className="form-control" pattern="[A-Za-z ]*" title="Please enter only alphabetic characters" name="name" value={addClient.values.name} onChange={addClient.handleChange} maxLength={30} />
                            <ValidationMessage formName={addClient} name={'name'} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Email Address {timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? null : '*'}</label>
                            <input type="email" className="form-control" placeholder="" name="email" value={addClient.values.email} onChange={addClient.handleChange} />
                            <ValidationMessage formName={addClient} name={'email'} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">DOB</label>
                            <input type="date" className="form-control" placeholder="" name="DOB" value={addClient.values.DOB} onChange={addClient.handleChange} max={date} />
                            {/* <ValidationMessage formName={addClient} name={'DOB'} /> */}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Mobile Number *</label>
                            {/* <input type="number" className="form-control" placeholder="" name="mobileNo" value={addClient.values.mobileNo} onChange={addClient.handleChange}
                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={10}
                                onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()} /> */}
                            <NumberInput
                                width={false}
                                Require={false}
                                Name="mobileNo"
                                HandleChange={addClient}
                                Value={addClient.values.mobileNo}
                            />
                            <ValidationMessage formName={addClient} name={'mobileNo'} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Gender * </label>
                            <select className="form-select form-control" aria-label="Default select example" name="gender" value={addClient.values.gender} onChange={addClient.handleChange}>
                                <option value='' hidden>Select Gender</option>
                                <option value="MALE">Male</option>
                                <option value="FEMALE">Female</option>
                                <option value="OTHER">Other</option>
                            </select>
                            <ValidationMessage formName={addClient} name={'gender'} />
                        </div>
                        <div className="d-grid  col-6 mx-auto mb-3">
                            <button type="submit" className="btn colorbtun popupbtn px" aria-label="Close">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
            </Backdrop>
        </>
    )
}

export default AddClient