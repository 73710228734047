import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { logOutApi, createWaitingListApi, waitinglistListingApi, removeWaitingListApi, bookApptWaitingListApi, clientListApi, staffListApi, multipleServicesListApi, branchListApi, serviceListApi } from '../../../Context/api'
import { WaitingListValidation } from '../../../Context/validation'
import axios from 'axios'
import { statusCodes, commonMessage, roleNames } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App'

const UseWaitingList = () => {
    const { userInfo } = useContext(Notifications)
    const [modalShow, setModalShow] = useState(false)
    const [modalBook, setModalBook] = useState(false)
    const [branchList, setBranchList] = useState([])
    const [teamList, setTeamList] = useState([])
    const [clientList, setClientList] = useState([])
    const [serviceCategory, setServiceCategory] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [waitListData, setWaitingListData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [waitingId, setWaitingId] = useState('')
    const [filter, setFilter] = useState({
        staff: '',
        client: '',
        date: '',
    })
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const [duration, setDuration] = useState('')
    const [clientPerPage, setClientPerPage] = useState(1)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let isActive = true
    const date = moment(new Date()).format('YYYY-MM-DD')
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getWaitingList()
            getStaffList()
            getProviderClientList()

        }
    }, [page])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const getProviderClientList = () => {
        const data = {
            userId: userData?.userId,
            isActive: true,
            isAccepted: 'accepted'
        }

        if (userData?.roleTitle == roleNames.STAFF) {
            delete data.userId
            data['providerId'] = userData?.createdbyId
        }
        axios.post(clientListApi(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setClientList(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const getStaffList = () => {
        axios.post(staffListApi(), { userId: userData?.userId, isActive: isActive, isAccepted: 'accepted', branchId: userData?.locationId, count: 100, }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setTeamList(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const multipleServicesList = () => {
        axios.post(multipleServicesListApi(), { userId: userData?.userId, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setServiceCategory(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const getBranchList = () => {
        axios.post(branchListApi(), { userId: userData?.userId, isDeleted: false, isActive: true, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setBranchList(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const createWaitingList = useFormik({
        initialValues: {
            date: '',
            startTime: '',
            endTime: '',
            client: '',
            staff: '',
            branch: '',
            service: '',
            serviceCategory: '',
            price: '',
            visitType: '',
        },
        validationSchema: WaitingListValidation,
        onSubmit: (values) => {
            if (values.startTime > values.endTime) return toast.warn('Start Time can not be greater than End Time')
            const data = {
                providerId: userData?.userId,
                createdby_id: userData?.userId,
                clientId: values.client.value,
                staffId: values.staff,
                branchId: values.branch,
                serviceId: values.service,
                timezone: timeZone,
                visitType: values.visitType,
                startDateTime: moment(values.date + '' + moment(values.startTime, 'HH:mm:ss').format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
                endDateTime: moment(values.date + '' + moment(values.endTime, 'HH:mm:ss').format('HH:mm'), 'YYYY-MM-DD HH:mm').format(),
            }
            if (values.client.length === 0) return toast.warn('Please select client')
            // console.log(data, 'data')
            // return
            setSpinner(true)
            axios.post(createWaitingListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setModalShow(false)
                    getWaitingList()
                    createWaitingList.resetForm()
                    setSpinner(false)
                    setClientPerPage(1)

                } else {
                    toast.error(resp.data.message)
                    setSpinner(false)

                }
            }).catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
        },
    })
    const getWaitingList = async () => {
        const data = {
            userId: userData?.userId,
            isActive: isActive,
            page: page,
            count: PER_PAGE,
            searchStaff: filter.staff,
            searchClient: filter.client,
            listDate: filter.date,
            branchId: userData?.locationId
        }
        setSpinner(true)
        axios.post(waitinglistListingApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setWaitingListData(resp.data.data)
                    setSpinner(false)
                    setCount(resp.data.totalCount)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setPage(1)
                    setSpinner(false)
                    setWaitingListData([])
                }
            })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const removeWaitingList = () => {
        const data = {
            waitingListId: waitingId._id,
            loggedInUserId: userData?.userId,
            status: waitingId.isActive,
        }
        setSpinner(true)
        axios.post(removeWaitingListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false)
                getWaitingList()
                setShow(false)
                toast.success(resp.data.message)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)

            }
        }).catch(() => { })
    }
    const bookAppointment = () => {
        const data = {
            waitingListId: waitingId._id,
            loggedInUserId: userData?.userId,
            status: waitingId.isActive,
        }
        setSpinner(true)
        axios.post(bookApptWaitingListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setSpinner(false)
                getWaitingList()
                setModalBook(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)

            }
        }).catch(() => { })
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFilter((preProps) => ({
            ...preProps,
            [name]: value
        }))
    }
    const servicesList = (e) => {
        const data = {
            categoryId: e,
            isActive: true,
            count: 100
        }
        axios.post(serviceListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setServiceList(resp.data.data)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const priceFun = (e) => {
        let selectService = serviceList.find((service) => service._id === e.target.value)
        createWaitingList.setFieldValue('price', selectService.fixedAmount)
        setDuration(selectService.duration)
        createWaitingList.setFieldValue('endTime', null)
        createWaitingList.setFieldValue('startTime', null)
    }
    const addTime = (e) => {
        let time = moment(e).add(duration, 'm').toDate();
        createWaitingList.setFieldValue('endTime', moment(time))
    }
    const loadClientOptions = async () => {
        const data = {
            userId: userData?.userId,
            isActive: true,
            isAccepted: 'accepted',
            page: clientPerPage,
            count: 10
        }
        if (userData?.roleTitle == roleNames.STAFF) {
            delete data.userId
            data['providerId'] = userData?.createdbyId
        }


        const response = await axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }, setClientPerPage(clientPerPage + 1))

        return {
            options: response.data.data?.map(item => ({ value: item._id, label: item.name })),
            hasMore: response.data.data.length >= 1
        };

    }
    return {

        getProviderClientList,
        getStaffList,
        multipleServicesList,
        getWaitingList,
        getBranchList,
        handleInputChange,
        bookAppointment,
        removeWaitingList,
        priceFun,
        servicesList,
        addTime,
        loadClientOptions,
        setModalShow,
        setBranchList,
        setTeamList,
        setClientList,
        setServiceCategory,
        setServiceList,
        setWaitingListData,
        setSpinner,
        setWaitingId,
        setFilter,
        setCount,
        setPage,
        setDuration,
        setClientPerPage,
        navigate,
        setModalBook,
        show, setShow,
        modalBook,
        clientPerPage,
        duration,
        page,
        count,
        filter,
        waitingId,
        spinner,
        waitListData,
        serviceList,
        serviceCategory,
        clientList,
        teamList,
        branchList,
        createWaitingList,
        modalShow,
        PER_PAGE,
        userData,
        timeZone,
        isActive,
        date,
        userInfo

    }
}

export default UseWaitingList