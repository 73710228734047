import { useState, useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { otpValidation } from '../../../Context/validation'
import { enterOtpApi, resendOTPApi } from '../../../Context/api'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { statusCodes, commonMessage } from '../../../Constants/generalConfig'


const UseEnterOtp = () => {
    const navigate = useNavigate()
    const mobileNumber = JSON.parse(localStorage.getItem('Mobile'))
    const email = JSON.parse(localStorage.getItem('email'))
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [timer, setTimer] = useState(60)
    const timeOutCallback = useCallback(() => {
        setTimer((currTimer) => currTimer - 1)
    }, [])

    useEffect(() => {
        window.onpopstate = () => {
            navigate("/");
        }
        timer > 0 && setTimeout(timeOutCallback, 1000)
    }, [timer, timeOutCallback])

    const resetTimer = () => {
        setTimer(60)
    }
    const resendOtp = () => {
        const data = timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? { mobileNo: mobileNumber, timeZone: timeZone, type: 'SIGNUP' } : { email: email, timeZone: timeZone, type: 'SIGNUP' }
        axios
            .post(resendOTPApi(), { ...data })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok || resp.data.code === statusCodes.token) {
                    toast.success(resp.data.message)
                } else if (resp.data.code === 410) {
                    toast.success(resp.data.message)
                } else {
                    toast.error(resp.data.message)
                }
            })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }
    const otpForm = useFormik({
        initialValues: {
            number: '',
        },
        validationSchema: otpValidation,
        onSubmit: (values) => {
            const data = timeZone == 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? { otp: +values.number, mobileNo: mobileNumber, timeZone: 'Asia/Kolkata' } : { otp: +values.number, email: email, timeZone: timeZone }
            otpForm.resetForm()
            axios
                .post(enterOtpApi(), { ...data })
                .then((resp) => {
                    if (resp.data.code === statusCodes.ok || resp.data.code === statusCodes.token) {
                        navigate(`/enterOtp/createPin/${resp.data.data.token}`)
                        toast.success(resp.data.message)
                        localStorage.setItem('Token', JSON.stringify(resp.data.data.token))
                    } else {
                        toast.error(resp.data.message)
                    }
                })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    return {
        navigate,
        resetTimer,
        resendOtp,
        setTimer,
        mobileNumber,
        otpForm,
        email,
        timeZone,
        timer,
    }
}

export default UseEnterOtp