import React from 'react'

const ValidationMessage = (props) => {
    return (
        <>
            {props.formName.touched[props.name] && props.formName.errors[props.name] ?
                <div className="text-start w-100" style={{ color: 'red', fontSize: '12px', width: '200%' }}>{props.formName.errors[props.name]}</div>
                : null}
        </>
    )
}

export default ValidationMessage