import React from 'react'
import Download from '../../Assets/images/download.png'
import { NavLink } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Spinner from '../../Components/spinner/spinner'
import ServiceImg from '../../Assets/images/services-icon.png';
import Paginations from '../../Components/Pagination/pagination'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord';
import UseServiceCategory from './Hooks/useServiceCategory'
import UseImage from '../../Hooks/useImage'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'react-bootstrap'
import { Backdrop, Button } from '@mui/material';
import { intialRole } from '../../Utils/Utils'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AsyncPaginate } from 'react-select-async-paginate'


const ServiceCategory = () => {
  const {
    servicesList,
    listCombo,
    serviceView,
    enterSearch,
    changeServiceStatus,
    navigate,
    changeStatusCombo,
    setImg,
    setShowService,
    setShow,
    setPage,
    setService,
    setIsActive,
    setArchive,
    setTab,
    setComboShow,
    setAdd,
    setAddShow,
    setSelectService,
    handleChange,
    setSessions,
    increaseArrayLength,
    removeItem,
    handleAddServices,
    increaseServiceCount,
    decreaseServiceCount,
    setCurrentSessionIndex,
    comboView,
    setCount,
    setAssign,
    loadClientOptions,
    setClientPerPage,
    purchaseComboPackages,
    setShowAddService,
    allServiceData, setAllServiceData,
    checkked,
    allServicesList,
    serviceDownload,
    uploadExcel,
    showAddService,
    assign,
    comboListData,
    sessions,
    selectService,
    add,
    addShow,
    serviceData,
    comboImgPath,
    addCombo,
    comboShow,
    tab,
    archive,
    addService,
    editService,
    serviceList,
    img,
    showService,
    show,
    spinner,
    count,
    page,
    PER_PAGE,
    service,
    imgPath, serviceId, fileInputRef,
    userInfo } = UseServiceCategory()
  const { imageDeleteUpload, imageDelete, uploadImage } = UseImage()

  const addServiceCheck = (event, serviceId, i, serviceIndex) => {
    let temp = allServiceData;
    let filterInputs = selectService;
    if (event.target.checked) {
      setSelectService((pre) => [...pre, { name: serviceId.name, _id: serviceId._id }]);
      temp[i].serviceData[serviceIndex]['isChecked'] = true;
    } else {
      filterInputs = selectService.filter((id) => id._id !== serviceId._id);
      temp[i].serviceData[serviceIndex]['isChecked'] = false;
      setSelectService(filterInputs);

      const newData = sessions.map(session => {
        const uniqueServices = session.services.filter(service => service._id !== serviceId._id);
        return { ...session, services: uniqueServices };
      });
      setSessions(newData);
    }
    setAllServiceData([...temp]);
  };

  // const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

  return (
    <>
      <ToastContainer />
      <section className='position-relative'>
        <div className="m-t-3 cus-padd cusheight ">
          {spinner ? <Spinner /> : null}
          <header>
            <div></div>
            <nav className="mb-3">
              <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
                <button className={` ${tab === 1 ? "activeNav" : "unActiveNav"} border-0`} onClick={() => { setTab(1); setPage(1); setCount(1); setService('') }} type="button">
                  Services Category
                </button>
                <button onClick={() => { setTab(2); setPage(1); setCount(1); setService('') }} className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`} type="button">
                  Combo & Packages
                </button>
              </div>
            </nav>

            <div className="d-flex justify-content-end">
              <ul className="navicon">
                <div className="search" style={{ width: '160px' }}>
                  <input className='form-control' type="search" placeholder='Search by name' style={{ height: '38px' }}
                    onKeyDown={(e) => enterSearch(e)}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        if (tab == 2) {
                          listCombo()
                        }
                        else {
                          servicesList()
                        }
                      }
                    }} />
                </div>
                {tab == 1 &&
                  <div>
                    <NavLink to='serviceDuration'><button className='btn  ms-2 text-white  btn_Style'>Add Duration</button></NavLink>
                    <button className='btn  ms-2 text-white  btn_Style' onClick={() => serviceDownload()}>Download</button>

                    {/* <button className='btn  ms-2 text-white  btn_Style'>Export
                    <input type="file" accept=".xlsx, .xls" onChange={(e)=>uploadExcel(e.target.files[0])} style={{display:'none'}}/>
                    </button> */}
                    <button className='btn ms-2 text-white btn_Style' onClick={handleButtonClick}>
                      Import
                      <input
                        type="file"
                        accept=".xlsx, .xls"
                        ref={fileInputRef}
                        onChange={(e) => uploadExcel(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </button>
                  </div>

                }
                <li>
                  <div className="dropdown me-2" style={{ marginTop: '10px' }}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Filter</Tooltip>}>
                      <i className="fa-solid fa-sliders " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></i>
                    </OverlayTrigger>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <h6 className="dropdown-item cursor" onClick={() => { setIsActive(true); setPage(1) }}>
                          Un-Archive
                        </h6>
                      </li>
                      <li>
                        <h6 className="dropdown-item cursor" onClick={() => { setIsActive(false); setPage(1) }}>
                          Archive
                        </h6>
                      </li>
                    </ul>

                  </div>
                </li>
              </ul>
            </div>
          </header>

          {
            tab == 1 && (
              intialRole({ permission: "Service Category", userData: userInfo, section: 'view', }) ?
                !serviceList?.length ? <NoRecord /> :
                  <div className="row">
                    {serviceList?.map((item) => (
                      <div className="col-sm-12 col-md-6 col-lg-2" key={item._id}>
                        <div className="box card text-center">
                          {
                            item.image === ''||item.image === null ?
                              <div style={{ height: '176px', marginTop: '5px' }} onClick={() => { navigate(`serviceList/${item._id}`); localStorage.setItem('service', item.name) }} >
                                <img className="mt-5 ms-4 me-4 img-fluid" src={ServiceImg} height={50} width={50} alt="" onClick={() => { navigate(`serviceList/${item._id}`); localStorage.setItem('service', item.name) }} />
                              </div>
                              :

                              <img className="mx-auto img-fluid" src={imgPath + item.image} height={180} width={180} alt="" onClick={() => { navigate(`serviceList/${item._id}`); localStorage.setItem('service', item.name) }} />

                          }
                          <h3 className='mt-3 mb-0'>{item.name}</h3>
                          <div className="hoverover">
                            <div className="overlay">
                              <button
                                className="btn"
                                onClick={() => {
                                  serviceView(item._id)
                                  setShowService(true)
                                }}
                                disabled={intialRole({ permission: "Service Category", userData: userInfo, section: 'edit', }) ? false : true}
                                style={{ border: 'none' }}
                              >
                                Edit
                              </button>
                              <NavLink to={`serviceList/${item._id}`} onClick={() => localStorage.setItem('service', item.name)}>Services</NavLink>
                              {
                                item.isActive ?
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Archive</Tooltip>}>
                                    <svg className='float-end mt-2 me-2' onClick={() => { setService(item); setArchive(true) }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsvgjs="http://svgjs.com/svgjs" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                                      <g>
                                        <path xmlns="http://www.w3.org/2000/svg" d="m20.54 5.23-1.39-1.68c-.27-.34-.68-.55-1.15-.55h-12c-.47 0-.88.21-1.16.55l-1.38 1.68c-.29.34-.46.79-.46 1.27v12.5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-12.5c0-.48-.17-.93-.46-1.27zm-14.3-.23h11.52l.81.97h-13.13zm-1.24 14v-11h14v11zm8.45-9h-2.9v3h-2.55l4 4 4-4h-2.55z"
                                          fill="#000000"
                                          data-original="#000000"
                                        />
                                      </g>
                                    </svg>
                                  </OverlayTrigger>
                                  :
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Un-Archive</Tooltip>}>
                                    <svg className='float-end ' style={{ transform: 'scale(0.5)', marginTop: '-5px' }} onClick={() => { setService(item); setArchive(true) }} xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V12.85q0-.75.15-1.275.15-.525.55-.975l2.8-3.8q.4-.45.925-.625T11.65 6h24.7q.7 0 1.2.175t.9.625l2.85 3.8q.4.45.55.975.15.525.15 1.275V39q0 1.2-.9 2.1-.9.9-2.1.9Zm.85-30.7H38.1L36.3 9H11.65Zm-.85 3V39h30V14.3Zm13.5 20.2h3V24.45l4.3 4.3 2-2-7.8-7.8-7.8 7.8 2 2 4.3-4.3ZM9 39h30H9Z" /></svg>
                                  </OverlayTrigger>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className='w-100 d-flex justify-content-center'>
                      {count > 8 &&
                        <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                      }
                    </div>
                  </div>
                : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
            )
          }

          {
            tab == 1 && (
              intialRole({ permission: "Service Category", userData: userInfo, section: 'add', }) ?
                <div className="d-flex justify-content-end">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Service Category</Tooltip>}>
                    <p className="btn addbtn2">
                      <i className="fa-solid fa-plus" onClick={() => { setShow(true); }}></i>
                    </p>
                  </OverlayTrigger>
                </div>
                : null
            )
          }
          {
            tab == 2 && (
              !comboListData?.length ? <NoRecord /> :
                <div className="row">
                  {comboListData?.map((item) => (
                    <div className="col-sm-12 col-md-6 col-lg-2" key={item._id}>
                      <div className="box card text-center">
                        {
                          item.image === '' ?
                            <div style={{ height: '176px', marginTop: '5px' }}>
                              <img className="mt-5 ms-4 me-4 img-fluid cursor" src={ServiceImg} height={50} width={50} alt="" onClick={() => { comboView(item._id); setComboShow({ open: true, data: item }); allServicesList() }} />
                            </div>
                            :

                            <img className="mx-auto img-fluid cursor" src={comboImgPath + item.image} height={180} width={180} alt="" onClick={() => { comboView(item._id); setComboShow({ open: true, data: item }); allServicesList() }} />

                        }
                        <h3 className='mt-3 mb-0'>{item.name}</h3>
                        <div className="hoverover">
                          <div className="overlay">
                            <button className="btn" onClick={() => { comboView(item._id); setComboShow({ open: true, data: item }); allServicesList() }}
                              // disabled={intialRole({ permission: "Service Category", userData: userInfo, section: 'edit', }) ? false : true}
                              style={{ border: 'none' }}
                            >
                              Edit
                            </button>
                            <a className='cursor' onClick={() => { setAssign((pre) => ({ ...pre, open: true, data: item })) }}>Assign</a>

                            {
                              item.isActive ?
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Archive</Tooltip>}>
                                  <svg className='float-end mt-2 me-2 cursor' onClick={() => { setService(item); setArchive(true) }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsvgjs="http://svgjs.com/svgjs" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                                    <g>
                                      <path xmlns="http://www.w3.org/2000/svg" d="m20.54 5.23-1.39-1.68c-.27-.34-.68-.55-1.15-.55h-12c-.47 0-.88.21-1.16.55l-1.38 1.68c-.29.34-.46.79-.46 1.27v12.5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-12.5c0-.48-.17-.93-.46-1.27zm-14.3-.23h11.52l.81.97h-13.13zm-1.24 14v-11h14v11zm8.45-9h-2.9v3h-2.55l4 4 4-4h-2.55z"
                                        fill="#000000"
                                        data-original="#000000"
                                      />
                                    </g>
                                  </svg>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Un-Archive</Tooltip>}>
                                  <svg className='float-end cursor' style={{ transform: 'scale(0.5)', marginTop: '-5px' }} onClick={() => { setService(item); setArchive(true) }} xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V12.85q0-.75.15-1.275.15-.525.55-.975l2.8-3.8q.4-.45.925-.625T11.65 6h24.7q.7 0 1.2.175t.9.625l2.85 3.8q.4.45.55.975.15.525.15 1.275V39q0 1.2-.9 2.1-.9.9-2.1.9Zm.85-30.7H38.1L36.3 9H11.65Zm-.85 3V39h30V14.3Zm13.5 20.2h3V24.45l4.3 4.3 2-2-7.8-7.8-7.8 7.8 2 2 4.3-4.3ZM9 39h30H9Z" /></svg>
                                </OverlayTrigger>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className='w-100 d-flex justify-content-center mb-3 position-absolute bottom-0 start-50 translate-middle-x'>
                    {count > 8 &&
                      <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                    }
                  </div>
                </div>
            )
          }
          {
            tab == 2 ?
              <div className="d-flex justify-content-end">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Combo</Tooltip>}>
                  <p className="btn addbtn2">
                    <i className="fa-solid fa-plus" onClick={() => { setComboShow({ open: true }); allServicesList() }}></i>
                  </p>
                </OverlayTrigger>
              </div>
              : null
          }
        </div>
      </section>

      {/* Add Service Category Start */}
      <Backdrop open={show}>
        <Offcanvas show={show} onHide={() => setShow(false)} placement="end" backdrop={false}>
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShow(false); addService.resetForm(); setImg('') }}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Add Category
            </h1>
          </div>
          <div className="offcanvas-body">
            <form className="cus-form" onSubmit={addService.handleSubmit}>
              <div className="col-6 mx-auto">
                {img === '' ?
                  <div className="bodrerer mb-4 ms-5">
                    <label htmlFor="inputTag">
                      <img src={Download} style={{ cursor: 'pointer' }} />
                      <input id="inputTag" ref={fileInputRef} type="file" style={{ display: 'none' }} accept="image/*" onChange={(e) => uploadImage(e.target.files[0], 'SERVICES', setImg, fileInputRef)} />
                      <span id="imageName"></span>
                      <p>upload image here</p>
                    </label>
                  </div>
                  :
                  <div className="text-center mb-4">
                    <img src={imgPath + img} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                    <svg id="imageDelete" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-70px', marginLeft: '150px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                      <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                      <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                    </svg>
                    <ul className="dropdown-menu" aria-labelledby="imageDelete">
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => uploadImage(e.target.files[0], 'SERVICES', setImg, fileInputRef)}
                      />
                      <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                      <br />
                      {img === '' ? null : (
                        <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(img, 'SERVICES', setImg)} startIcon={<DeleteIcon />}>Delete</Button>
                      )}
                    </ul>
                  </div>
                }
              </div>

              <div className="mb-2">
                <label className="form-label">Name * </label>
                <input type="text" className="form-control" placeholder="" name="name" onChange={addService.handleChange} value={addService.values.name} maxLength={50} />
              </div>
              <ValidationMessage formName={addService} name={'name'} />
              <div className="d-grid  col-6 mx-auto mb-3">
                <button className="btn colorbtun popupbtn px" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
      </Backdrop>
      {/* Add Service Category End */}

      {/* Edit Service Category Start */}
      <Backdrop open={showService}>
        <Offcanvas show={showService} onHide={() => setShowService(false)} placement="end" backdrop={false}>
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowService(false); setImg('') }}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Edit Service
            </h1>
          </div>
          <div className="offcanvas-body">
            <form className="cus-form" onSubmit={editService.handleSubmit}>
              <div className="col-6 mx-auto">
                <img src={img === ''||img === null ? ServiceImg : imgPath + img} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '155px', marginLeft: '-58px', fill: 'rgb(76 68 68)' }} width="35" height="30" className="bi bi-camera" viewBox="0 0 16 16">
                  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </svg>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <input ref={fileInputRef}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => uploadImage(e.target.files[0], 'SERVICES', setImg, fileInputRef)}
                  />
                  <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                  <br />
                  {img === ''||img === null ? null : (
                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(serviceId, img, 'SERVICES', '', setImg)} startIcon={<DeleteIcon />}>Delete</Button>
                  )}
                </ul>
              </div>
              <div className="mb-2">
                <label className="form-label">Name * </label>
                <input type="text" className="form-control" placeholder="" name="name" onChange={editService.handleChange} value={editService.values.name} maxLength={50} />
              </div>
              <ValidationMessage formName={editService} name={'name'} />
              <div className="d-grid  col-6 mx-auto mb-3">
                <button className="btn colorbtun popupbtn px" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
      </Backdrop>
      {/* Edit Service Category End */}

      {/* Archive/Un-Archive Start */}
      <Modal centered show={archive} size='sm' onHide={() => setArchive(false)}>
        <div className="modal-content">
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            {/* <p>You want to {service.isActive === true ? 'Archive' : 'Un-Archive'} this service</p> */}
            <p>You want to {service.isActive === true ? 'Archive' : 'Un-Archive'} this {tab == 2 ? 'combo' : 'service'}</p>
            <button type="button" className="btn nobtn" onClick={() => tab == 2 ? changeStatusCombo() : changeServiceStatus()}>
              Yes
            </button>
            <button type="button" className="btn yesbtn" onClick={() => setArchive(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
      {/* Archive/Un-Archive End */}

      {/* Add Combo Start */}
      <Backdrop open={comboShow?.open}>
        <Offcanvas show={comboShow?.open} onHide={() => setComboShow({ open: false })} placement="end" backdrop={false}>
          {
            showAddService ?
              <>
                <div style={{ padding: '10px' }}>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setComboShow({ open: false }); setImg(''); addCombo.resetForm(); setAddShow({ open: false }); setSelectService([]) }}></button>
                  <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                    {comboShow?.data?._id ? 'Edit' : 'Add'} Combo
                  </h1>
                </div>
                <div className="offcanvas-body">
                  <form className="cus-form">
                    <div className="col-6 mx-auto">
                      {img === '' ?
                        <div className="bodrerer mb-4 ms-5">
                          <label htmlFor="inputTag">
                            <img src={Download} style={{ cursor: 'pointer' }} />
                            <input id="inputTag" ref={fileInputRef} type="file" style={{ display: 'none' }} accept="image/*" onChange={(e) => uploadImage(e.target.files[0], 'COMBO', setImg, fileInputRef)} />
                            <span id="imageName"></span>
                            <p>upload image here</p>
                          </label>
                        </div>
                        :
                        <div className="text-center mb-4">
                          <img src={comboImgPath + img} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                          <svg id="imageDelete" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-70px', marginLeft: '150px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                          </svg>
                          <ul className="dropdown-menu" aria-labelledby="imageDelete">
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => uploadImage(e.target.files[0], 'COMBO', setImg, fileInputRef)}
                            />
                            <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                            <br />
                            {img === '' ? null : (
                              <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(img, 'COMBO', setImg)} startIcon={<DeleteIcon />}>Delete</Button>
                            )}
                          </ul>
                        </div>
                      }
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Name * </label>
                      <input type="text" className="form-control" placeholder="" name="name" onChange={addCombo.handleChange} value={addCombo.values.name} maxLength={30} />
                      <ValidationMessage formName={addCombo} name={'name'} />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Duration * </label>
                      <input type="number" className="form-control" name="duration" placeholder='In Days' onChange={handleChange} value={addCombo.values.duration} maxLength={30} min={1} />
                      <ValidationMessage formName={addCombo} name={'duration'} />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Price * </label>
                      <input type="number" className="form-control" placeholder="" name="price" onChange={addCombo.handleChange} value={addCombo.values.price} maxLength={30} min={0} />
                      <ValidationMessage formName={addCombo} name={'price'} />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Description * </label>
                      <input type='text' className="form-control" name="description" onChange={addCombo.handleChange} value={addCombo.values.description} id="" cols="20" rows="3" />
                      <ValidationMessage formName={addCombo} name={'description'} />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Term & Condition * </label>
                      <textarea className="form-control" name="termConditions" onChange={addCombo.handleChange} value={addCombo.values.termConditions} id="" cols="20" rows="3"></textarea>
                      <ValidationMessage formName={addCombo} name={'termConditions'} />
                    </div>
                    {
                      !addShow?.open ?
                        <div className='d-flex justify-content-center mt-3'>
                          <button className='btn bgOrange text-light w-100 ' type="button" onClick={() => { setAddShow({ open: true, type: 'Service' }); setSessions([{ session_number: 1, services: [] }]) }}><AddIcon />Add Service</button>
                          <button className='btn bgOrange text-light ms-1 w-100' type="button" onClick={() => { setAddShow({ open: true }); setSessions([{ session_number: 1, services: [] }]) }}><AddIcon />Sessions</button>
                        </div>
                        : null
                    }
                    <div>
                      {addShow?.open && (
                        <>
                          {sessions?.map((session, index) => (
                            <div className='form-control mt-3' key={index}>
                              <div className='d-flex justify-content-between'>
                                <div>{addShow?.type === 'Service' ? 'Service' : `Session ${session?.session_number}`}</div>
                                <div>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                    <i className="fa-solid fa-trash-can text-danger cursor" onClick={() => removeItem(index)}></i>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div>
                                {session?.services?.map((service, serviceIndex) => (
                                  <div key={serviceIndex} className="d-flex justify-content-between align-items-center mt-1 px-2" style={{ background: '#df540036', borderRadius: '5px' }}>
                                    <div>{service.name || service?.service_name}</div>
                                    {
                                      addShow?.type === 'Service' ?
                                        <div className='d-flex session'>
                                          <div className='cursor' onClick={() => decreaseServiceCount(index, service._id || service?.service_id)}><RemoveIcon /></div>
                                          {service.count}
                                          <div className='cursor' onClick={() => increaseServiceCount(index, service?._id || service?.service_id)}><AddIcon /></div>
                                        </div>
                                        :
                                        <div className='d-flex session px-3'>
                                          {service.count}
                                        </div>
                                    }
                                  </div>
                                ))}
                              </div>
                              {
                                // session?.services?.length && addShow?.type != 'Service' ? null :
                                <button
                                  className='btn bgOrange text-light w-100 mt-3'
                                  type="button"
                                  onClick={() => {
                                    setCurrentSessionIndex(index);
                                    setAdd({ open: true, type: 'Service' });
                                    // setAddShow(false);
                                    setShowAddService(false)
                                    checkked(index)
                                  }}
                                >
                                  <AddIcon /> Add Service
                                </button>
                              }
                            </div>
                          ))}
                          {
                            addShow?.type == 'Service' ? null :
                              <button className='btn bgOrange2 w-100 mt-3 mb-2' type="button" onClick={increaseArrayLength}>
                                <AddIcon /> Add Session
                              </button>
                          }
                        </>
                      )}
                    </div>
                    <div className="d-grid  col-6 mx-auto mb-3 mt-2">
                      <button className="btn colorbtun popupbtn px" type="button" onClick={addCombo.handleSubmit}>
                        {comboShow?.data?._id ? 'Update' : 'Submit'}
                      </button>
                    </div>
                  </form>
                </div>
              </>
              :
              <div className="offcanvas-body ">
                <form className="cus-form">
                  <div className='row mb-5'>
                    <div>
                      <div className=' d-flex justify-content-start'>
                        <button type="button" className="btn-close mb-2" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowAddService(true) }}></button>
                      </div>
                      <div className=' p-3 ' style={{ background: '#fcf6f3' }}>
                        {
                          allServiceData?.map((item, index) =>
                            <>
                              <div className="form-check" key={index}>
                                {index !== 0 && <hr />}
                                <h6 className='fw-bold my-2 heading-color mt-2'>{item.name}</h6>
                                {
                                  item?.serviceData?.map((service, serviceIndex) =>
                                    <>
                                      <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={service?.isChecked ? service?.isChecked : false} name="service" onChange={(e) => addServiceCheck(e, service, index, serviceIndex)} />
                                      <label className="form-check-label" htmlFor="flexCheckDefault">
                                        {service?.name}
                                      </label>
                                      <br />
                                    </>
                                  )
                                }
                              </div>
                            </>
                          )
                        }
                      </div>
                    </div>
                    <div className="d-grid  col-6 mx-auto mb-3 mt-2">
                      <button className="btn colorbtun popupbtn px" type="button" onClick={() => { handleAddServices(); }}>
                        Add Service
                      </button>
                    </div>
                  </div>
                </form>
              </div>
          }
        </Offcanvas>
      </Backdrop>
      {/* Add Combo End */}

      {/* Assign Combo  */}
      <Modal centered show={assign?.open}>
        <div className="modal-body">
          <h6 className='text-center'>Assign Combo & Packages</h6>
          <div className="mb-3">
            <label className="form-label">Client Name *</label>
            <AsyncPaginate
              loadOptions={loadClientOptions}
              onChange={(e) => { setAssign((pre) => ({ ...pre, client: e.value })) }}
            />
          </div>
          <div className="tab-pane fade show active d-flex mt-4" id="datepill" role="tabpanel" aria-labelledby="daterange" tabIndex="0">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                value='PAYNOW'
                onChange={(e) => setAssign((pre) => ({ ...pre, selectedOption: e.target.value }))}
                checked={assign?.selectedOption === 'PAYNOW'}
                disabled
              />
              <label className="form-check-label">
                Pay Now
              </label>
            </div>
            <div className="form-check ms-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                value='PAYLATER'
                onChange={(e) => setAssign((pre) => ({ ...pre, selectedOption: e.target.value }))}
                checked={assign?.selectedOption === 'PAYLATER'}
              />
              <label className="form-check-label">
                Paylater
              </label>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-5 mb-2'>
            <button type="submit" className="btn nobtn" onClick={() => { setAssign({ open: false, selectedOption: '', data: '' }); setClientPerPage(1); }}>
              Cancel
            </button>
            <button type="button" className="btn yesbtn ms-2" onClick={purchaseComboPackages}>
              Submit
            </button>
          </div>
        </div>
      </Modal>

    </>
  )
}

export default ServiceCategory;
