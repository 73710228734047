import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useFormik } from 'formik'
import { addTeamMemberValidation, addTeamMemberValidationIndia } from '../../../Context/validation'
import { addStaffApi, branchListApi, imgData, multipleServicesListApi, logOutApi, checkIsMobileExist, listRole, getAllPermission } from '../../../Context/api'
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import moment from 'moment/moment'
import { isValidPhoneNumber } from 'react-phone-number-input';

const UseAddTeam = () => {
    const [imageData, setImageData] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [serviceData] = useState([])
    const [branchList, setBranchList] = useState([])
    const [multipleService, setMultipleService] = useState([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    let branchListData = []
    const date = moment(new Date()).format('YYYY-MM-DD')
    const [userNumExist, setUserNumExist] = useState(true)
    const [permissions, setPermissions] = useState([]);
    const [roleList, setRoleList] = useState([])
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const [selectAll, setSelectAll] = useState(true);
    const [permissionBtn, setPermissionBtn] = useState(false)
    const navigate = useNavigate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    useEffect(() => {
        list()
        getBranchList()
        multipleServicesList()
    }, [])
    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const multipleServicesList = () => {
        axios.post(multipleServicesListApi(), { userId: userData?.userId, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setMultipleService(resp.data.data)
                if (serviceData.length === 0) {
                    resp.data.data.map((item) => {
                        return item.servicedetails.map((value) => {
                            return serviceData.push({ categoryName: item.categoryName, serviceName: value.serviceName, serviceId: value.serviceId })
                        })
                    })
                }
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const permissionsList = (id) => {
        axios.post(getAllPermission(), { roleId: id },).then((resp) => {
            // if (resp.data.code === statusCodes.ok) {
            setPermissions(resp.data.data.allPermissions)

            // }
            // else {
            //     setPermissions([])
            // }
        })
            .catch(() => { })
    }
    const getBranchList = () => {
        axios.post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                if (branchListData.length === 0) {
                    resp.data.data?.map((item) => {
                        return branchListData.push({ name: item.name, branchId: item._id })
                    })
                    setBranchList(branchListData)
                }
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        }).catch(() => { })
    }
    const staffExist = (num) => {
        const data = {
            mobileNo: num,
            timeZone: timeZone
        }
        axios.post(checkIsMobileExist(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setUserNumExist(resp.data.data.mobileExist)
            }
            else {
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError)
            })
    }
    const addTeamMember = useFormik({
        initialValues: {
            name: '',
            email: '',
            dob: '',
            gender: '',
            mobileNo: '',
            services: [],
            branch: [],
            pin: '',
            role: ''
        },
        validationSchema: timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? addTeamMemberValidationIndia : addTeamMemberValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            let data = {
                name: values.name.trim(),
                email: values.email.trim(),
                DOB: values.dob,
                gender: values.gender,
                mobileNo: `${values.mobileNo}`,
                image: imageData,
                // roleTitle: 'STAFF',
                // createdbyId: userData?.userId,
                createdbyId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
                branchId: values.branch.map((item) => item?.branchId),
                serviceId: values.services.map((item) => item?.serviceId),
                timeZone: timeZone,
                roleTitle: values.role,
                allpermissions: permissions

            }
            if (timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata') {
                data['confirmpin'] = values.pin
            }
            if (timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata') {
                if (!userNumExist && !values.pin) return toast.warn('Please enter PIN')
                if (!userNumExist && values.pin.toString().length != 4) return toast.warn('PIN should be 4 number')
            }
            if (values.services.length === 0 && values.branch.length === 0) return toast.warn("Please select service and branch")
            if (values.services.length === 0) return toast.warn("Please select service")
            if (values.branch.length === 0) return toast.warn("Please select branch")
            // console.log(data, 'ooooooo')
            //  return
            setSpinner(true)
            axios.post(addStaffApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    // getStaffList()
                    addTeamMember.resetForm()
                    setSpinner(false)
                    setImageData('')
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            })
                .catch(() => {
                    setSpinner(false)
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const list = () => {
        setSpinner(true)
        axios.post(listRole(), {},).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setRoleList(resp.data.data)
                setSpinner(false)
            }
            else {
                setRoleList([])
                setSpinner(false)
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
    }
    const handlePermissionChange = (categoryTitle, keyTitle) => {
        setPermissions(prevPermissions => {
            return prevPermissions.map(category => {
                if (category.title === categoryTitle) {
                    const updatedPermissionData = category.permissionData.map(permission => {
                        if (permission.title === keyTitle) {
                            return {
                                ...permission,
                                data: {
                                    ...permission.data,
                                    value: !permission.data.value
                                }
                            };
                        }
                        return permission;
                    });
                    return {
                        ...category,
                        permissionData: updatedPermissionData
                    };
                }
                return category;
            });
        });
    };
    const handleMarkAll = (value) => {
        const updatedPermissions = permissions.map(category => ({
            ...category,
            permissionData: category.permissionData.map(permission => ({
                ...permission,
                data: {
                    ...permission.data,
                    value: value
                }
            }))
        }));

        setPermissions(updatedPermissions);
    };
    const handleTogglePermissions = () => {
        const checkbox = document.getElementById('item-2');
        checkbox.checked = !checkbox.checked;
    };

    return {
        setImageData,
        navigate,
        staffExist,
        handlePermissionChange,
        handleMarkAll,
        setPermissionBtn,
        setSelectAll,
        setPermissions,
        permissionsList,
        permissionBtn,
        selectAll,
        permissions,
        addTeamMember,
        imageData,
        spinner,
        serviceData,
        branchList,
        date,
        exceptThisSymbols,
        timeZone,
        userNumExist,
        imgData,
        roleList,
        handleTogglePermissions

    }
}

export default UseAddTeam
