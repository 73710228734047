/* eslint-disable no-mixed-operators */
import React from 'react'
import moment from 'moment/moment';
import ValidationMessage from '../ValidationMessage/validationMessage'
import { ToastContainer } from 'react-toastify'
import { Modal } from 'react-bootstrap';
import UseAppointmentView from './Hooks/useAppointmentView';
import { intialRole } from '../../Utils/Utils';
import Spinner from '../../Components/spinner/spinner';

const AppointmentView = () => {
    const {
        accepted,
        setShowModal,
        navigate,
        appointmentCheckout,
        setShowVisitModal,
        setVisitModal,
        days,
        setVisitData,
        showVistModal,
        vistModal,
        cancelAppointment,
        appointmentViewData,
        showModal, userData,
        visitData,
        daysData,
        spinner,
        userInfo } = UseAppointmentView()

    const endDate = new Date(appointmentViewData.endDateTime);
    const currentDate = new Date();
    const isEndDateLessThanCurrentDate = endDate < currentDate;


    return (
        <>
            {spinner ? <Spinner /> : null}
            <ToastContainer />
            <section>
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className='page-tittle'>
                            <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>   Appointment Summary
                        </h2>
                        <hr />
                    </header>
                    <form className="row ps-4 cus-form mt-4">
                        <div class="shadow-sm py-3 mb-3 bg-body-tertiary rounded row">
                            <div className="col-md-6">
                                <div className="mb-1">
                                    <label className="form-label">Client Name</label>
                                    <input type="text" className="form-control alreadytext" value={appointmentViewData?.clientName} disabled={true} />
                                </div>
                            </div>
                            {
                                appointmentViewData.clientRole === 'MEMBER' ? null
                                    :

                                    <div className="col-md-6">
                                        <div className="mb-1">
                                            <label className="form-label">Mobile Number</label>
                                            <input type="text" className="form-control alreadytext" value={appointmentViewData?.clientMoblieNo} disabled={true} />
                                        </div>
                                    </div>
                            }
                            <div className="col-md-6 mt-2">
                                <div className="mb-1">
                                    <label className="form-label">Appointment ID </label>
                                    <input type="text" className="form-control alreadytext" value={appointmentViewData?.appointment_number} disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-6 mt-2">
                                <div className="mb-1">
                                    <label className="form-label">Date</label>
                                    <input type="text" className="form-control alreadytext" value={moment(appointmentViewData?.startDateTime).format('DD-MM-YYYY')} disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-6 mt-2">
                                <div className="mb-1">
                                    <label className="form-label">Time</label>
                                    <input
                                        type="text"
                                        className="form-control alreadytext"
                                        value={`${moment(appointmentViewData?.startDateTime).format('LT')} - ${moment(appointmentViewData?.endDateTime).format('LT')}`}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            {
                                appointmentViewData?.notes ?
                                    <div className="col-md-6 mt-2">
                                        <div className="mb-1">
                                            <label className="form-label">Special Notes</label>
                                            <textarea className="form-control alreadytext" value={appointmentViewData?.notes} disabled={true}></textarea>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                appointmentViewData?.remark ?
                                    <div className="col-md-6 mt-2">
                                        <div className="mb-1">
                                            <label className="form-label">Remark</label>
                                            <input
                                                type="text"
                                                className="form-control alreadytext"
                                                value={appointmentViewData?.remark}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div class="shadow-sm py-3 mb-3 bg-body-tertiary rounded row">
                            <div className="col-md-6">
                                <label className="form-label">Team Member</label>
                                <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e',borderRadius:'5px',color:'#867d7d' }}>
                                    <ul className='mt-1 ms-1'>
                                        {
                                           appointmentViewData?.staffDetail?.map((item)=>
                                            <li class="list-group-item">{item.name}</li>
                                        )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-1">
                                    <label className="form-label">Branch</label>
                                    <input type="text" className="form-control alreadytext" value={appointmentViewData?.branchName} disabled={true} />
                                </div>
                            </div>
                            {
                                appointmentViewData?.visitType ?
                                    <div className="col-md-6 mt-2">
                                        <div className="mb-1">
                                            <label className="form-label">Booking Type</label>
                                            <input type="text" className="form-control alreadytext" value={appointmentViewData?.visitType} disabled={true} />
                                        </div>
                                    </div>
                                    : null

                            }
                            <div className="col-md-6 mt-2">
                                <div className="mb-1">
                                    <label className="form-label">Status</label>
                                    <input type="text" className="form-control alreadytext" value={appointmentViewData?.reschedule?.status && appointmentViewData.status === 'CANCELLED' ? appointmentViewData?.status : appointmentViewData.status === 'PENDING' ? appointmentViewData?.status : appointmentViewData?.status === 'DECLINED' ? appointmentViewData?.status : appointmentViewData?.reschedule?.status ? 'RESCHEDULE' : appointmentViewData?.status} disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 shadow-sm py-3 mb-4 bg-body-tertiary rounded row">

                            {
                                appointmentViewData?.MainOfferData?.length ?
                                    <div className="col-md-6">
                                        <label className="form-label">Offer & Service</label>
                                        <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e' }}>
                                            <div className='row text-start' >
                                                <div className="col-lg-3">
                                                    <div className='col-lg-12'>Service</div>
                                                    {

                                                        appointmentViewData?.MainOfferData?.map((item, i) =>

                                                            <div className='col-lg-12' style={{ color: '#867d7d', fontSize: '16px' }}>{item?.serviceName}</div>
                                                        )}
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='col-lg-12'>Offer</div>
                                                    {

                                                        appointmentViewData?.MainOfferData?.map((item, i) =>
                                                            <div className='col-lg-12' style={{ color: '#867d7d', fontSize: '16px' }}>{!item?.offerName ? '--' : item?.offerName}</div>
                                                        )}
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='col-lg-12'>Actual Price</div>
                                                    {

                                                        appointmentViewData?.MainOfferData?.map((item, i) =>
                                                            <div className='col-lg-12' style={{ color: '#867d7d', fontSize: '16px' }}>{!item?.Amount ? '--' : `${userData.currencySymbol}${item?.Amount}`}</div>
                                                        )}
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className='col-lg-12'>Discount Price</div>
                                                    {

                                                        appointmentViewData?.MainOfferData?.map((item, i) =>
                                                            <div className='col-lg-12' style={{ color: '#867d7d', fontSize: '16px' }}>{!item?.finalAmount ? '--' : `${userData.currencySymbol}${item?.Amount - item?.finalAmount}`}</div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null

                            }
                            {
                            //    appointmentViewData?.isComboPackage &&
                                <div className="col-md-6">
                                <label className="form-label">Service</label>
                                <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e',borderRadius:'5px',color:'#867d7d' }}>
                                    <ul className='mt-1 ms-1'>
                                        {
                                           appointmentViewData?.serviceDetail?.map((item)=>
                                            <li class="list-group-item">{item.name}</li>
                                        )
                                        }
                                    </ul>
                                </div>
                            </div>
                            }



                            <div className="col-md-6 mt-2">
                                <div className="mb-1">
                                    <label className="form-label">Actual Price</label>
                                    <input type="text" className="form-control alreadytext" value={`${userData.currencySymbol}${appointmentViewData?.price}`} disabled={true} />
                                </div>
                            </div>

                            {
                                appointmentViewData?.AddonOfferData?.length ?
                                    <div className="col-md-6">
                                        <div className='px-2' style={{ background: '#fcf6f3', border: '1px solid #fcf6f3', boxShadow: '0px 1px 3px #fbbf9e' }}>
                                            {

                                                appointmentViewData?.AddonOfferData?.map((item, i) =>
                                                    <div className='row text-start' >
                                                        <div className="col-sm-3" style={{ color: '#867d7d', fontSize: '16px' }}>{item?.serviceName}</div>
                                                        <div className="col-sm-3" style={{ color: '#867d7d', fontSize: '16px' }}>{!item?.offerName ? '--' : item?.offerName}</div>
                                                        <div className="col-sm-3" style={{ color: '#867d7d', fontSize: '16px' }}>{!item?.Amount ? '--' : `${userData.currencySymbol}${item?.Amount}`}</div>
                                                        <div className="col-sm-3" style={{ color: '#867d7d', fontSize: '16px' }}>{!item?.finalAmount ? '--' : `${userData.currencySymbol}${item?.Amount - item?.finalAmount}`}</div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    :
                                    null

                            }

                            {appointmentViewData?.reschedule?.rescheduleByName && appointmentViewData.status === 'CANCELLED' || appointmentViewData?.status === 'DECLINED' ? null : appointmentViewData?.reschedule?.rescheduleByName ?
                                <div div className="col-md-6 mt-2">
                                    <div className="mb-1">
                                        <label className="form-label">Reschedule By</label>
                                        <input type="text" className="form-control alreadytext" value={appointmentViewData?.reschedule?.rescheduleByName} disabled={true} />
                                    </div>
                                </div>
                                : null
                            }
                            {
                                appointmentViewData?.cancel?.reason ?
                                    <>
                                        <div className="col-md-6 mt-2">
                                            <div className="mb-1">
                                                <label className="form-label">Cancelled Reason</label>
                                                <textarea className="form-control alreadytext" value={appointmentViewData?.cancel?.reason} disabled={true}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className="mb-1">
                                                <label className="form-label">Cancelled By</label>
                                                <input type="text" className="form-control alreadytext" value={appointmentViewData?.cancel?.userName} disabled={true} />
                                            </div>
                                        </div>
                                    </>
                                    : null

                            }
                            <div className="col-md-6 mt-2">
                                <div className="mb-1">
                                    <label className="form-label">Final Price</label>
                                    <input type="text" className="form-control alreadytext" value={`${userData.currencySymbol}${appointmentViewData?.amount}`} disabled={true} />
                                </div>
                            </div>
                            {
                                appointmentViewData?.status === 'PENDING' ?

                                    <div className='col-sm-12 mt-5'>
                                        {intialRole({ permission: "appointment", userData: userInfo, section: 'accept' }) ? <button type='button' className='btn btn_Style' onClick={() => accepted()}>Accept</button> : null}
                                        {intialRole({ permission: "appointment", userData: userInfo, section: 'reject' }) ? <button type='button' className='btn cancel_btn ms-3' onClick={() => setShowModal(true)}>Reject</button> : null}
                                    </div>
                                    :
                                    null
                            }
                            {

                                appointmentViewData?.status === 'PENDING' ? null :
                                    appointmentViewData?.status === 'VISITED' || appointmentViewData.status === 'NOTVISITED' ? null :
                                        isEndDateLessThanCurrentDate ?
                                            <div className='col-sm-12 mt-5'>
                                                <button type='button' className='btn btn_Style' onClick={() => { setVisitModal(true); setVisitData((pre) => ({ ...pre, remark: '', days: '' })); days() }}>Visit</button>
                                                <button type='button' className='btn cancel_btn ms-3' onClick={() => { setShowVisitModal(true); setVisitData((pre) => ({ ...pre, remark: '', days: '' })) }}>Not Visit</button>
                                            </div>
                                            : null
                            }
                        </div>

                    </form>
                </div>
                {/* Appointment Cancel Start  */}
                <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                    <div className="modal-body">
                        <p className="text-center">Are You Sure !</p>
                        <p className="text-center">You want to reject this appointment</p>
                        <div className="mb-3">
                            <label className="form-label">Reason *</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name='reason' value={cancelAppointment.values.reason} onChange={cancelAppointment.handleChange}></textarea>
                            <ValidationMessage formName={cancelAppointment} name={'reason'} />
                        </div>
                        <button
                            type="submit"
                            className="btn nobtn"
                            onClick={cancelAppointment.handleSubmit}
                            style={{ marginLeft: '20%' }}
                        >
                            Yes
                        </button>
                        <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => setShowModal(false)}>
                            No
                        </button>
                    </div>
                </Modal>
                {/* Appointment Cancel End */}

                {/* Appointment Visit Start  */}
                <Modal centered show={vistModal} onHide={() => setVisitModal(false)}>
                    <div className="modal-body">
                        <h5 className='text-center'>Follow up reminders</h5>
                        <div className="mb-3">
                            <label className="form-label">Days * </label>
                            <select className="form-select form-control" aria-label="Default select example" name='probability'
                                value={visitData?.days}
                                onChange={(e) => setVisitData((pre) => ({ ...pre, days: e.target.value }))}
                            >
                                <option hidden>Select Days</option>
                                {
                                    daysData?.map((item) =>
                                        <option value={item}>{item}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Remark *</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name='reason' value={visitData?.remark} onChange={(e) => setVisitData((pre) => ({ ...pre, remark: e.target.value }))}></textarea>
                            {/* <ValidationMessage formName={cancelAppointment} name={'reason'} /> */}
                        </div>
                        <button
                            type="submit"
                            className="btn nobtn my-2"
                            onClick={() => appointmentCheckout(true)}
                            style={{ marginLeft: '20%' }}
                        >
                            Submit
                        </button>
                        <button type="button" className="btn yesbtn" onClick={() => setVisitModal(false)}>
                            Cancel
                        </button>
                    </div>
                </Modal>
                {/* Appointment Visit Start*/}

                {/* Appointment Visit Cancel Start  */}
                <Modal centered show={showVistModal} onHide={() => setShowVisitModal(false)}>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Remark *</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name='reason' value={visitData?.remark} onChange={(e) => setVisitData((pre) => ({ ...pre, remark: e.target.value }))}></textarea>
                            {/* <ValidationMessage formName={cancelAppointment} name={'reason'} /> */}
                        </div>
                        <button
                            type="submit"
                            className="btn nobtn"
                            onClick={() => appointmentCheckout(false)}
                            style={{ marginLeft: '20%' }}
                        >
                            Yes
                        </button>
                        <button type="button" className="btn yesbtn" onClick={() => setShowVisitModal(false)}>
                            No
                        </button>
                    </div>
                </Modal>
                {/* Appointment Visit Cancel Start*/}
            </section>
        </>
    )
}

export default AppointmentView;

