import axios from 'axios';
import React, { useContext } from 'react'
import { branchListApi, pieChart, appointmentBook, waitinglistGraph, upcommingAppointment, visitGrowth, logOutApi } from '../../Context/api';
import { statusCodes, commonMessage } from '../../Constants/generalConfig'
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ScrollBars from 'react-scrollbar'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  // ReferenceLine,
  ResponsiveContainer,
  // AreaChart, Area
} from 'recharts';
import Spinner from '../../Components/spinner/spinner'
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';

const BookingDetails = () => {
  const { userInfo } = useContext(Notifications)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [branchList, setBranchList] = useState([])
  const [pieChartData, setPieChartData] = useState([])
  const [bookData, setBookData] = useState([])
  const [show, setShow] = useState(false)
  const [branchBookData, setBranchBookData] = useState([])
  const [waitinglistData, setWaitinglistData] = useState([])
  const [upcommingAppointmentData, setUpcommingAppointmentData] = useState([])
  const [visitGrowthData, setVisitGrowthData] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [state, setState] = useState({
    branch: userData?.locationId,
    days: "LAST7"
  });
  let bookedPercent = ''
  let cancelPercent = ''
  let visitPercent = ''
  let notVisitPercent = ''
  let totalAppointments = []
  const navigate = useNavigate()


  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      getBranchList()
    }
  }, [])

  useEffect(() => {
    chartPie()
    bookAppointDays()
    waitinglistDays()
    upcommingAppointmentBranch()
    visitGrowthDay()
  }, [state.days, state.branch])
  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  pieChartData?.map((item) => {
    totalAppointments = item.totalAppointment
    if (item.status === 'ACCEPTED') {
      bookedPercent = item.percent
    }
    if (item.status === 'VISITED') {
      visitPercent = item.percent
    }
    if (item.status === 'NOTVISITED') {
      notVisitPercent = item.percent
    }
    if (item.status === 'CANCELLED') {
      cancelPercent = item.percent
    }
  })
  // console.log(bookedPercent,cancelPercent,visitPercent,notVisitPercent,'PPPP')
  const getBranchList = () => {
    axios
      .post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
      .then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setBranchList(resp.data.data)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          setBranchList([])
        }
      }).catch(() => { })
  }
  const chartPie = () => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }
    setSpinner(true)
    axios.post(pieChart(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setPieChartData(resp.data.data)
        setSpinner(false)
        if (state.branch) {
          return setShow(true)
        }
        else if (!state.branch) {
          return setShow(false)
        }
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setSpinner(false)
        setPieChartData([])
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const bookAppointDays = (e) => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }

    if (state.branch.length === 0) {
      delete data.branchId
    }
    axios.post(appointmentBook(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setBookData(resp.data.data)
        setBranchBookData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setBookData([])
      }
    }).catch(() => { })
  }
  const waitinglistDays = (e) => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }
    if (state.branch.length === 0) {
      delete data.branchId
    }
    axios.post(waitinglistGraph(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setWaitinglistData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setWaitinglistData([])
      }
    }).catch(() => { })
  }
  const upcommingAppointmentBranch = () => {
    const data = {
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }
    if (state.branch.length === 0) {
      delete data.branchId
    }
    axios.post(upcommingAppointment(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setUpcommingAppointmentData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setUpcommingAppointmentData([])
      }
    }).catch(() => { })
  }
  const visitGrowthDay = () => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }

    if (state.branch.length === 0) {
      delete data.branchId
    }
    axios.post(visitGrowth(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setVisitGrowthData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }
  let items = []
  visitGrowthData.forEach((item) => {
    item.appointments?.map((data) => {
      items.push({ name: moment(item._id).format("MMM Do"), Appointment: item.count })
    })
  })

  const removeDuplicates = (arr) => {
    const tracking = {};

    for (const obj of arr) {
      const id = obj.name;

      if (!tracking[id]) {
        tracking[id] = obj;
      }
    }
    const uniqueArray = Object.values(tracking);
    return uniqueArray;
  }
  const uniqueArray = removeDuplicates(items);
  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="m-t-3  cus-padd cusheight">
          <header>
            <h2 className="page-tittle">
              <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Booking Details
            </h2>
            <hr />
          </header>
          {
            intialRole({ permission: "insights", userData: userInfo, section: 'viewBookingDeteil' }) ?
              <>
                <div className="d-flex justify-content-between listpadding">
                  <div className="dropdown">
                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example" name="days" value={state.days}
                      onChange={(e) => {
                        handleInputChange(e);
                      }} >
                      <option hidden>Select days</option>
                      <option value="TODAY">Today</option>
                      <option value="LAST7">Last 7 days</option>
                      <option value="LAST_MONTH">Last Month</option>
                      <option value="THIS_MONTH">This Month</option>
                    </select>
                  </div>
                  <div className="dropdown">
                    <select className='mb-2' disabled style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example" name="branch" value={state.branch}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}>
                      <option hidden>Select branch</option>
                      <option value="">All</option>
                      {branchList?.map((item) => (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='scroll mt-2'>
                  <div className="row listpadding mb-4">
                    {
                      show ?
                        <div className="col-sm-6 mt-4">
                          {branchBookData.map((item, i) =>
                            <React.Fragment key={i}>
                              <div className="d-flex justify-content-between">
                                <h3>Booking Growth</h3>
                                <h3>{item.bookingGrowth}%</h3>
                              </div>
                              <hr className="innerhr" />
                              <div className="card listpadding2">
                                <div className="d-flex justify-content-between">
                                  <h6>This Week</h6>
                                  <h6>{item.thisWeekpercentage}%</h6>
                                </div>
                                <div className="progress mb-3">
                                  <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.thisWeekpercentage ? item.thisWeekpercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h6>Last Week</h6>
                                  <h6>{item.previousWeekpercentage}%</h6>
                                </div>
                                <div className="progress">
                                  <div className="progress-bar bar2" role="progressbar" style={{ width: `${item.previousWeekpercentage ? item.previousWeekpercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                          }

                        </div>
                        :
                        <div className="col-sm-6 mt-4">
                          <h3>Booked</h3>
                          <hr className="innerhr" />
                          <div className="card listpadding2">
                            <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '265px' }} smoothScrolling={true} >
                              {
                                bookData.map((item, i) => (
                                  <React.Fragment key={i}>
                                    <div className="d-flex justify-content-between">
                                      <h5>{item.name}</h5>
                                      <h5>{item.branchPercentage ? item.branchPercentage : 0}%</h5>
                                    </div>
                                    <div className="progress mb-2">
                                      <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.branchPercentage ? item.branchPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>

                                  </React.Fragment>
                                ))
                              }
                            </ScrollBars>
                          </div>
                        </div>
                    }

                    <div className="col-sm-6 text-center pt-3">
                      <div style={{ width: '35%', marginLeft: '30%' }}>
                        <PieChart
                          data={
                            !bookedPercent && !cancelPercent && !visitPercent && !notVisitPercent ?
                              [
                                { title: 'Booking', value: (Math.round(100)), color: '#F2674A' },
                                // { title: 'Cancel', value: (Math.round(50)), color: '#F2674A' },
                              ]
                              :
                              [
                                { title: 'Booking', value: (Math.round(bookedPercent)), color: '#f79b68' },
                                { title: 'Cancel', value: (Math.round(cancelPercent)), color: '#82C8F1' },
                                { title: 'Visit', value: (Math.round(visitPercent)), color: 'green' },
                                { title: 'NotVisit', value: (Math.round(notVisitPercent)), color: 'pink' },
                              ]
                          }
                        />
                      </div>
                      {/* {pieChartData.length ?  */}
                      <>
                        <h3 className='mt-2'>Total Bookings - {totalAppointments.toString().length ? totalAppointments : 0}</h3>
                        <div className='d-flex justify-content-center'>
                          <p style={{ background: '#f79b68', padding: '5px 8px', height:'18px', borderRadius: '100%' }}></p>
                          <p className='ms-1'>Booking {Math.round(bookedPercent)}%</p>
                          <div className='d-flex ms-3'>
                            <p style={{ background: '#82C8F1', padding: '5px 8px', height:'18px', borderRadius: '100%', marginLeft: '2%' }}></p>
                          </div>
                          <p className='ms-1'>Cancel {Math.round(cancelPercent)}%</p>

                          <p className='ms-2' style={{ background: 'green', padding: '5px 8px', height:'18px', borderRadius: '100%' }}></p>
                          <p className='ms-1'>Visit {Math.round(visitPercent)}%</p>
                          <div className='d-flex ms-3'>
                            <p style={{ background: 'pink', padding: '5px 8px', height:'18px', borderRadius: '100%', marginLeft: '2%' }}></p>
                          </div>
                          <p className='ms-1'>Not Visit {Math.round(notVisitPercent)}%</p>
                        </div>
                        {/* <div className='d-flex justify-content-center'>
                          <p style={{ background: 'green', padding: '5px 8px', borderRadius: '100%' }}></p>
                          <p className='ms-1'>Visit {Math.round(visitPercent)}%</p>
                          <div className='d-flex ms-3'>
                            <p style={{ background: 'pink', padding: '5px 8px', borderRadius: '100%', marginLeft: '2%' }}></p>
                          </div>
                          <p className='ms-1'>Not Visit {Math.round(notVisitPercent)}%</p>
                        </div> */}
                      </>
                      {/* // : <p className='mb-5'>No Record found</p>} */}
                    </div>
                    {
                      show ?
                        <>
                          <div className="col-sm-6 mt-4 mb-4">

                            {
                              waitinglistData?.map((item, i) =>
                                <React.Fragment key={i}>
                                  <div className="d-flex justify-content-between">
                                    <h3>Waiting List</h3>
                                  </div>
                                  <hr className="innerhr" />
                                  <div className="card listpadding2">
                                    <div className="d-flex justify-content-between">
                                      <h6>Booked</h6>
                                      <h6>{item.AcceptedPercentage}%</h6>
                                    </div>
                                    <div className="progress mb-3">
                                      <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.AcceptedPercentage ? item.AcceptedPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <h6>Reject</h6>
                                      <h6>{item.RejectedPercentage}%</h6>
                                    </div>
                                    <div className="progress">
                                      <div className="progress-bar bar2" role="progressbar" style={{ width: `${item.RejectedPercentage ? item.RejectedPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                      <h6>Pending</h6>
                                      <h6>{item.PendingPercentage}%</h6>
                                    </div>
                                    <div className="progress">
                                      <div className="progress-bar bar2" role="progressbar" style={{ width: `${item.PendingPercentage ? item.PendingPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className='col-sm-6 text-center mt-4'>
                            <div className='mt-5 card listpadding2' style={{ height: '220px' }}>
                              <div className='d-flex justify-content-center'>
                                <h6 className='me-2'>Upcoming Bookings</h6>   -
                                <h6 className='ms-2'> {upcommingAppointmentData?.[0]?.totalCount ? upcommingAppointmentData?.[0]?.totalCount : 0}</h6>
                              </div>
                              {upcommingAppointmentData?.[0]?.Date ? <h6>Upcoming Bookings till {moment(upcommingAppointmentData?.[0]?.Date).format('DD-MM-YYYY')}</h6> : null}
                            </div>
                          </div>
                        </>
                        :
                        <div className="col-sm-6 mt-4 text-center">
                          <h3>Waiting List</h3>
                          <hr className="innerhr" />
                          <div className="card listpadding2">
                            <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '265px' }} smoothScrolling={true}>
                              {
                                waitinglistData?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <div className="d-flex justify-content-between">
                                      <h6>{item.name}</h6>
                                      <h6>{item.branchPercentage}%</h6>
                                    </div>
                                    <div className="progress mb-2">
                                      <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.branchPercentage ? item.branchPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </React.Fragment>
                                ))
                              }
                            </ScrollBars>
                          </div>
                        </div>
                    }

                    {
                      show ?
                        // <div className="row listpadding">
                        //   <div className="col-sm-6">
                        //     <h3>Visit Growth</h3>
                        //     <hr className="innerhr" />
                        //     <ResponsiveContainer width="100%" height="90%">
                        //       <AreaChart
                        //         width={500}
                        //         height={400}
                        //         data={data}
                        //         margin={{
                        //           top: 10,
                        //           right: 30,
                        //           left: 0,
                        //           bottom: 0,
                        //         }}
                        //       >
                        //         <CartesianGrid strokeDasharray="3 3" />
                        //         <XAxis dataKey="name" />
                        //         <YAxis />
                        //         <Tooltip />
                        //         <Area type="monotone" dataKey="uv" stroke="#71C6E0" fill="#71C6E0" />
                        //       </AreaChart>
                        //     </ResponsiveContainer>
                        //   </div>
                        // </div>
                        null
                        :
                        <div className="col-sm-6 mt-4 text-center">
                          <h3>Upcoming Appointments</h3>
                          <hr className="innerhr" />
                          <div className="card listpadding2">
                            <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '265px' }} smoothScrolling={true}>
                              {
                                upcommingAppointmentData?.map((item, i) =>
                                  <React.Fragment key={i}>
                                    <div className="d-flex justify-content-between">
                                      <h6>{item.name}</h6>
                                      <h6>{item.branchPercentage}%</h6>
                                    </div>
                                    <div className="progress mb-2">
                                      <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.branchPercentage ? item.branchPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>

                                  </React.Fragment>
                                )
                              }
                            </ScrollBars>
                          </div>
                        </div>
                    }
                    {
                      show ? null
                        :
                        <div className="col-sm-12 mt-4 text-center">
                          <h3>Visit Growth</h3>
                          <hr className="innerhr" />
                          <ResponsiveContainer width="100%" height="90%">
                            <LineChart width={500} height={300} data={uniqueArray}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="Appointment" stroke="#f47d3a" />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>

                    }
                  </div>
                </div>
              </>
              : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>
      </section>
    </>
  )
}

export default BookingDetails
