import React, { useContext, useEffect, useState } from 'react'
import { allLocation, selectLocationProvider, branchImgPath } from '../../Context/api'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import { Notifications } from '../../App'
import Spinner from '../../Components/spinner/spinner'

const Location = () => {
    const { setUserInfo } = useContext(Notifications)
    const [spinner, setSpinner] = useState(false)
    const userId = JSON.parse(localStorage.getItem('userId'))
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    console.log()
    useEffect(() => {
        branches()
    }, [])
    const branches = () => {
        axios.post(allLocation(), { userId: userId }).then((resp) => {
        // axios.post(allLocation(), { userId: '65e6a3aaeceabc112af54a16' }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setData(resp.data.data)
            }
            else {
                setData([])

            }
        })
            .catch(() => { })
    }
    const selectLocation = (item) => {
        const data = {
            locationId: item._id,
            loginDevice: 'WEB',
            userId: item.userId,
        }
        setSpinner(true)
        axios.post(selectLocationProvider(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.setItem('userData', JSON.stringify(resp.data.data))
                setUserInfo(resp.data.data)
                toast.success(resp.data.message)
                setTimeout(() => {
                    navigate('/layout/dashboard')
                }, 1000);
                setSpinner(false)
                localStorage.removeItem('userId')
                localStorage.removeItem(timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile' : 'Email')
            }
            else {
                setSpinner(false)
                toast.error(resp.data.message)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    return (
        <section>
            {spinner && <Spinner />}
            <ToastContainer />
            <div className='container-fluid' style={{ height: '100vh', width: '100vw', }}>
                <div className='d-flex justify-content-between pt-3 pb-2'>
                    <div>
                        <img src={require('../../Assets/images/logo white color.png')} alt="" width={70} height={70} />
                    </div>
                    {/* <svg onClick={() => navigate(-1)} className='cursor mt-3' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '40px' }}><path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z"></path></svg> */}
                </div>
                <div className='_card_Main' >
                    {
                        data?.map((item, index) =>
                            <div className="_card cursor mt-2" onClick={() => selectLocation(item)}>
                                {/* <div className="_card cursor mt-2"> */}
                                <div className="top-container d-flex">
                                    <img src={item.image == '' || item.image == null ? require("../../Assets/images/dummyimg.png") : branchImgPath + item.image} width='60'  style={{ borderRadius: '50%' }}/>
                                    <div className="flex-row" >
                                        <h5 className="ms-2">{item?.name}</h5>
                                        <span className="ms-2">({item?.city})</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className=''style={{position:'absolute',bottom:'5%',left:'47%'}}>
                    <button className='btn px-5' style={{background:'#f47d3a',color:'#fff'}} onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>
        </section>
    )
}

export default Location
