import React from 'react'
import { useState } from 'react';
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import { useLocation } from 'react-router-dom';
const NumberInput = ({ Name, Value, HandleChange, disabledInput, width, countrySelectComponent,extraFun }) => {
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    // console.log(splitLocation,'splitLocation')
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [countryMaxLength, setCountryMaxLength] = useState(15);
    const formatPhoneNumber = (value) => {
        if (!value) return '';
        const phoneNumber = value.replace(/[^\d]/g, '');
        const countryCode = phoneNumber.slice(0, 3);
        const number = phoneNumber.slice(3);
        return `+${countryCode} ${number}`;
    };
    const handleCountryChange = (selectedCountry) => {
        const countryName = selectedCountry;
        if (countryName === 'IN') {
            setCountryMaxLength((pre) => 15);
        } else {
            setCountryMaxLength((pre) => 20);
        }
    };
    return (
        <>
            <PhoneInput
                disabled={disabledInput}
                className={`${width && "w-100"} ${splitLocation[1]==''||splitLocation[1]=='forgotPin'?'phone_Input2':splitLocation[1]=='contact-us'?'phone_Input_Contact':'phone_Input'}`}
                countrySelectComponent={countrySelectComponent}
                defaultCountry={'IN'}
                countryCallingCodeEditable={false}
                international
                withCountryCallingCode
                value={Value && `${Value.includes("+") ? "" : "+"}${Value}`}
                onChange={(e) => { 
                    HandleChange.setFieldValue(Name, e)
                    if(extraFun){
                        if(e?.length>7){
                            extraFun(e)
                        }
                    }
                 }}
                format={formatPhoneNumber}
                maxLength={countryMaxLength}  // Set the dynamic maxLength
                onCountryChange={(country) => handleCountryChange(country)}
                placeholder="Enter phone number"
                
            />
        </>
    )
}

export default NumberInput

// import React, { useState } from 'react';
// import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';

// const NumberInput = ({ Name, Value, HandleChange, disabledInput, width, countrySelectComponent }) => {
//   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const [countryMaxLength, setCountryMaxLength] = useState(15);

//   // Function to separate country code and national number
//   const separateCountryCode = (phoneNumber) => {
//     const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
//     if (parsedPhoneNumber) {
//       return {
//         country: `+${parsedPhoneNumber?.countryCallingCode}`,
//         nationalNumber: parsedPhoneNumber?.nationalNumber,
//       };
//     }
//     return {
//       country: '',
//       nationalNumber: '',
//     };
//   };

//   const handlePhoneNumberChange = (value) => {
//     // Update state with the entered phone number
//     HandleChange.setFieldValue(Name, value);
//   };

//   const handleCountryChange = (selectedCountry) => {
//     const countryName = selectedCountry;
//     if (countryName === 'IN') {
//       setCountryMaxLength((pre) => 15);
//     } else {
//       setCountryMaxLength((pre) => 20);
//     }
//   };

//   const formatPhoneNumber = (value) => {
//     if (!value) return '';
//     const phoneNumber = value.replace(/[^\d]/g, '');
//     const countryCode = phoneNumber.slice(0, 3);
//     const number = phoneNumber.slice(3);
//     return `+${countryCode} ${number}`;
//   };

//   const separatedNumbers = separateCountryCode(Value);

//   return (
//     <>
//       <PhoneInput
//         disabled={disabledInput}
//         className={`${width && "w-100"} phone_Input`}
//         countrySelectComponent={countrySelectComponent}
//         defaultCountry={'IN'}
//         countryCallingCodeEditable={false}
//         international
//         withCountryCallingCode
//         value={Value && `${Value.includes("+") ? "" : "+"}${Value}`}
//         onChange={handlePhoneNumberChange}
//         format={formatPhoneNumber}
//         maxLength={countryMaxLength}  // Set the dynamic maxLength
//         onCountryChange={handleCountryChange}
//         placeholder="Enter phone number"
//       />
//       <div>
//         <strong>Country Code:</strong> {separatedNumbers?.country}
//       </div>
//       <div>
//         <strong>Mobile Number:</strong> {separatedNumbers?.nationalNumber}
//       </div>
//     </>
//   );
// };

// export default NumberInput;
