import { useState, useEffect } from 'react';
import { resetPinValidation } from '../../../Context/validation';
import { useFormik } from 'formik';
import axios from 'axios';
import { createPinApi, checkTokensExpire } from '../../../Context/api';
import { statusCodes, commonMessage } from '../../../Constants/generalConfig';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';


const UseCreatePin = () => {
    const [spinner, setSpinner] = useState(false)
    const mobileNo = JSON.parse(localStorage.getItem('Mobile'))
    const navigate = useNavigate();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const email = JSON.parse(localStorage.getItem('email'));
    const { token } = useParams()



    useEffect(() => {
        window.onpopstate = () => {
            navigate("/");
        }
        tokenCheck()
    }, [])


    const tokenCheck = () => {
        // setSpinner(true)
        axios.post(checkTokensExpire(), { token: token }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                // toast.success(resp.data.message)
                

            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
                setTimeout(() => {
                    navigate(`/`)
                },100);
            }
        }).catch(()=>{})
    }

    const formik = useFormik({
        initialValues: {
            pin: '',
            confirmPin: '',
        },
        validationSchema: resetPinValidation,
        onSubmit: values => {
            const data = timeZone == 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? { confirmPin: formik.values.confirmPin, mobileNo: mobileNo, token: token, timeZone: timeZone }
                : { confirmPin: formik.values.confirmPin, email: email, token: token, timeZone: timeZone }

            setSpinner(true)
            axios.post(createPinApi(), { ...data }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    navigate(`/enterOtp/createPin/basicDetailProfile`)
                    localStorage.setItem('userId', JSON.stringify(resp.data.data.userId))
                    formik.resetForm();
                    setSpinner(false)
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)

                })
        },
    });

    return {
        navigate,
        setSpinner,
        mobileNo,
        spinner,
        formik,
        timeZone,
        email,
        token
    }
}

export default UseCreatePin