import { useFormik } from 'formik'
import { addClientValidation, invitationReSendValidation, addClientValidationIndia } from '../../../Context/validation'
import { addClientApi, clientListApi, imgData, changeClientStatusApi, logOutApi, reSendInvitation, staffPermission, checkIsMobileExist } from '../../../Context/api'
import axios from 'axios'
import { commonMessage, statusCodes, roleNames } from '../../../Constants/generalConfig'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Notifications } from '../../../App'
const UseClient = () => {
    const { userInfo } = useContext(Notifications)
    const [imageData, setImageData] = useState('')
    const [listData, setListData] = useState([])
    const [clientDetail, setClientDetail] = useState()
    const [spinner, setSpinner] = useState(false)
    const [showClient, setShowClient] = useState(false)
    // const [showActive, setShowActive] = useState(false)
    const [filter, setFilter] = useState({
        status: '',
        search: ''
    })
    const [count, setCount] = useState(1)
    
    const [page, setPage] = useState(1)
    const [clientArchive, setClientArchive] = useState(false)
    const PER_PAGE = 10
    const [isActive, setIsActive] = useState(true)
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const [invitationShow, setInvitationShow] = useState(false)
    const [deletedAccountShow, setDeletedAccountShow] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [availCheck, setAvailCheck] = useState({
        chat: false,
        isActive: false
    })
    // const [userNumExist, setUserNumExist] = useState(true)

    const date = moment(new Date()).format('YYYY-MM-DD')
    const navigate = useNavigate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            setSpinner(true)
            getClientList()
        }
    }, [page, isActive])
    useEffect(() => {
        if (availCheck.isActive == true) {
            permission()
        }
    }, [availCheck])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        }).catch(()=>{})
    }
    const changeClientStatus = () => {
        axios.post(changeClientStatusApi(), { clientId: clientDetail._id, loggedInUserId: userData?.userId, status: !clientDetail.isActive }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                // isActive = clientDetail.isActive
                setClientArchive(false)
                getClientList()
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(()=>{})
    }
    const getClientList = () => {
        const data = {
            userId: userData?.userId,
            isActive: isActive,
            page: page,
            count: PER_PAGE,
            isAccepted: filter.status,
            searchText: filter.search,
            isProviderClient: true
        }
        if (userData?.roleTitle == roleNames.STAFF || userData?.roleTitle == roleNames.FRONTDESK) {
            delete data.userId
            data['providerId'] = userData?.createdbyId
        }
        // if (userData?.roleTitle !== roleNames.SERVICE_PROVIDER) {
        //     delete data.userId
        //     data['providerId'] = userData?.createdbyId
        // }
        setSpinner(true)
        axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setListData(resp.data.data)
                setSpinner(false)
                setCount(resp.data.totalCount)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else if (resp.data.code === statusCodes.notAuthorized) {
                logOutFun()
            }
            else {
                setPage(1)
                setSpinner(false)
                setListData([])
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const addClient = useFormik({
        initialValues: {
            name: '',
            email: '',
            DOB: '',
            mobileNo: '',
            gender: '',
            pin: ''
        },
        validationSchema: timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? addClientValidationIndia : addClientValidation,
        validate: (values) => {
            const errors = {};
            if (values?.mobileNo?.length > 0 && (values?.mobileNo && isValidPhoneNumber(values?.mobileNo))) {
                delete errors.mobileNo
            } else {
                errors.mobileNo = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: (values) => {
            let data = {
                name: values.name.trim(),
                email: values.email.trim(),
                DOB: values.DOB,
                gender: values.gender,
                mobileNo: `${values.mobileNo}`,
                roleTitle: 'CLIENT',
                // createdbyId: userData?.userId,
                createdbyId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
                image: imageData,
                // providerId: userData?.roleTitle === roleNames.STAFFroleTitle ? userData?.createdbyId : userData?.userId,
                // staffId: userData?.roleTitle === roleNames.STAFFroleTitle ? userData?.userId : null,
                providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
                staffId: userData?.roleTitle != roleNames.SERVICE_PROVIDER ? userData?.userId : null,
                relationName: 'Owner',
                timeZone: timeZone
            }
            // console.log(data, 'llllll') 
            // return
            setSpinner(true)
            axios.post(addClientApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    getClientList()
                    addClient.resetForm()
                    setShowClient(false)
                    setSpinner(false)
                    setImageData('')
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            }).catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
        },
    })
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilter((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };
    const invitationReSend = useFormik({
        initialValues: {
            oldEmail: '',
            email: ''
        },
        validationSchema: invitationReSendValidation,
        onSubmit: (values) => {
            const data = {
                old_email: values.oldEmail,
                new_email: values.email,
                roleTitle: 'CLIENT',
                userId: clientDetail._id
            }
            setSpinner(true)
            axios.post(reSendInvitation(), data, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    invitationReSend.resetForm()
                    setInvitationShow(false)
                    setSpinner(false)
                    getClientList()
                }
                else {
                    setSpinner(false)
                    toast.error(resp.data.message)
                }
            }).catch(() => {
                toast.error(commonMessage.networkError)
                setSpinner(false)
            })
        }
    })
    const permission = () => {
        const data = {
            chat: availCheck?.chat,
            userId: clientDetail._id,
            roletitle: 'CLIENT'
        }
        axios.post(staffPermission(), { ...data }).then((resp) => {
            if (resp?.data?.code === statusCodes.ok) {
                toast.success(resp?.data?.message)
                setAvailCheck((pre) => ({
                    ...pre, isActive: false
                }))
                getClientList()
            }
            else {
                toast.error(resp?.data?.message)
            }
        }).catch(()=>{})
    }
    return {
        permission,
        handleInputChange,
        changeClientStatus,
        navigate,
        setImageData,
        setListData,
        setClientDetail,
        setIsActive,
        setInvitationShow,
        setDeletedAccountShow,
        setAvailCheck,
        setSpinner,
        setShowClient,
        setFilter,
        setCount,
        setPage,
        getClientList,
        setImageData,
        setClientArchive,
        clientArchive,
        // clientExist,
        timeZone,
        invitationReSend,
        addClient,
        date,
        imageData,
        listData,
        clientDetail,
        spinner,
        showClient,
        filter,
        count,
        page,
        PER_PAGE,
        isActive,
        exceptThisSymbols,
        invitationShow,
        deletedAccountShow,
        userData,
        availCheck,
        imgData,
        roleNames,
        timeZone,
        userInfo,
    }
}

export default UseClient