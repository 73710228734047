import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import DummyLogo from '../../Assets/images/dummyimg.png'
import ScrollBars from 'react-scrollbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from '../../Components/spinner/spinner'
import Clip from '../../Assets/Video/Color Matte white.mp4';
import UseProfile from './Hooks/useProfile'
import html2canvas from 'html2canvas';
import Button from '@mui/material/Button';
import { environment } from '../../Context/environment'
import DownloadIcon from '@mui/icons-material/Download';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input'
import UseImage from '../../Hooks/useImage'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NumberInput from '../../Components/PhoneInput/phoneInput'
import { Backdrop } from '@mui/material'

const Profile = () => {
  const {
    qrCode,
    countryListData,
    stateListData,
    getBranchList,
    branchDelete,
    getBranchListDetails,
    setBranchId,
    setShowBranch,
    setShow,
    setDisableBranch,
    setTab,
    setChangeEmailShow,
    updateEmail,
    setDisabled,
    setOtp,
    emailVerify,
    getBasicProfileDetails,
    setFile,
    getBusinessProfileDetails,
    setBranchImg,
    branchImg,
    branchId,
    showQr,
    setShowQr,
    deleteBranch,
    setDeleteBranch,
    disabled,
    changeEmailShow,
    EditBranchForm,
    addBranchForm,
    businessProfileForm,
    basicProfileForm,
    userData,
    file,
    country,
    qrCodeData,
    showBranch,
    show,
    stateList,
    branchListArr,
    disableBranch,
    tab,
    otp,
    spinner,
    imgData,
    branchImgPath,
    userInfo,
    fileInputRef,
  } = UseProfile();
  const [base64Image, setBase64Image] = useState("");
  const { imageDelete, uploadImage, imageDeleteUpload } = UseImage();

  useEffect(() => {
    const imgData = environment.baseUrl + "/upload/profiles/thumbnail/";
    const file = userInfo?.image;

    const imageUrl = imgData + file;

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setBase64Image(base64data);
        };
      });
  }, [userInfo]);

  const handleDownload = () => {
    const container = document.querySelector(".qr-code-container");
    html2canvas(container, {
      width: container.offsetWidth,
      height: container.offsetHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "qrcode.png";
      link.click();
    });
  }
  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="cus-padd cusheight m-t-3">
          <h2 className="page-tittle">Profile</h2>
          <hr />
          <nav className="mb-3">
            <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
              <button
                className={` ${tab === 1 ? "activeNav" : "unActiveNav"
                  } border-0`}
                onClick={() => setTab(1)}
                type="button"
              >
                Basic Details
              </button>

              {userData?.roleTitle === 'SERVICE_PROVIDER' ? <button
                onClick={() => {
                  getBranchList()
                  setTab(2)
                  getBusinessProfileDetails()
                }}
                className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`}
                type="button"

              >
                Business Details
              </button>
                : null}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            {/* Basic Profile Start */}
            {tab === 1 && (
              <>
                <div className="text-center mb-4">
                  <img src={file === '' ? DummyLogo : imgData + file} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                  <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  </svg>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) =>uploadImage(e.target.files[0], "PROFILE", setFile, fileInputRef)}
                    />
                    <Button
                      variant="text"
                      sx={{
                        color: "black",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                      onClick={() =>
                        document.getElementById("icon-button-file").click()
                      }
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>

                    <br />
                    {file === "" ? null : (
                      <Button
                        variant="text"
                        sx={{
                          color: "black",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                        onClick={() =>
                          imageDelete(
                            userInfo?.userId,
                            file,
                            "PROFILE",
                            getBasicProfileDetails
                          )
                        }
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    )}
                  </ul>
                </div>
                <form
                  className="row g-3 cus-form"
                  onSubmit={basicProfileForm.handleSubmit}
                >
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        pattern="[A-Za-z ]*"
                        title="Please enter only alphabetic characters"
                        name="name"
                        onChange={basicProfileForm.handleChange}
                        onBlur={basicProfileForm.handleBlur}
                        value={basicProfileForm.values.name}
                        maxLength={30}
                      />
                      <i className="fa-solid fa-user"></i>
                      <ValidationMessage
                        formName={basicProfileForm}
                        name={"name"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">E-mail Address *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={(e) => {
                          basicProfileForm.setFieldValue(
                            "email",
                            e.target.value
                          );
                          if (e.target.value === userInfo.email) {
                            setDisabled(false);
                          } else {
                            setDisabled(true);
                          }
                        }}
                        onBlur={basicProfileForm.handleBlur}
                        value={basicProfileForm.values.email}
                      />
                      <i className="fa-solid fa-envelope"></i>
                      <ValidationMessage
                        formName={basicProfileForm}
                        name={"email"}
                      />
                      {!disabled ? null : (
                        <div className="d-flex justify-content-end mt-1">
                          <Button
                            variant="text"
                            disabled={!disabled}
                            sx={{ color: "black", fontSize: "12px" }}
                            onClick={() => updateEmail()}
                          >
                            Change Email
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Mobile Number *</label>
                      <NumberInput
                        width={false}
                        Require={false}
                        Name="mobileNo"
                        HandleChange={basicProfileForm}
                        Value={basicProfileForm.values.mobileNo}
                        disabledInput={true}
                      />
                      <i className="fa-solid fa-phone"></i>
                      <ValidationMessage
                        formName={basicProfileForm}
                        name={"mobileNo"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  {userData?.roleTitle === "SERVICE_PROVIDER" ? (
                    <div className="d-grid  justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end ">
                      <Button
                        variant="text"
                        sx={{ color: "black", fontSize: "12px" }}
                        onClick={() => {
                          qrCode();
                          setShowQr(true);
                        }}
                      >
                        Show QR code
                      </Button>
                    </div>
                  ) : null}
                  <div className="d-grid  col-12  mb-3 justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                    <button
                      className="btn colorbtun sidebtn px"
                      type="submit"
                      disabled={disabled}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </>
            )}
            {/* Basic Profile End */}

            {/* Business Profile Start */}
            {tab === 2 && (
              <>
                <form
                  className="row g-3 cus-form"
                  onSubmit={businessProfileForm.handleSubmit}
                >
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Business Name *</label>
                      <input
                        type="text"
                        className="form-control "
                        name="businessName"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.businessName}
                        maxLength={50}
                      />
                      <ValidationMessage
                        formName={businessProfileForm}
                        name={"businessName"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">Business Category *</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Default select example"
                        name="businessCategory"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.businessCategory}
                      />
                      <ValidationMessage
                        formName={businessProfileForm}
                        name={"businessCategory"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 inputwithicon">
                      <label className="form-label">About *</label>
                      <textarea
                        cols="30"
                        rows="2"
                        className="form-control "
                        style={{ resize: "none", height: "100px" }}
                        maxLength={300}
                        name="about"
                        onChange={businessProfileForm.handleChange}
                        onBlur={businessProfileForm.handleBlur}
                        value={businessProfileForm.values.about}
                      ></textarea>
                      <ValidationMessage
                        formName={businessProfileForm}
                        name={"about"}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="form-label" style={{ marginTop: "6px" }}>
                      Add Branch
                    </label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">Add Branch</Tooltip>
                      }
                    >
                      <p
                        className="btn addbtn "
                        onClick={() => {
                          countryListData();
                          setShowBranch(true);
                        }}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </p>
                    </OverlayTrigger>
                  </div>
                  <hr />
                  <ScrollBars
                    horizontal
                    autoHide={false}
                    style={{
                      height: "250px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    smoothScrolling={true}
                  >
                    {branchListArr?.map((item, i) => (
                      <div className="col-md-12" key={i}>
                        <p
                          className={`form-control ${item.isActive === false
                            ? `bg-dark bg-opacity-10 shadow-none`
                            : null
                            }`}
                        >
                          <i className="fa-solid fa-location-dot"></i> &nbsp;{" "}
                          <b>{item.name}</b>
                          <br />
                          {item.city}
                          <span
                            style={{
                              float: "right",
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setBranchId(item._id);
                              setDeleteBranch(true);
                            }}
                          >
                            Delete
                          </span>
                          <span
                            style={{ float: 'right', fontSize: '18px', cursor: 'pointer', marginRight: '20px' }}
                            onClick={() => {
                              getBranchListDetails(item._id);
                              countryListData();
                              setShow(true);
                            }}
                          >
                            Edit
                          </span>
                        </p>
                      </div>
                    ))}
                  </ScrollBars>
                  <div className="d-grid  col-12  mb-5 justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                    <button className="btn colorbtun sidebtn px" type="submit">
                      Update
                    </button>
                  </div>
                </form>
              </>
            )}
            {/* Business Profile End */}
          </div>
        </div>
        {/* Add Branch Start */}
        <Backdrop open={showBranch}>
          <Offcanvas
            show={showBranch}
            onHide={() => setShowBranch(false)}
            placement="end" backdrop={false}
          >
            <div style={{ padding: "10px" }}>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  setShowBranch(false);
                  addBranchForm.resetForm(); setBranchImg('');
                }}
              ></button>
              <h1
                className="popupheader text-center heading-color "
                id="offcanvasRightLabel"
              >
                Add Branch
              </h1>
            </div>
            <div className="text-center mb-4">
              <img src={branchImg === '' ? DummyLogo : branchImgPath + branchImg} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
              <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
              </svg>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <input
                  accept="image/*"
                  id="addBranch"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => uploadImage(e.target.files[0], 'BRANCH', setBranchImg, fileInputRef)}
                />
                <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('addBranch').click()} startIcon={<EditIcon />}>Edit</Button>

                <br />
                {branchImg === '' ? null : (
                  <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(branchImg, 'BRANCH', setBranchImg)} startIcon={<DeleteIcon />}>Delete</Button>
                )}
              </ul>
            </div>
            <div className="offcanvas-body">
              <form className="cus-form" onSubmit={addBranchForm.handleSubmit}>
                <div className="mb-2">
                  <label className="form-label">Branch Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    maxLength={30}
                    name="name"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.name}
                  />
                  <ValidationMessage formName={addBranchForm} name={"name"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Building *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="building"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                  />
                  <ValidationMessage formName={addBranchForm} name={"building"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Street *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="street"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.street}
                  />
                  <ValidationMessage formName={addBranchForm} name={"street"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Country *</label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    name="country"
                    onChange={(e) => {
                      addBranchForm.setFieldValue("country", e.target.value);
                      stateListData(e.target.value);
                    }}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.country}
                  >
                    <option value='' hidden>Select Country</option>
                    {country.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <ValidationMessage formName={addBranchForm} name={"country"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">State *</label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    name="state"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.state}
                  >
                    <option value='' hidden>Select State</option>
                    {stateList.map((item, i) => (
                      <option value={item._id} key={i}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <ValidationMessage formName={addBranchForm} name={"state"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">City *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="city"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.city}
                  />
                  <ValidationMessage formName={addBranchForm} name={"city"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Zip Code *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="zipCode"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.zipCode}
                    maxLength={10}
                  />
                  <ValidationMessage formName={addBranchForm} name={"zipCode"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">E-mail Address *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="email"
                    onChange={addBranchForm.handleChange}
                    onBlur={addBranchForm.handleBlur}
                    value={addBranchForm.values.email}
                  />
                  <ValidationMessage formName={addBranchForm} name={"email"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Mobile Number *</label>
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="mobileNo"
                    HandleChange={addBranchForm}
                    Value={addBranchForm.values.mobileNo}
                  />
                  <ValidationMessage formName={addBranchForm} name={"mobileNo"} />
                </div>
                <div className="mb-5">
                  <label className="form-label">Alternate Mobile Number</label>
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="alternateMobileNo"
                    HandleChange={addBranchForm}
                    Value={addBranchForm.values.alternateMobileNo}
                  />
                </div>
                <div className="d-grid  col-6 mx-auto mb-5">
                  <button className="btn colorbtun popupbtn px" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas>
        </Backdrop>
        {/* Add Branch End */}

        {/* Edit Branch Start */}
        <Backdrop open={show}>
          <Offcanvas show={show} onHide={() => setShow(false)} placement="end" backdrop={false}>
            <div style={{ padding: "10px" }}>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShow(false);
                  EditBranchForm.resetForm();
                  ; setBranchImg('')
                }}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
              <h1
                className="popupheader text-center heading-color "
                id="offcanvasRightLabel"
              >
                Edit Branch
              </h1>
            </div>
            <div className="text-center mb-4">
              <img src={branchImg === '' || branchImg === null || branchImg === undefined ? DummyLogo : branchImgPath + branchImg} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
              <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
              </svg>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <input
                  accept="image/*"
                  id="editBranch"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => uploadImage(e.target.files[0], 'BRANCH', setBranchImg, fileInputRef)}
                />
                <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('editBranch').click()} startIcon={<EditIcon />}>Edit</Button>

                <br />
                {branchImg === '' || branchImg === null || branchImg === undefined ? null : (
                  <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(branchId, branchImg, 'BRANCH', setBranchImg)} startIcon={<DeleteIcon />}>Delete</Button>
                )}
    
              </ul>
            </div>
            <div className="offcanvas-body">
              <form className="cus-form" onSubmit={EditBranchForm.handleSubmit}>
                <div className="mb-2">
                  <label className="form-label">Branch Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    maxLength={30}
                    onChange={EditBranchForm.handleChange}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.name}
                  />
                  <ValidationMessage formName={EditBranchForm} name={"name"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Building *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="building"
                    onChange={EditBranchForm.handleChange}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.building}
                  />
                </div>
                <ValidationMessage formName={EditBranchForm} name={"building"} />
                <div className="mb-2">
                  <label className="form-label">Street *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="street"
                    onChange={EditBranchForm.handleChange}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.street}
                  />
                  <ValidationMessage formName={EditBranchForm} name={"street"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Country *</label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    name="countryId"
                    onChange={(e) => {
                      EditBranchForm.setFieldValue("countryId", e.target.value);
                      stateListData(e.target.value);
                    }}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.countryId}
                  >
                    <option value="" hidden>Select Country</option>
                    {country.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <ValidationMessage
                    formName={EditBranchForm}
                    name={"countryId"}
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">State *</label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    name="stateId"
                    onChange={EditBranchForm.handleChange}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.stateId}
                  >
                    <option value="" hidden>Select State</option>
                    {stateList.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <ValidationMessage formName={EditBranchForm} name={"stateId"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">City *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    onChange={EditBranchForm.handleChange}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.city}
                  />
                  <ValidationMessage formName={EditBranchForm} name={"city"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Zip Code *</label>
                  <input type="text" className="form-control" name="zipCode" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.zipCode}
                    maxLength={10}
                  />
                  <ValidationMessage formName={EditBranchForm} name={"zipCode"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">E-mail Address *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="email"
                    onChange={EditBranchForm.handleChange}
                    onBlur={EditBranchForm.handleBlur}
                    value={EditBranchForm.values.email}
                  />
                  <ValidationMessage formName={EditBranchForm} name={"email"} />
                </div>
                <div className="mb-2">
                  <label className="form-label">Mobile Number *</label>
                  {/* <input type="number" className="form-control" placeholder="" name="mobileNumber" onChange={EditBranchForm.handleChange} onBlur={EditBranchForm.handleBlur} value={EditBranchForm.values.mobileNumber} onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxlength={10} /> */}
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="mobileNumber"
                    HandleChange={EditBranchForm}
                    Value={EditBranchForm.values.mobileNumber}
                  />
                  <ValidationMessage
                    formName={EditBranchForm}
                    name={"mobileNumber"}
                  />
                </div>
                <div className="mb-5">
                  <label className="form-label">Alternate Mobile Number</label>
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="alternateMobileNo"
                    HandleChange={EditBranchForm}
                    Value={EditBranchForm.values.alternateMobileNo}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <p>{disableBranch ? "Disable" : "Enable"} Branch</p>
                  <div className="form-check form-switch">
                    {/* <input className="form-check-input" type="checkbox" id="flexSwitchCheck" checked={disableBranch}  data-bs-toggle="modal" data-bs-target="#exampleModal" /> */}
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheck" checked={disableBranch} onChange={() => setDisableBranch(!disableBranch)} />
                  </div>
                </div>
                <div className="d-grid  col-6 mx-auto mb-2">
                  <button
                    className="btn colorbtun popupbtn px"
                    data-bs-dismiss="offcanvas"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas>
        </Backdrop>
        {/* Edit Branch End */}

        {/*  Branch Enable/Disable Start*/}
        {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body text-center">
                <p>Are You Sure !</p>
                <p>
                  You want to {disableBranch ? "disable" : "enable"} this branch
                </p>
                <button
                  type="button"
                  className="btn yesbtn"
                  data-bs-dismiss="modal"
                  onClick={() => setDisableBranch(!disableBranch)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn nobtn ms-1"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  Branch Enable/Disable End*/}

        {/* Qr Code */}
        <Modal size="lg" centered show={showQr} onHide={() => setShowQr(false)}>
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    qrCode();
                    setShowQr(false);
                  }}
                ></button>
              </div>
              <div className="row mb-4">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="text-center ">
                    <h4 className="fw-bold mt-5">Smart Sign-in via QR Code</h4>
                    <div className="qr-code-container">
                      <div className="mb-3 text-light">-</div>
                      <div className="  mt-3">
                        <img
                          src={userInfo?.image === "" ? DummyLogo : base64Image}
                          alt="img"
                          width={50}
                          height={50}
                          style={{ borderRadius: "50%" }}
                        />
                        <p className="mt-1">
                          {businessProfileForm.values.businessName}
                        </p>
                      </div>
                      <div
                        className="shadow p-3 mb-5 bg-body-tertiary rounded  "
                        style={{ width: "60%", marginLeft: "80px" }}
                      >
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: qrCodeData }}
                        />
                      </div>
                      <h6>
                        Your customers can scan this code to schedule
                        appointments with you
                      </h6>
                      {/* <h6>of Iskedo to sign in</h6> */}
                      <div className="mb-2 text-light">-</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      onClick={handleDownload}
                    >
                      Qr Code
                    </Button>
                  </div>
                  <div className="vl phonehide"></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="">
                    <video
                      className="ms-2 qr_video"
                      src={Clip}
                      muted
                      autoPlay={"autoplay"}
                      preLoad="auto"
                      loop
                    />
                    <div className="steps">
                      <h6 className="fw-bold ">Step to sign in</h6>
                      <p>
                        1. Open <span className="fw-bold">Iskedo</span> app on
                        your mobile device.
                      </p>
                      <p>
                        2. On the right hand side on{" "}
                        <span className="fw-bold">Search</span> Click on Scan{" "}
                        <span className="fw-bold">QR option.</span>
                      </p>
                      <p>
                        3. Scan the displayed{" "}
                        <span className="fw-bold">QR code.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div>
        </div> */}
        </Modal>

        {/* Branch Delete Start */}
        <Modal
          size="sm"
          centered
          show={deleteBranch}
          onHide={() => setDeleteBranch(false)}
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p>Are You Sure !</p>
              <p>You Want to Delete Your Branch</p>
              <button
                type="button"
                className="btn yesbtn"
                onClick={() => branchDelete()}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn nobtn ms-1"
                onClick={()=>setDeleteBranch(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        {/* Branch Delete End */}
        {/* Change Email */}
        <Modal
          centered
          show={changeEmailShow}
          onHide={() => setChangeEmailShow(false)}
        >
          <div className="modal-body">
            <p className="text-center mb-3">
              {" "}
              Enter OTP Code sent on your email
            </p>
            <div className="mt-5">
              <div className="pin-code">
                <OtpInput
                  isInputNum
                  value={otp}
                  onChange={(e) => {
                    setOtp(e);
                  }}
                  numInputs={4}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="btn yesbtn mt-5"
                onClick={() => emailVerify()}
              >
                Verify
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default Profile;
