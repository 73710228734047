import React, { useState } from 'react'
import ServiceImg from '../../Assets/images/services-icon.png';
import DummyLogo from '../../Assets/images/dummyimg.png'
import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import UseComboView from './Hooks/useComboView';
import TextField from "@mui/material/TextField";
import { Button, Checkbox } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useMuiComponent from '../../Hooks/useMuiComponent';
import Spinner from '../../Components/spinner/spinner';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ComboView = () => {
    const { data, methods } = UseComboView()
    const {
        allTeam,
        bookAppointment,
        spinner,
        type,
        userData,
        selectedServices,
        selectedSession,
        comboImgPath,
        imgPath,
        show,
        isExpanded,
        termShow,
        comboDetails,
        sessions } = data
    const {
        handleServiceCheckboxChange,
        handleSessionRadioChange,
        setShow,
        toggleReadMore,
        setTermShow
    } = methods
    const { CustomAutocomplete } = useMuiComponent();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);

    let BookShow = true;
    sessions?.forEach(session => {
        const allServicesMatch = session?.services?.every(service => service.count === service.serviceUsed);
        if (!allServicesMatch) {
            BookShow = false;
        }
    });

    return (
        <section>
            <ToastContainer />
            <div className="m-t-3 cus-padd cusheight">
                {spinner && <Spinner />}
                <header>
                    <div className='d-flex justify-content-between'>
                        <h2 className="page-tittle"><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i> Book Appointment</h2>
                    </div>
                    <hr />
                </header>
                <div className='shadow-sm combo_Main pb-0'>
                    <div className='text-center text-light py-1 bgOrange'>Combo Details</div>
                    <div className='col-12 align-items-center outerBox d-lg-flex p-4 boxShadow'>
                        <div className=''>
                            <div className=''>
                                <img className='mt-2' src={comboDetails?.image === '' || comboDetails?.image == null ? DummyLogo : comboImgPath + comboDetails?.image} alt="img" height={140} width={140} style={{ borderRadius: '50%' }} />
                            </div>
                        </div>
                        <div className='w-100 ps-lg-5'>
                            <div className='border-bottom pb-2 align-items-center'>
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <h4 className='fw-bold mt-2'>{comboDetails?.name}</h4>
                                        {comboDetails?.packageCompleted && <img src={require('../../Assets/images/Completed.png')} alt="completed" height={100} width={120} />}
                                    </div>
                                    <div>
                                        {comboDetails?.description?.length > 150 ? (
                                            <>
                                                {isExpanded ? (
                                                    comboDetails?.description
                                                ) : (
                                                    <>
                                                        {comboDetails?.description.substring(0, 150)}
                                                        ...
                                                    </>
                                                )}
                                                <span onClick={toggleReadMore} style={{ color: '#4183DA', cursor: 'pointer' }}>
                                                    {isExpanded ? ' Read Less' : ' Read More'}
                                                </span>
                                            </>
                                        ) : (
                                            comboDetails?.description
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex pt-1">
                                <div className='textLight d-flex'>
                                    <PersonIcon sx={{ fontSize: '27px' }} />
                                    <span className='mt-1 ms-2'>{comboDetails?.clientName}</span>
                                </div>
                                <div className='textLight d-flex ps-4'>
                                    <PaidIcon sx={{ fontSize: '27px' }} />
                                    <span className='mt-1 ms-2'>{userData?.currencySymbol} {comboDetails?.price}</span>
                                </div>
                                <div className='textLight d-flex ps-4'>
                                    <AccessTimeFilledIcon sx={{ fontSize: '27px' }} />
                                    <span className='mt-1 ms-2'>{comboDetails?.validity} Days</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button variant="text" onClick={() => setTermShow(true)}>Terms & Conditions</Button>
                    </div>
                </div>
                {
                    show ?
                        <div>
                            <div className="d-flex flex-wrap">
                                {!type ? sessions?.map((item, index) => <div className="d-flex col-lg-4" key={index}>
                                    <div className="w-100 ms-4">
                                        <h6 className={`${selectedSession === item?.session_number && 'blurColor'} py-3 fw-bold`}>Session {item?.session_number}</h6>
                                        <div className={`${selectedSession === item?.session_number ? 'sessions2' : 'sessions'} shadow-sm container`}>
                                            {
                                                item?.services?.map((service, i) =>
                                                    <>
                                                        <div className='d-flex justify-content-between  my-2'>
                                                            <div className='d-flex'>
                                                                <img className='mt-2' src={service.service_image === '' ? ServiceImg : imgPath + service.service_image} alt="img" height={40} width={40} style={{ borderRadius: '50%' }} />
                                                                <h6 className='ms-3 fw-bold mt-3'>{service?.service_name}</h6>
                                                            </div>
                                                        </div>
                                                        {item?.services.length - 1 === i ? "" : <hr className='m-0' />}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                ) :
                                    <div className="w-100">
                                        <h6 className='py-3 fw-bold'>Services</h6>
                                        <div className='sessions shadow-sm '>
                                            {sessions?.map((session, index) => (
                                                session?.services?.map((service, serviceIndex) => <>
                                                    <div className='d-flex justify-content-between p-1'>
                                                        <div className='d-flex align-items-center'>
                                                            <img className='ms-4' src={service.service_image === '' || service.service_image === null ? ServiceImg : imgPath + service.service_image} alt="img" height={40} width={40} style={{ borderRadius: '50%' }} />
                                                            <h6 className='ms-2 fw-bold mb-0' >{service.serviceName || service?.service_name}</h6>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <span>{service?.serviceUsed}/{service?.count} </span>
                                                        </div>
                                                    </div>
                                                    {session?.services.length - 1 === serviceIndex ? "" : <hr className='m-0' />}
                                                </>)))
                                            }
                                        </div>
                                    </div>}
                            </div>
                            {
                                !BookShow &&
                                <div className='d-flex justify-content-end w-100 mt-4'>
                                    <button type="submit" className="btn colorbtun" onClick={() => setShow(false)}>
                                        Book Appointment
                                    </button>
                                </div>
                            }
                        </div>
                        :
                        <div className="row mt-2">
                            <div className="col-lg-7 session_Scroll">

                                {
                                    !type ?
                                        sessions?.map((item, index) =>
                                            <div className="d-flex" key={index}>
                                                <input type="radio" name="session" checked={selectedSession === item?.session_number} onChange={() => handleSessionRadioChange(item?.session_number)}
                                                    disabled={item?.services?.every((ser) => ser.count === ser.serviceUsed)} />
                                                <div className="w-100 ms-4">
                                                    <h6 className={`${selectedSession === item?.session_number && 'blurColor'} py-3 fw-bold`}>Session {item?.session_number}</h6>
                                                    <div className={`${selectedSession === item?.session_number ? 'sessions2' : 'sessions'} shadow-sm container`}>
                                                        {
                                                            item?.services?.map((service, i) =>
                                                                <>
                                                                    <div className='d-flex justify-content-between  my-2'>
                                                                        <div className='d-flex'>
                                                                            <img className='mt-2' src={service.service_image === '' || service.service_image === null ? ServiceImg : imgPath + service.service_image} alt="img" height={40} width={40} style={{ borderRadius: '50%' }} />
                                                                            <h6 className='ms-3 fw-bold mt-3'>{service?.service_name}</h6>
                                                                        </div>
                                                                        <input type="checkbox" className='mt-2'
                                                                            checked={selectedServices?.includes(service.service_id) || false}
                                                                            onChange={() => handleServiceCheckboxChange(service.serviceId || service?.service_id, item?.session_number)}
                                                                            disabled={selectedSession !== item?.session_number} />
                                                                    </div>
                                                                    {item?.services.length - 1 === i ? "" : <hr className='m-0' />}
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <div className="w-100">
                                            <h6 className='py-3 fw-bold'>Services</h6>
                                            <div className='sessions shadow-sm container'>
                                                {sessions?.map((session, index) => (
                                                    session?.services?.map((service, serviceIndex) => <>
                                                        <div className='d-flex justify-content-between p-1'>
                                                            <div className='d-flex  align-items-center'>
                                                                <input type="checkbox" className='mt-2' id='1'
                                                                    checked={selectedServices?.includes(service.service_id) || service?.count === service.serviceUsed || false}
                                                                    onChange={() => handleServiceCheckboxChange(service?.serviceId || service?.service_id)}
                                                                    disabled={service?.count === service.serviceUsed ? true : false}
                                                                />
                                                                <img className='ms-4' src={service.service_image === '' || service.service_image === null ? ServiceImg : imgPath + service.service_image} alt="img" height={40} width={40} style={{ borderRadius: '50%' }} />
                                                                <h6 className='ms-3 fw-bold mb-0'>{service.serviceName || service?.service_name}</h6>
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                Available - {service?.count}
                                                                <span className='ms-2'>Used - {service?.serviceUsed} </span>
                                                            </div>
                                                        </div>
                                                        {session?.services.length - 1 === serviceIndex ? "" : <hr className='m-0' />}
                                                    </>)))
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="col-lg-5 mt-2" >
                                <div className='phonehide v2'></div>
                                <div className='row '>
                                    <div className='position-relative' style={{ height: "560px" }}>
                                        <form className="cus-form">
                                            <div className="mb-3 w-100 mt-5 ms-4 pe-4 col-lg-6">

                                                <div>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DateTimePicker']}>
                                                            <DateTimePicker label="Start Time" name="startTime" value={bookAppointment.values.startTime}
                                                                onChange={(e) => { bookAppointment.setFieldValue("startTime", e) }} />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                            <div className="mb-3 w-100  ms-4 pe-4 col-lg-6">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DateTimePicker']}>
                                                        <DateTimePicker label="End Time" name="endTime" value={bookAppointment.values.endTime}
                                                            onChange={(e) => { bookAppointment.setFieldValue("endTime", e) }} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            {
                                                userData?.roleTitle === "STAFF" ? null
                                                    :
                                                    <div className="mb-3 ms-4 pe-4 col-lg-12">
                                                        <label className="form-label">Team *</label>
                                                        <CustomAutocomplete multiple className="w-100" id="checkboxes-tags-demo" options={allTeam}
                                                            onChange={(event, newValue) => { bookAppointment.setFieldValue("team", newValue) }}
                                                            getOptionLabel={(option) => option?.name}
                                                            name='team'
                                                            value={bookAppointment.values.team}
                                                            size="small"
                                                            open={open}
                                                            onOpen={() => setOpen(true)}
                                                            onClose={() => setOpen(false)}
                                                            sx={{ background: '#fcf6f3', boxShadow: '0px 1px 3px #fbbf9e', }}
                                                            disableCloseOnSelect
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}>
                                                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                                                    {option.name}
                                                                </li>
                                                            )}
                                                            style={{ width: 500 }}
                                                            renderInput={(params) => (<TextField {...params} label=" " style={{ color: "red" }} InputLabelProps={{ shrink: false }} />)}
                                                            popupIcon={<IoIosArrowDown style={{ fontSize: "17px", fontWeight: "800", color: "#000" }} />}
                                                            disableClearable
                                                        />
                                                    </div>
                                            }

                                        </form>
                                        <div className='' style={{ position: 'absolute', bottom: '50px', left: "250px" }} >
                                            <button type="submit" onClick={bookAppointment.handleSubmit} className="btn colorbtun py-2" >
                                                Book Appointment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
            <Modal size='lg' show={termShow} onHide={() => setTermShow(false)} centered>
                <Modal.Body>
                    <h3 className='fw-bold'>Terms & Conditions</h3>
                    <div className='border p-3'>
                        {comboDetails?.term_condition}
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default ComboView
