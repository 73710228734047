import { deleteImage, deleteImageFromFolder, imageUploadApi } from '../Context/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { commonMessage, statusCodes } from '../Constants/generalConfig';
import { useContext } from 'react';
import { Notifications } from '../App';
import { useLocation } from 'react-router-dom';

const UseImage = () => {
    const { setUserInfo } = useContext(Notifications)
    const { pathname } = useLocation();

    const splitLocation = pathname.split("/");

    const uploadImage = (file, type, setImageData, fileInputRef) => {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('type', type);
        axios.post(imageUploadApi(), fd).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setImageData(resp.data.data.imagePath);
                if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            }
            else {
                toast.error(resp.data.message);
            }
        });
    };
    const imageDelete = (userId, fileName, type, onView, setImage) => {
        const data = {
            userId: userId,
            fileName: fileName,
            type: type,
        };

        axios.post(deleteImage(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message);
                if (onView) {
                    onView();
                }
                else {
                    setImage('')
                }

                if (splitLocation[2] == 'profile') {
                    let data = JSON.parse(localStorage.getItem('userData'))
                    let updateData = { ...data }
                    updateData.image = ''
                    localStorage.setItem("userData", JSON.stringify(updateData))
                    setUserInfo(JSON.parse(localStorage.getItem('userData')))
                }
            } else {
                toast.error(resp.data.message);
            }
        })
            .catch(() => {
                toast.error(commonMessage.networkError);
            });
    };
    const imageDeleteUpload = (fileName, type, setImage) => {
        const data = {
            fileName: fileName,
            type: type,
        };

        axios.post(deleteImageFromFolder(), { ...data })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message);
                    setImage('')
                } else {
                    toast.error(resp.data.message);
                }
            })
            .catch(() => {
                toast.error(commonMessage.networkError);
            });
    };

    return {
        uploadImage,
        imageDelete,
        imageDeleteUpload
    }
}

export default UseImage