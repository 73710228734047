export const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#!%*?&])[A-Za-z\d$@$!%*?&]{6,}/;
// export const whiteSpace = /^(?![\s-])[\w\s-]+$/;
export const whiteSpace = /^(?!\s).+(?<!\s)$/;
export const backspace = /^((?!\s{2,}).)*$/;
export const phone = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
export const email = /^(([^<>()\[\]\\.,,:\s@"]+(\.[^<>()\[\]\\.,,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const pin= "(?=(.*?[0-9]){4})" 
// export maxLenght='MAXLENGTH: 50,


// only alphabets allow
// export const name=/^[A-Za-z]+$/
// export const name=/\d+/
export const name=/^([^0-9]*)$/

