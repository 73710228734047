import React, { Fragment, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import moment from 'moment/moment'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Spinner from '../../Components/spinner/spinner'
import DummyLogo from '../../Assets/images/dummyimg.png'
import Paginations from '../../Components/Pagination/pagination'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord'
import UseTeamProfile from './Hooks/useTeamProfile'
import AddTeamAvailability from './addTeamAvailability'
import EditTeamAvailability from './editTeamAvailability'
import AddTeamUnAvailability from './addTeamUnAvailability'
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { IoIosArrowDown } from 'react-icons/io';
import UseImage from '../../Hooks/useImage';
import useMuiComponent from '../../Hooks/useMuiComponent';
import NumberInput from '../../Components/PhoneInput/phoneInput';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { intialRole } from '../../Utils/Utils';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TeamProfile = () => {
  const { timeZone,
    unAvailabilityList,
    unavailabilityDelete,
    getAvailabilityList,
    getAvailability,
    valueChangeToggleSwitch,
    showAdditionalTimingsBox,
    hideAdditionalTimingsBox,
    closeToggleBtn,
    StaffBranch,
    getBranchList,
    setSelectAll,
    setPermissionBtn,
    permissionBtn,
    setShowAvailability,
    setShowUnAvailability,
    setSpinner,
    setPage,
    setDisableBranch,
    setTab,
    navigate,
    profileView,
    setImageData,
    handlePermissionChange,
    handleMarkAll,
    permissionsCheck,
    showEditAvailability,
    setShowEditAvailability,
    handleTogglePermissions,
    selectAll,
    permissions,
    setUnavailability,
    formik,
    editAvailability,
    teamProfile,
    defaultFormValue,
    listData,
    availablityData,
    timeZoneData,
    unAvailablityData,
    showAvailability,
    showUnAvailability,
    imageData,
    branchList,
    serviceList,
    staffBranchesData,
    spinner,
    count,
    page,
    PER_PAGE,
    disableBranch,
    tab,
    date,
    imgData,
    staffId,
    roleList,
    fileInputRef,
    userInfo } = UseTeamProfile()
  const { imageDelete, uploadImage } = UseImage()
  const { CustomAutocomplete } = useMuiComponent()
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [open, setOpen] = useState(false);
  const [ServiceOpen, setServiceOpen] = useState(false);

  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd cusheight" style={{ borderRadius: '15px' }}>
          {spinner ? <Spinner /> : null}
          <h2 className="page-tittle">
            <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Team Member
          </h2>
          <hr />
          <nav className="mb-3">
            <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
              <button className={` ${tab === 1 ? 'activeNav' : 'unActiveNav'} border-0`} onClick={() => setTab(1)} id="basicdetails" type="button">
                Profile
              </button>
              {
                intialRole({ permission: "team", userData: userInfo, section: 'availability' }) ?
                  <button
                    className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`}
                    type="button"
                    onClick={() => { getAvailabilityList(); timeZone(); setSpinner(true); setTab(2); setPermissionBtn(false) }}
                  >
                    Availability
                  </button>
                  : null
              }
              {
                intialRole({ permission: "team", userData: userInfo, section: 'unavailability' }) ?
                  <button
                    className={` ${tab === 3 ? 'activeNav' : 'unActiveNav'} border-0`}
                    type="button"
                    onClick={() => { unAvailabilityList(); timeZone(); setSpinner(true); setTab(3); setPermissionBtn(false) }}
                  >
                    Unavailability
                  </button>
                  : null}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            {/* Profile Start*/}
            {
              tab === 1 &&
              <>
                <div className="text-center mb-4">
                  <img src={(imageData === '' || imageData === null) ? DummyLogo : imgData + imageData} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                  <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  </svg>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <input ref={fileInputRef}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => uploadImage(e.target.files[0], 'PROFILE', setImageData, fileInputRef)}
                    />
                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>
                    <br />
                    {imageData === '' || imageData === null || imageData === 'null' ? null : (
                      <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(staffId, imageData, 'PROFILE', profileView)} startIcon={<DeleteIcon />}>Delete</Button>
                    )}
                  </ul>
                </div>
                <form className="row g-3 cus-form" onSubmit={teamProfile.handleSubmit}>
                  <div className="col-md-6">
                    <label className="form-label">Name *</label>
                    <input type="text" className="form-control" pattern="[A-Za-z ]*" title="Please enter only alphabetic characters" name="name" value={teamProfile.values.name} onChange={teamProfile.handleChange} />
                    <ValidationMessage formName={teamProfile} name={'name'} />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">E-mail Address {currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? null : '*'}</label>
                    <input type="email" className="form-control" name="email" disabled value={teamProfile.values.email} onChange={teamProfile.handleChange} />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">DOB</label>
                    <input type="date" className="form-control" name="DOB" value={moment(teamProfile.values.DOB).format('YYYY-MM-DD')} onChange={teamProfile.handleChange}
                      max={date} />
                    {/* <ValidationMessage formName={teamProfile} name={'DOB'} /> */}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Mobile Number *</label>
                    {/* <input type="text" className="form-control" name="mobileNo" disabled value={teamProfile.values.mobileNo} onChange={teamProfile.handleChange} /> */}
                    <NumberInput
                      width={false}
                      Require={false}
                      Name="mobileNo"
                      HandleChange={teamProfile}
                      Value={teamProfile.values.mobileNo}
                      disabledInput={true}
                    />
                    <ValidationMessage formName={teamProfile} name={'mobileNo'} />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Gender *</label>
                    <select className="form-select form-control" aria-label="Default select example" name="gender" value={teamProfile.values.gender} onChange={teamProfile.handleChange}>
                      <option value='' hidden>Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                    <ValidationMessage formName={teamProfile} name={'gender'} />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Role * </label>
                    <select className="form-select form-control" name="roleTitle" disabled onChange={(e) => { teamProfile.setFieldValue('roleTitle', e.target.value); }} value={teamProfile.values.roleTitle}>
                      <option value="" hidden>
                        Select Role
                      </option>
                      {
                        roleList?.map((item, index) =>
                          <option value={item._id} key={index}>{item.title == 'Staff' ? 'Team' : item.title}</option>
                        )
                      }
                    </select>
                    <ValidationMessage formName={teamProfile} name={"roleTitle"} />
                  </div>
                  {/* <div className="col-sm-6"></div> */}

                  <div className="col-md-6">
                    <label className="form-label">Assigned Services *</label>
                    <CustomAutocomplete multiple className="w-100" id="checkboxes-tags-demo" options={serviceList}
                      onChange={(event, newValue) => {
                        teamProfile.setFieldValue("serviceData", newValue);
                      }}
                      open={ServiceOpen}
                      onOpen={() => setServiceOpen(true)}
                      onClose={() => setServiceOpen(false)}
                      groupBy={(option) => option?.categoryName}
                      getOptionLabel={(option) => option?.name}
                      value={teamProfile.values.serviceData}
                      sx={{ background: '#fcf6f3', boxShadow: '0px 1px 3px #fbbf9e' }}
                      size="small"
                      disableCloseOnSelect
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                          {option.name}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (<TextField {...params} label=" " style={{ color: "red" }} InputLabelProps={{ shrink: false }} />)}
                      popupIcon={<IoIosArrowDown style={{ fontSize: "17px", fontWeight: "800", color: "#000" }} />}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                    />
                  </div>
                  <div className="col-sm-6 mb-2">
                    <label className="form-label">Branch *</label>
                    <CustomAutocomplete multiple className="w-100" id="checkboxes-tags-demo" options={branchList}
                      onChange={(event, newValue) => {
                        teamProfile.setFieldValue("branchData", newValue);
                      }}
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      sx={{ background: '#fcf6f3', boxShadow: '0px 1px 3px #fbbf9e' }}
                      getOptionLabel={(option) => option?.name}
                      value={teamProfile.values.branchData}
                      size="small"
                      disableCloseOnSelect
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                          {option.name}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (<TextField {...params} label=" " style={{ color: "red" }} InputLabelProps={{ shrink: false }} />)}
                      popupIcon={<IoIosArrowDown style={{ fontSize: "17px", fontWeight: "800", color: "#000" }} />}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    />
                  </div>
                  <div className="ex2">
                    {
                      permissionBtn ?
                        <Button size='small' id="togglePermissions" variant="contained" style={{ backgroundColor: '#f47d3a' }} onClick={() => { setPermissionBtn(false); handleTogglePermissions() }} endIcon={<ArrowUpwardIcon />}>
                          Permissions
                        </Button>
                        :
                        <Button size='small' id="togglePermissions" variant="contained" style={{ background: '#f47d3a' }} onClick={() => { setPermissionBtn(true); permissionsCheck(); handleTogglePermissions() }} endIcon={<ArrowDownwardIcon />}>
                          Permissions
                        </Button>
                    }
                    <input type="checkbox" name="rwo" id="item-2" style={{ display: 'none' }} />
                    <div className="inner">
                      <div className="hide2">
                        <div className="row my-3">
                          <div className="col-sm-12 d-flex justify-content-end">
                            {
                              selectAll ?
                                <Button size='small' id="togglePermissions" onClick={() => { handleMarkAll(true); setSelectAll(false) }} style={{ color: 'black' }} variant="text">
                                  Mark All
                                </Button>
                                :
                                <Button size='small' id="togglePermissions" onClick={() => { handleMarkAll(false); setSelectAll(true) }} style={{ color: 'black' }} variant="text">
                                  Unmark All
                                </Button>
                            }
                          </div>
                          {permissions?.map((category) => {
                            return (
                              <span className={"col-3 mt-2"} key={category?.title}>
                                <h5>{category?.title}</h5>
                                <FormGroup>
                                  {category?.permissionData?.map((key, index) => (
                                    <React.Fragment key={index}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={key.data.value}
                                            // value={key.data.value}
                                            onChange={() =>
                                              handlePermissionChange(category.title, key.title)
                                            }
                                          // disabled={(checkRole=='Staff' && (category.title === "Team" || category.title === "Insights" || category.title === "Waiting List" || category.title === "Offers"
                                          //   || category.title === "Service Category" || category.title === "Services" || category.title === "Service Duration"|| category.title === "Currency"))}
                                          />

                                        }
                                        label={key.data.title}
                                      />
                                    </React.Fragment>
                                  ))}
                                </FormGroup>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex">
                      <p>Assign Login</p>
                      <div className="form-check form-switch ms-5">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => setDisableBranch(e.target.checked)} checked={disableBranch} />
                      </div>
                    </div>
                  </div>
                  <div className="d-grid  col-12  mb-3 justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                    <button type="submit" className="btn colorbtun">
                      Update
                    </button>
                  </div>
                </form>

              </>
            }
            {/* Profile End*/}
            {/* < Modal Start*/}
            {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <p>Are You Sure !</p>
                    <p>You want to assign {disableBranch ? 'disable' : 'login'} permission to this member</p>
                    <button type="button" className="btn nobtn" data-bs-dismiss="modal" onClick={() => setDisableBranch(!disableBranch)}>
                      Yes
                    </button>
                    <button type="button" className="btn yesbtn" data-bs-dismiss="modal">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            {/* < Modal End*/}

            {/* {Availability List Start} */}
            {
              tab === 2 &&
              <>
                {
                  !availablityData?.length && tab === 2 ? <NoRecord />
                    :
                    <>
                      <div className="table-responsive mt-4">
                        <table className="table table-bordered availtable cus-table">
                          <thead className="text-center tableheader">
                            <tr>
                              <th scope="col" className="availability_table">Branch</th>
                              <th scope="col" className="availability_table">
                                Sunday
                              </th>
                              <th scope="col" className="availability_table">
                                Monday
                              </th>
                              <th scope="col" className="availability_table">
                                Tuesday
                              </th>
                              <th scope="col" className="availability_table">
                                Wednesday
                              </th>
                              <th scope="col" className="availability_table">
                                Thursday
                              </th>
                              <th scope="col" className="availability_table">
                                Friday
                              </th>
                              <th scope="col" className="availability_table">
                                Saturday
                              </th>
                            </tr>
                          </thead>
                          <tbody className="cus-availability-table-align">
                            {availablityData?.map((item, index) => (
                              <Fragment key={index}>
                                <tr className='cursor' key={index}>
                                  <td key={index}>{item.branchName}</td>
                                  <td>
                                    {item.available_days[0]?.arrayOfTimings?.length ?
                                      item.available_days[0]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'
                                    }
                                  </td>
                                  <td>
                                    {item.available_days[1]?.arrayOfTimings?.length ?
                                      item.available_days[1]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'}
                                  </td>
                                  <td>
                                    {item.available_days[2]?.arrayOfTimings?.length ?
                                      item.available_days[2]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'}
                                  </td>
                                  <td>
                                    {' '}
                                    {item.available_days[3]?.arrayOfTimings?.length ?
                                      item.available_days[3]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'}
                                  </td>
                                  <td>
                                    {item.available_days[4]?.arrayOfTimings?.length ?
                                      item.available_days[4]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'}
                                  </td>
                                  <td>
                                    {item.available_days[5]?.arrayOfTimings?.length ?
                                      item.available_days[5]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'}
                                  </td>
                                  <td>
                                    {item.available_days[6]?.arrayOfTimings?.length ?
                                      item.available_days[6]?.arrayOfTimings.map((time, timingIndex) => {
                                        return (
                                          <ul
                                            onClick={() => {
                                              getBranchList()
                                              getAvailability(item._id)
                                              setShowEditAvailability(true)
                                            }}
                                          >
                                            <span>
                                              {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                            </span>
                                          </ul>
                                        )
                                      })
                                      : '--'}
                                  </td>
                                </tr>
                              </Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className='w-100 d-flex justify-content-center'>
                        {count > 10 &&
                          <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                        }
                      </div>
                    </>
                }

                <div className="d-flex justify-content-end">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Availability</Tooltip>}>
                    <button onClick={() => { setShowAvailability(true); StaffBranch() }} className="btn addbtn2">
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </>
            }
            {/* {Availability List End} */}

            {/* {UnAvailability List Start} */}
            {
              tab === 3 &&
              <>
                {
                  !unAvailablityData?.length && tab === 3 ? <NoRecord />
                    :
                    <>
                      <div className="row mt-4">
                        {unAvailablityData?.map((item, index) => (
                          <React.Fragment key={index}>
                            <div className="col-sm-6" key={item.branchId}>
                              <div className="accordion accordion-flush" id="accordionFlushEx ample">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="branch-un">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={'#un-branch' + item.appointment_number}
                                      aria-expanded="false"
                                      aria-controls="un-branch"
                                    >
                                      {item.branchName}
                                    </button>
                                  </h2>
                                  <div id={'un-branch' + item.appointment_number} className="accordion-collapse collapse" aria-labelledby="branch-un" data-bs-parent="#accordionFlushEx ample">
                                    <div className="accordion-body text-center">
                                      <div className="d-flex justify-content-between">
                                        <p>Start Date: {moment(item.startDateTime).format('DD-MM-YYYY')}</p>
                                        <p>End Date: {moment(item.endDateTime).format('DD-MM-YYYY')} </p>
                                      </div>
                                      {item.timmingArr?.map((times, index) => (
                                        <div className="d-flex justify-content-between" key={index}>
                                          <p>Start Time: {moment(times.startTime).format('hh:mm:A')}</p>
                                          <p>End Time: {moment(times.endTime).format('hh:mm:A')} </p>
                                        </div>
                                      ))}
                                      <div className="d-flex justify-content-between">
                                        <p>Time Zone: {item.timezone}</p>
                                      </div>
                                      <div className="d-flex justify-content-end cursor">
                                        <i className="fa-solid fa-trash-can" data-bs-toggle="modal" data-bs-target="#unavailabilitydelete"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* UnAvailability Delete Start */}
                            <div className="modal fade" id="unavailabilitydelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered modal-sm">
                                <div className="modal-content">
                                  <div className="modal-body text-center">
                                    <p>Are you sure !</p>
                                    <p>You want to delete your unavailability ?</p>
                                    <button type="button" className="btn nobtn" onClick={() => unavailabilityDelete(item.appointment_number)} data-bs-dismiss="modal">
                                      Yes
                                    </button>
                                    <button type="button" className="btn yesbtn" data-bs-dismiss="modal">
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* UnAvailability Delete End */}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className='w-100 d-flex justify-content-center'>
                        {count > 10 &&
                          <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                        }
                      </div>
                    </>
                }

                <div className="d-flex justify-content-end">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Un-Availability</Tooltip>}>
                    <button className="btn addbtn2 " onClick={() => { StaffBranch(); setShowUnAvailability(true) }}>
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </OverlayTrigger>
                </div>
                {/* </div> */}
              </>
            }
            {/* {UnAvailability List End} */}
          </div>
        </div>
      </section>

      <AddTeamAvailability showAvailability={showAvailability} setShowAvailability={setShowAvailability} closeToggleBtn={closeToggleBtn} formik={formik} timeZoneData={timeZoneData}
        defaultFormValue={defaultFormValue} staffBranchesData={staffBranchesData} valueChangeToggleSwitch={valueChangeToggleSwitch} showAdditionalTimingsBox={showAdditionalTimingsBox}
        hideAdditionalTimingsBox={hideAdditionalTimingsBox} />

      <EditTeamAvailability showEditAvailability={showEditAvailability} setShowEditAvailability={setShowEditAvailability} closeToggleBtn={closeToggleBtn} editAvailability={editAvailability} timeZoneData={timeZoneData} defaultFormValue={defaultFormValue} valueChangeToggleSwitch={valueChangeToggleSwitch}
        showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} listData={listData} />

      <AddTeamUnAvailability showUnAvailability={showUnAvailability} setShowUnAvailability={setShowUnAvailability} setUnavailability={setUnavailability} timeZoneData={timeZoneData} staffBranchesData={staffBranchesData}
        showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} defaultFormValue={defaultFormValue} />
    </>
  )
}
export default TeamProfile
