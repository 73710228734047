import React from 'react';
import ForgotLogo from '../../Assets/images/forgot.png';
import { ToastContainer} from "react-toastify";
import ValidationMessage from '../ValidationMessage/validationMessage';
import UseForgotPin from './Hooks/useForgotPin';
import Spinner from '../../Components/spinner/spinner';
import NumberInput from '../../Components/PhoneInput/phoneInput';

const ForgotPin = () => {
    const {
        timeZone,
        formik,
        forgotPinWithEmail,
        spinner
    } = UseForgotPin()

    return (
        <>
            {spinner && <Spinner />}
            <ToastContainer />
            <section>
                <div className="container d-flex justify-content-center align-items-center cusheight">
                    <div className="row shadow_none">
                        <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>
                            <div className="text-center">
                                <img src={ForgotLogo} alt='' /> </div>
                            <h1 className="text-center heading-color mb-3">Forgot PIN</h1>
                            {timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ?
                                <>
                                    <p>Enter your Registered Mobile number to receive  an OTP.</p>
                                    <form className="cus-form" onSubmit={formik.handleSubmit}>
                                        <div style={{ width: '350px', position: "relative" }}>
                                            <i className="fa-solid fa-phone" style={{ position: 'absolute', left: '7%', top: '25%', color: '#acacac' }}></i>
                                            <NumberInput
                                                width={false}
                                                Require={false}
                                                Name="mobileNo"
                                                HandleChange={formik}
                                                Value={formik.values.mobileNo}
                                                countrySelectComponent="null"
                                            />
                                            <ValidationMessage formName={formik} name={'mobileNo'} />
                                        </div>
                                        <div className="d-grid  col-6 mx-auto mb-3">
                                            <button type="submit" className="btn colorbtun px">Continue</button>
                                        </div>
                                    </form>
                                </>
                                :
                                <>
                                    <p>Enter your Registered Email to receive an OTP.</p>
                                    <form className="cus-form" onSubmit={forgotPinWithEmail.handleSubmit}>
                                        <div className="input-field">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                            <input type="email" placeholder='Enter email' name="email" onChange={forgotPinWithEmail.handleChange}
                                                onBlur={forgotPinWithEmail.handleBlur} value={forgotPinWithEmail.values.email} />

                                        </div>
                                        <ValidationMessage formName={forgotPinWithEmail} name={'email'} />
                                        <div className="d-grid  col-6 mx-auto mb-3">
                                            <button type="submit" className="btn colorbtun px" style={{ marginLeft: '-35px' }}>Continue</button>
                                        </div>
                                    </form>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPin;