import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createCoinAppointment, coinList, coinView, coinAppointmentUpdate, coinDuration, spinCondition, coinDurationList, durationView, durationUpdate, conditionUpdate, conditionList, conditionView} from '../../../Context/api';
import axios from 'axios';
import { commonMessage, statusCodes } from '../../../Constants/generalConfig';
import UseOffer from '../../Offer/Hooks/useOffer';

const UseReward = () => {
    const { valuetext,
        handleChange,
        offerView,
        servicesList,
        setImageData,
        setInActive,
        setShow,
        setShowOffer,
        navigate,
        setMale,
        setFemale,
        setOther,
        setRewardOffer,
        rewardOffer, 
        spinner,
        createOffer,
        editOffer,
        value,
        imageData,
        serviceList,
        inActive,
        offerListData,
        selectedService,
        show,
        showOffer,
        male,
        female,
        other,
        date,
        imgFolder,
        offerId,
        fileInputRef,
        zeroOffer,
        setZeroOffer } = UseOffer()
    const userData = JSON.parse(localStorage.getItem('userData'))   
    const [showModal, setShowModal] = useState(false)
    const [spinModal, setSpinModal] = useState(false)
    const [expiryModal, setExpiryModal] = useState(false)
    const [values, setValues] = useState({
        amount: '',
        coin: '',
        coinPerSpin: '',
        coinDeductionPerSpin: '',
        expiry: ''
    })
    const [durationData, setDurationData] = useState([])
    const [coinConditionData, setcoinConditionData] = useState([])
    const [coinListData, setCoinListData] = useState([])

    useEffect(() => {
        durationList()
        coinConditionList()
        listCoin()
    }, [])
    const COLORS = [
        '#3801BD',
        '#D44324',
        '#B2194E',
        '#6301A4',
        '#214559',
        '#00626f',
        '#391285',
        '#0000aa',
        '#11887b',
        '#696006',
        '#004953',
        '#d1001c',
        '#900020',
        '#d5b60a',
        '#4a0100',
        '#af2f0d',
        '#9c004a',
        '#cb416b',
        '#d90166',
        '#490648',
        '#80444c',
        '#673a3f',
        '#605467',
        '#754600',
        '#4f1507',
        '#341c02',
        '#937a62',
        '#5c5337',
        '#415764',
        '#333333',
        '#363737',
        '#646356',
        '#6f828a',
        '#171717',
        '#5729ce',
        '#5a4fcf',
        '#e17701',
        '#e08119',
        '#e1ad01',
        '#e27a53',
        '#fc642d',
        '#ff7f00',
        '#9878f8',
        '#6c3461',
        '#4b0082',
        '#871f78',
        '#871f78',
        '#412a7a',
        '#ebe5d0',
        '#eeebe3',
        '#014421',
        '#048243',
        '#004b49',
        '#446d46',
        '#a3fa4b',
        '#66ffc9',
        '#48eab1',
        '#fa5b09'
    ]
    let data = []
    offerListData?.map((item) =>
        data.push({ name: item?.name, value: 1, id: item._id }))
    if (!offerListData.length) {
        data.push({ name: 'No Offer Available', value: 1 })
    }
    const handleRewardChange = (e) => {
        const { name, value } = e.target
        setValues((pre) => ({
            ...pre,
            [name]: value
        }))
    }
    const createAppointmentCoin = () => {
        const data = {
            amount: +values.amount,
            coin: +values.coin,
            providerId: userData?.userId
        }
        if (!values.amount && !values.coin) return toast.warn('Please enter amount and coin')
        if (!values.amount) return toast.warn('Please enter amount')
        if (values.amount == 0) return toast.warn('Amount should be greater than 0')
        if (!values.coin) return toast.warn('Please enter coin')
        if (values.coin == 0) return toast.warn('Coin should be greater than 0')

        // console.log(data,'DDDDDDD')
        // return     
        axios.post(createCoinAppointment(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setValues({ ...values, amount: '', coin: '' })
                setShowModal(false)
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { })
    }
    const listCoin = () => {
        axios.post(coinList(), { providerId: userData?.userId, isActive: true, isDeleted: false }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setCoinListData(resp.data.data)
            }
            else {
                setCoinListData([])
            }
        }).catch(() => { })
    }
    const viewCoin = () => {
        axios.post(coinView(), { coinId: coinListData[0]._id }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setValues({ ...values, amount: resp.data.data.amount, coin: resp.data.data.coin })
            }
            else {
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const appointmentUpdate = () => {
        const data = {
            coinId: coinListData[0]._id,
            amount: +values.amount,
            coin: +values.coin
        }
        if (data.amount == 0) return toast.warn('Amount should be greater than 0')
        if (data.coin == 0) return toast.warn('Coin should be greater than 0')

        axios.post(coinAppointmentUpdate(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setShowModal(false)
                setValues({ ...values, amount: '', coin: '' })
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const createSpinCondition = () => {
        const data = {
            providerId: userData?.userId,
            coinPerSpin: +values.coinPerSpin,
            coinDeductionPerSpin: +values.coinDeductionPerSpin
        }
        if (!values.coinPerSpin && !values.coinDeductionPerSpin) return toast.warn('Please enter coin per spin and coin deduction per spin')
        if (!values.coinPerSpin) return toast.warn('Please enter coin per spin')
        if (values.coinPerSpin == 0) return toast.warn('Coin per spin should be greater than 0')
        if (!values.coinDeductionPerSpin) return toast.warn('Please enter  coin deduction per spin')
        if (values.coinDeductionPerSpin == 0) return toast.warn('Coin deduction per spin should be greater than 0')

        // console.log(data,'llllll')
        // return
        axios.post(spinCondition(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setValues({ ...values, coinPerSpin: '', coinDeductionPerSpin: '' })
                setSpinModal(false)
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const coinConditionList = () => {
        axios.post(conditionList(), { providerId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setcoinConditionData(resp.data.data)
            }
            else {
                setcoinConditionData([])
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const coinConditionView = () => {
        axios.post(conditionView(), { spinId: coinConditionData[0]._id }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setValues({ ...values, coinPerSpin: resp.data.data.coinPerSpin, coinDeductionPerSpin: resp.data.data.coinDeductionPerSpin })
            }
            else {
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const coinConditionUpdate = () => {
        const data = {
            spinId: coinConditionData[0]._id,
            providerId: userData?.userId,
            coinPerSpin: +values.coinPerSpin,
            coinDeductionPerSpin: +values.coinDeductionPerSpin
        }
        if (data.coinPerSpin == 0) return toast.warn('Coin per spin should be greater than 0')
        if (data.coinDeductionPerSpin == 0) return toast.warn('Coin deduction per spin should be greater than 0')
        axios.post(conditionUpdate(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setSpinModal(false)
                setValues({ ...values, coinPerSpin: '', coinDeductionPerSpin: '' })
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const createCoinDuration = () => {
        const data = {
            providerId: userData?.userId,
            duration: +values.expiry
        }
        if (!values.expiry) return toast.warn('Please select expiry')
        axios.post(coinDuration(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setValues({ ...values, expiry: '' })
                setExpiryModal(false)
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const durationList = () => {
        axios.post(coinDurationList(), { providerId: userData?.userId }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setDurationData(resp.data.data)
            }
            else {
                setDurationData([])
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const coinDurationView = () => {
        axios.post(durationView(), { durationId: durationData[0]._id }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setValues({ ...values, expiry: resp.data.data.duration })
            }
            else {
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    const coinDurationUpdate = () => {
        const data = {
            durationId: durationData[0]._id,
            duration: +values.expiry,
            providerId: userData?.userId,
        }
        axios.post(durationUpdate(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setExpiryModal(false)
                setValues({ ...values, expiry: '' })
            }
            else {
                toast.error(resp.data.message)
            }
        }).catch(() => { toast.error(commonMessage.networkError) })
    }
    return {
        setShowModal,
        setSpinModal,
        setExpiryModal,
        setValues,
        setDurationData,
        setcoinConditionData,
        setCoinListData,
        handleRewardChange,
        createAppointmentCoin,
        viewCoin,
        appointmentUpdate,
        createSpinCondition,
        coinConditionView,
        coinConditionUpdate,
        createCoinDuration,
        coinDurationView,
        coinDurationUpdate,
        showModal,
        spinModal,
        expiryModal,
        values,
        durationData,
        coinConditionData,
        coinListData,
        data,
        COLORS,

        valuetext,
        handleChange,
        offerView,
        servicesList,
        setImageData,
        setInActive,
        setShow,
        setShowOffer,
        navigate,
        setMale,
        setFemale,
        setOther,
        rewardOffer, 
        setRewardOffer,
        spinner,
        createOffer,
        editOffer,
        value,
        imageData,
        serviceList,
        inActive,
        offerListData,
        selectedService,
        show,
        showOffer,
        male,
        female,
        other,
        date,
        imgFolder,
        offerId,
        fileInputRef,
        zeroOffer,
        setZeroOffer

    }
}

export default UseReward
