import React from 'react'
import Paginations from '../../Components/Pagination/pagination'
import { ToastContainer } from 'react-toastify';
import moment from 'moment/moment';
import Spinner from '../../Components/spinner/spinner'
import NoRecord from '../../Components/noRecord';
import UseNotification from './Hooks/useNotification'
import { intialRole } from '../../Utils/Utils';

const NotificationPage = () => {
  const {
    allNotificationRead,
    navigation,
    setChecked,
    setStatus,
    setPage,
    listNotification,
    checked,
    spinner,
    pageCount,
    page,
    PER_PAGE,
    userInfo
  } = UseNotification()
  // console.log(userInfo,'userInfo')
  // console.log(intialRole({ permission: "notification", userData: userInfo, section: 'View'}),'PPPPPP')
  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd cusheight">
          {spinner && <Spinner />}
          <header>
            <h2 className="page-tittle">Notifications</h2>
            <hr />
          </header>
          <div className="row">
            {
              intialRole({ permission: "notification", userData: userInfo, section: 'view' }) ?

                <div className="col-sm-12 listpadding">
                  <div className="d-flex justify-content-between">
                    <div className='mb-3'>
                      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={checked ? true : false} onClick={() => { setStatus(true); setChecked(true); setPage(1) }} />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio1" >All</label>

                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" checked={!checked ? true : false} onClick={() => { setStatus(false); setChecked(false); setPage(1) }} />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio2" >Unread</label>
                      </div>
                    </div>
                    {
                      intialRole({ permission: "notification", userData: userInfo, section: 'MarkRead', }) ?
                        listNotification?.data?.length ?
                          listNotification.unreadedCount == 0 ?
                            <h6 className='mt-2' style={{ cursor: 'pointer', pointerEvents: "none", color: "#aaaaaa" }}>Mark All Read</h6>
                            :
                            <h6 className='mt-2' onClick={() => { allNotificationRead(); }} style={{ cursor: 'pointer' }}>Mark All Read</h6>
                          : null
                        : null
                    }

                  </div>
                  {
                    !listNotification?.data?.length ? <NoRecord />
                      :
                      <div className="table-responsive" style={{ overflowX: 'hidden' }}>
                        <table className="table ">
                          <thead className=" text-center notifaication_Tab pt-5">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Title</th>
                              <th scope="col">Description</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              listNotification.data?.map((item, index) =>
                                <React.Fragment key={item._id}>
                                  <tr><td></td></tr>
                                  <tr onClick={() => navigation(item)}
                                    className={item.isReaded === true ? 'readtable' : 'notifaication_Tab'} style={{ cursor: 'pointer' }}>
                                    <td>{PER_PAGE * (page - 1) + index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>{item.message}</td>
                                    <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                                  </tr>
                                </React.Fragment>
                              )
                            }
                          </tbody>
                        </table>
                        <div className='w-100 d-flex justify-content-center'>
                          {
                            pageCount > 10 &&
                            <Paginations setpage={setPage} count={Math.ceil(pageCount / PER_PAGE)} page={page} />
                          }
                        </div>
                      </div>
                  }
                </div>
                : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
            }

          </div>
        </div>
      </section>

    </>
  )
}

export default NotificationPage
