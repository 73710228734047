import React from 'react'

const FailedStatus = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center cusheight card_Main">
    <div className="Success_Card">
        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
            <i className="fa-solid fa-x text-center text-danger" style={{marginLeft:'63px'}}></i>
        </div>
        <h1 className='text-center text-danger mt-3'>Your payment failed</h1>
    </div>
</div>
  )
}

export default FailedStatus
