/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ToastContainer } from 'react-toastify'
import Spinner from '../../Components/spinner/spinner'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Paginations from '../../Components/Pagination/pagination'
import DummyLogo from '../../Assets/images/dummyimg.png'
import { Modal } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord';
import UseTeamList from './Hooks/useTeamList'
import { Button } from '@mui/material';
import { intialRole } from '../../Utils/Utils';



const TeamList = () => {
  const { changeStaffStatus,
    setInvitationShow,
    setPage,
    setStaffId,
    setIsActive,
    getStaffList,
    navigate,
    handleInputChange,
    setModalArchive,
    modalArchive,
    invitationReSend,
    staffList,
    spinner,
    userInfo,
    invitationShow,
    count,
    filter,
    page,
    PER_PAGE,
    staffId,
    imgData, roleList } = UseTeamList()
  return (
    <>
      <ToastContainer />
      <section className='position-relative'>
        {spinner && <Spinner />}
        <div className="m-t-3 cus-padd cusheight" style={{ borderRadius: '15px' }}>
          <header>
            <h2 className="page-tittle">Teams</h2>
            <hr />
          </header>

          <div className="row">
            {
              intialRole({ permission: "team", userData: userInfo, section: 'view' }) ?
                <div className="col-sm-12 listpadding">
                  <div className="d-flex justify-content-end">
                    <>
                      <div className='me-2 phonehide'>
                        <select className='form-select ms-2' name='role' value={filter.role} onChange={(e) => handleInputChange(e)}>
                          <option value="" hidden>Role</option>
                          <option value="ALL">All</option>
                          {
                            roleList?.map((item, index) =>
                              <option value={item._id} key={index}>{item.title === 'Staff' ? 'Team' : item.title}</option>
                            )
                          }
                        </select>
                      </div>
                      <div style={{ width: '260px' }}>
                        <select className='form-select ms-2' name='status' value={filter.status} onChange={(e) => handleInputChange(e)}>
                          <option value="" hidden>Status</option>
                          <option value="accepted">Accepted</option>
                          <option value="pending">Pending</option>
                          <option value="declined">Declined</option>
                        </select>
                      </div>
                      <div>
                        <input type="search" placeholder='Search by name' className='form-control ms-3'
                          name='search'
                          value={filter.search}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                      <button className='btn ms-4 mb-3 btn_Style' type='submit' onClick={() => filter.status === '' && filter.search === '' && filter.role === '' ? null : getStaffList()}>Search</button>
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset</Tooltip>}>
                        <button className="btn mb-2 ms-2" style={{ marginRight: '10px' }}>
                          <i className="fa fa-refresh" aria-hidden="true" onClick={() => window.location.reload()} style={{ marginRight: '-10px' }}></i>
                        </button>
                      </OverlayTrigger>
                    </>
                    <ul className="navi">
                      <li>
                        <div className="dropdown">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Filter</Tooltip>}>
                            <i className="fa-solid fa-sliders" style={{ marginTop: '11px' }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></i>
                          </OverlayTrigger>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item cursor" onClick={() => { setIsActive(true); setPage(1) }}>
                              Un-Archive
                            </li>
                            <li className="dropdown-item cursor" onClick={() => { setIsActive(false); setPage(1) }}>
                              Archive
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {!staffList.length ? <NoRecord /> :
                    <div className="table-responsive">
                      <table className="table cus-table">
                        <thead className="text-center tableheader">
                          <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Team</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Role</th>
                            <th scope="col">Invitation Status</th>
                            {intialRole({ permission: "team", userData: userInfo, section: 'action' }) ? <th scope="col">Action</th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {staffList?.map((item) => (
                            <tr className={intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? 'cursor' : null} key={item._id}>
                              <td style={{ padding: '0.3rem .5rem' }} onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`teamProfile/${item._id}`) : null}>
                                <img src={(item.image === '' || item.image === null) ? DummyLogo : imgData + item.image} height={30} width={30} alt="" style={{ borderRadius: '50%' }} />
                              </td>
                              <td onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`teamProfile/${item._id}`) : null}>
                                {item.name}
                              </td>
                              <td onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`teamProfile/${item._id}`) : null}>
                                {item.email ? item.email : '--'}
                              </td>
                              <td onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`teamProfile/${item._id}`) : null}>
                                {item.mobileNo}
                              </td>
                              <td onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`teamProfile/${item._id}`) : null}>
                                {item.roleTitle === 'STAFF' ? 'Team' : 'Front Desk'}
                              </td>
                              <td onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`teamProfile/${item._id}`) : null}>
                                {item.isAccepted?.charAt(0)?.toUpperCase() + item.isAccepted?.slice(1)}
                              </td>
                              {
                                intialRole({ permission: "team", userData: userInfo, section: 'action' }) ?
                                  <td>
                                    <Button variant="text" sx={{ color: 'black', fontSize: '12px' }} id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i className="fa-solid fa-ellipsis-vertical cursor"></i>
                                    </Button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      {item.isActive ? <a className="dropdown-item cursor" xmlns="http://www.w3.org/2000/svg" onClick={() => { setStaffId(item); setModalArchive(true) }}>Archive</a>
                                        :
                                        <a className="dropdown-item cursor" xmlns="http://www.w3.org/2000/svg" onClick={() => { setStaffId(item); setModalArchive(true) }}>Un-Archive</a>
                                      }
                                      {item.isAccepted === 'Accepted' ? null : <a className="dropdown-item cursor" onClick={() => { setInvitationShow(true); setStaffId(item); invitationReSend.setFieldValue('oldEmail', item.email); invitationReSend.setFieldValue('email', '') }}>Resend Invitation</a>}
                                    </ul>
                                  </td>
                                  : null}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='w-100 d-flex justify-content-center'>
                        {staffList.length === 0 ? null :
                          count > 10 &&
                          <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                        }
                      </div>
                    </div>
                  }
                </div>

                : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
            }
          </div>
        </div>
        {
          intialRole({ permission: "team", userData: userInfo, section: 'add' }) ?
            <div className="">
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Team</Tooltip>}>
                <p className="btn addbtn2">
                  <i className="fa-solid fa-plus" onClick={() => { navigate('add-team') }}></i>
                </p>
              </OverlayTrigger>
            </div>
            : null

        }
      </section>


      {/* Team Member Archive/Un-Archive */}
      <Modal centered size='sm' show={modalArchive} onHide={() => setModalArchive(false)}>
        <div className="modal-content">
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            <p>You want to {staffId.isActive ? 'Archive' : 'Un-Archive'} this team member</p>
            <button type="button" className="btn nobtn" data-bs-dismiss="modal" onClick={() => changeStaffStatus()}>
              Yes
            </button>
            <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => setModalArchive(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>

      {/* Invitation  */}
      <Modal centered show={invitationShow} onHide={() => setInvitationShow(false)}>
        <div className="modal-body">
          <h5 className='text-center mt-2 '>Resend Invitation</h5>
          <div>
            <label className="form-label">Old Email *</label>
            <input type="text" className='form-control' disabled name='oldEmail' value={invitationReSend.values.oldEmail} onChange={invitationReSend.handleChange} />
          </div>
          <div className='mt-3'>
            <label className="form-label">New Email *</label>
            <input type="text" className='form-control' name='email' value={invitationReSend.values.email} onChange={invitationReSend.handleChange} />
            <ValidationMessage formName={invitationReSend} name={'email'} />
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <button type='button' className='btn yesbtn' style={{ background: '#f47d3a', color: 'white' }} onClick={invitationReSend.handleSubmit}>Submit</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default TeamList
