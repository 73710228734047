/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react'
import 'rc-time-picker/assets/index.css'
import { useFormik } from 'formik'
import axios from 'axios'
import {
  staffBranches,
  branchListApi,
  setAvailability,
  getAvailabilityApi,
  updateAvailabilityApi,
  unAvailability,
  unAvailabilityLists,
  unavailabilityDeleteApi,
  timeZoneApi,
  getAvailabilityListApi,
  logOutApi
} from '../../../Context/api'
import { useEffect } from 'react'
import { statusCodes, commonMessage, roleNames, initialState } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import { availabilityValidation, unAvailabilityValidation } from '../../../Context/validation'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App'

const UseAvailability = () => {
  const { userInfo } = useContext(Notifications)
  const [defaultFormValue, setFormValue] = useState(initialState)
  const [listData, setListData] = useState([])
  const [availablityData, setAvailablityData] = useState()
  const [availabilityId, setAvailabilityId] = useState()
  const [unAvailablityData, setUnAvailablityData] = useState([])
  const [timeZoneData, setTimeZoneData] = useState()
  const [showAvailability, setShowAvailability] = useState(false)
  const [show, setShow] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showUnAvailability, setShowUnAvailability] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState(1)
  const PER_PAGE = 8
  const [unAvailabilityId, setUnavailabilityId] = useState('')
  const userData = JSON.parse(localStorage.getItem('userData'))
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const navigate = useNavigate()

  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      getAvailabilityList()
      setSpinner(true)
      unAvailabilityList()
    }
  }, [page])

  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData.deviceId, userId: userData?.userId, type: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }

  const staffBranchList = () => {
    axios.post(staffBranches(), { userId: userData?.userId, providerId: userData?.createdbyId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setListData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
    })
  }

  const getBranchList = () => {
    axios.post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setListData(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
    })
  }
  const getAvailabilityList = () => {
    const data = {
      userId: userData?.userId,
      page: page,
      count: PER_PAGE,
      timezone: currentTimeZone,
      providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
      branchId: userData?.locationId
    }
    setSpinner(true)
    axios.post(getAvailabilityListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setAvailablityData(resp.data.data)
        setSpinner(false)
        setCount(resp.data.totalCount)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setSpinner(false)
        setAvailablityData([])
      }
    })
      .catch(() => {
        setSpinner(false)
        toast.error(commonMessage.networkError)
      })
  }
  const getAvailability = (id) => {
    axios.post(getAvailabilityApi(), { availabilityId: id, userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setAvailabilityId(id)
        editAvailability.setValues(resp.data.data)
        let tempArr = defaultFormValue
        let available_days = resp.data.data.available_days
        available_days.forEach((day, dayIndex) => {
          if (day.isChecked) {
            if (dayIndex === 0) {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeSunday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeSunday', moment(time.endTime))
                } else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeSunday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeSunday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeSunday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeSunday', moment(time.endTime))
                }
              })
            } else if (dayIndex === 1) {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeMonday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeMonday', moment(time.endTime))
                } else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeMonday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeMonday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeMonday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeMonday', moment(time.endTime))
                }
              })
            } else if (dayIndex === 2) {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeTuesday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeTuesday', moment(time.endTime))
                }
                else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeTuesday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeTuesday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeTuesday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeTuesday', moment(time.endTime))
                }
              })
            } else if (dayIndex === 3) {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeWednesday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeWednesday', moment(time.endTime))
                } else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeWednesday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeWednesday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeWednesday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeWednesday', moment(time.endTime))
                }
              })
            } else if (dayIndex === 4) {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeThursday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeThursday', moment(time.endTime))
                } else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeThursday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeThursday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeThursday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeThursday', moment(time.endTime))
                }
              })
            } else if (dayIndex === 5) {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeFriday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeFriday', moment(time.endTime))
                } else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeFriday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeFriday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeFriday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeFriday', moment(time.endTime))
                }
              })
            } else {
              day.arrayOfTimings.forEach((time, timeIndex) => {
                if (timeIndex === 0) {
                  editAvailability.setFieldValue('startTimeSaturday', moment(time.startTime))
                  editAvailability.setFieldValue('endTimeSaturday', moment(time.endTime))
                } else if (timeIndex === 1) {
                  editAvailability.setFieldValue('secondStartTimeSaturday', moment(time.startTime))
                  editAvailability.setFieldValue('secondEndTimeSaturday', moment(time.endTime))
                } else {
                  editAvailability.setFieldValue('thirdStartTimeSaturday', moment(time.startTime))
                  editAvailability.setFieldValue('thirdEndTimeSaturday', moment(time.endTime))
                }
              })
            }

            tempArr[dayIndex].isChecked = day.isChecked
            tempArr[dayIndex].arrayOfTimings = day.arrayOfTimings
            if (day.arrayOfTimings.length === 2) {
              tempArr[dayIndex].isAllowedTimes.first = true
            } else if (day.arrayOfTimings.length === 3) {
              tempArr[dayIndex].isAllowedTimes.first = true
              tempArr[dayIndex].isAllowedTimes.second = true
            }
          }
        })
        setFormValue([...tempArr])
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
      }
    }).catch(() => { })
  }
  const formik = useFormik({
    initialValues: {
      timeZone: '',
      branch: '',

      startTimeSunday: null,
      endTimeSunday: null,
      secondStartTimeSunday: null,
      secondEndTimeSunday: null,
      thirdStartTimeSunday: null,
      thirdEndTimeSunday: null,

      startTimeMonday: null,
      endTimeMonday: null,
      secondStartTimeMonday: null,
      secondEndTimeMonday: null,
      thirdStartTimeMonday: null,
      thirdEndTimeMonday: null,

      startTimeTuesday: null,
      endTimeTuesday: null,
      secondStartTimeTuesday: null,
      secondEndTimeTuesday: null,
      thirdStartTimeTuesday: null,
      thirdEndTimeTuesday: null,

      startTimeWednesday: null,
      endTimeWednesday: null,
      secondStartTimeWednesday: null,
      secondEndTimeWednesday: null,
      thirdStartTimeWednesday: null,
      thirdEndTimeWednesday: null,

      startTimeThursday: null,
      endTimeThursday: null,
      secondStartTimeThursday: null,
      secondEndTimeThursday: null,
      thirdStartTimeThursday: null,
      thirdEndTimeThursday: null,

      startTimeFriday: null,
      endTimeFriday: null,
      secondStartTimeFriday: null,
      secondEndTimeFriday: null,
      thirdStartTimeFriday: null,
      thirdEndTimeFriday: null,

      startTimeSaturday: null,
      endTimeSaturday: null,
      secondStartTimeSaturday: null,
      secondEndTimeSaturday: null,
      thirdStartTimeSaturday: null,
      thirdEndTimeSaturday: null,
    },
    validationSchema: availabilityValidation,
    onSubmit: (values) => {
      let data = {
        timezone: values.timeZone,
        userId: userData?.userId,
        providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
        branchId: values.branch,
        available_days: [
          {
            id: 0,
            name: 'Sunday',
            isChecked: true,
            arrayOfTimings: [],
          },
          {
            id: 1,
            name: 'Monday',
            isChecked: true,
            arrayOfTimings: [],
          },
          {
            id: 2,
            name: 'Tuesday',
            isChecked: true,
            arrayOfTimings: [],
          },
          {
            id: 3,
            name: 'Wednesday',
            isChecked: true,
            arrayOfTimings: [],
          },
          {
            id: 4,
            name: 'Thursday',
            isChecked: true,
            arrayOfTimings: [],
          },
          {
            id: 5,
            name: 'Friday',
            isChecked: true,
            arrayOfTimings: [],
          },
          {
            id: 6,
            name: 'Saturday',
            isChecked: true,
            arrayOfTimings: [],
          },
        ],
      }
      if (!defaultFormValue[0].isChecked) {
        values.startTimeSunday = ''
        values.endTimeSunday = ''
        values.secondStartTimeSunday = ''
        values.secondEndTimeSunday = ''
        values.thirdStartTimeSunday = ''
        values.thirdEndTimeSunday = ''
      }
      if (!defaultFormValue[1].isChecked) {
        values.startTimeMonday = ''
        values.endTimeMonday = ''
        values.secondStartTimeMonday = ''
        values.secondEndTimeMonday = ''
        values.thirdStartTimeMonday = ''
        values.thirdEndTimeMonday = ''
      }
      if (!defaultFormValue[2].isChecked) {
        values.startTimeTuesday = ''
        values.endTimeTuesday = ''
        values.secondStartTimeTuesday = ''
        values.secondEndTimeTuesday = ''
        values.thirdStartTimeTuesday = ''
        values.thirdEndTimeTuesday = ''
      }
      if (!defaultFormValue[3].isChecked) {
        values.startTimeWednesday = ''
        values.endTimeWednesday = ''
        values.secondStartTimeWednesday = ''
        values.secondEndTimeWednesday = ''
        values.thirdStartTimeWednesday = ''
        values.thirdEndTimeWednesday = ''
      }
      if (!defaultFormValue[4].isChecked) {
        values.startTimeThursday = ''
        values.endTimeThursday = ''
        values.secondStartTimeThursday = ''
        values.secondEndTimeThursday = ''
        values.thirdStartTimeThursday = ''
        values.thirdEndTimeThursday = ''
      }
      if (!defaultFormValue[5].isChecked) {
        values.startTimeFriday = ''
        values.endTimeFriday = ''
        values.secondStartTimeFriday = ''
        values.secondEndTimeFriday = ''
        values.thirdStartTimeFriday = ''
        values.thirdEndTimeFriday = ''
      }
      if (!defaultFormValue[6].isChecked) {
        values.startTimeSaturday = ''
        values.endTimeSaturday = ''
        values.secondStartTimeSaturday = ''
        values.secondEndTimeSaturday = ''
        values.thirdStartTimeSaturday = ''
        values.thirdEndTimeSaturday = ''
      }
      if (formik.values.startTimeSunday > formik.values.endTimeSunday) return toast.warn('Sunday start time can not be greater than end time')
      if (formik.values.startTimeSunday && formik.values.endTimeSunday)
        data.available_days[0].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeSunday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeSunday)).set('second', 0).format(),
        })

      if (formik.values.secondStartTimeSunday > formik.values.secondEndTimeSunday) return toast.warn('Sunday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeSunday && formik.values.secondEndTimeSunday)
        data.available_days[0].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeSunday)).seconds(0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeSunday)).seconds(0).format(),
        })
      if (formik.values.thirdStartTimeSunday > formik.values.thirdEndTimeSunday) return toast.warn('Sunday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeSunday && formik.values.thirdEndTimeSunday)
        data.available_days[0].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeSunday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeSunday)).set('second', 0).format(),
        })

      if (formik.values.startTimeMonday > formik.values.endTimeMonday) return toast.warn('Monday start time can not be greater than end time')
      if (formik.values.startTimeMonday && formik.values.endTimeMonday)
        data.available_days[1].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeMonday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeMonday)).set('second', 0).format(),
        })
      if (formik.values.secondStartTimeMonday > formik.values.secondEndTimeMonday) return toast.warn('Monday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeMonday && formik.values.secondEndTimeMonday)
        data.available_days[1].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeMonday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeMonday)).set('second', 0).format(),
        })
      if (formik.values.thirdStartTimeMonday > formik.values.thirdEndTimeMonday) return toast.warn('Monday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeMonday && formik.values.thirdEndTimeMonday)
        data.available_days[1].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeMonday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeMonday)).set('second', 0).format(),
        })

      if (formik.values.startTimeTuesday > formik.values.endTimeTuesday) return toast.warn('Tuesday start time can not be greater than end time')
      if (formik.values.startTimeTuesday && formik.values.endTimeTuesday)
        data.available_days[2].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeTuesday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeTuesday)).set('second', 0).format(),
        })
      if (formik.values.secondStartTimeTuesday > formik.values.secondEndTimeTuesday) return toast.warn('Tuesday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeTuesday && formik.values.secondEndTimeTuesday)
        data.available_days[2].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeTuesday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeTuesday)).set('second', 0).format(),
        })
      if (formik.values.thirdStartTimeTuesday > formik.values.thirdEndTimeTuesday) return toast.warn('Tuesday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeTuesday && formik.values.thirdEndTimeTuesday)
        data.available_days[2].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeTuesday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeTuesday)).set('second', 0).format(),
        })

      if (formik.values.startTimeWednesday > formik.values.endTimeWednesday) return toast.warn('Wednesday start time can not be greater than end time')
      if (formik.values.startTimeWednesday && formik.values.endTimeWednesday)
        data.available_days[3].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeWednesday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeWednesday)).set('second', 0).format(),
        })
      if (formik.values.secondStartTimeWednesday > formik.values.secondEndTimeWednesday) return toast.warn('Wednesday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeWednesday && formik.values.secondEndTimeWednesday)
        data.available_days[3].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeWednesday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeWednesday)).set('second', 0).format(),
        })
      if (formik.values.thirdStartTimeWednesday > formik.values.thirdEndTimeWednesday) return toast.warn('Wednesday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeWednesday && formik.values.thirdEndTimeWednesday)
        data.available_days[3].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeWednesday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeWednesday)).set('second', 0).format(),
        })

      if (formik.values.startTimeThursday > formik.values.endTimeThursday) return toast.warn('Thursday start time can not be greater than end time')
      if (formik.values.startTimeThursday && formik.values.endTimeThursday)
        data.available_days[4].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeThursday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeThursday)).set('second', 0).format(),
        })
      if (formik.values.secondStartTimeThursday > formik.values.secondEndTimeThursday) return toast.warn('Thursday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeThursday && formik.values.secondEndTimeThursday)
        data.available_days[4].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeThursday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeThursday)).set('second', 0).format(),
        })
      if (formik.values.thirdStartTimeThursday > formik.values.thirdEndTimeThursday) return toast.warn('Thursday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeThursday && formik.values.thirdEndTimeThursday)
        data.available_days[4].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeThursday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeThursday)).set('second', 0).format(),
        })

      if (formik.values.startTimeFriday > formik.values.endTimeFriday) return toast.warn('Friday start time can not be greater than end time')
      if (formik.values.startTimeFriday && formik.values.endTimeFriday)
        data.available_days[5].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeFriday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeFriday)).set('second', 0).format(),
        })
      if (formik.values.secondStartTimeFriday > formik.values.secondEndTimeFriday) return toast.warn('Friday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeFriday && formik.values.secondEndTimeFriday)
        data.available_days[5].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeFriday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeFriday)).set('second', 0).format(),
        })
      if (formik.values.thirdStartTimeFriday > formik.values.thirdEndTimeFriday) return toast.warn('Friday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeFriday && formik.values.thirdEndTimeFriday)
        data.available_days[5].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeFriday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeFriday)).set('second', 0).format(),
        })

      if (formik.values.startTimeSaturday > formik.values.endTimeSaturday) return toast.warn('Saturday start time can not be greater than end time')
      if (formik.values.startTimeSaturday && formik.values.endTimeSaturday)
        data.available_days[6].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.startTimeSaturday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.endTimeSaturday)).set('second', 0).format(),
        })
      if (formik.values.secondStartTimeSaturday > formik.values.secondEndTimeSaturday) return toast.warn('Saturday second start time can not be greater than second end time')
      if (formik.values.secondStartTimeSaturday && formik.values.secondEndTimeSaturday)
        data.available_days[6].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.secondStartTimeSaturday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.secondEndTimeSaturday)).set('second', 0).format(),
        })
      if (formik.values.thirdStartTimeSaturday > formik.values.thirdEndTimeSaturday) return toast.warn('Saturday third start time can not be greater than third end time')
      if (formik.values.thirdStartTimeSaturday && formik.values.thirdEndTimeSaturday)
        data.available_days[6].arrayOfTimings.push({
          startTime: moment(new Date(formik.values.thirdStartTimeSaturday)).set('second', 0).format(),
          endTime: moment(new Date(formik.values.thirdEndTimeSaturday)).set('second', 0).format(),
        })

      if (!data.available_days[0].arrayOfTimings.length) data.available_days[0].isChecked = false
      if (!data.available_days[1].arrayOfTimings.length) data.available_days[1].isChecked = false
      if (!data.available_days[2].arrayOfTimings.length) data.available_days[2].isChecked = false
      if (!data.available_days[3].arrayOfTimings.length) data.available_days[3].isChecked = false
      if (!data.available_days[4].arrayOfTimings.length) data.available_days[4].isChecked = false
      if (!data.available_days[5].arrayOfTimings.length) data.available_days[5].isChecked = false
      if (!data.available_days[6].arrayOfTimings.length) data.available_days[6].isChecked = false

      //  console.log(data,'data')
      if (
        data.available_days[0].isChecked === false &&
        data.available_days[1].isChecked === false &&
        data.available_days[2].isChecked === false &&
        data.available_days[3].isChecked === false &&
        data.available_days[4].isChecked === false &&
        data.available_days[5].isChecked === false &&
        data.available_days[6].isChecked === false
      ) return toast.warning('Please select atleast one day')
      //     console.log(values.startTimeSunday,'llllllllll')
      // return
      setSpinner(true)
      axios.post(setAvailability(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
        .then((resp) => {
          if (resp.data.code === statusCodes.ok) {
            toast.success(resp.data.message)
            getAvailabilityList()
            formik.resetForm()
            setShow(false)
            setSpinner(false)
            closeToggleBtn()
          }
          else if (resp.data.code === statusCodes.tokenExpire) {
            logOutFun()
          }
          else {
            toast.error(resp.data.message)
            setSpinner(false)
          }
        })
        .catch(() => {
          setSpinner(false)
          toast.error(commonMessage.networkError)
        })
    },
  })
  const editAvailability = useFormik({
    initialValues: {
      timezone: '',
      branchId: '',

      startTimeSunday: null,
      endTimeSunday: null,
      secondStartTimeSunday: null,
      secondEndTimeSunday: null,
      thirdStartTimeSunday: null,
      thirdEndTimeSunday: null,

      startTimeMonday: null,
      endTimeMonday: null,
      secondStartTimeMonday: null,
      secondEndTimeMonday: null,
      thirdStartTimeMonday: null,
      thirdEndTimeMonday: null,

      startTimeTuesday: null,
      endTimeTuesday: null,
      secondStartTimeTuesday: null,
      secondEndTimeTuesday: null,
      thirdStartTimeTuesday: null,
      thirdEndTimeTuesday: null,

      startTimeWednesday: null,
      endTimeWednesday: null,
      secondStartTimeWednesday: null,
      secondEndTimeWednesday: null,
      thirdStartTimeWednesday: null,
      thirdEndTimeWednesday: null,

      startTimeThursday: null,
      endTimeThursday: null,
      secondStartTimeThursday: null,
      secondEndTimeThursday: null,
      thirdStartTimeThursday: null,
      thirdEndTimeThursday: null,

      startTimeFriday: null,
      endTimeFriday: null,
      secondStartTimeFriday: null,
      secondEndTimeFriday: null,
      thirdStartTimeFriday: null,
      thirdEndTimeFriday: null,

      startTimeSaturday: null,
      endTimeSaturday: null,
      secondStartTimeSaturday: null,
      secondEndTimeSaturday: null,
      thirdStartTimeSaturday: null,
      thirdEndTimeSaturday: null,
    },
    onSubmit: (values) => {
      let data = {
        timezone: values.timezone,
        availabilityId: availabilityId,
        providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
        userId: userData?.userId,
        branchId: values.branchId,
        available_days: [
          {
            id: 0,
            name: 'Sunday',
            isChecked: defaultFormValue[0].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 1,
            name: 'Monday',
            isChecked: defaultFormValue[1].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 2,
            name: 'Tuesday',
            isChecked: defaultFormValue[2].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 3,
            name: 'Wednesday',
            isChecked: defaultFormValue[3].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 4,
            name: 'Thursday',
            isChecked: defaultFormValue[4].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 5,
            name: 'Friday',
            isChecked: defaultFormValue[5].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 6,
            name: 'Saturday',
            isChecked: defaultFormValue[6].isChecked,
            arrayOfTimings: [],
          },
        ],
      }

      if (editAvailability.values.startTimeSunday > editAvailability.values.endTimeSunday) return toast.warn('Sunday start time cannot be greater than end time')
      if (editAvailability.values.startTimeSunday && editAvailability.values.endTimeSunday)
        data.available_days[0].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeSunday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeSunday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeSunday > editAvailability.values.secondEndTimeSunday) return toast.warn('Sunday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeSunday && editAvailability.values.secondEndTimeSunday)
        data.available_days[0].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeSunday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeSunday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeSunday > editAvailability.values.thirdEndTimeSunday) return toast.warn('Sunday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeSunday && editAvailability.values.thirdEndTimeSunday)
        data.available_days[0].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeSunday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeSunday)).set('second', 0).format(),
        })

      if (editAvailability.values.startTimeMonday > editAvailability.values.endTimeMonday) return toast.warn('Monday start time cannot be greater than end time')
      if (editAvailability.values.startTimeMonday && editAvailability.values.endTimeMonday)
        data.available_days[1].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeMonday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeMonday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeMonday > editAvailability.values.secondEndTimeMonday) return toast.warn('Monday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeMonday && editAvailability.values.secondEndTimeMonday)
        data.available_days[1].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeMonday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeMonday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeMonday > editAvailability.values.thirdEndTimeMonday) return toast.warn('Monday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeMonday && editAvailability.values.thirdEndTimeMonday)
        data.available_days[1].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeMonday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeMonday)).set('second', 0).format(),
        })

      if (editAvailability.values.startTimeTuesday > editAvailability.values.endTimeTuesday) return toast.warn('Tuesday start time cannot be greater than end time')
      if (editAvailability.values.startTimeTuesday && editAvailability.values.endTimeTuesday)
        data.available_days[2].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeTuesday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeTuesday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeTuesday > editAvailability.values.secondEndTimeTuesday) return toast.warn('Tuesday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeTuesday && editAvailability.values.secondEndTimeTuesday)
        data.available_days[2].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeTuesday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeTuesday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeTuesday > editAvailability.values.thirdEndTimeTuesday) return toast.warn('Tuesday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeTuesday && editAvailability.values.thirdEndTimeTuesday)
        data.available_days[2].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeTuesday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeTuesday)).set('second', 0).format(),
        })

      if (editAvailability.values.startTimeWednesday > editAvailability.values.endTimeWednesday) return toast.warn('Wednesday start time cannot be greater than end time')
      if (editAvailability.values.startTimeWednesday && editAvailability.values.endTimeWednesday)
        data.available_days[3].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeWednesday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeWednesday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeWednesday > editAvailability.values.secondEndTimeWednesday) return toast.warn('Wednesday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeWednesday && editAvailability.values.secondEndTimeWednesday)
        data.available_days[3].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeWednesday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeWednesday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeWednesday > editAvailability.values.thirdEndTimeWednesday) return toast.warn('Wednesday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeWednesday && editAvailability.values.thirdEndTimeWednesday)
        data.available_days[3].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeWednesday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeWednesday)).set('second', 0).format(),
        })

      if (editAvailability.values.startTimeThursday > editAvailability.values.endTimeThursday) return toast.warn('Thursday start time cannot be greater than end time')
      if (editAvailability.values.startTimeThursday && editAvailability.values.endTimeThursday)
        data.available_days[4].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeThursday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeThursday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeThursday > editAvailability.values.secondEndTimeThursday) return toast.warn('Thursday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeThursday && editAvailability.values.secondEndTimeThursday)
        data.available_days[4].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeThursday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeThursday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeThursday > editAvailability.values.thirdEndTimeThursday) return toast.warn('Thursday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeThursday && editAvailability.values.thirdEndTimeThursday)
        data.available_days[4].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeThursday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeThursday)).set('second', 0).format(),
        })

      if (editAvailability.values.startTimeFriday > editAvailability.values.endTimeFriday) return toast.warn('Friday start time cannot be greater than end time')
      if (editAvailability.values.startTimeFriday && editAvailability.values.endTimeFriday)
        data.available_days[5].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeFriday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeFriday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeFriday > editAvailability.values.secondEndTimeFriday) return toast.warn('Friday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeFriday && editAvailability.values.secondEndTimeFriday)
        data.available_days[5].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeFriday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeFriday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeFriday > editAvailability.values.thirdEndTimeFriday) return toast.warn('Friday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeFriday && editAvailability.values.thirdEndTimeFriday)
        data.available_days[5].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeFriday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeFriday)).set('second', 0).format(),
        })

      if (editAvailability.values.startTimeSaturday > editAvailability.values.endTimeSaturday) return toast.warn('Saturday start time cannot be greater than end time')
      if (editAvailability.values.startTimeSaturday && editAvailability.values.endTimeSaturday)
        data.available_days[6].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.startTimeSaturday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.endTimeSaturday)).set('second', 0).format(),
        })
      if (editAvailability.values.secondStartTimeSaturday > editAvailability.values.secondEndTimeSaturday) return toast.warn('Saturday second start time cannot be greater than second end time')
      if (editAvailability.values.secondStartTimeSaturday && editAvailability.values.secondEndTimeSaturday)
        data.available_days[6].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.secondStartTimeSaturday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.secondEndTimeSaturday)).set('second', 0).format(),
        })
      if (editAvailability.values.thirdStartTimeSaturday > editAvailability.values.thirdEndTimeSaturday) return toast.warn('Saturday third start time cannot be greater than third end time')
      if (editAvailability.values.thirdStartTimeSaturday && editAvailability.values.thirdEndTimeSaturday)
        data.available_days[6].arrayOfTimings.push({
          startTime: moment(new Date(editAvailability.values.thirdStartTimeSaturday)).set('second', 0).format(),
          endTime: moment(new Date(editAvailability.values.thirdEndTimeSaturday)).set('second', 0).format(),
        })
      setSpinner(true)
      axios
        .post(updateAvailabilityApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
          if (resp.data.code === statusCodes.ok) {
            toast.success(resp.data.message)
            getAvailabilityList()
            closeToggleBtn()
            setShowAvailability(false)
            setSpinner(false)
          }
          else if (resp.data.code === statusCodes.tokenExpire) {
            logOutFun()
          }
          else {
            toast.error(resp.data.message)
            setSpinner(false)
          }
        })
        .catch(() => {
          toast.error(commonMessage.networkError)
          setSpinner(false)
        })
    },
  })
  const valueChangeToggleSwitch = (day) => {
    let formValues = defaultFormValue
    formValues[day].isChecked = !formValues[day].isChecked
    setFormValue([...formValues])
  }
  const showAdditionalTimingsBox = (day) => {
    let formValues = defaultFormValue
    if (formValues[day].isAllowedTimes.first && formValues[day].isAllowedTimes.second) return toast.warn('Maximun 3 times allowed')
    if (!formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.first = true
    else if (formValues[day].isAllowedTimes.first) formValues[day].isAllowedTimes.second = true
    setFormValue([...formValues])
  }
  const hideAdditionalTimingsBox = (day, index) => {
    let formValues = defaultFormValue
    switch (index) {
      case 1:
        formValues[day].isAllowedTimes.first = false
        break
      case 2:
        formValues[day].isAllowedTimes.second = false
        break
      default:
    }
    setFormValue([...formValues])
  }

  const closeToggleBtn = () => {
    setFormValue([...initialState])
  }
  const timeZone = () => {
    axios.post(timeZoneApi(), { isActive: true, count: 102 }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setTimeZoneData(resp.data.data)
        const timeZone = resp.data.data.find((item) => item.name === 'Asia/Kolkata')
        formik.setFieldValue('timeZone', currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? timeZone.name : null)
        setUnavailability.setFieldValue('timeZone', currentTimeZone === 'Asia/Calcutta' || currentTimeZone === 'Asia/Kolkata' ? timeZone.name : null)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
    })
  }
  const unAvailabilityList = () => {
    const data = {
      providerId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      page: page,
      count: PER_PAGE,
      branchId: userData?.locationId
    }
    if (userData?.roleTitle !== roleNames.SERVICE_PROVIDER) {
      data.staffId = userData?.userId
      // delete data.providerId
    }
    setSpinner(true)
    axios.post(unAvailabilityLists(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setUnAvailablityData(resp.data.data)
        setSpinner(false)
        setCount(resp.data.totalCount)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setUnAvailablityData([])
        setSpinner(false)
      }
    }).catch(() => { })

  }
  const unavailabilityDelete = () => {
    axios.post(unavailabilityDeleteApi(), { unAvailabilityId: unAvailabilityId }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        toast.success(resp.data.message)
        unAvailabilityList()
        setShowDelete(false)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        toast.error(resp.data.message)
      }
    })
  }
  const setUnavailability = useFormik({
    initialValues: {
      timeZone: '',
      branch: '',
      startDate: '',
      endDate: '',
      startTime: null,
      endTime: null,
      secondStartTime: null,
      secondEndTime: null,
      thirdStartTime: null,
      thirdEndTime: null,
    },
    validationSchema: unAvailabilityValidation,
    onSubmit: (values) => {
      let data = {
        branchId: values.branch,
        timezone: values.timeZone,
        startDate: values.startDate,
        endDate: values.endDate,
        providerId: userData.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
        timingArr: [],
      }



      if (userData.roleTitle !== roleNames.SERVICE_PROVIDER) {
        data.staffId = userData?.userId
      }

      if (setUnavailability.values.startTime > setUnavailability.values.endTime) return toast.warn('Start time can not be greater than end time')
      if (setUnavailability.values.startTime && setUnavailability.values.endTime)
        data.timingArr.push({
          startTime: moment(setUnavailability.values.startTime).format('HH:mm'),
          endTime: moment(setUnavailability.values.endTime).format('HH:mm'),
        })

      if (setUnavailability.values.secondStartTime > setUnavailability.values.secondEndTime) return toast.warn('Second start time time can not be greater than second end time')
      if (setUnavailability.values.secondStartTime && setUnavailability.values.secondEndTime)
        data.timingArr.push({
          startTime: moment(setUnavailability.values.secondStartTime).format('HH:mm'),
          endTime: moment(setUnavailability.values.secondEndTime).format('HH:mm'),
        })

      if (setUnavailability.values.thirdStartTime > setUnavailability.values.thirdEndTime) return toast.warn('Third start time time can not be greater than third end time')
      if (setUnavailability.values.thirdStartTime && setUnavailability.values.thirdEndTime)
        data.timingArr.push({
          startTime: moment(setUnavailability.values.thirdStartTime).format('HH:mm'),
          endTime: moment(setUnavailability.values.thirdEndTime).format('HH:mm'),
        })

      setSpinner(true)
      axios.post(unAvailability(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          setUnavailability.resetForm()
          unAvailabilityList()
          setShowUnAvailability(false)
          setSpinner(false)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          toast.error(resp.data.message)
          setSpinner(false)
        }
      })
        .catch(() => {
          toast.error(commonMessage.networkError)
        })
    },
  })

  return {
    logOutFun,
    staffBranchList,
    getBranchList,
    getAvailabilityList,
    getAvailability,
    valueChangeToggleSwitch,
    showAdditionalTimingsBox,
    hideAdditionalTimingsBox,
    closeToggleBtn,
    timeZone,
    unAvailabilityList,
    unavailabilityDelete,
    setFormValue,
    setListData,
    setAvailablityData,
    setUnAvailablityData,
    setTimeZoneData,
    setShowAvailability,
    setShow,
    setShowUnAvailability,
    setSpinner,
    setCount,
    setPage,
    setTab,
    setUnavailabilityId,
    navigate,
    setShowDelete,
    showDelete,
    initialState,
    setUnavailability,
    formik,
    editAvailability,
    defaultFormValue,
    listData,
    availablityData,
    unAvailablityData,
    timeZoneData,
    showAvailability,
    show,
    showUnAvailability,
    spinner,
    count,
    page,
    tab,
    PER_PAGE,
    unAvailabilityId,
    userData,
    currentTimeZone,
    userInfo

  }
}

export default UseAvailability