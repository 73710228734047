import * as Yup from 'yup'
import * as Regux from '../Context/regux'

export const loginValidation = Yup.object({
  mobileNo: Yup.string().required('Please enter mobile number'),
  // .min(10, 'Please enter mobile number'),
  password: Yup.string().required('Please enter PIN')
})
export const loginWithEmailValidation = Yup.object({
  email: Yup.string().required('Please enter email').matches(Regux.email, 'Please enter correct Email'),
  password: Yup.string().required('Please enter PIN'),
})
export const signUpValidation = Yup.object({
  mobileNo: Yup.string().required('Please enter mobile number')
  // .max(10, 'Please enter mobile number or less')
  // .min(10, 'Please enter mobile number')
})
export const signUpWithEmailValidation = Yup.object({
  email: Yup.string().required('Please enter email').matches(Regux.email, 'Please enter correct email'),
})
export const forgotPinValidation = Yup.object({
  mobileNo: Yup.string().required('Please enter mobile number')
  // .min(10, 'Please enter mobile number'),
})
export const forgotPinValidationWithEmail = Yup.object({
  email: Yup.string().required('Please enter email'),
})
export const basicProfileValidation = Yup.object({
  name: Yup.string()
    .max(30, 'Must be 30 characters or less').required('Please enter name'),
  email: Yup.string().email('Please enter correct email')
    .required('Please enter  email'),
  mobileNo: Yup.string().required('Please enter correct mobile number')
  // .min(10, 'Please enter mobile number'),
})
export const basicTeamProfileValidation = Yup.object({
  name: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .required('Please enter name'),
  // .matches(Regux.name,'Numbers are not allowed') 
  mobileNo: Yup.string().required('Please enter correct mobile number')
})
export const businessDetailsValidation = Yup.object({
  businessName: Yup.string().required('Please enter business name'),
  businessCategory: Yup.string().required('Please enter business category'),
  about: Yup.string().required('Please enter about')
})
export const addBranchValidation = Yup.object({
  name: Yup.string().required('Please enter branch name'),
  building: Yup.string().required('Please enter building name'),
  street: Yup.string().required('Please enter street'),
  city: Yup.string().required('Please enter city'),
  country: Yup.string().required('Please select country'),
  state: Yup.string().required('Please select state'),
  zipCode: Yup.string().required('Please enter zip code'),
  email: Yup.string().required('Please enter email')
    .matches(Regux.email, 'Please enter correct email'),
  mobileNo: Yup.string().required('Please enter mobile number')
  // .min(10, 'Please enter mobile number'),
})
export const editBranchValidation = Yup.object({
  name: Yup.string().required('Please enter branch name'),
  building: Yup.string().required('Please enter building name'),
  street: Yup.string().required('Please enter street'),
  city: Yup.string().required('Please enter city'),
  countryId: Yup.string().required('Please select country'),
  stateId: Yup.string().required('Please select state'),
  zipCode: Yup.string().required('Please enter zip code'),
  email: Yup.string().required('Please enter email')
    .matches(Regux.email, 'Please enter correct email'),
  mobileNumber: Yup.string().required('Please enter mobile number')
  // .min(10, 'Please enter mobile number'),
})
export const otpValidation = Yup.object({
  number: Yup.string().min(4, 'Please enter OTP').required('Please enter OTP'),
})
export const resetPinValidation = Yup.object({
  pin: Yup.string().min(4, 'Please enter PIN').required('Please enter PIN'),
  confirmPin: Yup.string().min(4, 'Please enter confirm PIN')
    .required('Please enter confirm PIN')
    .oneOf([Yup.ref('pin')], 'New PIN and Confirm PIN must be match'),
})
export const availabilityValidation = Yup.object({
  branch: Yup.string().required('Please select branch'),
  timeZone: Yup.string().required('Please select time zone'),
})
export const unAvailabilityValidation = Yup.object({
  branch: Yup.string().required('Please select branch'),
  timeZone: Yup.string().required('Please select time zone'),
  startDate: Yup.string().required('Please select start date'),
  endDate: Yup.string().nullable().required('Please select end date'),
  startTime: Yup.string().nullable().required('Please select start time'),
  endTime: Yup.string().required('Please select end time')
})
export const unAvailabilityValidationCalendar = Yup.object({
  branch: Yup.string().required('Please select branch'),
  team: Yup.string().required('Please select team'),
  timeZone: Yup.string().required('Please select time zone'),
  startDate: Yup.string().required('Please select start date'),
  endDate: Yup.string().required('Please select end date'),
  startTime: Yup.string().nullable().required('Please select start time'),
  endTime: Yup.string().nullable().required('Please select end time')
})
export const changePinValidation = Yup.object({
  oldPin: Yup.string().required('Please enter old PIN'),
  newPin: Yup.string().required('Please enter new PIN'),
  confirmNewPin: Yup.string()
    .required('Please enter confirm new PIN')
    .oneOf([Yup.ref('newPin')], 'New PIN and Confirm New PIN must be match'),
})
export const bookAppointmentValidation = Yup.object({
  branch: Yup.string().required('Please select branch'),
  serviceCategory: Yup.string().required('Please select service category'),
  services: Yup.string().required('Please select service'),
  price: Yup.string().required('Please enter price'),
  availabaleStaff: Yup.string().required('Please select available staff'),
})
export const staffBookAppointmentValidation = Yup.object({
  branch: Yup.string().required('Please select branch'),
  services: Yup.string().required('Please select service'),
  price: Yup.string().required('Please enter price'),
})
export const addClientValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  email: Yup.string().required('Please enter email').
    matches(Regux.email, 'Please enter correct email'),
  // DOB: Yup.string().required('Please select DOB'),
  mobileNo: Yup.string().required('Please enter mobile number'),
  // .min(10, 'Please enter mobile number'),
  gender: Yup.string().required('Please select gender'),
})
export const addClientValidationIndia = Yup.object({
  name: Yup.string().required('Please enter name'),
  // email: Yup.string().required('Please enter email').
  //   matches(Regux.email, 'Please enter correct email'),
  // DOB: Yup.string().required('Please select DOB'),
  mobileNo: Yup.string().required('Please enter mobile number'),
  // .min(10, 'Please enter mobile number'),
  gender: Yup.string().required('Please select gender'),
})
export const addTeamMemberValidationIndia = Yup.object({
  name: Yup.string().required('Please enter name'),
  gender: Yup.string().required('Please select gender'),
  mobileNo: Yup.string().required('Please enter mobile number'),
  role: Yup.string().required('Please select role title')
  // .min(10, 'Please enter mobile number'),
})
export const addTeamMemberValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  email: Yup.string().required('Please enter email').matches(Regux.email, 'Please enter correct email'),
  gender: Yup.string().required('Please select gender'),
  mobileNo: Yup.string().required('Please enter mobile number'),
  role: Yup.string().required('Please select role title')
  // .min(10, 'Please enter mobile number'),
})
export const teamProfileValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  // DOB: Yup.string().required('Please select DOB'),
  gender: Yup.string().required('Please select gender'),
  mobileNo: Yup.string().required('Please enter mobile number'),
  roleTitle: Yup.string().required('Please select role title')
})
// export const appointmentRescheduleValidation = Yup.object({
//   reason: Yup.string().required('Please enter reason')
//     .matches(Regux.whiteSpace, 'White space should not be allowed as a prefix')
// })
// export const staffAppointmentRescheduleValidation = Yup.object({
//   branchName: Yup.string().required('Please select branch')
// })
export const offerValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  startDate: Yup.string().required('Please select start date'),
  endDate: Yup.string().required('Please select end date'),
  description: Yup.string().required('Please enter description'),
  terms_condition: Yup.string().required('Please enter terms conditions'),
  discountType: Yup.string().nullable().required('Please select discount type'),
  discountValue: Yup.string().nullable().required('Please enter discount value'),
  maximumValue: Yup.string().nullable().required('Please enter max discount value'),
  probability: Yup.string().required('Please select probability')
})
export const normalOfferValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  startDate: Yup.string().required('Please select start date'),
  endDate: Yup.string().required('Please select end date'),
  description: Yup.string().required('Please enter description'),
  terms_condition: Yup.string().required('Please enter terms conditions'),
  discountType: Yup.string().nullable().required('Please select discount type'),
  discountValue: Yup.string().nullable().required('Please enter discount value'),
  maximumValue: Yup.string().nullable().required('Please enter max discount value'),
})
export const zeroOfferValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  description: Yup.string().required('Please enter description'),
  terms_condition: Yup.string().required('Please enter terms conditions'),
  probability: Yup.string().required('Please select probability')
})
export const WaitingListValidation = Yup.object({
  date: Yup.string().required('Please select date'),
  startTime: Yup.string().required('Please enter start time'),
  endTime: Yup.string().required('Please enter end time'),
  staff: Yup.string().required('Please select staff'),
  branch: Yup.string().required('Please select branch'),
  service: Yup.string().required('Please select service'),
  serviceCategory: Yup.string().required('Please select service category'),
  visitType: Yup.string().required('Please select booking type')


})
export const contactValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  email: Yup.string().required('Please enter email').matches(Regux.email, 'Please enter correct Email'),
  subject: Yup.string().required('Please enter subject'),
  message: Yup.string().required('Please enter message'),
})
export const serviceCategoryValidation = Yup.object({
  name: Yup.string().required('Please enter service category')
})
export const addComboValidation = Yup.object({
  name: Yup.string().required('Please enter name'),
  price: Yup.string().required('Please enter price'),
  duration: Yup.string().required('Please enter duration'),
  description: Yup.string().required('Please enter description'),
  termConditions: Yup.string().required('Please enter term & conditions')
})
// export const bookComboValidation = Yup.object({
//   startTime: Yup.string().required('Please select start time'),
//   endTime: Yup.string().required('Please select end time'),
//   team:  Yup.array().required('Please enter team'),
// })
export const addServiceValidation = Yup.object({
  name: Yup.string().required('Please enter service name'),
  fixedAmount: Yup.string().nullable().required("Please enter regular price ").test('greater-than-or-equal-to-zero', 'Regular price must be greater than zero and equal to zero', value => {
    const numericValue = Number(value);
    return !isNaN(numericValue) && numericValue >= 0;
  }),
  variableAmount: Yup.string().nullable().test('greater-than-or-equal-to-zero', 'Discount price must be greater than zero and equal to zero', value => {
    const numericValue = Number(value);
    if (!value) {
      return true
    } else {
      return !isNaN(numericValue) && numericValue >= 0;
    }
  }),

  duration: Yup.string().required('Please select duration'),
  description: Yup.string().required('Please enter description')
})
export const addFaqValidation = Yup.object({
  question: Yup.string().required('Please enter question'),
  answer: Yup.string().required('Please enter answer'),
})
export const durationValidation = Yup.object({
  duration: Yup.string().required('Please select duration')
})
export const cancelAppointmentValidation = Yup.object({
  reason: Yup.string().required('Please enter reason')
})
export const invitationReSendValidation = Yup.object({
  email: Yup.string().required('Please enter email').matches(Regux.email, 'Please enter correct Email'),
})
export const ticket = Yup.object({
  subject: Yup.string().required('Please enter subject'),
  description: Yup.string().required('Please enter description'),
  // priority: Yup.string().required('Please select priority'),
})
// export const amountValidation = Yup.object({
//   name: Yup.string().required('Please enter name').matches(Regux.whiteSpace, 'White space should not be allowed as a prefix'),
//   card: Yup.string().required('Please enter card number').matches(Regux.whiteSpace, 'White space should not be allowed as a prefix'),
//   expMonth: Yup.string().required('Enter expiry month').matches(Regux.whiteSpace, 'White space should not be allowed as a prefix'),
//   expYear: Yup.string().required('Enter expiry year').matches(Regux.whiteSpace, 'White space should not be allowed as a prefix'),
//   cvv: Yup.string().required('Enter card cvv').matches(Regux.whiteSpace, 'White space should not be allowed as a prefix'),
// })
export const paymentValidation = Yup.object({
  card: Yup.string().required('Please enter card number').min(16),
  expMonth: Yup.string().required('Enter expiry month'),
  expYear: Yup.string().required('Enter expiry year'),
  cvv: Yup.string().required('Enter card cvv'),
})
export const contactUSValidation = Yup.object({
  name: Yup.string().required('Please enter name').matches(Regux.name, 'Numbers are not allowed'),
  email: Yup.string().required('Please enter email').matches(Regux.email, 'Please enter correct email'),
  mobile: Yup.string().required('Please enter mobile number').min(10, 'Please enter mobile number'),
  description: Yup.string().required('Please enter description'),
  providerName: Yup.string().required('Please enter business name').matches(Regux.name, 'Numbers are not allowed'),
  country: Yup.string().required('Please select country')

})
export const addCardValidation = Yup.object({
  cardHolderName: Yup.string().required('Please enter name'),
  cardNumber: Yup.string().required('Please enter card number'),
  expMonth: Yup.string().required('Enter expiry month'),
  expYear: Yup.string().required('Enter expiry year'),
  cvv: Yup.string().required('Please enter cvv'),
})
