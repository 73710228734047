import React from 'react'
import Pagination from '@mui/material/Pagination';

const Paginations = (props) => {
  return (
    <>
      <Pagination count={props.count} page={props.page} color="primary" onChange={(event, value) => { { props.setpage(value) } }} />
    </>
  )
}

export default Paginations;