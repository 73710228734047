import React, { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import VerifyLogo from '../../Assets/images/verify.png';
import { otpValidation } from '../../Context/validation';
import { toast, ToastContainer } from 'react-toastify'
import OtpInput from 'react-otp-input'
import axios from 'axios';
import { statusCodes, commonMessage } from '../../Constants/generalConfig'
import { enterOtpApi, resendOTPApi } from '../../Context/api'
import { useNavigate } from 'react-router-dom'
import UseVerifyOtp from './Hooks/useVerifyOtp'

const VerifyOtp = () => {
  const { navigate,
    resendOtp,
    resetTimer,
    timeOutCallback,
    setTimer,
    timer,
    formik,
    timeZone,
    email,
    mobileNumber } = UseVerifyOtp()

  return (
    <>
      <ToastContainer />
      <section>
        <div className="container d-flex justify-content-center align-items-center cusheight">
          <div className="row shadow_none">
            <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>

              <div className="text-center">
                <img className="mb-2" src={VerifyLogo} alt='' />
                <h1 className="text-center heading-color mb-3">Verify OTP</h1>
                <p> Enter OTP Code sent on your {timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile Number' : 'Email'}.</p>
                <form className="cus-form" onSubmit={formik.handleSubmit}>
                  <div className="pin-code mb-3">
                    <OtpInput
                      isInputNum
                      value={formik.values.number}
                      onChange={(e) => {
                        formik.setFieldValue('number', e)
                      }}
                      numInputs={4}
                    />
                  </div>
                  {formik.touched.number && formik.errors.number ? (
                    <div className='err_msg'>
                      {formik.errors.number}
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-between">
                    <p className="small-font">{timer > 0 ? timer : null} {timer > 0 ? 'Second' : null}</p>
                    <p className="small-font" style={{ display: `${timer > 0 ? 'none' : 'block'}`, color: '#212529', cursor: 'pointer', }}
                      onClick={() => { resetTimer(); resendOtp() }}
                    >
                      Resend OTP
                    </p>
                  </div>
                  <div className="d-grid  col-6 mx-auto mb-3 ms-4">
                    <button type="submit" className="btn colorbtun px">Continue</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default VerifyOtp