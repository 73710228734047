import React, { useContext, useEffect } from 'react'
import StaffNav from '../SideNav/staffNav';
import Routing from '../Routing/routing';
import ProviderNav from '../SideNav/providerNav'
import io from 'socket.io-client';
import { environment } from '../../Context/environment';
import { Notifications } from '../../App';
import { useNavigate } from 'react-router-dom';
import { permissionsForStaff } from '../../Context/api';
import axios from 'axios';
import { statusCodes } from '../../Constants/generalConfig';
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "@mui/icons-material/Menu";


const Layout = () => {
  const { setReceiveData, setSocket, setUserInfo, sideShow, setSideShow } = useContext(Notifications)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate()

  useEffect(() => {
    const connectSocket = () => {
      
      const sockets = io(`${environment.baseUrl}/chat`);
      // console.log(sockets.connected,'connected')
      sockets.on('receive-message', (data) => {
        console.log(data, 'Receive_Message')
        setReceiveData(data?.data);
        if (userData?.userId !== data?.data?.sender_id) {
          showNotification(data);
        }
      });
      sockets.emit('join-conversation', { loggedInUser: userData?.userId });
      setSocket(sockets);
     };
    connectSocket();
    return () => {
      if (setSocket) {
        setSocket(null);
      }
    };
  }, [setReceiveData, setSocket]);



  useEffect(() => {
    staffPermission()
  }, [])


  const staffPermission = () => {
    axios.post(permissionsForStaff(), { userId: userData?.userId, }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        let data = JSON.parse(localStorage.getItem('userData'))
        let updateData = { ...data }
        updateData.allpermission = resp.data.data[0]?.allpermission
        localStorage.setItem("userData", JSON.stringify(updateData))
        setUserInfo(JSON.parse(localStorage.getItem('userData')))
        // console.log(resp.data.data[0]?.allpermission, 'lllllllllll')
      }
      else {
      }
    }).catch(() => { })

  }



  const showNotification = (message) => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        sendNotification(message);
      } else if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            sendNotification(message);
          }
        });
      }
    }
  };
  const sendNotification = (message) => {
    const notification = new Notification(message?.data?.message);
    notification.onclick = function (event) {
      event.preventDefault();
      navigate(`chat`);
    };
  };

  return (
    <>
      <div className="main-background container-fluid">
        <div className="row phonehide">
          <div className="col-2 px-0 max-width">
            {userData?.roleTitle === "STAFF" ? <StaffNav /> : <ProviderNav />}
          </div>
          <div className="col-10 px-0">
            <Routing />
          </div>
        </div>
      </div>
      <div className="main-background webhide">
        <div className="col-lg-12 bgoffcanvas p-2 text-end">
          <div className="d-flex justify-content-between">
            <div className="col-lg-2 pt-2   ">
              <h4 style={{ color: "#ffffff" }}> ISKEDO</h4>
            </div>
            <div className="col-lg-2">
              <Button
                variant=""
                style={{ backgroundColor: "#ffffff" }}
                onClick={() => setSideShow(true)}
              >
                <MenuIcon />
              </Button>

              <Offcanvas show={sideShow} onHide={() => setSideShow(false)}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    <h4 className="p-0 m-0">ISKEDO</h4>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {userData?.roleTitle === "STAFF" ? (
                    <StaffNav />
                  ) : (
                    <ProviderNav />
                  )}
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <Routing />
        </div>
      </div>
    </>
  );
};


export default Layout;
