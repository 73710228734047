import React from 'react'
import OtpLogo from '../../Assets/images/enter0tp.png'
import { ToastContainer } from 'react-toastify'
import OtpInput from 'react-otp-input'
import UseEnterOtp from './Hooks/useEnterOtp'


const EnterOtp = () => {
  const {
    resetTimer,
    resendOtp,
    otpForm,
    timeZone,
    timer
  } = UseEnterOtp()

  return (
    <>
      <ToastContainer />
      <section>
        <div className="container d-flex justify-content-center align-items-center cusheight">
          <div className="row">
            <div className="col-sm-12 bg-white shadow" style={{ padding: '90px' }}>
              <div className="text-center">
                <img className="mb-2" src={OtpLogo} alt="" />
                <h1 className="text-center heading-color mb-3">Enter OTP</h1>
                <p>Enter OTP has been sent to your registered {timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile number' : 'Email'}.</p>
                <form className="cus-form" onSubmit={otpForm.handleSubmit}>
                  <div className="pin-code mb-3">
                    <OtpInput
                      isInputNum
                      value={otpForm.values.number}
                      onChange={(e) => {
                        otpForm.setFieldValue('number', e)
                      }}
                      numInputs={4}
                    />
                  </div>
                  {otpForm.touched.number && otpForm.errors.number ? <div className='err_msg'>{otpForm.errors.number}</div> : null}
                  <div className="d-flex justify-content-between">
                    <p className="small-font">
                      {timer > 0 ? `OTP will expire in ${timer}` : null} {timer > 0 ? 'seconds' : null}
                    </p>
                    <p
                      className="small-font"
                      style={{ display: `${timer > 0 ? 'none' : 'block'}`, color: '#212529', cursor: 'pointer' }}
                      onClick={() => {
                        resetTimer()
                        resendOtp()
                      }}
                    >
                      Resend OTP
                    </p>
                  </div>
                  <div className="d-grid  col-6 mx-auto mb-3">
                    <button type="submit" className="btn colorbtun px1">
                      Verify
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EnterOtp
