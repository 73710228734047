import React from 'react'
import { NavLink } from 'react-router-dom';

const InsightList = () => {
  return (
    <>
      <section>
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className='page-tittle'>Insights</h2>
            <hr />
          </header>
          <div className="row">
            <div className="col-sm-12 listpadding">
              <ul className="cus-list">
                <li>
                  <NavLink to='bookingDetails'><div className="d-flex justify-content-between">
                    <div>Booking Details</div>
                    <i className="fa-solid fa-angle-right"></i>
                  </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='clientReport'>
                    <div className="d-flex justify-content-between">
                      <div>Client Reports</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='teamReport'>
                    <div className="d-flex justify-content-between">
                      <div>Team Reports</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='serviceReport'>
                    <div className="d-flex justify-content-between">
                      <div>Services</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='rushHours'>
                    <div className="d-flex justify-content-between">
                      <div>Rush Hours</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='feedBack'>
                    <div className="d-flex justify-content-between">
                      <div>Feedback</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='demoGraphics'>
                    <div className="d-flex justify-content-between">
                      <div>Demographics</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='combo-report'>
                    <div className="d-flex justify-content-between">
                      <div>Combo Reports</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='combo-package-report'>
                    <div className="d-flex justify-content-between">
                      <div>Combo Package Reports</div>
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InsightList;