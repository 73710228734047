import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../Assets/images/logo white color.png'
import { rejectInvitation } from '../../Context/api';

const Rejectpage = () => {
    const [message, setMessage] = useState('')
    const { token } = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        invitationReject()
    }, [])

    const invitationReject = () => {
        axios.post(rejectInvitation(), { token: token }).then((resp) => {
            setMessage(resp.data.message)
        })
    }
    return (
        <>
            <section className="bgImg">
                <nav className="navbar navbar-expand-lg pb-2 pb-mg-0 pb-lg-0">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src={logo} height="50px" className="img-fluid my-3" onClick={() => navigate('/')}
                                style={{ margin: '0px !important', maxWidth: '100%', padding: '0px' }} width="60px" alt="" />
                        </a>
                    </div>
                </nav>

                <div className="d-flex justify-content-around padd">
                    <div className=" my-5">
                        {message === 'Token is expire' ? <h2>Token is expired</h2> : <h1>You have declined Invitation</h1>}
                    </div>
                    <div className=""></div>
                    <div className=""></div>
                </div>

            </section>
        </>
    )
}

export default Rejectpage;