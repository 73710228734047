import React from 'react'
import Download from '../../Assets/images/download.png'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Spinner from '../../Components/spinner/spinner'
import Pagination from '../../Components/Pagination/pagination'
import ServiceImg from '../../Assets/images/services-icon.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord';
import UseServiceList from './Hooks/useServiceList'
import { ToastContainer } from 'react-toastify';
import UseImage from '../../Hooks/useImage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'react-bootstrap';
import { Backdrop, Button } from '@mui/material';
import { intialRole } from '../../Utils/Utils';

const ServiceList = () => {
  const {
    serviceList,
    listDuration,
    serviceView,
    changeServiceStatus,
    enterSearch,
    setImage,
    setServiceId,
    setShowService,
    setShow,
    setPage,
    setIsActive,
    navigate,
    setArchive,
    visit,
    handleCheckboxChange,
    clearAll,
    archive,
    currencyName,
    page,
    count,
    show,
    showService,
    spinner,
    serviceId,
    listData,
    editService,
    addService,
    ServiceCategory,
    image,
    PER_PAGE,
    duration,
    imgPath,
    fileInputRef,
    userInfo } = UseServiceList()

  const { imageDeleteUpload, imageDelete, uploadImage } = UseImage()


  return (
    <>
      <ToastContainer />
      <section className='position-relative'>
        <div className="m-t-3 cus-padd cusheight">
          {spinner ? <Spinner /> : null}
          <header>
            <h2 className="page-tittle"><i className="fa-solid fa-angle-left cusgap cursor mb-1" onClick={() => navigate(-1)}></i>{ServiceCategory}</h2>
            <hr />
          </header>
          <div className="row">
            {
              intialRole({ permission: "services", userData: userInfo, section: 'view', nested: 'service' }) ?
                <div className="col-sm-12 listpadding">
                  <div className="d-flex justify-content-end">
                    <ul className="navicon">
                      <input type="search" className='form-control' placeholder='Search by service'
                        onKeyDown={(e) => enterSearch(e)}
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            serviceList()
                          }
                        }} />
                      <li>
                        <div className="dropdown">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Filter</Tooltip>}>
                            <i className="fa-solid fa-sliders mt-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></i>
                          </OverlayTrigger>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <h6 className="dropdown-item cursor" onClick={() => { setIsActive(true); setPage(1) }}>
                                Un-Archive
                              </h6>
                            </li>
                            <li>
                              <h6 className="dropdown-item cursor" onClick={() => { setIsActive(false); setPage(1) }}>
                                Archive
                              </h6>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {!listData?.length ? <NoRecord /> :
                    <div className="table-responsive">
                      <table className="table cus-table">
                        <thead className="text-center tableheader">
                          <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Services</th>
                            <th scope="col">Duration(Minutes)</th>
                            <th scope="col">Regular Price</th>
                            <th scope="col">Discount Price</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {listData?.map((item) => {
                            return (
                              <React.Fragment key={item._id}>
                                <tr className={intialRole({ permission: "services", userData: userInfo, section: 'edit', nested: 'service' }) ? 'cursor' : null}>
                                  <td style={{ padding: '0.3rem .5rem' }}
                                    onClick={() => intialRole({ permission: "services", userData: userInfo, section: 'edit', nested: 'service' }) ? serviceView(item._id) : null}
                                  >
                                    <img src={item.image === ''||item.image === null ? ServiceImg : imgPath + item.image} alt="" height={30} width={30} style={{ borderRadius: '50%' }} />
                                  </td>
                                  <td onClick={() => intialRole({ permission: "services", userData: userInfo, section: 'edit', nested: 'service' }) ? serviceView(item._id) : null}>{item.name}</td>
                                  <td onClick={() => intialRole({ permission: "services", userData: userInfo, section: 'edit', nested: 'service' }) ? serviceView(item._id) : null}>{item.duration}</td>
                                  <td onClick={() => intialRole({ permission: "services", userData: userInfo, section: 'edit', nested: 'service' }) ? serviceView(item._id) : null}>{currencyName} {item.fixedAmount}</td>
                                  <td onClick={() => intialRole({ permission: "services", userData: userInfo, section: 'edit', nested: 'service' }) ? serviceView(item._id) : null}>{item.variableAmount || item.variableAmount == 0 ? currencyName : '--'} {item.variableAmount}</td>
                                  <td>
                                    {
                                      item.isActive === true ?
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Archive</Tooltip>}>
                                          <svg onClick={() => { setServiceId(item); setArchive(true) }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsvgjs="http://svgjs.com/svgjs" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve">
                                            <g>
                                              <path xmlns="http://www.w3.org/2000/svg" d="m20.54 5.23-1.39-1.68c-.27-.34-.68-.55-1.15-.55h-12c-.47 0-.88.21-1.16.55l-1.38 1.68c-.29.34-.46.79-.46 1.27v12.5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-12.5c0-.48-.17-.93-.46-1.27zm-14.3-.23h11.52l.81.97h-13.13zm-1.24 14v-11h14v11zm8.45-9h-2.9v3h-2.55l4 4 4-4h-2.55z"
                                                fill="#000000"
                                                data-original="#000000"
                                              />
                                            </g>
                                          </svg>
                                        </OverlayTrigger> :
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Un-Archive</Tooltip>}>
                                          <svg onClick={() => { setServiceId(item); setArchive(true) }} style={{ transform: 'scale(0.5)', marginBottom: '-20px', marginTop: '-20px' }} xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V12.85q0-.75.15-1.275.15-.525.55-.975l2.8-3.8q.4-.45.925-.625T11.65 6h24.7q.7 0 1.2.175t.9.625l2.85 3.8q.4.45.55.975.15.525.15 1.275V39q0 1.2-.9 2.1-.9.9-2.1.9Zm.85-30.7H38.1L36.3 9H11.65Zm-.85 3V39h30V14.3Zm13.5 20.2h3V24.45l4.3 4.3 2-2-7.8-7.8-7.8 7.8 2 2 4.3-4.3ZM9 39h30H9Z" /></svg>
                                        </OverlayTrigger>
                                    }
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          })}
                        </tbody>

                      </table>
                      <div className='w-100 d-flex justify-content-center'>
                        {count > 10 &&
                          <Pagination setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                        }
                      </div>
                    </div>
                  }

                </div>
                : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
            }
          </div>
        </div>
        {
          intialRole({ permission: "services", userData: userInfo, section: 'add', nested: 'service' }) ?
            <div className="d-flex justify-content-end position-relative">
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Service</Tooltip>}>
                <button className="btn addbtn2" onClick={() => { setShow(true); listDuration();clearAll() }}>
                  <i className="fa-solid fa-plus"></i>
                </button>
              </OverlayTrigger>
            </div>
            : null
        }
      </section>
      {/* Archive and Un-Archive Start */}
      <Modal centered size='sm' show={archive} onHide={() => setArchive(false)}>
        <div className="modal-content">
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            <p>You want to {serviceId?.isActive === true ? 'Archive' : 'Un-Archive'} this service</p>
            <button type="button" className="btn nobtn" onClick={() => changeServiceStatus()} data-bs-dismiss="modal">
              Yes
            </button>
            <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => setArchive(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
      {/* Archive and Un-Archive End */}

      {/* Add Service Start*/}
      <Backdrop open={show}>
        <Offcanvas show={show} onHide={() => setShow(false)} placement="end" backdrop={false}>
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShow(false); addService.resetForm(); setImage('');clearAll() }}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Add Service
            </h1>
          </div>
          <div className="offcanvas-body">
            {image === '' ?
              <div className="col-6 mx-auto">
                <div className="bodrerer mb-4 ms-5">
                  <label htmlFor="inputTag">
                    <img src={Download} style={{ cursor: 'pointer' }} alt='img' />
                    <input ref={fileInputRef} id="inputTag" type="file" style={{ display: 'none' }} accept="image/*" onChange={(e) => uploadImage(e.target.files[0], 'SERVICES', setImage, fileInputRef)} />
                    <span id="imageName"></span>
                    <p>upload image here</p>
                  </label>
                </div>
              </div>
              :
              <div className="text-center mb-4">
                <img src={imgPath + image} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                <svg id="imageDelete" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" class="bi bi-camera" viewBox="0 0 16 16">
                  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </svg>
                <ul className="dropdown-menu" aria-labelledby="imageDelete">
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => uploadImage(e.target.files[0], 'SERVICES', setImage, fileInputRef)}
                  />
                  <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                  <br />
                  {image === '' ? null : (
                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(image, 'SERVICES', setImage)} startIcon={<DeleteIcon />}>Delete</Button>
                  )}
                </ul>
              </div>
            }
            <form className="cus-form" onSubmit={addService.handleSubmit}>
              <div className="mb-2">
                <label className="form-label">Service Name * </label>
                <input type="text" className="form-control" placeholder="" name="name" onChange={addService.handleChange} value={addService.values.name} maxLength={50} />
                <ValidationMessage formName={addService} name={'name'} />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking Type *</label>
                <div className="col-sm-12">
                  <div className="accordion accordion-flush" id="accordionFlushEx ample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="branch-un">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#un-branch" aria-expanded="false" aria-controls="un-branch">
                          Select  Booking Type
                        </button>
                      </h2>
                      <div id="un-branch" className="accordion-collapse collapse" aria-labelledby="branch-un" data-bs-parent="#accordionFlushEx ample">
                        <div className="accordion-body">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={() => handleCheckboxChange('INOFFICE')}   checked={visit.INOFFICE !== ''} />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                              In-Office
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2" onChange={() => handleCheckboxChange('HOME')}   checked={visit.HOME !== ''}/>
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              Home
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio3" onChange={() => handleCheckboxChange('ONLINE')}  checked={visit.ONLINE !== ''}/>
                            <label className="form-check-label" htmlFor="inlineRadio3">
                              Online
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Duration(Minutes) *</label>
                <select className="form-select form-control" aria-label="Default select example" name="duration" onChange={addService.handleChange} value={addService.values.duration}>
                  <option hidden>Select Duration</option>
                  {
                    duration?.map((item, index) =>
                      <option key={index} value={item.duration}>{item.duration} Minutes</option>
                    )
                  }
                </select>
                <ValidationMessage formName={addService} name={'duration'} />
              </div>
              <div className="mb-3">
                <label className="form-label">Regular Price * </label>
                <input type="number" className="form-control" placeholder="" name="fixedAmount" onChange={addService.handleChange} value={addService.values.fixedAmount} maxLength={6} pattern="[0-9]*"
                  inputMode="numeric"
                  onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                <ValidationMessage formName={addService} name={'fixedAmount'} />
              </div>

              <div className="mb-3">
                <label className="form-label">Discount Price (Optional) </label>
                <input type="number" className="form-control" placeholder="" name="variableAmount" onChange={addService.handleChange} value={addService.values.variableAmount}
                  onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={6} />
                <ValidationMessage formName={addService} name={'variableAmount'} />
              </div>

              <div className="mb-3">
                <label className="form-label">Description *</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="description" onChange={addService.handleChange} value={addService.values.description}></textarea>
                <ValidationMessage formName={addService} name={'description'} />
              </div>
              <div className='mb-3'>
                <p className='font12'><span className='star_clr'>*</span> Note : You must have <span className='cursor navi_clr' onClick={() => navigate('/layout/service/serviceDuration')}>Duration</span> to add service</p>
              </div>

              <div className="d-grid  col-6 mx-auto mb-3">
                <button className="btn colorbtun popupbtn px" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
      </Backdrop>
      {/* Add Service Start*/}

      {/* Edit Service Start*/}
      <Backdrop open={showService}>
        <Offcanvas show={showService} onHide={() => setShowService(false)} placement="end" backdrop={false}>
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowService(false); setImage('');clearAll() }}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Edit Service
            </h1>
          </div>
          <div className="offcanvas-body">
            <div className="col-6 mx-auto">
              <img src={image === ''||image === null ? ServiceImg : imgPath + image} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
              <svg id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-80px', marginLeft: '150px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
              </svg>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <input ref={fileInputRef}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => uploadImage(e.target.files[0], 'SERVICES', setImage, fileInputRef)}
                />
                <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                <br />
                {image === ''||image === null ? null : (
                  <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDelete(serviceId, image, 'SERVICES', '', setImage)} startIcon={<DeleteIcon />}>Delete</Button>
                )}
              </ul>
            </div>
            <form className="cus-form" onSubmit={editService.handleSubmit}>
              <div className="mb-2">
                <label className="form-label">Service Name *</label>
                <input type="text" className="form-control" name="name" onChange={editService.handleChange} value={editService.values.name} maxLength={50} />
                <ValidationMessage formName={editService} name={'name'} />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking Type *</label>
                <div className="col-sm-12">
                  <div className="accordion accordion-flush" id="accordionFlushEx ample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="branch-un">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#un-branch" aria-expanded="false" aria-controls="un-branch">
                          Select  Booking Type
                        </button>
                      </h2>
                      <div id="un-branch" className="accordion-collapse collapse" aria-labelledby="branch-un" data-bs-parent="#accordionFlushEx ample">
                        <div className="accordion-body">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={() => handleCheckboxChange('INOFFICE')}   checked={visit.INOFFICE !== ''} />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                              In-Office
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2" onChange={() => handleCheckboxChange('HOME')}   checked={visit.HOME !== ''}/>
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              Home
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio3" onChange={() => handleCheckboxChange('ONLINE')}  checked={visit.ONLINE !== ''}/>
                            <label className="form-check-label" htmlFor="inlineRadio3">
                              Online
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <label className="form-label">Duration(Minutes) *</label>
                <select className="form-select form-control" aria-label="Default select example" name="duration" onChange={editService.handleChange} value={editService.values.duration}>
                  <option hidden>Select Duration</option>
                  {
                    duration?.map((item, index) =>
                      <option value={item.duration} key={index}>{item.duration} Minutes</option>
                    )
                  }
                </select>
                <ValidationMessage formName={editService} name={'duration'} />
              </div>
              <div className="mb-3">
                <label className="form-label">Regular Price *</label>
                <input type="number" className="form-control" name="fixedAmount" onChange={editService.handleChange} value={editService.values.fixedAmount} maxLength={6} />
                <ValidationMessage formName={editService} name={'fixedAmount'} />
              </div>
              <div className="mb-3">
                <label className="form-label">Discount Price (Optional)</label>
                <input type="number" className="form-control" name="variableAmount" onChange={editService.handleChange}
                  // value={editService.values.variableAmount}
                  value={editService.values.variableAmount !== null ? editService.values.variableAmount : ''}
                  maxLength={6} />
                <ValidationMessage formName={editService} name={'variableAmount'} />
              </div>
              <div className="mb-3">
                <label className="form-label">Description *</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="description" onChange={editService.handleChange} value={editService.values.description}></textarea>
                <ValidationMessage formName={editService} name={'description'} />
              </div>

              <div className="d-grid  col-6 mx-auto mb-3">
                <button className="btn colorbtun popupbtn px" type="submit" data-bs-dismiss="offcanvas">
                  Update
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
      </Backdrop>
      {/* Edit Service End*/}
    </>
  )
}

export default ServiceList
