import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import TimePicker from 'rc-time-picker'
import { Backdrop } from '@mui/material'

const EditAvailability = ({ showAvailability, setShowAvailability, closeToggleBtn, editAvailability, timeZoneData, listData, defaultFormValue, valueChangeToggleSwitch, showAdditionalTimingsBox, hideAdditionalTimingsBox }) => {
    return (
        <>
            <Backdrop open={showAvailability}>
                <Offcanvas show={showAvailability} onHide={() => setShowAvailability(false)} placement="end" backdrop={false}>
                    <div style={{ padding: '10px' }}>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas" 
                            aria-label="Close"
                            onClick={() => {
                                closeToggleBtn()
                                setShowAvailability(false)
                            }}
                        ></button>
                        <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                            Edit Availability
                        </h1>
                    </div>
                    <div className="offcanvas-body">
                        <form className="cus-form" onSubmit={editAvailability.handleSubmit}>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <select className="form-select form-control" aria-label="Default select example" name="timezone" onChange={editAvailability.handleChange} value={editAvailability.values.timezone}>
                                        <option value='' hidden>Select time zone</option>
                                        {timeZoneData?.map((item, index) => (
                                            <option value={item.name} key={index}>
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <select className="form-control" aria-label="Default select example" name="branchId" onChange={editAvailability.handleChange} value={editAvailability.values.branchId} disabled>
                                    <option hidden>Select Branch</option>
                                    {listData?.map((item, index) => (
                                        <option value={item._id} key={index}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Sunday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[0].isChecked}
                                        onChange={() => valueChangeToggleSwitch(0)}
                                        role="switch"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseOne" className={defaultFormValue[0].isChecked ? 'collapse show' : 'collapse'} aria-labelledby="flush-headingOne">
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-3">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeSunday"
                                            onChange={(e) => {
                                                editAvailability.setFieldValue('startTimeSunday', e)
                                            }}
                                            value={editAvailability.values.startTimeSunday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeSunday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeSunday', e)}
                                            value={editAvailability.values.endTimeSunday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(0)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[0].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeSunday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeSunday', e)}
                                                value={editAvailability.values.secondStartTimeSunday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeSunday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeSunday', e)}
                                                value={editAvailability.values.secondEndTimeSunday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(0, 1)
                                                        editAvailability.setFieldValue('secondStartTimeSunday', '')
                                                        editAvailability.setFieldValue('secondEndTimeSunday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {defaultFormValue[0].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeSunday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeSunday', e)}
                                                value={editAvailability.values.thirdStartTimeSunday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeSunday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeSunday', e)}
                                                value={editAvailability.values.thirdEndTimeSunday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(0, 2)
                                                        editAvailability.setFieldValue('thirdStartTimeSunday', '')
                                                        editAvailability.setFieldValue('thirdEndTimeSunday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Monday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[1].isChecked}
                                        onChange={() => valueChangeToggleSwitch(1)}
                                        role="switch"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseTwo" className={defaultFormValue[1].isChecked ? 'collapse show' : 'collapse'}>
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-3">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeMonday"
                                            onChange={(e) => editAvailability.setFieldValue('startTimeMonday', e)}
                                            value={editAvailability.values.startTimeMonday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeMonday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeMonday', e)}
                                            value={editAvailability.values.endTimeMonday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(1)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[1].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeMonday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeMonday', e)}
                                                value={editAvailability.values.secondStartTimeMonday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeMonday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeMonday', e)}
                                                value={editAvailability.values.secondEndTimeMonday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(1, 1);
                                                        editAvailability.setFieldValue('secondStartTimeMonday', '')
                                                        editAvailability.setFieldValue('secondEndTimeMonday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {defaultFormValue[1].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeMonday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeMonday', e)}
                                                value={editAvailability.values.thirdStartTimeMonday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeMonday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeMonday', e)}
                                                value={editAvailability.values.thirdEndTimeMonday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(1, 2)
                                                        editAvailability.setFieldValue('thirdStartTimeMonday', '')
                                                        editAvailability.setFieldValue('thirdEndTimeMonday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Tuesday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[2].isChecked}
                                        onChange={() => valueChangeToggleSwitch(2)}
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseThree" className={defaultFormValue[2].isChecked ? 'collapse show' : 'collapse'}>
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-3">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeTuesday"
                                            onChange={(e) => editAvailability.setFieldValue('startTimeTuesday', e)}
                                            value={editAvailability.values.startTimeTuesday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeTuesday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeTuesday', e)}
                                            value={editAvailability.values.endTimeTuesday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(2)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[2].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeTuesday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeTuesday', e)}
                                                value={editAvailability.values.secondStartTimeTuesday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeTuesday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeTuesday', e)}
                                                value={editAvailability.values.secondEndTimeTuesday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(2, 1)
                                                        editAvailability.setFieldValue('secondStartTimeTuesday', '')
                                                        editAvailability.setFieldValue('secondEndTimeTuesday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {defaultFormValue[2].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeTuesday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeTuesday', e)}
                                                value={editAvailability.values.thirdStartTimeTuesday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeTuesday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeTuesday', e)}
                                                value={editAvailability.values.thirdEndTimeTuesday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(2, 2)
                                                        editAvailability.setFieldValue('thirdStartTimeTuesday', '')
                                                        editAvailability.setFieldValue('thirdEndTimeTuesday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Wednesday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[3].isChecked}
                                        onChange={() => valueChangeToggleSwitch(3)}
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFour"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFour"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseFour" className={defaultFormValue[3].isChecked ? 'collapse show' : 'collapse'}>
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-3">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeWednesday"
                                            onChange={(e) => editAvailability.setFieldValue('startTimeWednesday', e)}
                                            value={editAvailability.values.startTimeWednesday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeWednesday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeWednesday', e)}
                                            value={editAvailability.values.endTimeWednesday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(3)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[3].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeWednesday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeWednesday', e)}
                                                value={editAvailability.values.secondStartTimeWednesday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeWednesday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeWednesday', e)}
                                                value={editAvailability.values.secondEndTimeWednesday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(3, 1)
                                                        editAvailability.setFieldValue('secondStartTimeWednesday', '')
                                                        editAvailability.setFieldValue('secondEndTimeWednesday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {defaultFormValue[3].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeWednesday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeWednesday', e)}
                                                value={editAvailability.values.thirdStartTimeWednesday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeWednesday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeWednesday', e)}
                                                value={editAvailability.values.thirdEndTimeWednesday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                                        hideAdditionalTimingsBox(3, 2)
                                                        editAvailability.setFieldValue('thirdStartTimeWednesday', '')
                                                        editAvailability.setFieldValue('thirdEndTimeWednesday', '')
                                                    }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Thursday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[4].isChecked}
                                        onChange={() => valueChangeToggleSwitch(4)}
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFive"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFive"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseFive" className={defaultFormValue[4].isChecked ? 'collapse show' : 'collapse'}>
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-3">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeThursday"
                                            onChange={(e) => editAvailability.setFieldValue('startTimeThursday', e)}
                                            value={editAvailability.values.startTimeThursday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeThursday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeThursday', e)}
                                            value={editAvailability.values.endTimeThursday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i
                                                    className="fa-solid fa-plus cus-plus"
                                                    onClick={() => {
                                                        showAdditionalTimingsBox(4)
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[4].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeThursday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeThursday', e)}
                                                value={editAvailability.values.secondStartTimeThursday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeThursday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeThursday', e)}
                                                value={editAvailability.values.secondEndTimeThursday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i
                                                        className="fa-solid fa-minus cus-minus"
                                                        onClick={() => {
                                                            hideAdditionalTimingsBox(4, 1)
                                                            editAvailability.setFieldValue('secondStartTimeThursday', '')
                                                            editAvailability.setFieldValue('secondEndTimeThursday', '')
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {defaultFormValue[4].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeThursday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeThursday', e)}
                                                value={editAvailability.values.thirdStartTimeThursday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeThursday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeThursday', e)}
                                                value={editAvailability.values.thirdEndTimeThursday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i
                                                        className="fa-solid fa-minus cus-minus"
                                                        onClick={() => {
                                                            hideAdditionalTimingsBox(4, 2)
                                                            editAvailability.setFieldValue('thirdStartTimeThursday', '')
                                                            editAvailability.setFieldValue('thirdEndTimeThursday', '')
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Friday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[5].isChecked}
                                        onChange={() => valueChangeToggleSwitch(5)}
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSix"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSix"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseSix" className={defaultFormValue[5].isChecked ? 'collapse show' : 'collapse'}>
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-3">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeFriday"
                                            onChange={(e) => editAvailability.setFieldValue('startTimeFriday', e)}
                                            value={editAvailability.values.startTimeFriday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeFriday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeFriday', e)}
                                            value={editAvailability.values.endTimeFriday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i
                                                    className="fa-solid fa-plus cus-plus"
                                                    onClick={() => {
                                                        showAdditionalTimingsBox(5)
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[5].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeFriday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeFriday', e)}
                                                value={editAvailability.values.secondStartTimeFriday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeFriday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeFriday', e)}
                                                value={editAvailability.values.secondEndTimeFriday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i
                                                        className="fa-solid fa-minus cus-minus"
                                                        onClick={() => {
                                                            hideAdditionalTimingsBox(5, 1)
                                                            editAvailability.setFieldValue('secondStartTimeFriday', '')
                                                            editAvailability.setFieldValue('secondEndTimeFriday', '')
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {defaultFormValue[5].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeFriday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeFriday', e)}
                                                value={editAvailability.values.thirdStartTimeFriday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeFriday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeFriday', e)}
                                                value={editAvailability.values.thirdEndTimeFriday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i
                                                        className="fa-solid fa-minus cus-minus"
                                                        onClick={() => {
                                                            hideAdditionalTimingsBox(5, 2)
                                                            editAvailability.setFieldValue('thirdStartTimeFriday', '')
                                                            editAvailability.setFieldValue('thirdEndTimeFriday', '')
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between form-control mb-3">
                                <h3>Saturday</h3>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={defaultFormValue[6].isChecked}
                                        onChange={() => valueChangeToggleSwitch(6)}
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeven"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeven"
                                    />
                                </div>
                            </div>
                            <div className="accordion">
                                <div id="flush-collapseSeven" className={defaultFormValue[6].isChecked ? 'collapse show' : 'collapse'}>
                                    <div style={{ display: 'flex', marginLeft: '20px' }}>
                                        <p>From</p>
                                        <p style={{ marginLeft: '140px' }}>To</p>
                                    </div>
                                    <div style={{ display: 'flex' }} className="mb-5">
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            style={{ marginRight: '20px' }}
                                            name="startTimeSaturday"
                                            onChange={(e) => editAvailability.setFieldValue('startTimeSaturday', e)}
                                            value={editAvailability.values.startTimeSaturday}
                                            autoComplete="off"
                                        />
                                        <TimePicker
                                            placeholder="Select Time"
                                            use12Hours
                                            showSecond={false}
                                            focusOnOpen={true}
                                            format="hh:mm A"
                                            name="endTimeSaturday"
                                            onChange={(e) => editAvailability.setFieldValue('endTimeSaturday', e)}
                                            value={editAvailability.values.endTimeSaturday}
                                            autoComplete="off"
                                        />
                                        <div className="col-sm-1 offset-sm-1">
                                            <div className="d-flex justify-content-end">
                                                <i
                                                    className="fa-solid fa-plus cus-plus"
                                                    onClick={() => {
                                                        showAdditionalTimingsBox(6)
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    {defaultFormValue[6].isAllowedTimes.first ? (
                                        <div style={{ display: 'flex' }} className="mb-3">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="secondStartTimeSaturday"
                                                onChange={(e) => editAvailability.setFieldValue('secondStartTimeSaturday', e)}
                                                value={editAvailability.values.secondStartTimeSaturday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="secondEndTimeSaturday"
                                                onChange={(e) => editAvailability.setFieldValue('secondEndTimeSaturday', e)}
                                                value={editAvailability.values.secondEndTimeSaturday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i
                                                        className="fa-solid fa-minus cus-minus"
                                                        onClick={() => {
                                                            hideAdditionalTimingsBox(6, 1)
                                                            editAvailability.setFieldValue('secondStartTimeSaturday', '')
                                                            editAvailability.setFieldValue('secondEndTimeSaturday', '')
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    {defaultFormValue[6].isAllowedTimes.second ? (
                                        <div style={{ display: 'flex' }} className="mb-5">
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                style={{ marginRight: '20px' }}
                                                name="thirdStartTimeSaturday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdStartTimeSaturday', e)}
                                                value={editAvailability.values.thirdStartTimeSaturday}
                                                autoComplete="off"
                                            />
                                            <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                name="thirdEndTimeSaturday"
                                                onChange={(e) => editAvailability.setFieldValue('thirdEndTimeSaturday', e)}
                                                value={editAvailability.values.thirdEndTimeSaturday}
                                                autoComplete="off"
                                            />
                                            <div className="col-sm-1 offset-sm-1">
                                                <div className="d-flex justify-content-end">
                                                    <i
                                                        className="fa-solid fa-minus cus-minus"
                                                        onClick={() => {
                                                            hideAdditionalTimingsBox(6, 2)
                                                            editAvailability.setFieldValue('thirdStartTimeSaturday', '')
                                                            editAvailability.setFieldValue('thirdEndTimeSaturday', '')
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-grid  col-6 mx-auto mb-3">
                                <button className="btn colorbtun popupbtn px" type="submit" data-bs-dismiss="offcanvas">
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas>
            </Backdrop>
        </>
    )
}

export default EditAvailability