import React, { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ValidationMessage from '../ValidationMessage/validationMessage';
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import JoditEditor from 'jodit-react';
import Download from '../../Assets/images/download.png'
// import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Backdrop, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { IoIosArrowDown } from 'react-icons/io';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddOffer = ({rewardOffer,showOffer, setShowOffer, createOffer, setImageData, setMale, setFemale, setOther, imageUpload, imageData, male, female, other, date, valuetext, navigate, serviceList, handleChange,
    value, imgFolder, setInActive, imageDeleteUpload, fileInputRef, CustomAutocomplete, zeroOffer, setZeroOffer }) => {
        const [open, setOpen] = useState(false);
    return (
        <>
            <Backdrop open={showOffer}>
                <Offcanvas show={showOffer} onHide={() => setShowOffer(false)} placement="end" backdrop={false}>
                    <div style={{ padding: '10px' }}>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowOffer(false); createOffer.resetForm(); setImageData(''); setMale(false); setFemale(false); setOther(false); setZeroOffer(false) }}></button>
                        <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                           {rewardOffer?'Create Reward':' Create Offer'}
                        </h1>
                    </div>
                    <div className="offcanvas-body">
                        {imageData === '' ?
                            <div className="col-6 mx-auto">
                                <div className="bodrerer mb-4 ms-5">
                                    <label htmlFor="inputTag">
                                        <img src={Download} style={{ cursor: 'pointer' }} alt='offer_image' />
                                        <input id="inputTag" type="file" style={{ display: 'none' }} accept="image/*" onChange={(e) => imageUpload(e.target.files[0], 'OFFER', setImageData, fileInputRef)} />
                                        <span id="imageName"></span>
                                        <p>upload image here</p>
                                    </label>
                                </div>
                            </div>
                            :
                            <div className="text-center mb-4">
                                <img src={imgFolder + imageData} width={200} height={200} style={{ borderRadius: '50%' }} alt="" />
                                <svg id="imageDelete" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '150px', marginLeft: '-55px', fill: 'rgb(76 68 68)' }} width="40" height="35" className="bi bi-camera" viewBox="0 0 16 16">
                                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                </svg>
                                <ul className="dropdown-menu" aria-labelledby="imageDelete">
                                    <input ref={fileInputRef}
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => imageUpload(e.target.files[0], 'OFFER', setImageData, fileInputRef)}
                                    />
                                    <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => document.getElementById('icon-button-file').click()} startIcon={<EditIcon />}>Edit</Button>

                                    <br />
                                    {imageData === '' ? null : (
                                        <Button variant="text" sx={{ color: 'black', fontSize: '12px', marginLeft: '10px' }} onClick={() => imageDeleteUpload(imageData, 'OFFER', setImageData)} startIcon={<DeleteIcon />}>Delete</Button>
                                    )}
                                </ul>
                            </div>
                        }
                        <form className="cus-form" onSubmit={createOffer?.handleSubmit}>
                            <div className="mb-2">
                                <label className="form-label">Name * </label>
                                <input type="text" className="form-control" placeholder="" name="name" onChange={createOffer.handleChange} value={createOffer.values.name} maxLength={45} />
                                <ValidationMessage formName={createOffer} name={'name'} />
                            </div>
                            {
                                rewardOffer &&
                                <>
                            <div className='d-flex mt-3'>
                                <p>Better luck next time</p>
                                <div className="form-check form-switch ms-2">
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheck" name='zeroOffer' onChange={(e) => setZeroOffer(e.target.checked)} value={zeroOffer} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Probability * </label>
                                <select className="form-select form-control" aria-label="Default select example" name='probability'
                                    value={createOffer.values.probability}
                                    onChange={createOffer.handleChange}
                                >
                                    <option hidden>Select Probability</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </select>
                                <ValidationMessage formName={createOffer} name={'probability'} />
                            </div>
                                </>
                            }
                            {
                                zeroOffer ? null
                                    :
                                    <>
                                    {
                                        rewardOffer &&
                                        <div className="mb-3">
                                            <label className="form-label">Audience *</label>
                                            <div className="col-sm-12">
                                                <div className="accordion accordion-flush" id="accordionFlushEx ample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="branch-un">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#un-branch" aria-expanded="false" aria-controls="un-branch">
                                                                Custom Audience
                                                            </button>
                                                        </h2>
                                                        <div id="un-branch" className="accordion-collapse collapse" aria-labelledby="branch-un" data-bs-parent="#accordionFlushEx ample">
                                                            <div className="accordion-body">
                                                                <label className="form-label">Gender</label>
                                                                <br />
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1" onChange={() => setMale(!male)} checked={male ? true : false} />
                                                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                                                        Male
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2" onChange={() => setFemale(!female)} checked={female ? true : false} />
                                                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                                                        Female
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio3" onChange={() => setOther(!other)} checked={other ? true : false} />
                                                                    <label className="form-check-label" htmlFor="inlineRadio3">
                                                                        Other
                                                                    </label>
                                                                </div>
                                                                <br />
                                                                <label className="form-label mt-3">Age Group</label>
                                                                <br />
                                                                <Box sx={{ width: 300 }}>
                                                                    <Slider getAriaLabel={() => 'Temperature range'} value={value} onChange={handleChange} valueLabelDisplay="auto" getAriaValueText={valuetext} />
                                                                </Box>
                                                                <br />
                                                                <input type="checkbox" value={true} name="inActive" onChange={() => setInActive(true)} />
                                                                <label className="form-label  ms-2">In Active</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                        <div className="mb-3">
                                            <label className="form-label">Date Range * </label>
                                            <div className="row">
                                                <div className="col-5">
                                                    <input type="date" className="form-control" placeholder="" name="startDate" onChange={createOffer.handleChange} value={createOffer.values.startDate} />
                                                </div>
                                                <div className="col-auto">-</div>
                                                <div className="col-5">
                                                    <input type="date" className="form-control" placeholder="" name="endDate" onChange={createOffer.handleChange} value={createOffer.values.endDate} />
                                                </div>
                                            </div>
                                            {(createOffer.touched.startDate && createOffer.errors.startDate) || (createOffer.touched.endDate && createOffer.errors.endDate) ? (
                                                <div className='err_msg'>{createOffer.errors.startDate || createOffer.errors.endDate}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Service *</label>
                                            <CustomAutocomplete multiple className="w-100" id="checkboxes-tags-demo" options={serviceList}
                                                onChange={(event, newValue) => { createOffer.setFieldValue("service", newValue) }}
                                                getOptionLabel={(option) => option?.name}
                                                groupBy={(option) => option?.categoryName}
                                                name='service'
                                                value={createOffer.values.service}
                                                size="small"
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                sx={{ background: '#fcf6f3', boxShadow: '0px 1px 3px #fbbf9e', }}
                                                disableCloseOnSelect
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                                        {option.name}
                                                    </li>
                                                )}
                                                style={{ width: 500 }}
                                                renderInput={(params) => (<TextField {...params} label=" " style={{ color: "red" }} InputLabelProps={{ shrink: false }} />)}
                                                popupIcon={<IoIosArrowDown style={{ fontSize: "17px", fontWeight: "800", color: "#000" }} />} // Custom dropdown arrow icon
                                                disableClearable
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Discount Type * </label>
                                            <select className="form-select form-control" aria-label="Default select example" name='discountType'
                                                value={createOffer.values.discountType}
                                                onChange={createOffer.handleChange}
                                            >
                                                <option hidden>Select Discount Type</option>
                                                <option value="FLAT">Flat</option>
                                                <option value="PERCENT">Percent</option>
                                            </select>
                                            <ValidationMessage formName={createOffer} name={'discountType'} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Discount Value *</label>
                                            <input className='form-control' type="number" min={0} name="discountValue" value={createOffer.values.discountValue} onChange={createOffer.handleChange}
                                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={10} />
                                            <ValidationMessage formName={createOffer} name={'discountValue'} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Max Discount upto Value * </label>
                                            <input className='form-control' type="number" min={0} name="maximumValue" value={createOffer.values.maximumValue} onChange={createOffer.handleChange}
                                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} maxLength={10} />
                                            <ValidationMessage formName={createOffer} name={'maximumValue'} />
                                        </div>
                                    </>
                            }
                            <div className="mb-3">
                                <label className="form-label">Description * </label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" name="description" onChange={createOffer.handleChange} value={createOffer.values.description}></textarea>
                                <ValidationMessage formName={createOffer} name={'description'} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Terms & Conditions * </label>
                                <JoditEditor tabIndex={1}
                                    name='terms_condition'
                                    onChange={(e) => createOffer.setFieldValue('terms_condition', e)}
                                    value={createOffer.values.terms_condition}
                                />
                                <ValidationMessage formName={createOffer} name={'terms_condition'} />
                            </div>
                            <div className='mb-5'>
                                <p className='font12'><span className='star_clr'>*</span> Note : You must have <span className='cursor navi_clr' onClick={() => navigate('/layout/service')}>Services</span> to add offer.</p>
                            </div>
                            <div className="d-grid  col-6 mx-auto mb-3">
                                <button className="btn colorbtun popupbtn px" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas>
            </Backdrop>
        </>
    )
}

export default AddOffer