import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import TimePicker from "rc-time-picker";
import { ToastContainer } from "react-toastify";
import moment from "moment/moment";
import Spinner from "../../Components/spinner/spinner";
import ValidationMessage from "../ValidationMessage/validationMessage";
import Paginations from "../../Components/Pagination/pagination";
import { AsyncPaginate } from "react-select-async-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NoRecord from "../../Components/noRecord";
import UseWaitingList from "./Hooks/useWaitingList";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { intialRole } from '../../Utils/Utils'
import { Backdrop } from '@mui/material'

const WaitingList = () => {
  const {
    multipleServicesList,
    getWaitingList,
    getBranchList,
    handleInputChange,
    bookAppointment,
    removeWaitingList,
    priceFun,
    servicesList,
    addTime,
    loadClientOptions,
    setModalShow,
    setWaitingId,
    setPage,
    setClientPerPage,
    setModalBook,
    modalBook,
    page,
    count,
    filter,
    spinner,
    waitListData,
    serviceList,
    serviceCategory,
    clientList,
    teamList,
    branchList,
    createWaitingList,
    modalShow,
    PER_PAGE,
    date,
    userData,
    show, setShow,
    userInfo } = UseWaitingList()


  return (
    <>
      <ToastContainer />
      <section className="position-relative">
        <div className="m-t-3 cus-padd cusheight">
          {spinner && <Spinner />}
          <header>
            <h2 className="page-tittle">Waiting List</h2>
            <hr />
          </header>
          <div className="row">
            {
              intialRole({ permission: "waiting List", userData: userInfo, section: 'view' }) ?
                !waitListData?.length ?
                  <NoRecord />
                  :
                  <div className="col-sm-12 listpadding">
                    <div className="d-flex justify-content-end">
                      <div className='me-2'>
                        <select className='form-select ms-2' name='staff' onChange={(e) => handleInputChange(e)} value={filter.staff}>
                          <option value="" hidden>Team</option>
                          {teamList?.map((item) => (
                            <option value={item.name} key={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <select className='form-select ms-2' name='client' onChange={(e) => handleInputChange(e)} value={filter.client}>
                          <option value="" hidden>Client</option>
                          {clientList?.map((item) => (
                            <option value={item.name} key={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='ms-3 phonehide'>
                        <input className='form-control' type="date" name="date" onChange={(e) => handleInputChange(e)} value={filter.date} style={{ height: '37px' }} />
                      </div>
                      <div className="ms-2 webhide"></div>
                      <button className='btn  ms-2 btn_Style' type='submit' onClick={() => filter.staff === '' && filter.client === '' && filter.date === '' ? null : getWaitingList()}>Search</button>
                      <i className="fa fa-refresh ms-2 mt-2" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => filter.staff === '' && filter.client === '' && filter.date === '' ? null : window.location.reload()}></i>
                    </div>

                    <div className="table-responsive mt-3">
                      <table className="table cus-table">
                        <thead className="text-center tableheader">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Team</th>
                            <th scope="col">Branch</th>
                            <th scope="col">Price</th>
                            <th scope="col">Client</th>
                            <th scope="col">Service</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {waitListData?.map((item, index) => (
                            <tr key={index}>
                              <td>{moment(item.startDateTime).format('DD-MM-YYYY')}</td>
                              <td>{moment(item.startDateTime).format('LT')} - {moment(item.endDateTime).format('LT')}</td>
                              <td> {item.staffName}</td>
                              <td>{item.branchName}</td>
                              <td>{item.fixedAmount}</td>
                              <td> {item.clientName}</td>
                              <td>{item.serviceName}</td>
                              <td>
                                <ul className="iconul" style={{ marginBottom: '-1px' }}>
                                  <li>
                                    {
                                      intialRole({ permission: "waiting List", userData: userInfo, section: 'book' }) ?
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reject Appointment</Tooltip>}>
                                          <svg className="cursor" onClick={() => { setWaitingId(item); setShow(true) }} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><circle cx="8" cy="8" r="6.25" /><path d="m4.25 11.75l8-8" /></g></svg>
                                        </OverlayTrigger>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16" fill='#98969682' style={{ color: '#98969682' }}><g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><circle cx="8" cy="8" r="6.25" /><path d="m4.25 11.75l8-8" /></g></svg>

                                    }
                                  </li>
                                  <li>
                                    {
                                      intialRole({ permission: "waiting List", userData: userInfo, section: 'reject' }) ?
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Book Appointment</Tooltip>}>
                                          <svg className="cursor" onClick={() => { setWaitingId(item); setModalBook(true); }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="currentColor" d="M256 48a208 208 0 1 1 0 416a208 208 0 1 1 0-416zm0 464a256 256 0 1 0 0-512a256 256 0 1 0 0 512zm113-303c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111l-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        </OverlayTrigger>
                                        :
                                        <svg style={{ color: '#98969682' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="currentColor" d="M256 48a208 208 0 1 1 0 416a208 208 0 1 1 0-416zm0 464a256 256 0 1 0 0-512a256 256 0 1 0 0 512zm113-303c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111l-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                    }
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className='w-100 d-flex justify-content-center'>
                      {
                        count > 10 &&
                        <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                      }
                    </div>
                  </div>
                : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
            }
          </div>
        </div>
        {
          intialRole({ permission: "waiting List", userData: userInfo, section: 'create' }) ?
            <div className="">
              <p className="btn addbtn2">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Create Waiting List</Tooltip>}>
                  <i className="fa-solid fa-plus" onClick={() => { setModalShow(true); multipleServicesList(); getBranchList(); createWaitingList.setFieldValue('branch', userData?.locationId) }}></i>
                </OverlayTrigger>
              </p>
            </div>
            : null}
      </section>

      {/* Book Apointment Start */}
      <Modal
        centered
        size="sm"
        show={modalBook}
        onHide={() => setModalBook(false)}
      >
        <div className="modal-content">
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            <p>You want to Book this appointment</p>
            <button
              type="button"
              className="btn nobtn"
              data-bs-dismiss="modal"
              onClick={() => setModalBook(false)}
            >
              No
            </button>
            <button
              type="button"
              className="btn yesbtn"
              data-bs-dismiss="modal"
              onClick={() => {
                bookAppointment();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      {/* Book Appointment End */}

      {/* Reject Appointment Start */}
      <Modal size="sm" show={show} centered onHide={() => setShow(false)}>
        <div>
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            <p>You want to Reject this appointment</p>
            <button
              type="button"
              className="btn nobtn"
              data-bs-dismiss="modal"
              onClick={() => removeWaitingList()}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn yesbtn"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      {/* Book Appointment End */}



      {/* Add Waiting List Start */}
      <Backdrop open={modalShow}>
        <Offcanvas show={modalShow} onHide={() => setModalShow(false)} placement="end" backdrop={false}>
          <div style={{ padding: '10px' }}>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setModalShow(false); createWaitingList.resetForm(); setClientPerPage(1) }}></button>
            <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
              Create Waiting List
            </h1>
          </div>
          <div className="offcanvas-body">
            <form className="cus-form" onSubmit={createWaitingList.handleSubmit}>

              <div className="mb-3">
                <label className="form-label">Branch *</label>
                <select className="form-control" aria-label="Default select example" name="branch" onChange={createWaitingList.handleChange} value={createWaitingList.values.branch} disabled>
                  <option value='' hidden >Select Branch</option>
                  {branchList?.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage formName={createWaitingList} name={"branch"} />
              </div>
              <div className="mb-3">
                <label className="form-label">Client *</label>
                <AsyncPaginate
                  loadOptions={loadClientOptions}
                  name="client"
                  value={createWaitingList.values.client}
                  onChange={(e) => {
                    createWaitingList.setFieldValue("client", e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking Type *</label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="visitType"
                  value={createWaitingList.values.visitType}
                  onChange={createWaitingList.handleChange}
                // disabled={createWaitingList.values.clientName.value?.length ? false : true}
                >
                  <option hidden>Select Booking Type</option>
                  <option value='INOFFICE'>In-Office</option>
                  <option value='HOME'>Home</option>
                  <option value='ONLINE'>Online</option>
                </select>
                <ValidationMessage formName={createWaitingList} name={"visitType"} />
              </div>
              <div className="mb-3">
                <label className="form-label">Date *</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  min={date}
                  value={createWaitingList.values.date}
                  onChange={createWaitingList.handleChange}
                />
                <ValidationMessage formName={createWaitingList} name={"date"} />
              </div>
              <div className="mb-3">
                <label className="form-label">Services Category*</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="serviceCategory"
                  value={createWaitingList.values.serviceCategory}
                  onChange={(e) => {
                    createWaitingList.setFieldValue(
                      "serviceCategory",
                      e.target.value
                    );
                    servicesList(e.target.value);
                  }}
                >
                  <option hidden>Select Service Category </option>
                  {serviceCategory?.map((item) => (
                    <option value={item.categoryId} key={item.categoryId}>
                      {item.categoryName}
                    </option>
                  ))}
                </select>
                <ValidationMessage
                  formName={createWaitingList}
                  name={"serviceCategory"}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Service *</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="service"
                  onChange={(e) => {
                    createWaitingList.setFieldValue("service", e.target.value);
                    priceFun(e);
                  }}
                  value={createWaitingList.values.service}
                >
                  <option value="" selected hidden disabled>
                    Select Service
                  </option>
                  {serviceList?.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage
                  formName={createWaitingList}
                  name={"service"}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Price</label>
                <input
                  type="number"
                  className="form-control"
                  disabled
                  name="price"
                  value={createWaitingList.values.price}
                  onChange={createWaitingList.handleChange}
                />
              </div>
              <div className="mb-3">
                <div>
                  <label className="form-label">Start Time *</label>
                  <label className="form-label" style={{ marginLeft: "125px" }}>
                    End Time *
                  </label>
                </div>
                <div className="mb-3 d-flex">
                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    style={{ marginRight: "20px" }}
                    autoComplete="off"
                    name="startTime"
                    value={createWaitingList.values.startTime}
                    onChange={(e) => {
                      createWaitingList.setFieldValue("startTime", e);
                      addTime(e);
                    }}
                  />

                  <TimePicker
                    placeholder="Select Time"
                    use12Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    autoComplete="off"
                    disabled
                    name="endTime"
                    value={createWaitingList.values.endTime}
                    onChange={(e) =>
                      createWaitingList.setFieldValue("endTime", e)
                    }
                  />
                </div>
                {(createWaitingList.touched.startTime &&
                  createWaitingList.errors.startTime) ||
                  (createWaitingList.touched.endTime &&
                    createWaitingList.errors.endTime) ? (
                  <div className="err_msg">
                    {createWaitingList.errors.startTime ||
                      createWaitingList.errors.endTime}
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">Team *</label>
                <select
                  className="form-select form-control"
                  aria-label="Default select example"
                  name="staff"
                  onChange={createWaitingList.handleChange}
                  value={createWaitingList.values.staff}
                >
                  <option value="" selected hidden disabled>
                    Select Team
                  </option>
                  {teamList?.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage formName={createWaitingList} name={"staff"} />
              </div>

              <div className="d-grid  col-6 mx-auto mb-3">
                <button className="btn colorbtun popupbtn px" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Offcanvas>
      </Backdrop>
      {/* Add Waiting List End */}
    </>
  );
};

export default WaitingList;
