import { uploadPoliciesApi, updatePoliciesApi, getPoliciesApi, pdf, deletePdfApi, logOutApi } from '../../../Context/api'
import { toast } from 'react-toastify'
import axios from 'axios'
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App'
const border = {
    textAlign: 'center',
    padding: '3%',
    borderStyle: 'dotted',
    borderRadius: '15px',
}

const UseBookingPolicy = () => {
    const { userInfo } = useContext(Notifications)
    const [policiesData, setPoliciesData] = useState()
    const [pdfdData, setPdfData] = useState([])
    const [show, setShow] = useState(true)
    const [spinner, setSpinner] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [showDelete,setShowDelete]=useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            getPolicies()
            setSpinner(true)
        }
    }, [])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const uploadPdf = (e) => {
        const fd = new FormData()
        const fileTypes = { pdf_File_Type: ['application/pdf'] }

        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 mb')
        if (!e.type.includes(fileTypes.pdf_File_Type)) return toast.warn('Only PDF format allowed')
        fd.append('file', e)
        fd.append('type', 'PDF')
        axios.post(uploadPoliciesApi(), fd, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setPoliciesData(resp.data.data.db_name)
            } else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            } else {
                toast.error(resp.data.message)
            }
        })
    }
    const updatePdf = () => {
        const data={
            loggedInUserId: userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId,
             pdfName: policiesData,
              policyType: 'BOOKING_POLICY',
              branchId:userData?.locationId
        }
        setSpinner(true)
        axios.post(updatePoliciesApi(), {...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                getPolicies()
                toast.success(resp.data.message)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
            }
        })
    }
    const deletePdf = () => {
        let data = { loggedInUserId: userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId, policyType: 'BOOKING_POLICY', pdfName: pdfdData,branchId:userData?.locationId }
        deletePdfApi(data)
        setShow(true)
        setShowDelete(false)
        setPoliciesData('')
    }
    const getPolicies = () => {
        const data={ 
            // loggedInUserId: userData?.userId
            loggedInUserId:userData?.roleTitle==roleNames.SERVICE_PROVIDER?userData?.userId:userData?.createdbyId,
            branchId:userData?.locationId

         }
         if(userData?.roleTitle!==roleNames.SERVICE_PROVIDER){
            data['createdbyId']=userData?.createdbyId
         }
        axios.post(getPoliciesApi(),data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setShow(false)
                setSpinner(false)
                if (resp.data.data.booking === '') setShow(true)
                setPdfData(resp.data.data.booking)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            // else if (resp.data.code === statusCodes.notAuthorized) {
            //     logOutFun()
            // }
            else {
                setSpinner(false)
            }
        })
        .catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    return {
        uploadPdf,
        updatePdf,
        deletePdf,
        setPoliciesData,
        setPdfData,
        setShow,
        setSpinner,
        navigate,
        setShowDelete,
        showDelete,
        userData,
        spinner,
        show,
        pdfdData,
        policiesData,
        border,
        pdf,
        userInfo
    }
}

export default UseBookingPolicy