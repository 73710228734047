import React, { useContext, useEffect, useState } from 'react'
import logo from '../../Assets/images/logo white color.png'
import './style.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { imgData } from '../../Context/api'
import DummyLogo from '../../Assets/images/dummyimg.png'
import axios from 'axios';
import { logOutApi, notificationList, chatPermission } from '../../Context/api';
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import io from "socket.io-client";
import { Notifications } from '../../App';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import { environment } from '../../Context/environment'
import { toast, ToastContainer } from 'react-toastify'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UseProviderHook from './Hooks/useProviderHook'
import Backdrop from '@mui/material/Backdrop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const StaffNav = () => {
  const {
    data,
    open,
    roleswitch,
    setOpen,
    selectMember,
    setBranchOpen,
    branchShow,
    branchData,
    selectLocation
  } = UseProviderHook()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate()
  const { count, setCount, userInfo, setSideShow } = useContext(Notifications)
  const [chatCheck, setChatCheck] = useState('')
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  useEffect(() => {
    const socket = io(environment.baseUrl)
    // Notification.requestPermission()
    if ('Notification' in window) {
      Notification.requestPermission();
    }
    socket.on('notification', ((data) => { listNotifications(); showNotification(data); }))
    socket.emit("add-user", { user_Id: userData.userId });
  }, [])

  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      listNotifications()
      permissionChat()
    }
  }, [])

  const showNotification = (message) => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        sendNotification(message);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            sendNotification(message);
          }
        });
      }
    }
  };
  const sendNotification = (message) => {
    const notification = new Notification(message?.title);
    notification.onclick = function (event) {
      event.preventDefault();
      if (message.type === 'BOOKAPPOINTMENT' || message.type === 'CANCELAPPOINTMENT' || message.type === 'RESCHEDULEDAPPOINTMENT' || message.type === 'DELETAPPOINTMENT') {
        navigate(`appointmentList/appointmentView/${message.type_id}`);
      }
    };
  };
  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.clear()
        navigate('/')
      } else {
        toast.error(resp.data.message)
      }
    })
      .catch(() => {
        toast.error(commonMessage.networkError)
      })
  }
  const listNotifications = () => {
    const data = {
      receiver: userData?.userId,
    }
    axios.post(notificationList(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCount({ totalCount: resp.data.unreadedCount })
      }
      else {
        setCount({})
      }
    }).catch(() => { })
  }
  const permissionChat = () => {
    axios.post(chatPermission(), { userId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      setChatCheck(resp.data.data.permission.chat)
    }).catch(() => { })
  }
  let checkPlanDetails = Array.isArray(userData?.planDetails)
  if (checkPlanDetails) {
    localStorage.clear();
    navigate('/')
  }
  return (
    <>
      <aside>
        <span className='d-flex justify-content-center align-items-center'> <img src={logo} alt="" width={100} height={100} /></span>
        <div className='mlistenu-'>
          <NavLink to="dashboard" activeclassname="active" onClick={() => setSideShow(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="35" width="45" className="icons-size">
              <path d="M22.417 15.208V5.792h11.791v9.416ZM5.833 20.542V5.792H18.25v14.75Zm16.584 13.625v-14.75h11.791v14.75Zm-16.584 0v-9.459H18.25v9.459Zm2.125-15.75h8.167v-10.5H7.958Zm16.584 13.625h7.541v-10.5h-7.541Zm0-18.959h7.541V7.917h-7.541ZM7.958 32.042h8.167v-5.167H7.958Zm8.167-13.625Zm8.417-5.334Zm0 8.459Zm-8.417 5.333Z" />
            </svg>
            Dashboard
          </NavLink>
          <NavLink to="appointmentList" activeclassname="active" onClick={() => setSideShow(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" className="icons-size">
              <path d="m18.208 29.875-5.291-5.25 1.5-1.458 3.791 3.791 7.375-7.416 1.5 1.458Zm-9.291 5.958q-1.292 0-2.209-.916-.916-.917-.916-2.25V10.292q0-1.292.916-2.23.917-.937 2.209-.937h2.416V3.792h2.292v3.333H26.5V3.792h2.208v3.333h2.375q1.292 0 2.209.937.916.938.916 2.23v22.375q0 1.333-.916 2.25-.917.916-2.209.916Zm0-2.125h22.166q.375 0 .688-.333.312-.333.312-.708V17H7.917v15.667q0 .375.312.708.313.333.688.333Zm-1-18.833h24.166v-4.583q0-.375-.312-.709-.313-.333-.688-.333H8.917q-.375 0-.688.333-.312.334-.312.709Zm0 0V9.25v5.625Z" />
            </svg>
            Appointment List
          </NavLink>
          <NavLink to="availability" activeclassname="active" onClick={() => setSideShow(false)}>
            <i className="fa-sharp fa-regular fa-clock fs-5" style={{ padding: '8px 0px', marginLeft: '10px' }}></i>
            <span style={{ marginLeft: '6px' }}> Availability</span>
          </NavLink>
          <NavLink to="client" activeclassname="active" onClick={() => setSideShow(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="45" className="icons-size">
              <path d="M1.9 40v-4.7q0-1.75.9-3.175Q3.7 30.7 5.3 30q3.65-1.6 6.575-2.3Q14.8 27 17.9 27q3.1 0 6 .7t6.55 2.3q1.6.7 2.525 2.125.925 1.425.925 3.175V40Zm35 0v-4.7q0-3.15-1.6-5.175t-4.2-3.275q3.45.4 6.5 1.175t4.95 1.775q1.65.95 2.6 2.35.95 1.4.95 3.15V40Zm-19-16.05q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1Zm18-7.5q0 3.3-2.1 5.4-2.1 2.1-5.4 2.1-.55 0-1.225-.075T25.95 23.6q1.2-1.25 1.825-3.075.625-1.825.625-4.075t-.625-3.975Q27.15 10.75 25.95 9.3q.55-.15 1.225-.25t1.225-.1q3.3 0 5.4 2.1 2.1 2.1 2.1 5.4ZM4.9 37h26v-1.7q0-.8-.475-1.55T29.25 32.7q-3.6-1.6-6.05-2.15-2.45-.55-5.3-.55-2.85 0-5.325.55T6.5 32.7q-.7.3-1.15 1.05-.45.75-.45 1.55Zm13-16.05q1.95 0 3.225-1.275Q22.4 18.4 22.4 16.45q0-1.95-1.275-3.225Q19.85 11.95 17.9 11.95q-1.95 0-3.225 1.275Q13.4 14.5 13.4 16.45q0 1.95 1.275 3.225Q15.95 20.95 17.9 20.95Zm0 16.05Zm0-20.55Z" />
            </svg>
            Clients
          </NavLink>
          {
            userData?.planDetails?.addons?.length && userData?.planDetails?.addons?.find((item) => item.keyName == 'chat')?.keyValue ?
              <NavLink to="chat" activeclassname="active" onClick={() => setSideShow(false)} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <span>
                  <i className="fs-5 fa-regular fa-comment ms-2" style={{ paddingRight: '8px' }}></i>
                </span>
                Chat
              </NavLink>
              :
              <a className='cursor' style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={() => toast.warn("You don't have permission, Please contact provider")}>
                <span>
                  <i className="fs-5 fa-regular fa-comment ms-2" style={{ paddingRight: '8px' }}></i>
                </span>
                Chat
              </a>
          }
          <NavLink to="notification" activeclassname="active" onClick={() => setSideShow(false)}>
            <Badge style={{ marginLeft: '5px', padding: '5px 0px' }} badgeContent={count.totalCount} color="error">
              <NotificationsNoneIcon />
            </Badge>
            <span style={{ marginLeft: '10px' }}>Notifications</span>
          </NavLink>
          <NavLink to="setting" activeclassname="active" onClick={() => setSideShow(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="35" width="40" className="icons-size">
              <path d="m16.5 35.875-.708-5q-.709-.208-1.667-.729-.958-.521-1.583-1.104l-4.625 2.041-3.542-6.333 4-3q-.042-.417-.104-.896-.063-.479-.063-.937 0-.375.063-.813.062-.437.104-.979l-4-3 3.542-6.167 4.5 1.959q.708-.584 1.625-1.104.916-.521 1.708-.813l.75-4.958h7.042l.666 5q.875.333 1.709.812.833.479 1.541 1.063l4.709-1.959 3.458 6.167-4.167 3.083q.084.5.146.959.063.458.063.833 0 .333-.063.792-.062.458-.104 1l4.042 2.958-3.5 6.333-4.709-2.041q-.75.583-1.541 1.083-.792.5-1.542.708l-.708 5.042Zm3.333-11.125q2.042 0 3.438-1.396 1.396-1.396 1.396-3.396 0-2.041-1.396-3.437t-3.438-1.396q-2 0-3.395 1.396-1.396 1.396-1.396 3.437 0 2 1.396 3.396 1.395 1.396 3.395 1.396Zm0-2.125q-1.125 0-1.895-.792-.771-.791-.771-1.875 0-1.125.791-1.916.792-.792 1.875-.792 1.125 0 1.917.792.792.791.792 1.916t-.792 1.896q-.792.771-1.917.771ZM20 19.958ZM18.292 33.75h3.375l.625-4.625q1.291-.333 2.375-.958 1.083-.625 2.208-1.667l4.208 1.833 1.667-2.916-3.667-2.75q.167-.75.271-1.417.104-.667.104-1.292 0-.708-.104-1.291-.104-.584-.229-1.375l3.708-2.792-1.625-2.875-4.333 1.792q-.792-.917-2.104-1.709-1.313-.791-2.521-.958l-.5-4.542h-3.5l-.5 4.5q-1.417.292-2.562.938-1.146.646-2.188 1.729l-4.167-1.75L7.125 14.5l3.667 2.75q-.209.708-.292 1.375-.083.667-.083 1.333 0 .625.083 1.271.083.646.25 1.438l-3.625 2.75 1.708 2.916L13 26.542q1.125 1.083 2.25 1.708t2.5.958Z" />
            </svg>
            Settings
          </NavLink>
          <a className={`${splitLocation[2] == 'profile' ? 'active' : null} cursor`}>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{userInfo?.businessName}</Tooltip>}>
              <div className='userData-menu' onClick={() => setSideShow(false)}>
                <img src={userInfo?.image === '' ? DummyLogo : imgData + userInfo?.image} alt="" style={{ borderRadius: '50%' }} onClick={() => navigate('/layout/profile')} />
                <span style={{ padding: '5px 0px' }} onClick={() => navigate('/layout/profile')}>{userInfo?.businessName?.slice(0, 10) + (userInfo?.businessName?.length > 10 ? "..." : "")}</span>
              </div>
            </OverlayTrigger>
            {
              userData?.isMultiprofile ?
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Profile Switch</Tooltip>}>
                  <AutorenewIcon className='me-2' onClick={() => { roleswitch() }} />
                </OverlayTrigger>
                : null
            }
          </a>
          <a onClick={() => { logOutFun() }} style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" className="icons-size" >
              <path d="M18.958 21.625V5.542h2.125v16.083ZM20 34.208q-2.958 0-5.563-1.125-2.604-1.125-4.52-3.041Q8 28.125 6.896 25.521 5.792 22.917 5.792 20q0-3.208 1.333-6.042 1.333-2.833 3.75-4.833l1.417 1.417q-2.084 1.708-3.23 4.187-1.145 2.479-1.145 5.229 0 5 3.541 8.563Q15 32.083 20 32.083q5.042 0 8.562-3.562 3.521-3.563 3.521-8.563 0-2.75-1.146-5.229-1.145-2.479-3.229-4.187l1.417-1.417q2.458 2.042 3.771 4.854 1.312 2.813 1.312 6.021 0 2.917-1.083 5.521t-3.042 4.521q-1.958 1.916-4.562 3.041T20 34.208Z" />
            </svg>
            Logout
          </a>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <section>
              <div style={{ float: 'right', marginTop: '-15px' }}>
                <IconButton className="close-button" onClick={() => setOpen(false)}>
                  <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
              </div>
              <div className='_card_Main'>
                {
                  data?.map((item, index) =>
                    <div className='cursor _card mt-2 text-dark'
                      onClick={() => {
                        selectMember(item);
                        setOpen(false);
                      }} key={index}>
                      <div className="top-container">
                        {item?.image ? <img src={imgData + item?.image} alt="" width='70' style={{ borderRadius: '5px' }} /> :
                          <img src={require("../../Assets/images/dummyimg.png")} width='70' />}
                        <div className="flex-row mt-2" >
                          <h5 className="ms-2">{item.name}</h5>
                          <span className="ms-2">({item?.roleTitle == 'SERVICE_PROVIDER' ? 'Service Provider' : item?.roleTitle == 'STAFF' ? 'Team' : 'Front Desk'})</span>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </section>
          </Backdrop>

          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={branchShow}>
            <section>
              <div style={{ float: 'right', marginTop: '-15px' }}>
                <IconButton className="close-button" onClick={() => setBranchOpen(false)}>
                  <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
              </div>
              <div className='_card_Main' >
                {
                  !branchData?.length ?
                    <div className='No_Location_card  text-dark'>
                      <h5>No location assigned</h5>
                    </div>
                    :
                    branchData?.map((item, index) =>

                      <div className={`${item?._id === userInfo?.locationId && item?.userId === userInfo?.userId ? '_card2' : 'cursor _card'} mt-2 text-dark`}
                        onClick={() => {
                          if (!(item?._id === userInfo?.locationId && item?.userId === userInfo?.userId)) {
                            selectLocation(item)
                          }
                        }}

                        key={index}>
                        <div className="top-container">
                          <div className="flex-row" >
                            <h5 className="ms-2">{item?.name}</h5>
                            <span className="ms-2">({item?.city})</span>
                          </div>
                        </div>
                      </div>
                    )
                }
              </div>

            </section>
          </Backdrop>

        </div>
      </aside>
    </>
  )
}

export default StaffNav;