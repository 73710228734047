import { roleNames } from "../Constants/generalConfig"

export const intialRole = ({ permission, userData, section }) => {
    let active = false
    if (userData?.roleTitle != roleNames.SERVICE_PROVIDER) {
        let newdata = userData?.allpermission?.find((cur, index) => cur?.title?.toLowerCase() === permission?.toLowerCase())
        // console.log(newdata, 'lllll')
        if (newdata) {
            let conditionData = newdata?.permissionData?.find((cur, index) => cur?.title?.toLowerCase() === section?.toLowerCase())
            active = conditionData?.data?.value
        }

    } else if (userData?.roleTitle == roleNames.SERVICE_PROVIDER) {
        active = true
    } else {
        active = false
    }

    return active
}