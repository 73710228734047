import React, { useState, useEffect, } from 'react'
import { switchRole, selectProviderLinkedStaff, imgData } from '../../Context/api';
import { commonMessage, statusCodes, } from '../../Constants/generalConfig';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/spinner/spinner';
import { Button } from '@mui/material';

const MultipleLogin = () => {
    const [spinner, setSpinner] = useState(false)
    const [data, setData] = useState([])
    const mobileNo = JSON.parse(localStorage.getItem('Mobile'))
    const email = JSON.parse(localStorage.getItem('Email'))
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // const { id } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        roleswitch()
    }, [])

    const roleswitch = () => {

        const data = timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ?
            {
                timeZone: timeZone,
                mobileNo: mobileNo,
            }
            :
            {
                timeZone: timeZone,
                email: email
            }
        axios.post(switchRole(), { ...data }).then((resp) => {
            if (resp.data.code === 301) {
                setData(resp.data.data)
            }
            else {
                setData([])
                   
            }
        })
    }
    const selectMember = (item) => {
        const data = timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ?
            {
                timeZone: timeZone,
                staffId: item.staffId,
                providerId: item._id,
                mobileNo: mobileNo
            }
            :
            {
                timeZone: timeZone,
                staffId: item.staffId,
                providerId: item._id,
                email: email
            }
            setSpinner(true)
        axios.post(selectProviderLinkedStaff(), { ...data }).then((resp) => {
            // if (resp.data.code === statusCodes.ok) {
            //     localStorage.setItem('userData', JSON.stringify(resp.data.data))
            //     setUserInfo(resp.data.data)
            //     toast.success(resp.data.message)
            //     navigate('/layout/dashboard')
            //     localStorage.removeItem(timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile' : 'Email')
            // }
            if (resp.data.code === statusCodes.locationScreen) {
                localStorage.setItem('userId', JSON.stringify(resp?.data?.data?.userId))
                setSpinner(false)
                // toast.success(resp.data.message)
                navigate('/branches')
                // localStorage.removeItem(timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? 'Mobile' : 'Email')
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(()=>{
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    return (
        <section>
              {spinner&&<Spinner/>}
            <ToastContainer/>
            <div className='container-fluid' style={{ height: '100vh', width: '100vw', }}>
                <div className='d-flex justify-content-between pt-3 pb-2'>
                    <div>
                        <img src={require('../../Assets/images/logo white color.png')} alt="" width={100} height={100} />
                    </div>
                    <div className='mt-3'>
                    <Button variant="text" sx={{ color: 'black', }} onClick={() => navigate(-1)}>Logout</Button>
                    </div>
                    {/* <svg onClick={() => navigate(-1)} className='cursor mt-3' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '40px' }}><path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z"></path></svg> */}
                </div>
                <div className='_card_Main' >
                    {
                        data?.map((item, index) =>
                            <div className="_card cursor mt-2" onClick={() => selectMember(item)} key={index}>
                                <div className="top-container">
                                    {item?.image ? <img src={imgData + item?.image} alt="" width='70' style={{ borderRadius: '5px' }} /> :
                                        <img src={require("../../Assets/images/dummyimg.png")} width='70' />}
                                    <div className="flex-row" >
                                        <h5 className="ms-2">{item.name}</h5>
                                        <span className="ms-2">({item.roleTitle == 'SERVICE_PROVIDER' ? 'Provider' :item.roleTitle == 'STAFF'? 'Team':'Front Desk'})</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    )
}

export default MultipleLogin