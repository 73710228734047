/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import { useEffect, useState } from "react"
import { staffListApi, getDetailCombo, comboBookAppointment, comboImgPath, imgPath } from "../../../Context/api"
import { commonMessage, statusCodes } from "../../../Constants/generalConfig"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import { useNavigate, useParams } from "react-router-dom"


const UseComboView = () => {
  const [allTeam, setAllTeam] = useState([])
  const [sessions, setSessions] = useState([]);
  const [comboDetails, setComboDetails] = useState([]);
  const [type, setType] = useState(false)
  const [show, setShow] = useState(true)
  const [spinner, setSpinner] = useState(false)
  const [termShow, setTermShow] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const { id } = useParams()
  const navigate = useNavigate()
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };



  const sessionServicesfiltered = sessions
    .filter(session => session.session_number === selectedSession)
    .flatMap(session =>
      session.services.filter(service => selectedServices?.includes(service.service_id))
        .map(service => ({
          service_id: service.service_id,
          session_number: session.session_number ? session.session_number : null,
          count: 1,
          _id: service._id
        }))
    );


  const serviceFilter = sessions[0]?.services?.filter(service => selectedServices?.includes(service.service_id))?.map((item) => ({
    service_id: item.service_id,
    session_number: null,
    count: 1,
    _id: item._id
  }))


  const handleSessionRadioChange = (sessionIndex) => {
    setSelectedSession(sessionIndex);
    setSelectedServices([]);
  };

  const handleServiceCheckboxChange = (serviceId, sessionIndex) => {
    setSelectedServices(prev => {
      if (prev.includes(serviceId)) {
        return prev.filter(id => id !== serviceId);
      } else {
        return [...prev, serviceId];
      }
    });
  };

  useEffect(() => {
    getAllStaffList()
    getCombo()
  }, [])

  const getAllStaffList = () => {
    setSpinner(true)
    axios.post(staffListApi(), { userId: userData?.userId, isActive: true, isAccepted: 'accepted', branchId: userData?.locationId, count: 100, }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setAllTeam(resp.data.data)
        setSpinner(false)
      }
      else {
        setAllTeam([])
        setSpinner(false)
      }
    }).catch(() => {
      toast.error(commonMessage.networkError)
      setSpinner(false)
    })
  }
  const getCombo = () => {
    axios.post(getDetailCombo(), { comboOffer_Id: id, purchase: true }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        bookAppointment.setFieldValue('clientId', resp.data.data.clientId)
        const sessionCheck = resp?.data?.data?.sessions.some((item) => item.session_number)
        setComboDetails(resp.data.data)
        if (sessionCheck) {
          setSessions(resp?.data?.data?.sessions)

        }
        else {
          setType(true)
          setSessions([{ services: resp?.data?.data?.sessions }])
        }
      }
      else {
        // setAllTeam([])
      }
    }).catch(() => { })
  }
  const bookAppointment = useFormik({
    initialValues: {
      startTime: null,
      endTime: null,
      team: [],
      clientId: '',
    },
    onSubmit: (values) => {
      if (type && !serviceFilter?.length) return toast.warn('Please add at least one service')
      if (!type && !sessionServicesfiltered?.length) return toast.warn('Please add at least one service')
      if (!values.startTime && !values.endTime) return toast.warn('Please select start and end time')
      if (!values.startTime) return toast.warn('Please select start time')
      if (!values.endTime) return toast.warn('Please select end time')
      if (userData?.roleTitle !== "STAFF" && !values.team.length) return toast.warn('Please select team')
      if (values.startTime > values.endTime) return toast.warn('Start time can not be greater than end time')

      const data = {
        startDateTime: values.startTime,
        endDateTime: values.endTime,
        timezone: currentTimeZone === 'Asia/Calcutta' ? 'Asia/Kolkata' : currentTimeZone,
        branchId: userData?.locationId,
        amount: 0,
        clientId: values?.clientId,
        providerId: userData?.userId,
        price: 0,
        staffId: userData?.roleTitle === "STAFF" ? userData?.userId : values.team?.map((item) => item?._id),
        serviceId: type ? serviceFilter : sessionServicesfiltered,
        comboPurchasedId: id

      }
      setSpinner(true)
      // return
      axios.post(comboBookAppointment(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          toast.success(resp.data.message)
          setSpinner(false)
          setTimeout(() => {
            navigate(-1)
            bookAppointment.resetForm()
          }, 500);

        }
        else {
          toast.error(resp.data.message)
          setSpinner(false)
        }
      }).catch(() => {
        toast.warn(commonMessage.networkError)
        setSpinner(false)
      })
    },
  })


  return {
    data: {
      allTeam,
      bookAppointment,
      sessions,
      selectedServices,
      selectedSession,
      spinner,
      type,
      userData,
      comboImgPath,
      imgPath,
      show,
      isExpanded,
      termShow, 
      comboDetails
    },
    methods: {
      handleServiceCheckboxChange,
      handleSessionRadioChange,
      setSpinner,
      setShow,
      toggleReadMore,
      setTermShow
    }

  }
}

export default UseComboView
