import JoditEditor from 'jodit-react';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import ScrollBars from 'react-scrollbar'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const ViewTicket = ({ ticketDetailShow, setTicketDetailShow, ticketDetail, getFileExtensionFromUrl, replyMsg, moment, attachments, ticketAttachment, imgData, userData, status, showMore, setShowMore }) => {

  return <>
    <Modal size="lg" centered show={ticketDetailShow} onHide={() => setTicketDetailShow(false)}>
      <div className='m-3 modal-lg'>
        <div style={{ minHeight: '55vh' }}>
          <div className='d-flex justify-content-between text-center'>
            <h5 className='mt-1'>
              Ticket Details</h5>
            <button className='btn cursor text-danger' onClick={() => { setTicketDetailShow(false); ticketDetail.resetForm() }}>X</button>
          </div>
          <ScrollBars horizontal autoHide={false} style={{ height: '600px', width: '100%' }} smoothScrolling={true}>
            <div style={{ border: '1px solid #b3b3b3', borderRadius: '5px', padding: '10px' }}>
              <div className="row mb-3 mt-2">
                <div className="col-sm-12">
                  <div className='flex-column'>
                    <strong className="mt-1">Subject</strong>
                    <p className='font12'>{ticketDetail.values.subject}</p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-between">
                  <div className="flex-column">
                    <strong className='mt-3'>Status</strong>
                    <span style={{ fontSize: "12px", marginTop: "0px", display: "block" }}>{status}</span>
                  </div>


                  <div className="flex-column">
                    <strong className='mt-3'>Priority</strong>
                    <span style={{ fontSize: "12px", marginTop: "0px", display: "block" }}>{ticketDetail.values.priority ? ticketDetail.values.priority : 'N/A'}</span>
                  </div>




                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <div className="col-sm-12">
                  <div className="flex-column">
                    <strong className='mt-3'>Description</strong>
                    <span className='text-break' style={{ fontSize: "12px", marginTop: "0px", display: "block" }}>
                      {showMore ? (
                        <span className='changeClass' dangerouslySetInnerHTML={{ __html: ticketDetail.values.description }} />
                      ) : (
                        <>
                          <span className='changeClass' dangerouslySetInnerHTML={{ __html: ticketDetail.values.description.substring(0, 200) }} />

                          {ticketDetail?.values?.description?.length > 200 ? <span > ... </span> : null}
                        </>
                      )}
                      {ticketDetail?.values?.description?.length > 200 ?
                        <span className='cursor fw-bold d-inline' style={{ color: '#15568A' }} onClick={() => setShowMore(!showMore)}>
                          {showMore ? " Show less" : " Show more"}
                        </span>
                        : null}
                    </span>

                  </div>
                </div>
              </div>
              <hr />
              <div className="row mb-3">
                <div className="col-sm-12 d-flex">
                  {
                    attachments?.length ?
                      attachments?.map((item) =>
                        getFileExtensionFromUrl(item) === 'png' || getFileExtensionFromUrl(item) === 'jpeg' || getFileExtensionFromUrl(item) === 'jpg' ?
                          <div className='ms-2'> <img className='cursor' alt='image' height={100} width={130} src={ticketAttachment + item} onClick={() => window.open(ticketAttachment + item)} /></div>
                          : getFileExtensionFromUrl(item) === 'mp4' || getFileExtensionFromUrl(item) === 'wmv' || getFileExtensionFromUrl(item) === 'mov' ?
                            <video src={ticketAttachment + item} height={100} width={100} controls autoPlay></video>
                            : <i className="fa-sharp fa-regular fa-file cursor mt-4 ms-2" style={{ fontSize: '48px', color: 'red' }} onClick={() => window.open(ticketAttachment + item)}></i>

                      )
                      : null
                  }
                </div>
              </div>
              <div className="row mb-3 ">
                <div className="col-sm-12 fw-bold">Replies</div>
                <div className="col-sm-12 mt-2">
                  <div style={{ border: '1px solid #b3b3b3', minHeight: '20vh', borderRadius: '5px' }}>
                    {/* <ScrollBars horizontal autoHide={false} style={{ height: '300px', width: '100%' }} smoothScrolling={true}> */}
                    <div className='p-2'>
                      {
                        replyMsg?.map((item, index) =>
                          <div className='row mb-2' key={index}>
                            <div className="col-sm-1">
                              <img src={imgData + userData?.image} alt="" height={40} width={40} style={{ borderRadius: '50%' }} />
                            </div>
                            <div className="col-sm-11">
                              <div className="flex-column">
                                <span className='mt-3 ' style={{ fontSize: "16px", fontWeight: "800" }}>{item?.createdByData?.firstName}</span>
                                <span style={{ fontSize: "12px", marginTop: "0px", display: "block" }}>Created At :{moment(item.createdAt).calendar()} </span>
                              </div>
                            </div>
                            {/* <div><span className='font12'>{item.reply}</span></div> */}
                            <div><span className='font12' dangerouslySetInnerHTML={{ __html: item.reply }}></span></div>
                          </div>
                        )
                      }
                    </div>
                    {/* </ScrollBars> */}
                    <div style={{ background: '#f8f9fa', padding: '12px' }}>
                      <CKEditor
                        editor={ClassicEditor}
                        name='reply'
                        onChange={(event, editor) => {
                          const data = editor?.getData()
                          ticketDetail.setFieldValue('reply', data)
                        }}
                        data={ticketDetail?.values?.reply}
                      />
                      <div className='d-flex justify-content-end mt-2'>
                        <button className='btn btn-sm btn_Style' type='submit' onClick={ticketDetail.handleSubmit}>Reply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollBars>
        </div>
      </div>
    </Modal>
  </>
}

export default ViewTicket