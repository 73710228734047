import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import TimePicker from 'rc-time-picker'
import { Backdrop } from '@mui/material'

const AddUnAvailability = ({ showUnAvailability, setShowUnAvailability, setUnavailability, timeZoneData, listData, showAdditionalTimingsBox, hideAdditionalTimingsBox, userData, roleNames, defaultFormValue, navigate }) => {
    return (
        <Backdrop open={showUnAvailability}>
        <Offcanvas show={showUnAvailability} onHide={() => setShowUnAvailability(false)} placement="end" backdrop={false}>
            <div style={{ padding: '10px' }}>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { setShowUnAvailability(false); setUnavailability.resetForm() }}></button>
                <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">
                    Set Un-Availability
                </h1>
            </div>
            <div className="offcanvas-body">
                <form className="cus-form" onSubmit={setUnavailability.handleSubmit}>
                    <div className="mb-3">
                        <select className="form-select form-control" aria-label="Default select example" name="timeZone" onChange={setUnavailability.handleChange} value={setUnavailability.values.timeZone}>
                            <option value=''   hidden>Select time zone</option>
                            {timeZoneData?.map((item, index) => (
                                <option value={item.name} key={index}>
                                    {item.text}
                                </option>
                            ))}
                        </select>
                        {setUnavailability.touched.timeZone && setUnavailability.errors.timeZone ? <div className='err_msg'>{setUnavailability.errors.timeZone}</div> : null}
                    </div>
                    <div className="mb-3">
                        <select className="form-control" aria-label="Default select example" name="branch" onChange={setUnavailability.handleChange} value={setUnavailability.values.branch} disabled>
                            <option value='' hidden>Select Branch</option>
                            {listData?.map((item, index) => (
                                <option value={item._id} key={index}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                        {setUnavailability.touched.branch && setUnavailability.errors.branch ? <div className='err_msg'>{setUnavailability.errors.branch}</div> : null}
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-sm-6">
                                <label className="form-label">From:</label>
                                <input type="date" className="form-control" name="startDate" onChange={setUnavailability.handleChange} value={setUnavailability.values.startDate} />
                                {setUnavailability.touched.startDate && setUnavailability.errors.startDate ? <div className='err_msg'>{setUnavailability.errors.startDate}</div> : null}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">To:</label>
                                <input type="date" className="form-control" name="endDate" onChange={setUnavailability.handleChange} value={setUnavailability.values.endDate} />
                                {setUnavailability.touched.endDate && setUnavailability.errors.endDate ? <div className='err_msg'>{setUnavailability.errors.endDate}</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row timebg mb-3">
                        <div style={{ display: 'flex' }}>
                            <label className="form-label">From:</label>
                            <label className="form-label" style={{ marginLeft: '140px' }}>
                                To:
                            </label>
                        </div>
                        <div style={{ display: 'flex' }} className="mb-3">
                            <TimePicker
                                placeholder="Select Time"
                                use12Hours
                                showSecond={false}
                                focusOnOpen={true}
                                format="hh:mm A"
                                style={{ marginRight: '20px' }}
                                name="startTime"
                                onChange={(e) => {e==null?setUnavailability.setFieldValue('startTime', ''):setUnavailability.setFieldValue('startTime', e)}}
                                value={setUnavailability.values.startTime}
                                autoComplete="off"
                            />
                            <TimePicker
                                placeholder="Select Time"
                                use12Hours
                                showSecond={false}
                                focusOnOpen={true}
                                format="hh:mm A"
                                name="endTime"
                                onChange={(e) => {e==null?setUnavailability.setFieldValue('endTime', ''):setUnavailability.setFieldValue('endTime', e)}}
                                value={setUnavailability.values.endTime}
                                autoComplete="off"
                            />
                            <div className="col-sm-1 offset-sm-1">
                                <div className="d-flex justify-content-end">
                                    <i className="fa-solid fa-plus cus-plus" onClick={() => showAdditionalTimingsBox(0)}></i>
                                </div>
                            </div>
                        </div>
                        {(setUnavailability.touched.startTime && setUnavailability.errors.startTime) || (setUnavailability.touched.endTime && setUnavailability.errors.endTime) ? (
                            <div className='err_msg'>{setUnavailability.errors.startTime || setUnavailability.errors.endTime}</div>
                        ) : null}

                        {defaultFormValue[0].isAllowedTimes.first ? (
                            <div style={{ display: 'flex' }} className="mb-3">
                                <TimePicker
                                    placeholder="Select Time"
                                    use12Hours
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="hh:mm A"
                                    style={{ marginRight: '20px' }}
                                    name="secondStartTime"
                                    onChange={(e) => setUnavailability.setFieldValue('secondStartTime', e)}
                                    value={setUnavailability.values.secondStartTime}
                                    autoComplete="off"
                                />
                                <TimePicker
                                    placeholder="Select Time"
                                    use12Hours
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="hh:mm A"
                                    name="secondEndTime"
                                    onChange={(e) => setUnavailability.setFieldValue('secondEndTime', e)}
                                    value={setUnavailability.values.secondEndTime}
                                    autoComplete="off"
                                />
                                <div className="col-sm-1 offset-sm-1">
                                    <div className="d-flex justify-content-end">
                                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                            hideAdditionalTimingsBox(0, 1); 
                                            setUnavailability.setFieldValue('secondStartTime', '');
                                            setUnavailability.setFieldValue('secondEndTime', '')
                                        }}></i>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {defaultFormValue[0].isAllowedTimes.second ? (
                            <div style={{ display: 'flex' }} className="mb-3">
                                <TimePicker
                                    placeholder="Select Time"
                                    use12Hours
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="hh:mm A"
                                    style={{ marginRight: '20px' }}
                                    name="thirdStartTime"
                                    onChange={(e) => setUnavailability.setFieldValue('thirdStartTime', e)}
                                    value={setUnavailability.values.thirdStartTime}
                                    autoComplete="off"
                                />
                                <TimePicker
                                    placeholder="Select Time"
                                    use12Hours
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="hh:mm A"
                                    name="thirdEndTime"
                                    onChange={(e) => setUnavailability.setFieldValue('thirdEndTime', e)}
                                    value={setUnavailability.values.thirdEndTime}
                                    autoComplete="off"
                                />
                                <div className="col-sm-1 offset-sm-1">
                                    <div className="d-flex justify-content-end">
                                        <i className="fa-solid fa-minus cus-minus" onClick={() => {
                                            hideAdditionalTimingsBox(0, 2); setUnavailability.setFieldValue('thirdStartTime', '');
                                            setUnavailability.setFieldValue('thirdEndTime', '')
                                        }}></i>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {
                        userData.roleTitle === roleNames.SERVICE_PROVIDER ?
                            <div className='mb-2'>
                                <p className='font12'><span className='star_clr'>*</span> Note : You must have <span className='cursor navi_clr' onClick={() => navigate('/layout/profile')}>Branch</span> to add un-availability.</p>
                            </div>
                            : null
                    }

                    <div className="d-grid  col-6 mx-auto mb-3">
                        <button className="btn colorbtun popupbtn px" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </Offcanvas>
        </Backdrop>
    )
}

export default AddUnAvailability