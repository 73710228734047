import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { combopackagesCount, higestEarningCombo } from '../../Context/api'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../Constants/generalConfig'
import Spinner from '../../Components/spinner/spinner'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

const ComboPacakgeReport = () => {
    const [spinner, setSpinner] = useState(false)
    const [comboCountData, setComboCountData] = useState([])
    const [comboEarningData, setComboEarningData] = useState([])
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData'))


    useEffect(() => {
        comboCount()
        comboEarning()
    }, [])


    const comboCount = () => {
        setSpinner(true)
        axios.post(combopackagesCount(), {}, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setComboCountData(resp.data.data)
                setSpinner(false)
            }
            else {
                setSpinner(false)
                setComboCountData([])
                toast.error(resp.data.message)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const comboEarning = () => {
        setSpinner(true)
        axios.post(higestEarningCombo(), {}, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setComboEarningData(resp.data.data)
                setSpinner(false)
            }
            else {
                setSpinner(false)
                setComboEarningData([])
                toast.error(resp.data.message)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }

    // let comboCounts = []
    // comboCountData?.map((item) => {
    //     comboCounts.push({ _id:item?.comboPackageName, Total_Visit: item?.value })
    // })
    let comboCounts = comboCountData?.map(item => ({
        _id: item?.comboPackageName,
        Booked: item?.value
    }));
    let comboEarningCounts = comboEarningData?.map(item => ({
        _id: item?.comboPackageName,
        Earning: item?.TotalEarning
    }));
    console.log(comboCounts, 'comboCounts')
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className='page-tittle'> <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Combo Package Reports</h2>
                        <hr />
                    </header>
                    <div className='fw-bold'>Booked Chart</div>
                        <ResponsiveContainer width="100%" height="45%">
                            <BarChart
                                width={600}
                                height={500}
                                data={comboCounts}
                                margin={{ top: 20, left: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                <YAxis style={{ fontSize: '13px' }} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Booked" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                        <div className='fw-bold'>Earning Chart</div>
                        <ResponsiveContainer width="100%" height="45%">
                            <BarChart
                                width={600}
                                height={500}
                                data={comboEarningCounts}
                                margin={{ top: 20, left: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                <YAxis style={{ fontSize: '13px' }} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Earning" fill="#f47d3a" />
                            </BarChart>
                        </ResponsiveContainer>

                    {/* <div className='d-flex' style={{ width: '100%', height: '500px' }}>
                        <div style={{ flex: 1 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={comboCounts}
                                    margin={{ top: 20, left: 0 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                    <YAxis style={{ fontSize: '13px' }} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Booked" fill="#f47d3a" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div style={{ flex: 1 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={comboEarningCounts}
                                    margin={{ top: 20, left: 0 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                    <YAxis style={{ fontSize: '13px' }} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Earning" fill="#f47d3a" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div> */}
                </div>

            </section>
        </>
    )
}

export default ComboPacakgeReport
