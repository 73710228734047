import { useEffect, useState } from 'react'
import axios from 'axios'
import { notificationList, notificationReadSingle, notificationReadAll, logOutApi } from '../../../Context/api';
import { useContext } from 'react'
import { Notifications } from '../../../App';
import { commonMessage, roleNames, statusCodes } from '../../../Constants/generalConfig';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const UseNotification = () => {
    const { count, setCount,userInfo } = useContext(Notifications)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [listNotification, setListNotification] = useState([])
    const [checked, setChecked] = useState(true)
    const [status, setStatus] = useState(true)
    const [spinner, setSpinner] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            listNotifications()
        }
    }, [page, status])


    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: userData?.roleTitle == "SERVICE_PROVIDER" ? 'WEBPROVIDER' : 'WEBSTAFF' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const listNotifications = () => {
        const data = {
            receiver: userData?.userId,
            page: page,
            count: PER_PAGE,
            isReaded: status,
            roleTitle: userData?.roleTitle
        }
        if (userData?.roleTitle == roleNames.STAFF) {
            data['providerId'] = userData?.createdbyId
        }
        setSpinner(true)
        axios.post(notificationList(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setListNotification(resp.data)
                setPageCount(resp.data.totalCount)
                setCount({ totalCount: resp.data.unreadedCount })
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setListNotification([])
                setPageCount(1)
                setCount({})
                setSpinner(false)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const singleReadNotification = (id) => {
        const data = {
            notification_id: id,
            reader_id: userData?.userId,
        }
        setSpinner(true)
        axios.post(notificationReadSingle(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                listNotifications()
                setSpinner(false)

            }
            else {
                // toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const allNotificationRead = () => {
        const data = {
            user_id: userData?.userId,
        }
        setSpinner(true)
        axios.post(notificationReadAll(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                // toast.success(resp.data.message)
                listNotifications()
                setSpinner(false)
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    const navigation = (item) => {
        singleReadNotification(item._id)
        if (item.type === 'BOOKAPPOINTMENT' || item.type === 'CANCELAPPOINTMENT' || item.type === 'RESCHEDULEDAPPOINTMENT' || item.type === 'DELETAPPOINTMENT') {
            navigate(`/layout/appointmentList/appointmentView/${item.type_id}`)
        }
    }

    return {
        listNotifications,
        singleReadNotification,
        allNotificationRead,
        navigation,
        logOutFun,
        setListNotification,
        setChecked,
        setStatus,
        setSpinner,
        setPageCount,
        setPage,
        navigate,
        count, setCount,
        userData,
        listNotification,
        checked,
        status,
        spinner,
        pageCount,
        page,
        PER_PAGE,
        userInfo
    }
}

export default UseNotification