import React, { useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    logOutApi, addServicesCategoryApi, servicesCategoryListApi, imgPath, comboImgPath, serviceCategoryViewApi, editServiceCategoryApi, changeServiceCategoryStatusApi, listServices,
    comboCreate, comboList, changeComboStatus, getDetailCombo, comboEdit, clientListApi, purchaseComboPackage, downloadServieCate, exportServieCate
} from '../../../Context/api'
import axios from 'axios'
import { statusCodes, commonMessage, roleNames } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useEffect } from 'react'
import { addComboValidation, serviceCategoryValidation } from '../../../Context/validation';
import { Notifications } from '../../../App'

const UseServiceCategory = () => {
    const { userInfo } = useContext(Notifications)
    const [serviceList, setServiceList] = useState()
    const [img, setImg] = useState('')
    const [serviceId, setServiceId] = useState()
    const [showService, setShowService] = useState(false)
    const [show, setShow] = useState(false)
    const [comboShow, setComboShow] = useState({ open: false })
    const [assign, setAssign] = useState({ open: false, data: '', selectedOption: '', client: '' })
    const [archive, setArchive] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 8
    const [service, setService] = useState([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [isActive, setIsActive] = useState(true)
    const [addShow, setAddShow] = useState({ open: false })
    const [add, setAdd] = useState({ open: false })
    const [showAddService, setShowAddService] = useState(true)
    const [serviceData] = useState([])
    const [allServiceData, setAllServiceData] = useState([])
    const [selectService, setSelectService] = useState([])
    const [sessions, setSessions] = useState([{ session_number: 1, services: [] }]);
    const [currentSessionIndex, setCurrentSessionIndex] = useState(null);
    const [comboListData, setComboListData] = useState([]);
    const [clientPerPage, setClientPerPage] = useState(1)


    const navigate = useNavigate()
    const [tab, setTab] = useState(1)
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            if (tab == 1) {
                servicesList()

            }
            if (tab == 2) {
                // allServicesList()
                listCombo()
            }
        }
    }, [page, isActive, tab])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const servicesList = (e) => {
        const data = {
            userId: userData?.userId,
            page: page,
            count: PER_PAGE,
            isActive: isActive,
            branchId: userData?.locationId,
            searchText: e
        }
        setSpinner(true)
        axios
            .post(servicesCategoryListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setServiceList(resp.data.data)
                    setSpinner(false)
                    setCount(resp.data.totalCount)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setServiceList([])
                    setSpinner(false)
                }
            })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const serviceView = (id) => {
        axios.post(serviceCategoryViewApi(), { servicesId: id, loggedInUserId: userData?.userId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                editService.setValues(resp.data.data)
                setImg(resp.data.data.image)
                setServiceId(id)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const serviceDownload = (id) => {
        setSpinner(true)
        axios.get(downloadServieCate(), {}, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.status === statusCodes.ok) {
                setSpinner(false)
                window.open(resp.data.downloadUrl)
                // console.log(resp.data.downloadUrl)
            }
            else if (resp.data.status === statusCodes.tokenExpire) {
                logOutFun()
                setSpinner(false)
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => {
            toast.error(commonMessage.networkError)
            setSpinner(false)
        })
    }
    const addService = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: serviceCategoryValidation,
        onSubmit: (values) => {

            const data = {
                name: values.name.trim(),
                image: img,
                createdby_id: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
                branchId: userData?.locationId
            }
            setSpinner(true)
            axios.post(addServicesCategoryApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    servicesList()
                    setShow(false)
                    setSpinner(false)
                    addService.resetForm()
                    setImg('')
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
        },
    })
    const editService = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: serviceCategoryValidation,
        onSubmit: (values) => {
            setSpinner(true)
            axios.post(editServiceCategoryApi(), { name: values.name.trim(), image: img, loggedInUserId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId, servicesId: serviceId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    servicesList()
                    setShowService(false)
                    setSpinner(false)
                    setImg('')
                    addService.resetForm()
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
        },
    })
    const enterSearch = (event) => {
        if (event.keyCode === 13) {
            if (tab == 1) {
                servicesList(event.target.value)
            }
            if (tab == 2) {
                listCombo(event.target.value)
            }
            // setSpinner(true)
        }
    }
    const changeServiceStatus = () => {
        const data = {
            servicesId: service?._id,
            loggedInUserId: userData?.roleTitle == roleNames.SERVICE_PROVIDER ? userData?.userId : userData.createdbyId,
            status: service?.isActive,
            statusDeleted: service?.isDeleted
        }
        axios.post(changeServiceCategoryStatusApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                servicesList()
                setArchive(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const listCombo = (e) => {
        const data = {
            page: page,
            count: PER_PAGE,
            isActive: isActive,
            isDeleted: false,
            searchText: e
        }
        setSpinner(true)
        axios
            .post(comboList(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setComboListData(resp.data.data)
                    setSpinner(false)
                    setCount(resp.data.totalCount)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setComboListData([])
                    setSpinner(false)
                }
            })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const changeStatusCombo = () => {
        const data = {
            isActive: !service?.isActive,
            comboId: service?._id
        }
        axios.post(changeComboStatus(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                listCombo()
                setArchive(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }
    const addCombo = useFormik({
        initialValues: {
            name: '',
            duration: '',
            price: '',
            description: '',
            termConditions: ''
        },
        validationSchema: addComboValidation,
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                price: values?.price,
                image: img,
                description: values.description,
                term_condition: values.termConditions,
                validity: values.duration,
                session_combo: addShow?.type == 'Service' ? false : true,
                session: convertData(sessions),
            }
            if (comboShow?.data?._id) {
                data.comboId = comboShow?.data?._id
            }
            if (!data?.session?.length) return toast.warn('Please add at least one service or session')
            // console.log(data, 'llllll')
            // return
            setSpinner(true)
            axios.post(comboShow?.data?._id ? comboEdit() : comboCreate(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setAddShow({ open: false })
                    setComboShow(false)
                    setSpinner(false)
                    addCombo.resetForm()
                    setImg('')
                    listCombo()
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            }).catch(() => setSpinner(false))
        },
    })
    const comboView = (id) => {
        setSpinner(true)
        axios.post(getDetailCombo(), { comboOffer_Id: id }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                addCombo.setValues(resp.data.data)
                setImg(resp.data.data.image)
                setSpinner(false)
                addCombo.setFieldValue('duration', resp.data.data.validity)
                addCombo.setFieldValue('termConditions', resp.data.data.term_condition)

                const sessionCheck = resp?.data?.data?.sessions.some((item) => item.session_number)
                if (sessionCheck) {
                    setAddShow({ open: true })
                    setSessions(resp?.data?.data?.sessions)

                }
                else {
                    setAddShow({ open: true, type: 'Service' })
                    setSessions([{ services: resp?.data?.data?.sessions }])
                }
                setServiceId(id)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => setSpinner(false))
    }
    const allServicesList = () => {
        setSpinner(true)
        axios.post(listServices(), {}, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setSpinner(false)
                setAllServiceData(resp.data.data)
                if (serviceData.length === 0) {
                    resp.data.data.map((item) => {
                        return item.serviceData.map((value) => {
                            return serviceData.push({ categoryName: item.name, serviceName: value.name, serviceId: value._id })
                        })
                    })
                }
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
            }
        })
            .catch(() => {
                setSpinner(false)
                // toast.error(commonMessage.networkError)
            })
    }
    const increaseArrayLength = () => {
        setSessions([...sessions, { session_number: sessions?.length + 1, services: [] }]);
    };
    const removeItem = (index) => {
        const sessionServiceIds = sessions[index].services?.map((service) => service._id || service.service_id) || [];
        const updatedServiceData = allServiceData.map((category) => {
            const updatedServices = category.serviceData.map((service) => {
                if (sessionServiceIds.includes(service._id)) {
                    return { ...service, isChecked: false };
                }
                return service;
            });
            return { ...category, serviceData: updatedServices };
        });

        // Update allServiceData state
        setAllServiceData(updatedServiceData);
        console.log(updatedServiceData, 'updatedServiceData');


        const newSessions = sessions.filter((_, i) => i !== index);
        setSessions(newSessions);
        if (!newSessions.length) {
            setAddShow({ open: false })
        }


        // updateIsChecked()
    };
    const handleAddServices = () => {
        if (currentSessionIndex === null) return;
        // if (!sessions.length) {
        //     toast.warn("Please select at least one service.");
        //     return;
        // }
        setShowAddService(true)
        const updatedSessions = [...sessions];
        if (currentSessionIndex !== null) {
            const sessionServices = updatedSessions[currentSessionIndex].services;
            selectService.forEach(service => {
                const existingService = sessionServices.find(s => s._id === service._id);
                if (existingService) {
                    existingService.count += 1;

                } else {
                    sessionServices.push({ ...service, count: 1 });
                }
            });
            setSessions(updatedSessions);
            setAdd({ open: false });
            setSelectService([]);
            setCurrentSessionIndex(null);
            setAddShow((pre) => ({ ...pre, open: true }));
        }
    };
    const increaseServiceCount = (sessionIndex, _id) => {
        const updatedSessions = [...sessions];
        const service = updatedSessions[sessionIndex].services.find(s => s._id === _id || s?.service_id === _id);

        if (service) {
            if (service.count < 10) {
                service.count += 1;
                setSessions(updatedSessions);
            } else {
                toast.warn("Maximum 10 allowed");
            }
        }
    };
    const decreaseServiceCount = (sessionIndex, _id) => {
        const updatedSessions = [...sessions];
        const service = updatedSessions[sessionIndex].services.find(s => s._id === _id || s?.service_id === _id);
        if (service) {
            if (service.count > 1) {
                service.count -= 1;
            } else {
                updatedSessions[sessionIndex].services = updatedSessions[sessionIndex].services.filter(s => s._id !== _id && s?.service_id !== _id);

                const updatedData = allServiceData.map(category => {
                    const index = category.serviceData.findIndex(service => service._id === _id);
                    if (index !== -1) {
                        const updatedServiceData = category.serviceData.map((service, idx) =>
                            idx === index ? { ...service, isChecked: false } : service
                        );
                        return { ...category, serviceData: updatedServiceData };
                    }
                    return category;
                });
                setAllServiceData(updatedData)
            }
            setSessions(updatedSessions);
        }
    };
    const convertData = (data) => {
        return data.flatMap(session =>
            session.services.map(service => {
                const result = {
                    service_id: service?.service_id || service?._id,
                    count: service.count
                };
                if (addShow?.type !== 'Service') {
                    result.session_number = session.session_number;
                }

                return result;
            })
        );
    };
    const loadClientOptions = async () => {
        const data = {
            userId: userData?.userId,
            isActive: true,
            isAccepted: 'accepted',
            page: clientPerPage,
            isDeleted: false,
            isProviderClient: true,
            count: 10
        }
        const response = await axios.post(clientListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }, setClientPerPage(clientPerPage + 1))
        if (response.data.code === 200) {
        }
        else if (response.data.code === statusCodes.tokenExpire) {
            logOutFun()
        }
        return {
            options: response.data.data?.map(item => ({ value: item._id, label: item.name })),
            hasMore: response.data.data.length >= 1
        };

    }
    const purchaseComboPackages = () => {
        const data = {
            clientId: assign?.client,
            comboId: assign?.data?._id,
            payType: assign?.selectedOption
        }
        if (!assign?.client && !assign?.selectedOption) return toast.warn('Please select client & pay type')
        if (!assign?.client) return toast.warn('Please select client')
        if (!assign?.selectedOption) return toast.warn('Please select pay type')

        // console.log(data, 'Data')
        // return
        setSpinner(true)
        axios.post(purchaseComboPackage(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                setSpinner(false)
                setAssign({ open: false, data: '', selectedOption: '', client: '' })
                setClientPerPage(1)
            }
            else {
                toast.error(resp.data.message)
                setSpinner(false)
            }
        }).catch(() => setSpinner(false))

    }
    const handleChange = (event) => {
        const newValue = event.target.value;
        // Allow empty input or integer input only
        if (/^\d*$/.test(newValue)) {
            addCombo.setFieldValue('duration', newValue);
        }
    };
    const checkked = (index) => {
        const sessionServiceIds = sessions[index].services?.map((service) => service.service_id || service._id) || [];
        const updatedServiceData = allServiceData.map((category) => {
            const updatedServices = category.serviceData.map((service) => {
                if (sessionServiceIds.includes(service._id)) {
                    return { ...service, isChecked: true };
                }
                return { ...service, isChecked: false };
            });
            return { ...category, serviceData: updatedServices };
        });
        setAllServiceData(updatedServiceData);
    }

    const uploadExcel = (file) => {
        // Ensure file is a valid File object
        if (!(file instanceof File)) {
            console.error('Invalid file object');
            return;
        }
        const fd = new FormData();
        console.log(file, 'File Data');
        fd.append('excelData', file);

        // Log the formData content
        for (let pair of fd.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
    
        // Append other data to formData
        fd.append('createdby_id', userData?.userId);
        fd.append('branchId', userData?.locationId);
    
        axios.post(exportServieCate(), fd, {headers: { Authorization: `Bearer ${userData?.token}` }}).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message);
                window.location.reload()
            } else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun();
            } else {
                toast.error(resp.data.message);
            }
        }).catch(() => {
            toast.error(commonMessage.networkError);
        });
    };
    
    return {
        servicesList,
        serviceView,
        enterSearch,
        changeServiceStatus,
        navigate,
        setServiceList,
        setImg,
        setServiceId,
        setShowService,
        setShow,
        setSpinner,
        setPage,
        setService,
        setIsActive,
        setCount,
        setArchive,
        setTab,
        setComboShow,
        setAdd,
        setAddShow,
        setSelectService,
        setSessions,
        increaseArrayLength,
        removeItem,
        handleAddServices,
        increaseServiceCount,
        decreaseServiceCount,
        setCurrentSessionIndex,
        setCount,
        setPage,
        changeStatusCombo,
        listCombo,
        comboView,
        setAssign,
        loadClientOptions,
        setClientPerPage,
        purchaseComboPackages,
        handleChange,
        setShowAddService,
        allServiceData, setAllServiceData,
        checkked,
        allServicesList,
        serviceDownload,
        uploadExcel,
        showAddService,
        assign,
        comboListData,
        sessions,
        selectService,
        add,
        addShow,
        addCombo,
        comboImgPath,
        comboShow,
        tab,
        archive,
        addService,
        editService,
        serviceList,
        img,
        serviceId,
        showService,
        show,
        spinner,
        count,
        page,
        PER_PAGE,
        service,
        userData,
        isActive,
        imgPath,
        fileInputRef,
        userInfo,
        serviceData

    }
}

export default UseServiceCategory