import React from 'react'
import { ToastContainer } from 'react-toastify'
import logo from '../../Assets/images/logo.png'
import SignUpWithEmail from './signupWithEmailPage'
import LoginWithEmail from './loginwithEmailPage'
import UseLoginSignUpPage from './Hooks/useLoginSignUpPage'
import Spinner from '../../Components/spinner/spinner'
import NumberInput from '../../Components/PhoneInput/phoneInput'
import { Button } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const LoginSignUp = () => {
  const {
    showPassword,
    hidePassword,
    navigate,
    spinner,
    setSpinner,
    ip,
    icon,
    passwordShow,
    signInForm,
    signUpForm,
    timeZone,
    imgData,
    tab, setTab,
    disabled, setDisabled,
    userData, setUserData,
    active, SetActive,
    selectMember,
    setUserInfoData,
    branchData,
    branchImgPath,
    selectLocation,
  } = UseLoginSignUpPage()

  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="container container2">
        <div className="col-sm-12 cus-signup-box  bg-white">
          <div className="signin-signup">
            {/* Sign up Start */}
            {timeZone === "Asia/Calcutta" || timeZone === "Asia/Kolkata" ? (
              <form
                id="signInSignUpForm"
                className="sign-up-form"
                onSubmit={signUpForm.handleSubmit}
              >
                <h1 className="text-center heading-color mb-5">Sign up</h1>
                <div style={{ width: '350px', position: 'relative' }}>
                  <i className="fa-solid fa-phone" style={{ position: 'absolute', left: '7%', top: '25%', color: '#acacac' }}></i>
                  <NumberInput
                    width={false}
                    Require={false}
                    Name="mobileNo"
                    HandleChange={signUpForm}
                    Value={signUpForm.values.mobileNo}
                    countrySelectComponent="null"
                  />
                  {signUpForm.touched.mobileNo && signUpForm.errors.mobileNo ? (
                    <div
                      className="text-start w-100"
                      style={{ color: "red", fontSize: "12px", width: "200%" }}
                    >
                      {signUpForm.errors.mobileNo}
                    </div>
                  ) : null}
                </div>
                <input
                  type="submit"
                  className="btn colorbtun px"
                  value="Sign up"
                />
              </form>
            ) : (
              <SignUpWithEmail setSpinner={setSpinner} />
            )}
            {/* Sign up End */}

            {/* Sign In Start */}
            {
              tab === 1 ?
                timeZone === 'Asia/Calcutta' || timeZone === 'Asia/Kolkata' ? (
                  <form id="signInSignUpForm" className="sign-in-form" onSubmit={signInForm.handleSubmit}>
                    <h1 className="text-center heading-color mb-5">Sign in</h1>
                    <div style={{ position: 'relative' }} className='w-75 custom_input_width'>
                      <i className="fa-solid fa-phone" style={{ position: 'absolute', left: '7%', top: '25%', color: '#acacac' }}></i>
                      <NumberInput
                        width={false}
                        Require={false}
                        Name="mobileNo"
                        HandleChange={signInForm}
                        Value={signInForm.values.mobileNo}
                        countrySelectComponent="null"
                      />
                      {signInForm.touched.mobileNo && signInForm.errors.mobileNo ? <div style={{ color: 'red', fontSize: '12px', width: '350%' }}>{signInForm.errors.mobileNo}</div> : null}
                    </div>
                    <div className="input-field w-75 custom_input_width">
                      {icon ? <i className="fa fa-eye" onClick={hidePassword}></i> : <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                      <input
                        type={passwordShow ? 'text' : 'password'}
                        placeholder="PIN"
                        name="password"
                        onChange={signInForm.handleChange}
                        onBlur={signInForm.handleBlur}
                        value={signInForm.values.password}
                        maxLength={4}
                        onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                      />
                      {signInForm.touched.password && signInForm.errors.password ? <div style={{ color: 'red', fontSize: '12px', width: '350%' }}>{signInForm.errors.password}</div> : null}
                    </div>
                    <div className="d-flex justify-content-end mb-4 mywidth w-75">
                      <p className="forgot-pin" onClick={() => navigate('forgotPin')} style={{ cursor: 'pointer' }}>
                        Forgot PIN?
                      </p>
                    </div>
                    <input type="submit" value="Sign In" className="btn colorbtun px" />
                  </form>
                ) : (
                  <LoginWithEmail ip={ip} setSpinner={setSpinner} setTab={setTab} setUserData={setUserData} />
                )
                : null
            }
            {
              tab === 2 ?
                <>
                  <form
                    id="signInSignUpForm" className="sign-in-form  d-flex justify-content-center">
                    <div className='animate four fadeInRight'>
                      <h1 className="text-center heading-color mb-5">Select Profile</h1>
                      <div className="USER-PROFILE" style={{ overflow: 'scroll' }}>
                        <div className='_card_Main' >
                          {
                            userData?.map((item, index) =>
                              <div className={`${index === active ? '_card_Login_Shadow' : '_card_Login'} cursor`} onClick={() => { setDisabled(false); SetActive(index); setUserInfoData(item) }}>
                                <div className="top-container mt-0">
                                  <img src={item?.image === '' || item?.image == null ? require("../../Assets/images/dummyimg.png") : imgData + item?.image} width='60' alt='img'/>
                                  <div className="flex-row ms-2" >
                                    <h5 className="ms-2 mt-1 mb-0">{item?.name}</h5>
                                    <span className="ms-2 heading-color">{item?.roleTitle === 'SERVICE_PROVIDER' ? 'Service Provider' : item?.roleTitle === 'STAFF' ? 'Team' : 'Front Desk'}</span>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                      <div className='d-flex justify-content-center'>
                        <input value="Proceed" disabled={disabled} className={`btn px ${disabled ? 'disable_Btn' : 'colorbtun'}`} onClick={() => { selectMember() }} />
                      </div>
                      <div className='d-flex justify-content-center mt-3'>
                        <Button variant="text" startIcon={<KeyboardBackspaceIcon />} sx={{ color: '#f47d3a', fontSize: '12px', }} onClick={() => setTab(1)}>Back</Button>
                      </div>
                    </div>
                  </form>
                </>
                : null
            }
            {
              tab === 3 ?
                <>
                  <form id="signInSignUpForm" className="sign-in-form  d-flex justify-content-center">
                    <div className='animate four fadeInRight'>
                      <h1 className="text-center heading-color mb-5">Select Location</h1>
                      <div className="USER-PROFILE" style={{ overflow: 'scroll' }}>
                        <div className='_card_Main' >
                          {
                            branchData?.map((item, index) =>
                              <div className={`${index === active ? '_card_Login_Shadow' : '_card_Login'} cursor`} onClick={() => { setDisabled(false); SetActive(index); setUserInfoData(item) }}>
                                <div className="top-container mt-0">
                                  <img src={item.image === '' || item.image == null ? require("../../Assets/images/dummyimg.png") : branchImgPath + item.image} width='60' alt='img'/>
                                  <div className="flex-row ms-2" >
                                    <h5 className="ms-2 mt-1 mb-0">{item?.name}</h5>
                                    <span className="ms-2 heading-color">{item?.city}</span>
                                  </div>
                                </div>
                              </div>
                            )
                          }

                        </div>
                      </div>
                      <div className='d-flex justify-content-center'>
                        <input value="Proceed" className={`btn px ${disabled ? 'disable_Btn' : 'colorbtun'}`} disabled={disabled} onClick={() => selectLocation()} />
                      </div>
                      <div className='d-flex justify-content-center mt-3'>
                        <Button variant="text" startIcon={<KeyboardBackspaceIcon />} sx={{ color: '#f47d3a', fontSize: '12px', }} onClick={() => setTab(2)}>Back</Button>
                      </div>
                    </div>
                  </form>
                </>
                : null
            }
            {/* Sign In End */}
            {/* Sign In End */}
          </div>
        </div>

        <div className="panels-container2">
          <div className="panel left-panel">
            <div className="justify-content- d-flex phonehide">
              <a href="http://iskedo.com/">
                <img src={logo} style={{ cursor: "pointer" }} alt='img'/>
              </a>
            </div>
            <div className="content text-center">
              <h1>Hello, Friend!</h1>
              <p>Enter your personal details and start journey with us</p>
              <button className="btn linebtn px" id="sign-up-btn">
                Sign up
              </button>
            </div>
          </div>
          <div className="panel right-panel">
            <div className="justify-content-start mt-4 phonehide">
              <a href="http://iskedo.com/">
                <img src={logo} alt="" style={{ cursor: "pointer" }} />
              </a>
            </div>
            <div className="content">
              <h1>Welcome Back!</h1>
              <p>
                To keep connected with us please login with your personal info
              </p>
              <button className="btn linebtn px" id="sign-in-btn">
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSignUp;
