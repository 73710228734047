import React, { Fragment } from 'react'
import 'rc-time-picker/assets/index.css'
import { roleNames } from '../../Constants/generalConfig'
import { ToastContainer } from 'react-toastify'
import moment from 'moment/moment'
import Spinner from '../../Components/spinner/spinner'
import Paginations from '../../Components/Pagination/pagination'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoRecord from '../../Components/noRecord'
import AddAvailability from './addAvailability'
import EditAvailability from './editAvailability'
import AddUnAvailability from './addUnAvailability'
import UseAvailability from './Hooks/useAvailability'
import { Modal } from 'react-bootstrap'
import { intialRole } from '../../Utils/Utils'



const Availability = () => {
  const {
    staffBranchList,
    getBranchList,
    getAvailability,
    valueChangeToggleSwitch,
    showAdditionalTimingsBox,
    hideAdditionalTimingsBox,
    closeToggleBtn,
    timeZone,
    unavailabilityDelete,
    setShowAvailability,
    setShow,
    setShowUnAvailability,
    setPage,
    setTab,
    setUnavailabilityId,
    navigate,
    setShowDelete,
    showDelete,
    setUnavailability,
    formik,
    editAvailability,
    defaultFormValue,
    listData,
    availablityData,
    unAvailablityData,
    timeZoneData,
    showAvailability,
    show,
    showUnAvailability,
    spinner,
    count,
    page,
    tab,
    PER_PAGE,
    userData,
    userInfo
  } = UseAvailability()

  return (
    <>
      <ToastContainer />
      <section>
        <div className="m-t-3 cus-padd cusheight">
          {spinner ? <Spinner /> : null}
          <h2 className="page-tittle">
            Availability
          </h2>
          <hr />
          <nav className="mb-3">
            <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
              <button
                className={` ${tab === 1 ? 'activeNav' : 'unActiveNav'} border-0`}
                type="button"
                onClick={() => setTab(1)}
              >
                Availability
              </button>
              <button
                className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`}
                type="button"
                onClick={() => setTab(2)}
              >
                Un-Availability
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            {
              tab === 1 &&
              <>
                {
                  intialRole({ permission: "Availability", userData: userInfo, section: 'view', nested: 'availability' }) ?
                    !availablityData?.length && tab === 1 ? <NoRecord />
                      :
                      <>
                        <div className="table-responsive mt-4">
                          <table className="table table-bordered availtable cus-table">
                            <thead className="text-center tableheader">
                              <tr>
                                <th scope="col" className="availability_table">Branch</th>
                                <th scope="col" className="availability_table">
                                  Sunday
                                </th>
                                <th scope="col" className="availability_table">
                                  Monday
                                </th>
                                <th scope="col" className="availability_table">
                                  Tuesday
                                </th>
                                <th scope="col" className="availability_table">
                                  Wednesday
                                </th>
                                <th scope="col" className="availability_table">
                                  Thursday
                                </th>
                                <th scope="col" className="availability_table">
                                  Friday
                                </th>
                                <th scope="col" className="availability_table">
                                  Saturday
                                </th>
                              </tr>
                            </thead>
                            <tbody className="cus-availability-table-align">
                              {availablityData?.map((item, index) => (
                                <Fragment key={index}>
                                  <tr className={intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' }) ? 'cursor' : null} key={index}>
                                    <td key={index}>{item.branchName}</td>
                                    <td>
                                      {item.available_days[0]?.arrayOfTimings?.length ?
                                        item.available_days[0]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                    <td>
                                      {item.available_days[1]?.arrayOfTimings?.length ?
                                        item.available_days[1]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                    <td>
                                      {item.available_days[2]?.arrayOfTimings?.length ?
                                        item.available_days[2]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                    <td>
                                      {' '}
                                      {item.available_days[3]?.arrayOfTimings?.length ?
                                        item.available_days[3]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                    <td>
                                      {item.available_days[4]?.arrayOfTimings?.length ?
                                        item.available_days[4]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                    <td>
                                      {item.available_days[5]?.arrayOfTimings?.length ?
                                        item.available_days[5]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                    <td>
                                      {item.available_days[6]?.arrayOfTimings?.length ?
                                        item.available_days[6]?.arrayOfTimings.map((time, timingIndex) => {
                                          return (
                                            <ul
                                              className="timetable"
                                              key={timingIndex}
                                              onClick={() => {
                                                if (intialRole({ permission: "Availability", userData: userInfo, section: 'edit', nested: 'availability' })) {
                                                  getAvailability(item._id);
                                                  setShowAvailability(true);
                                                  timeZone();
                                                  if (userData.roleTitle === roleNames.STAFF) {
                                                    staffBranchList();
                                                  } else {
                                                    getBranchList();
                                                  }
                                                }
                                              }}
                                            >
                                              <span>
                                                {moment(time.startTime).format('LT')} - {moment(time.endTime).format('LT')}
                                              </span>
                                            </ul>
                                          )
                                        })
                                        : '--'
                                      }
                                    </td>
                                  </tr>
                                </Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className='w-100 d-flex justify-content-center'>
                          {
                            count > 8 &&
                            <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                          }
                        </div>
                      </>
                    : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                }

                {
                  intialRole({ permission: "Availability", userData: userInfo, section: 'add', nested: 'availability' }) ?
                    <div className="d-flex justify-content-end">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Availability</Tooltip>}>
                        <button onClick={() => {
                          setShow(true)
                          timeZone()
                          formik.setFieldValue('branch', userData?.locationId)
                          userData.roleTitle === roleNames.SERVICE_PROVIDER ? getBranchList() : staffBranchList()
                        }} className="btn addbtn2" >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                    : null
                }
              </>
            }

            {
              tab === 2 &&
              <>
                {
                  intialRole({ permission: "Un-Availability", userData: userInfo, section: 'view', }) ?
                    !unAvailablityData?.length && tab === 2 ? <NoRecord />
                      :
                      <>
                        <div className="row mt-4">
                          {unAvailablityData?.map((item) => (
                            <div className="col-sm-6" key={item.branchId}>
                              <div className="accordion accordion-flush" id="accordionFlushEx ample">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="branch-un">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={'#un-branch' + item.appointment_number}
                                      aria-expanded="false"
                                      aria-controls="un-branch"
                                    >
                                      {item.branchName}
                                    </button>
                                  </h2>
                                  <div id={'un-branch' + item.appointment_number} className="accordion-collapse collapse" aria-labelledby="branch-un" data-bs-parent="#accordionFlushEx ample">
                                    <div className="accordion-body text-center">
                                      {item.timmingArr?.map((data, index) => <React.Fragment key={index}>
                                        <div className="d-flex justify-content-between">
                                          <p>Start Date: {moment(item.startDateTime).format('DD-MM-YYYY')}</p>
                                          <p>End Date: {moment(item.endDateTime).format('DD-MM-YYYY')} </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>Start Time: {moment(data.startTime).format('hh:mm A')}</p>
                                          <p>End Time: {moment(data.endTime).format('hh:mm A')} </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>Time Zone: {item.timezone}</p>
                                        </div>
                                      </React.Fragment>)}
                                      {
                                        intialRole({ permission: "Un-Availability", userData: userInfo, section: 'delete', }) ?
                                          <div className="d-flex justify-content-end">
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                              <i className="fa-solid fa-trash-can cursor" onClick={() => { setUnavailabilityId(item.appointment_number); setShowDelete(true) }}></i>
                                            </OverlayTrigger>
                                          </div>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className='w-100 d-flex justify-content-center'>
                          {
                            count > 10 &&
                            <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                          }
                        </div>
                      </>
                    : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                }
                {
                  intialRole({ permission: "Un-Availability", userData: userInfo, section: 'add', }) ?
                    <div className="d-flex justify-content-end">
                      {userData.roleTitle === roleNames.SERVICE_PROVIDER ?
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Un-Availability</Tooltip>}>
                          <button className="btn addbtn2 ">
                            <i
                              className="fa-solid fa-plus"
                              onClick={() => {
                                setShowUnAvailability(true)
                                getBranchList()
                                setUnavailability.setFieldValue('branch', userData?.locationId)
                                timeZone()
                              }}
                            ></i>
                          </button>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Un-Availability</Tooltip>}>
                          <button className="btn addbtn2">
                            <i
                              className="fa-solid fa-plus"
                              onClick={() => {
                                setShowUnAvailability(true)
                                staffBranchList()
                                setUnavailability.setFieldValue('branch', userData?.locationId)
                                timeZone()
                              }}
                            ></i>
                          </button>
                        </OverlayTrigger>
                      }
                    </div>
                    : null
                }
              </>}
          </div>
        </div>
      </section>

      <AddAvailability show={show} setShow={setShow} closeToggleBtn={closeToggleBtn} formik={formik} timeZoneData={timeZoneData} listData={listData} defaultFormValue={defaultFormValue}
        valueChangeToggleSwitch={valueChangeToggleSwitch} showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} userData={userData} roleNames={roleNames} navigate={navigate} />

      <EditAvailability showAvailability={showAvailability} setShowAvailability={setShowAvailability} closeToggleBtn={closeToggleBtn} editAvailability={editAvailability} timeZoneData={timeZoneData} listData={listData}
        defaultFormValue={defaultFormValue} valueChangeToggleSwitch={valueChangeToggleSwitch} showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} />

      <AddUnAvailability showUnAvailability={showUnAvailability} setShowUnAvailability={setShowUnAvailability} setUnavailability={setUnavailability} timeZoneData={timeZoneData} listData={listData}
        showAdditionalTimingsBox={showAdditionalTimingsBox} hideAdditionalTimingsBox={hideAdditionalTimingsBox} userData={userData} roleNames={roleNames} defaultFormValue={defaultFormValue} navigate={navigate} />


      {/* UnAvailability Delete*/}
      {/* <div className="modal fade" id="unavailabilitydelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm"> */}
      <Modal centered size='sm' show={showDelete} onHide={() => setShowDelete(false)}>
        <div className="modal-content">
          <div className="modal-body text-center">
            <p>Are You Sure !</p>
            <p>You Want to Delete Your unavailability</p>
            <button type="button" className="btn nobtn" onClick={() => unavailabilityDelete()} data-bs-dismiss="modal">
              Yes
            </button>
            <button type="button" className="btn yesbtn" data-bs-dismiss="modal" onClick={() => setShowDelete(false)}>
              No
            </button>
          </div>
        </div>
      </Modal>
      {/* </div>
      </div> */}
    </>
  )
}

export default Availability
