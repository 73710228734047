import React from 'react'
import Calendar from '../../Assets/icon/calendar.png';
import Upcoming from '../../Assets/icon/upcominmg.png';
import Cancel from '../../Assets/icon/cancel.png';
import { roleNames } from '../../Constants/generalConfig';
import DummyLogo from '../../Assets/images/dummyimg.png'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ToastContainer } from 'react-toastify';
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from '../../Components/spinner/spinner'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltips from 'react-bootstrap/Tooltip';
import UseDashboard from './Hooks/useDashboard';
import { Button } from '@mui/material';
import { intialRole } from '../../Utils/Utils';

const Dashboard = () => {
    const {
        fetchMoreData,
        appointmentAndService,
        revenue,
        rushHour,
        navigate,
        setDate,
        setBtn,
        setApptBtn,
        appointments,
        team,
        client,
        appointServiceData,
        date,
        btn,
        spinner,
        apptBtn,
        userData,
        imgData,
        newData,
        rush,
        planExpMsg,
        userInfo,
    } = UseDashboard()

    // const {selectLocation} = UseProviderHook()
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3 cus-padd cusheight">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h1 className='page-tittle'>Dashboard</h1>
                        </div>
                        {
                            intialRole({ permission: "dashboard", userData: userInfo, section: 'dashboard' }) ?
                                <>
                                    {
                                        planExpMsg ?
                                            <div className="marquee-container">
                                                <span className="marquee-text">
                                                    {planExpMsg}
                                                    <a href="https://iskedo.com/contact-us/">
                                                    <Button variant="text" sx={{ color: '#f47d3a' }}>Buy Plan</Button>
                                                    </a>
                                                </span>
                                            </div>

                                            : null
                                    }
                                </>
                                : null
                        }
                    </div>
                    <hr />
                    {
                        intialRole({ permission: "dashboard", userData: userInfo, section: 'dashboard' }) ?

                            <div className='scroll mt-3'>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-7"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-5">
                                        <div className='d-flex justify-content-around'>
                                            <button className={apptBtn === 'TODAY' ? 'dash-btn2' : 'dash-btn'} onClick={() => { setApptBtn('TODAY'); appointmentAndService('TODAY') }}>TODAY</button>
                                            <button className={apptBtn === 'LAST_MONTH' ? 'dash-btn2' : 'dash-btn'} onClick={() => { setApptBtn('LAST_MONTH'); appointmentAndService('LAST_MONTH') }}>LAST MONTH</button>
                                            <button className={apptBtn === 'UPCOMMING' ? 'dash-btn2' : 'dash-btn'} onClick={() => { setApptBtn('UPCOMMING'); appointmentAndService('UPCOMMING') }}>UPCOMING</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-4'>
                                    <div className='col-sm-7'>
                                        <h5 className='heading2 m-0'>Appointments</h5>
                                        <div className='row'>
                                            <div className="row insightlist mt-4 padding_ps_5">
                                                <div className='col-lg-4 margin_mb_4'>
                                                    <div className="dashboard-card">
                                                        <OverlayTrigger overlay={<Tooltips style={{ fontSize: '14px', padding: '8px 12px' }} id="tooltip-disabled">{appointments?.BookedCount === undefined ? 0 : appointments?.BookedCount}</Tooltips>}>
                                                            <div className='row'>
                                                                <div className='col-sm-12 col-md-12 col-lg-6 '>
                                                                    <h4 className='colors'>{appointments?.BookedCount === undefined ? 0 : Math.abs(appointments?.BookedCount) > 9999 ? Math.sign(appointments?.BookedCount) * ((Math.abs(appointments?.BookedCount) / 1000).toFixed(1)) + 'K' : Math.sign(appointments?.BookedCount) * Math.abs(appointments?.BookedCount)}</h4>
                                                                    <p className='fw-bold'>Booked</p>
                                                                </div>
                                                                <div className='col-sm-12 col-md-12 col-lg-6  phonehide'>
                                                                    <img src={Calendar} className='ms-1 img_Style' alt="" />
                                                                </div>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 margin_mb_4'>
                                                    <div className="dashboard-card">
                                                        <OverlayTrigger overlay={<Tooltips style={{ fontSize: '14px', padding: '8px 12px' }} id="tooltip-disabled">{appointments?.pending === undefined ? 0 : appointments?.pending}</Tooltips>}>
                                                            <div className='row'>
                                                                <div className='col-sm-12 col-md-12 col-lg-6 '>
                                                                    <h4 className='colors'>{appointments?.pending === undefined ? 0 : Math.abs(appointments?.pending) > 9999 ? Math.sign(appointments?.pending) * ((Math.abs(appointments?.pending) / 1000).toFixed(1)) + 'K' : Math.sign(appointments?.pending) * Math.abs(appointments?.pending)}</h4>
                                                                    <p className='fw-bold'>Pending</p>
                                                                </div>
                                                                <div className='col-sm-12 col-md-12 col-lg-6  phonehide'>
                                                                    <img src={Upcoming} alt="" className='img_Style' />
                                                                </div>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 margin_mb_4'>
                                                    <div className="dashboard-card">
                                                        <OverlayTrigger overlay={<Tooltips style={{ fontSize: '14px', padding: '8px 12px' }} id="tooltip-disabled">{appointments?.cancel === undefined ? 0 : appointments?.cancel}</Tooltips>}>
                                                            <div className='row'>
                                                                <div className='col-sm-12 col-md-12 col-lg-6 '>
                                                                    <h4 className='colors'>{appointments?.cancel === undefined ? 0 : Math.abs(appointments?.cancel) > 9999 ? Math.sign(appointments?.cancel) * ((Math.abs(appointments?.cancel) / 1000).toFixed(1)) + 'K' : Math.sign(appointments?.cancel) * Math.abs(appointments?.cancel)}</h4>
                                                                    <p className='fw-bold'>Cancelled</p>
                                                                </div>
                                                                <div className='col-sm-12 col-md-12 col-lg-6  phonehide'>
                                                                    <img src={Cancel} alt="" className='img_Style' />
                                                                </div>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-sm-5'>
                                        <h5 className='heading me-5 mb-4'>Top Services</h5>
                                        <div className='ms-4 Service_box'>
                                            <div className='mx-auto row'>
                                                {
                                                    appointServiceData[0]?.TopService?.length === 0 ? <h6 className='d-flex justify-content-center'>No record found</h6>
                                                        :
                                                        appointServiceData[0]?.TopService?.map((data, i) =>
                                                            <React.Fragment key={i}>
                                                                <OverlayTrigger overlay={<Tooltips style={{ fontSize: '14px', padding: '8px 12px' }} id="tooltip-disabled">{data?.serviceName}</Tooltips>}>
                                                                    <div className="service-card mb-4" key={data._id}>
                                                                        <h4 className='colors text-center'>{Math.abs(data?.totalservice) > 9999 ? Math.sign(data?.totalservice) * ((Math.abs(data?.totalservice) / 1000).toFixed(1)) + 'K' : Math.sign(data?.totalservice) * Math.abs(data?.totalservice)}</h4>
                                                                        <p className='fw-bold'>{data?.serviceName.slice(0, 7) + (data.serviceName.length > 6 ? "..." : "")}</p>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </React.Fragment>
                                                        )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <hr className='mt-2' />
                                    <div className='col-sm-7'>
                                        <h5 className='heading2 mt-2 pb-2'>Revenue</h5>
                                        <div className='mb-5 ms-4'>
                                            <button className={btn === 'DAILY' ? 'dash-btn2' : 'dash-btn'} onClick={() => { setBtn('DAILY'); revenue('DAILY') }}>Daily</button>
                                            <button className={btn === 'MONTHLY' ? 'dash-btn2' : 'dash-btn'} onClick={() => { setBtn('MONTHLY'); revenue('MONTHLY') }}>Monthly</button>
                                            <button className={btn === 'YEARLY' ? 'dash-btn2' : 'dash-btn'} onClick={() => { setBtn('YEARLY'); revenue('YEARLY') }}>Yearly</button>
                                        </div>
                                        <ResponsiveContainer width="100%" height="70%">
                                            <BarChart
                                                width={600}
                                                height={500}
                                                data={newData}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                                <YAxis style={{ fontSize: '13px' }} />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="Total_Revenue" fill="#f47d3a" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className='col-sm-5'>
                                        <h5 className='heading mt-2 pb-3'>Clients</h5>
                                        <div className='team'>
                                            <div className="list-group">
                                                <div id="scrollableDiv" className='scroll_client'>
                                                    <InfiniteScroll
                                                        dataLength={client.length}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        scrollableTarget="scrollableDiv"
                                                    >
                                                        <div className="list-group">
                                                            {
                                                                client.length === 0 ? <h6 className='d-flex justify-content-center mt-5'>No record found</h6>
                                                                    :
                                                                    <>
                                                                        {
                                                                            client.map((item) =>
                                                                                <div key={item._id} className='d-flex justify-content-between mx-0 px-0' style={{ alignItems: 'center' }}>
                                                                                    <div className={`col-sm-6 d-flex heading_font  ${intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? 'cursor' : null}`}>
                                                                                        <img src={item.image === '' ? DummyLogo : imgData + item.image} className='client_ms mt-3' alt="" height={50} width={50} style={{ borderRadius: '50%' }} onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? navigate(`/layout/client/clientView/${item._id}`) : null} />
                                                                                        <h6 className='client_ms' style={{ marginTop: '32px' }} onClick={() => intialRole({ permission: "client", userData: userInfo, section: 'edit' }) ? navigate(`/layout/client/clientView/${item._id}`) : null}>{item.name.toUpperCase()}
                                                                                        </h6>
                                                                                    </div>
                                                                                    <div className='col-sm-6 pe-4 heading_font'>
                                                                                        <h6 className='dollar'>{item.email}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>
                                                            }

                                                        </div>
                                                    </InfiniteScroll>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-7 '>
                                        <h5 className='heading2 mt-4 pb-3'>Rush Hours</h5>
                                        <div className='col-sm-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3  mb-4 pb-1 ms-5'>
                                            <input type="date" className='form-control' value={date} onChange={(e) => { rushHour(e.target.value); setDate(e.target.value) }} />
                                        </div>
                                        <ResponsiveContainer width="100%" height="70.5%">
                                            <BarChart
                                                width={600}
                                                height={500}
                                                data={rush}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: -50
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                                <YAxis style={{ fontSize: '13px' }} />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="Total_Visit" fill="#f47d3a" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    {
                                        userData?.roleTitle === roleNames.STAFF ? null :
                                            <div className='col-sm-5'>
                                                <h5 className='heading  mt-4 mb-4 team_member'>Team Members</h5>
                                                <div className='team scroll_team'>
                                                    <div className="list-group" >
                                                        {
                                                            team.length === 0 ? <h6 className='d-flex justify-content-center mt-5'>No record found</h6>
                                                                :
                                                                <>
                                                                    {
                                                                        team.map((item, i) =>
                                                                            <React.Fragment key={i}>
                                                                                <div className='d-flex justify-content-between' style={{ display: 'flex' }}>
                                                                                    <div className={`col-sm-6 d-flex heading_font ${intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? 'cursor' : null}`}>
                                                                                        <img onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`/layout/team/teamProfile/${item._id}`) : null} src={item.image === '' ? DummyLogo : imgData + item.image} className='client_ms mt-3 ' alt="" height={50} width={50} style={{ borderRadius: '50%' }} />
                                                                                        <h6 className='client_ms mt-4 pt-2' onClick={() => intialRole({ permission: "team", userData: userInfo, section: 'edit' }) ? navigate(`/layout/team/teamProfile/${item._id}`) : null}>{item?.name?.toUpperCase()}
                                                                                            <br />
                                                                                        </h6>
                                                                                    </div>
                                                                                    <div className='col-sm-6 pe-4 pt-2 heading_font'>
                                                                                        <h6 className='dollar'>{item.email ? item.email : '--'}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                </div>
            </section>
        </>
    )
}

export default Dashboard;