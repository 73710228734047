import React, { useContext, useState } from 'react'
import DummyLogo from '../../Assets/images/dummyimg.png'
import axios from 'axios'
import {
  branchListApi, appointmentBook, teamBookingRevenue, currency, teamRevenue, serviceReport, imgPath, serviceDetail,
  logOutApi, serviceExport, multipleServicesListApi
} from '../../Context/api';
import { useEffect } from 'react';
import { commonMessage, statusCodes } from '../../Constants/generalConfig';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ScrollBars from 'react-scrollbar'
import moment from 'moment/moment';
import ServiceImg from '../../Assets/images/services-icon.png';
import Spinner from '../../Components/spinner/spinner'
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';

const Services = () => {
  const { userInfo } = useContext(Notifications)
  const [show, setShow] = useState(true)
  const [branchList, setBranchList] = useState([])
  const [currencyName, setCurrencyName] = useState([])
  const [serices, setServices] = useState([])
  // const [serviceDatilData, setServiceDatilData] = useState([])
  const [top10Service, setTop10Service] = useState([])
  const [totalServiceData, setTotalServiceData] = useState([])
  const [revenue, setRevenue] = useState([])
  const [sort, setSort] = useState('')
  const [spinner, setSpinner] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [state, setState] = useState({
    branch: userData?.locationId,
    days: "LAST7"
  });
  const [serviceId, setServiceId] = useState('All')
  const [serviceSelected, setServiceSelected] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [data, setData] = useState('')
  const navigate = useNavigate()


  useEffect(() => {
    if (userData == null) {
      navigate('/')
    }
    else {
      getBranchList()
      currencyList()
      bookAppointment()
      totalRevenue()
      servicesList()
    }
  }, [])

  useEffect(() => {
    if (state.branch) {
      topServiceBranch()
    }
    totalServiceBranch()
  }, [state.branch, state.days, serviceId, sort])

  const logOutFun = () => {
    axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        localStorage.removeItem('userData')
        navigate('/')
      }
    })
  }
  const currencyList = () => {
    axios.post(currency(), { loggedInUserId: userData?.userId }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setCurrencyName(resp.data.data.symbol)
      }
    }).catch(() => { })
  }
  const getBranchList = () => {
    axios.post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
      .then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setBranchList(resp.data.data)
        }
        else if (resp.data.code === statusCodes.tokenExpire) {
          logOutFun()
        }
        else {
          setBranchList([])
        }
      })
      .catch(() => {
        toast.error(commonMessage.networkError)
      })
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  const totalServiceBranch = () => {
    const data = {
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD'),
      // serviceId: serviceId
    }
    if (!state.branch) {
      delete data.branchId
    }
    setShow(false)
    setSpinner(true)
    axios.post(teamBookingRevenue(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setSpinner(false)
        resp.data.data?.map((item) => {
          setTotalServiceData(item)
        })
        if (resp.data.data.length === 0) {
          setTotalServiceData([])
        }
        if (state.branch) {
          return setShow(false)
        }
        else if (!state.branch) {
          return setShow(true)
        }
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setSpinner(false)
        setTotalServiceData([])
      }
    }).catch(() => { setSpinner(false) })
  }
  const bookAppointment = (e) => {
    const data = {
      days: e || state.days,
      today: moment(new Date()).format('YYYY-MM-DD')
    }
    axios.post(appointmentBook(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setServices(resp.data.data)
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setServices([])
      }
    }).catch(() => { })
  }
  const totalRevenue = (e) => {
    const data = {
      days: e || state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD')
    }
    axios.post(teamRevenue(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        resp.data.data?.map((item) => {
          setRevenue(item)
        })
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setRevenue([])
      }
    }).catch(() => { })
  }
  const topServiceBranch = () => {
    const data = {
      sortBy: sort,
      days: state.days,
      branchId: state.branch,
      today: moment(new Date()).format('YYYY-MM-DD'),
      serviceId: serviceId
    }
    setData(data)
    axios.post(serviceReport(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        setTop10Service(resp.data.data)

        let temp = resp.data.data?.map((item) => ({ userName: item.userName, _id: item._id }))
        setServiceSelected([...serviceSelected, ...temp])
      }
      else if (resp.data.code === statusCodes.tokenExpire) {
        logOutFun()
      }
      else {
        setTop10Service([])
      }
    }).catch(() => { })
  }
  const exportService = () => {
    delete data.serviceId
    setSpinner(true)
    axios.post(serviceExport(), data, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
      if (resp.data.code === statusCodes.ok) {
        window.open(resp.data.data)
        setSpinner(false)
      }
      else {
        toast.error(resp.data.message)
        setSpinner(false)
      }
    }).catch(() => { })
  }
  const servicesList = () => {
    axios
      .post(multipleServicesListApi(), { userId: userData?.userId, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
      .then((resp) => {
        if (resp.data.code === statusCodes.ok) {
          setServiceList(resp.data.data)
        }
        else {
          setServiceList([])
        }
      }).catch(() => { })
  }

  return (
    <>
      <ToastContainer />
      <section>
        {spinner && <Spinner />}
        <div className="m-t-3 cus-padd cusheight">
          <header>
            <h2 className='page-tittle'> <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Services Reports</h2>
            <hr />
          </header>
          {
            intialRole({ permission: "insights", userData: userInfo, section: 'viewServices' }) ?
              <>
                <div className="d-flex justify-content-between listpadding">
                  <div className="dropdown">
                    <select className='mb-2' style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example"
                      name="days" value={state.days}
                      onChange={(e) => {
                        handleInputChange(e)
                        bookAppointment(e.target.value)
                        totalRevenue(e.target.value)
                      }}
                    >
                      <option hidden>Select days</option>
                      <option value="TODAY">Today</option>
                      <option value="LAST7">Last 7 days</option>
                      <option value="LAST_MONTH">Last Month</option>
                      <option value="THIS_MONTH">This Month</option>
                    </select>
                  </div>
                  <div className="dropdown">
                    <select className='mb-2' disabled style={{ border: 'none', borderBottom: '2px solid black', background: 'white', outline: 'none' }} aria-label="Default select example"
                      name="branch" value={state.branch}
                      onChange={(e) => {
                        handleInputChange(e)
                      }}
                    >
                      <option hidden>All</option>
                      <option value="">All</option>
                      {branchList?.map((item) => (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="row my-4">
                    <div className='col-lg-6'>
                      <div className='d-flex justify-content-sm-center justify-content-md-center justify-content-lg-end mb-4'>
                      <div className="card1">
                        <h2>{totalServiceData.totalBooking ? totalServiceData.totalBooking : 0}</h2>
                        <p>Total Services</p>
                      </div>
                      </div>
                      </div>
                    {
                      show ? null :
                        <div className='col-lg-6'>
                          <div className='mb-4'>
                          <div className="card1">
                            <h2>{currencyName}{totalServiceData.totalRevenue ? totalServiceData.totalRevenue : 0}</h2>
                            <p>Revenue Earned</p>
                          </div>
                          </div>
                        </div>
                    }
                  </div>
                  {
                    show ?
                      <div className="col-sm-12 d-flex">
                        <div className="col-sm-6 mt-1 me-2">
                          <h3>Total Services</h3>
                          <div className="card listpadding2">
                            <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '265px' }} smoothScrolling={true} >
                              {
                                serices.map((item, i) => (
                                  <React.Fragment key={i} className='me-5'>
                                    <div className="d-flex justify-content-between">
                                      <h5>{item.name}</h5>
                                      <h5>{item.branchPercentage ? item.branchPercentage : 0}%</h5>
                                    </div>
                                    <div className="progress mb-2">
                                      <div className="progress-bar bar1" role="progressbar" style={{ width: `${item.branchPercentage ? item.branchPercentage : 0}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </React.Fragment>
                                ))
                              }
                            </ScrollBars>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className='d-flex justify-content-between'>
                            <h3>Revenue Earned</h3>
                            {
                              revenue.totalRevenew?.map((item) =>
                                <h5 className='me-5 pe-4' key={item._id}>{currencyName}{item.amount}</h5>
                              )
                            }
                          </div>
                          <div className="card listpadding2">
                            <ScrollBars horizontal autoHide={false} style={{ width: '100%', height: '265px' }} smoothScrolling={true}>
                              {
                                revenue.branches?.map((item, i) =>
                                  <React.Fragment key={i}>
                                    <div className="d-flex justify-content-between" key={item._id}>
                                      <h5>{item.name}</h5>
                                      <h5>{currencyName}{item.amount}</h5>
                                    </div>
                                    <hr />
                                  </React.Fragment>
                                )
                              }
                            </ScrollBars>
                          </div>
                        </div>
                      </div>
                      :
                      <>
                        <div className="d-flex justify-content-between ">
                         <select className='mb-2' style={{ border: 'none', width:'150px', borderBottom: '2px solid black', background: 'white', outline: 'none'}}
                            onChange={(e) => setServiceId(e.target.value)}>
                            <option hidden>All</option>
                            <option value="All" >All</option>
                            {
                              serviceList?.map((item) =>
                                item.servicedetails?.map((data, i) =>
                                  <option value={data.serviceId} key={i}>{data.serviceName}</option>
                                ))
                            }
                          </select>
                          <div className='d-flex'>
                            <button className='btn me-2' style={{ background: '#FDF6F3', color: 'black' }} onClick={() => exportService()}>Export</button>
                            <div className="dropdown">
                              <a className="btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: '#FDF6F3' }}>
                                Sort by
                              </a>
                              <ul className="dropdown-menu">
                                <li><h6 className="dropdown-item"><div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => { setSort('SERVICETAKEN') }} />
                                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Service Taken
                                  </label>
                                </div></h6></li>
                                <li><h6 className="dropdown-item"><div className="form-check">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => { setSort('REVENUE') }} />
                                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Revenue Earned
                                  </label>
                                </div></h6></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive client-scroll">
                          <table className="table cus-table">
                            <thead className="text-center tableheader">
                              <tr>
                                <th scope="col">Image</th>
                                <th scope="col">Service  Name</th>
                                <th scope="col">Booking</th>
                                <th scope="col">Service Taken</th>
                                <th scope="col">Total Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {top10Service.length ? (
                                top10Service.map((item, i) => (
                                  <tr key={i} data-bs-toggle="offcanvas" data-bs-target="#insightclient" aria-controls="insightclient">
                                    <td><img alt='img' src={item.userImage === '' ? ServiceImg : imgPath + item.userImage} height={60} width={60} /></td>
                                    <td><b>{item.userName}</b></td>
                                    <td>
                                      <div className="progress mb-2">
                                        <div className="progress-bar bar1 text-dark" role="progressbar" style={{ width: `${item.acceptedBookingServicePer}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{item.acceptedBookingServicePer}%</div>
                                      </div>
                                    </td>
                                    <td>{item.serviceAcceptedGiven}</td>
                                    <td>{currencyName}{item.totalRevenue}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">No record found</td>
                                </tr>
                              )}
                            </tbody>

                          </table>
                        </div>
                      </>
                  }
                </div>
              </>
              : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
          }
        </div>
      </section>

      {/* Service Detail Start */}
      {/* <div className="offcanvas offcanvas-end" tabIndex="-1" id="insightclient" aria-labelledby="offcanvasRightLabel">
        <div style={{ padding: "10px" }}>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <h1 className="popupheader text-center heading-color " id="offcanvasRightLabel">Service </h1>
        </div>
        <div className="offcanvas-body">
          <div className="text-center mb-3">
            <img alt='img' src={serviceDatilData.Image = '' ? ServiceImg : imgPath + serviceDatilData.Image} width="150px" height="150px" style={{ borderRadius: '10%' }} />
          </div>
          <h3 className="text-center">{serviceDatilData.name}</h3>
          <p className="text-center">Total Service Taken - {serviceDatilData.serviceTaken}</p>
          <p className="text-center mb-3">Total Revenue - {currencyName}{serviceDatilData.totalReveneuEarned}</p>
          <textarea className="form-control" style={{ padding: "20px" }}>{serviceDatilData.description}</textarea>
        </div>
      </div> */}
      {/* Service Detail End */}

    </>
  )
}

export default Services