import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Label } from 'recharts';
import { Modal } from 'react-bootstrap';
import AddOffer from '../Offer/addOffer';
import UseImage from '../../Hooks/useImage';
import useMuiComponent from '../../Hooks/useMuiComponent';
import EditOffer from '../Offer/editOffer';
import moment from 'moment';
import Spinner from '../../Components/spinner/spinner';
import { ToastContainer } from 'react-toastify';
import { Button } from '@mui/material';
import UseReward from './Hooks/useReward';



const Reward = () => {
    const { imageDeleteUpload, imageDelete, uploadImage } = UseImage()
    const { CustomAutocomplete } = useMuiComponent()
    const {
        setShowModal,
        setSpinModal,
        setExpiryModal,
        setValues,
        handleRewardChange,
        createAppointmentCoin,
        viewCoin,
        appointmentUpdate,
        createSpinCondition,
        coinConditionView,
        coinConditionUpdate,
        createCoinDuration,
        coinDurationView,
        coinDurationUpdate,
        showModal,
        spinModal,
        expiryModal,
        values,
        durationData,
        coinConditionData,
        coinListData,
        data,
        COLORS,
        valuetext,
        handleChange,
        offerView,
        servicesList,
        setImageData,
        setInActive,
        setShow,
        setShowOffer,
        navigate,
        setMale,
        setFemale,
        setOther,
        setRewardOffer,
        rewardOffer, 
        spinner,
        createOffer,
        editOffer,
        value,
        imageData,
        serviceList,
        inActive,
        offerListData,
        selectedService,
        show,
        showOffer,
        male,
        female,
        other,
        date,
        imgFolder,
        offerId,
        fileInputRef,
        zeroOffer,
        setZeroOffer
    } = UseReward()
    return (
        <section>
            {spinner && <Spinner />}
            <ToastContainer />
            <div className="m-t-3 cus-padd cusheight">
                <header>
                    <h2 className="page-tittle">Rewards</h2>
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <Button variant="outlined" onClick={() => { setShowOffer(true); servicesList(); setZeroOffer(false);setRewardOffer(true) }}>
                            Create Reward
                        </Button>
                        {/* <Button variant="outlined" onClick={() => { setShowOffer(true); servicesList(); setZeroOffer(true) }}>
                            Create Zero Offer
                        </Button> */}
                        <Button variant="outlined" onClick={() => { if (coinListData?.length >= 1) { viewCoin() } setShowModal(true) }}>
                            Apppointment
                        </Button>
                        <Button variant="outlined" onClick={() => { if (coinConditionData?.length >= 1) { coinConditionView() } setSpinModal(true) }}>
                            Spin Coin
                        </Button>
                        <Button variant="outlined" onClick={() => {
                            if (durationData?.length >= 1) {
                                coinDurationView();
                            }
                            setExpiryModal(true);
                        }}>
                            Expiry
                        </Button>
                    </div>
                </header>
                <div className="row">
                    <div style={{ width: '100%', height: 600 }}>
                        {
                            offerListData?.length > 1 ?
                                <ResponsiveContainer>
                                    <PieChart>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={false}
                                            outerRadius={200}
                                            fill="#8884d8"
                                            legendType='circle'
                                            dataKey="value"
                                            cursor={'pointer'}
                                            onClick={(e) => { offerView(e.id) }}
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                                :
                                <ResponsiveContainer>
                                    <PieChart>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            stroke="transparent"
                                            outerRadius={200}
                                            fill="#F79B68"
                                            dataKey="value"
                                            onClick={(e) => { offerView(e.id) }}
                                            cursor={'pointer'}
                                        >
                                            {data.map((entry, index) => (
                                                <Label
                                                    key={`label-${index}`}
                                                    value={entry.name}
                                                    position="center"
                                                    // position="inside"
                                                    fill='white'
                                                    content={({ value }) => value}
                                                />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                        }
                    </div>
                </div>
            </div>

            <AddOffer rewardOffer={rewardOffer} zeroOffer={zeroOffer} setZeroOffer={setZeroOffer} fileInputRef={fileInputRef} imageDeleteUpload={imageDeleteUpload} showOffer={showOffer} setShowOffer={setShowOffer} createOffer={createOffer} setImageData={setImageData}
                setMale={setMale} setFemale={setFemale} setOther={setOther} imageUpload={uploadImage} CustomAutocomplete={CustomAutocomplete}
                imageData={imageData} male={male} female={female} other={other} date={date} valuetext={valuetext} navigate={navigate} serviceList={serviceList} handleChange={handleChange} value={value}
                imgFolder={imgFolder} setInActive={setInActive} />

            <EditOffer rewardOffer={rewardOffer} zeroOffer={zeroOffer} setZeroOffer={setZeroOffer} fileInputRef={fileInputRef} offerId={offerId} imageDelete={imageDelete} show={show} setShow={setShow} setImageData={setImageData} male={male} setMale={setMale} female={female}
                setFemale={setFemale} other={other} setOther={setOther} imageData={imageData} CustomAutocomplete={CustomAutocomplete}
                imageUpload={uploadImage} editOffer={editOffer} value={value} serviceList={serviceList} moment={moment} handleChange={handleChange} imgFolder={imgFolder} valuetext={valuetext} setInActive={setInActive}
                inActive={inActive} selectedService={selectedService} />

            {/* Modal Appointment Coin */}
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <div className="modal-body">
                    <div className='float-end'>
                        <i className="fa-solid fa-xmark fs-5 cursor" onClick={() => { setShowModal(false); setValues({ ...values, amount: '', coin: '' }) }}></i>
                    </div>
                    <h5 className="text-center">Appointment Coin</h5>
                    <div className="mb-3">
                        <label className="form-label">Amount *</label>
                        <input type='number' min={1} className="form-control" id="exampleFormControlTextarea1" name='amount' value={values.amount} onChange={handleRewardChange} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Coin *</label>
                        <input type='number' min={1} className="form-control" id="exampleFormControlTextarea1" name='coin' value={values.coin} onChange={handleRewardChange} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn yesbtn" onClick={() => coinListData.length >= 1 ? appointmentUpdate() : createAppointmentCoin()}>
                            {coinListData?.length >= 1 ? 'Update' : 'Submit'}
                        </button>

                    </div>
                </div>
            </Modal>
            {/* Model Spin Coin   */}
            <Modal centered show={spinModal} onHide={() => setSpinModal(false)}>
                <div className="modal-body">
                    <div className='float-end'>
                        <i className="fa-solid fa-xmark fs-5 cursor" onClick={() => { setSpinModal(false); setValues({ ...values, coinPerSpin: '', coinDeductionPerSpin: '' }) }}></i>
                    </div>
                    <h5 className="text-center">Spin Coin</h5>
                    <div className="mb-3">
                        <label className="form-label">Coin Per Spin *</label>
                        <input type='number' min={1} className="form-control" id="exampleFormControlTextarea1" name='coinPerSpin' value={values.coinPerSpin} onChange={handleRewardChange} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Coin Deduction Per Spin *</label>
                        <input type='number' min={1} className="form-control" id="exampleFormControlTextarea1" name='coinDeductionPerSpin' value={values.coinDeductionPerSpin} onChange={handleRewardChange} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn yesbtn" onClick={() => coinConditionData.length >= 1 ? coinConditionUpdate() : createSpinCondition()}>
                            {coinConditionData?.length >= 1 ? 'Update' : 'Submit'}
                        </button>

                    </div>
                </div>
            </Modal>
            {/* Model Expiry Coin   */}
            <Modal centered show={expiryModal} onHide={() => setExpiryModal(false)}>
                <div className="modal-body">
                    <div className='float-end'>
                        <i className="fa-solid fa-xmark fs-5 cursor" onClick={() => { setExpiryModal(false); setValues({ ...values, expiry: '' }) }}></i>
                    </div>
                    <h5 className="text-center">Expiry Coin</h5>
                    <div className="mb-3">
                        <label className="form-label">Expiry (Months) *</label>
                        <select className="form-select form-control" aria-label="Default select example"
                            name='expiry' value={values.expiry} onChange={handleRewardChange}
                        >
                            <option hidden>Select</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>

                        </select>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn yesbtn" onClick={() => durationData.length >= 1 ? coinDurationUpdate() : createCoinDuration()} >
                            {durationData.length >= 1 ? 'Update' : 'Submit'}
                        </button>

                    </div>
                </div>
            </Modal>
        </section>
    );
};

export default Reward;
