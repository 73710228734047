import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Profile from '../../Assets/images/profile.png';
import { commonMessage, statusCodes } from '../../Constants/generalConfig';
import { feedBackList, branchListApi, logOutApi } from '../../Context/api';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../../Components/spinner/spinner'
import moment from 'moment/moment';
import Paginations from '../../Components/Pagination/pagination'
import NoRecord from '../../Components/noRecord';
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';

const FeedBack = () => {
    const { userInfo } = useContext(Notifications)
    const [feedBackListData, setFeedBackListData] = useState([])
    const [branchList, setBranchList] = useState([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [currentBranch, setCurrentBranch] = useState(userData?.locationId)
    const [spinner, setSpinner] = useState(false)
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 6
    const navigate = useNavigate()
    const [rating, setRating] = useState('')

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            feedback()
            getBranchList()
        }
    }, [page, rating, currentBranch])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        }).catch(()=>{})
    }
    const getBranchList = () => {
        axios.post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setBranchList(resp.data.data)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setBranchList([])
                }
            })
            .catch(() => { })
    }
    const feedback = () => {
        const data = {
            providerId: userData?.userId,
            branchId: currentBranch,
            page: page,
            count: PER_PAGE,
            rating: +rating
        }
        if (currentBranch == '') {
            delete data.branchId
        }
        if (rating == '') {
            delete data.rating
        }
        setSpinner(true)
        axios.post(feedBackList(), { ...data }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setFeedBackListData(resp.data.data)
                setSpinner(false)
                setCount(resp.data.totalCount)
            }
            else {
                setFeedBackListData(resp.data.data)
                setSpinner(false)
            }
        }).catch(()=>{
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }

    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3 cus-padd cusheight">
                    <header>
                        <h2 className='page-tittle'><i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Feedback</h2>
                        <hr />
                    </header>
                    {
                        intialRole({ permission: "insights", userData: userInfo, section: 'viewFeedBack' }) ?
                            <>
                                <div className="d-flex justify-content-between listpadding mb-3">
                                    <div className="dropdown">
                                        <div className='d-flex w-100'>
                                            <h6 className='mt-2'>Sort</h6>
                                            <h6 className='ms-1 mt-2'>By</h6>
                                            <h6 className='ms-1 mt-2'>Rating</h6>
                                            <select className='form-select ms-1' name="" id="" onChange={(e) => { setRating(e.target.value); }}>
                                                <option hidden>All</option>
                                                <option defaultValue="">All</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <select className='form-control' disabled name="" id="" value={currentBranch} onChange={(e) => { setCurrentBranch(e.target.value) }}>
                                            <option hidden>All</option>
                                            <option value="">All</option>
                                            {branchList?.map((item) => (
                                                <option value={item._id} key={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row listpadding">
                                    {
                                        !feedBackListData?.length ? <NoRecord />
                                            :
                                            <>
                                                {
                                                    feedBackListData?.map((item, i) =>
                                                        <div className="col-sm-6 mb-3 " key={i}>
                                                            <div className="feedbackbg">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <Rating name="read-only" value={item?.rating} readOnly />
                                                                    </div>
                                                                    <p>{moment(item.createdAt).format('DD-MM-YYYY')}</p>
                                                                </div>
                                                                <b>{item.serviceName}</b>
                                                                <p>{item.reason}</p>
                                                                <ul className="feedbackul">
                                                                    <li><img alt='img' src={Profile} /></li>
                                                                    <li className='mt-3'>{item.ratingGivenBy}</li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                    }
                                </div>
                                <div className='w-100 d-flex justify-content-center'>
                                    {feedBackListData?.length ?
                                        count > 6 &&
                                        <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                                        : null
                                    }
                                </div>
                            </>
                            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                </div>
            </section>
        </>
    )
}

export default FeedBack