import React, { useEffect, useState } from 'react'
import { myPlanHistory, paymentHistoryDetail } from '../../../Context/api'
import axios from 'axios'
import { commonMessage, statusCodes } from '../../../Constants/generalConfig'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../Components/spinner/spinner'
import Paginations from '../../../Components/Pagination/pagination'
import NoRecord from '../../../Components/noRecord'

const MyPlanHistory = () => {
    const navigate = useNavigate()
    const [planData, setPlanData] = useState([])
    const [paymentData, setPaymentData] = useState([])
    const [count, setCount] = useState('')
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const [spinner, setSpinner] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const { currency } = useParams()
    const [tab, setTab] = useState(1)



    useEffect(() => {
        if (tab == 1) {
            getPlanHistory()
        }
        else {
            paymentHistory()
        }
    }, [page])

    const getPlanHistory = () => {
        setSpinner(true)
        axios.post(myPlanHistory(), { userId: userData?.userId, page: page, count: PER_PAGE }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setPlanData(resp.data)
                setCount(resp.data.totalCount)
                setSpinner(false)
            }
            else {
                setPlanData([])
                setSpinner(false)
            }
        })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const paymentHistory = () => {
        setSpinner(true)
        axios.post(paymentHistoryDetail(), { userId: userData?.userId, page: page, count: PER_PAGE }, { headers: { Authorization: `Bearer ${userData.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setPaymentData(resp.data)
                setCount(resp.data.totalCount);
                setSpinner(false)
            }
            else {
                setSpinner(false)

            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }
    return (
        <>
            <section>
                <div className="m-t-3 cus-padd cusheight">
                    {spinner && <Spinner />}
                    <header>
                        <h2 className="page-tittle"><i className="fa-solid fa-angle-left cusgap cursor mb-1" onClick={() => navigate(-1)}></i>Plan History</h2>
                        <hr />
                    </header>
                    <div className="row">
                        <div className="col-sm-12 listpadding">
                            <nav className="mb-3">
                                <div className="nav nav-tabs tabber" id="nav-tab" role="tablist">
                                    <button className={` ${tab === 1 ? 'activeNav' : 'unActiveNav'} border-0`} onClick={() => { getPlanHistory(); setPage(1); setTab(1); }} id="basicdetails" type="button">
                                        Plan History
                                    </button>
                                    <button
                                        className={` ${tab === 2 ? 'activeNav' : 'unActiveNav'} border-0`}
                                        type="button"
                                        onClick={() => { setTab(2); paymentHistory(); setPage(1) }}
                                    >
                                        Payment History
                                    </button>
                                </div>
                            </nav>
                            {
                                tab == 1 &&
                                <>

                                    <p>Total History : {planData.totalCount}</p>
                                    {
                                                    !planData?.data?.length ? <NoRecord />
                                                        :
                                    <div className="table-responsive">
                                        <table className="table cus-table">
                                            <thead className="text-center tableheader">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Validity</th>
                                                    <th scope="col">Payment Mode</th>
                                                    <th scope="col">Start Date</th>
                                                    <th scope="col">End Date</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Status</th>
                                                    {/* <th scope="col">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {
                                                                planData.data?.map((item, index) => {
                                                                    return <React.Fragment key={index}>
                                                                        <tr className="text-center" key={index}>
                                                                            <th style={{ padding: '0.4rem .5rem' }}>{PER_PAGE * (page - 1) + index + 1}</th>
                                                                            <td style={{ padding: '0.4rem .5rem' }}>{item.validity}</td>
                                                                            <td style={{ padding: '0.4rem .5rem' }}>{item.paymentMode}</td>
                                                                            <td style={{ padding: '0.4rem .5rem' }}>{moment(item.startDate).format('DD/MM/YYYY')}</td>
                                                                            <td style={{ padding: '0.4rem .5rem' }}>{moment(item.endDate).format('DD/MM/YYYY')}</td>
                                                                            <td style={{ padding: '0.4rem .5rem' }}>{currency} {item.paidAmount}</td>
                                                                            <td style={{ padding: '0.4rem .5rem' }}>{item.status}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                })
                                                            }
                                                        
                                            </tbody>

                                        </table>
                                        <div className='w-100 d-flex justify-content-center'>
                                            {count > 10 &&
                                                <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                                            }
                                        </div>
                                    </div>
}
                                </>
                            }
                            {
                                tab == 2 &&
                                <>
                                    <p>Total Payment : {paymentData.totalCount}</p>
                                    {
                                         !paymentData?.data?.length ? <NoRecord />
                                         :
                                    <div className="table-responsive">
                                        <table className="table cus-table">
                                            <thead className="text-center tableheader">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Payment Mode</th>
                                                    <th scope="col">Payment Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    paymentData.data?.map((item, index) => {
                                                        return <React.Fragment key={index}>
                                                            <tr className="text-center">
                                                                <th style={{ padding: '0.4rem .5rem' }}>{PER_PAGE * (page - 1) + index + 1}</th>
                                                                <td style={{ padding: '0.4rem .5rem' }}>{currency} {item.amount}</td>
                                                                <td style={{ padding: '0.4rem .5rem' }}>{item.paymentMode}</td>
                                                                <td style={{ padding: '0.4rem .5rem' }}>{item.message}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    })
                                                }
                                                
                                            </tbody>
                                        </table>
                                        <div className='w-100 d-flex justify-content-center'>
                                            {count > 10 &&
                                                <Paginations setpage={setPage} count={Math.ceil(count / PER_PAGE)} page={page} />
                                            }
                                        </div>
                                    </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyPlanHistory