import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { branchListApi, rushHours, logOutApi } from '../../Context/api';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { commonMessage, roleNames, statusCodes } from '../../Constants/generalConfig';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/spinner/spinner'
import { Notifications } from '../../App'
import { intialRole } from '../../Utils/Utils';

const RushHours = () => {
    const { userInfo } = useContext(Notifications)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [branchList, setBranchList] = useState([])
    const [branchId, setBranchId] = useState(userData?.locationId)
    const [rushHourData, setRushHourData] = useState([])
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [spinner, setSpinner] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            rushHour()
        }
    }, [date, branchId])

    useEffect(() => {
        getBranchList()
    }, [])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData?.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        }).catch(() => { })
    }
    const getBranchList = () => {
        axios
            .post(branchListApi(), { userId: userData?.userId, isActive: true, isDeleted: false, page: 1, count: 100 }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    setBranchList(resp.data.data)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setBranchList([])
                }
            })
            .catch(() => { })
    }
    const rushHour = () => {
        const data = {
            today: date,
            branchId: branchId
        }
        if (branchId.length == 0) {
            delete data.branchId
        }
        if (userData?.roleTitle == roleNames.STAFF) {
            data['createdbyId'] = userData?.createdbyId
        }
        setSpinner(true)
        axios.post(rushHours(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setRushHourData(resp.data.data)
                setSpinner(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                setSpinner(false)
            }
        }).catch(() => {
            setSpinner(false)
            toast.error(commonMessage.networkError)
        })
    }

    let rush = []
    rushHourData?.map((item) => {
        rush.push({ _id: moment(item._id).format('LT'), Total_Visit: item.totalVisit })
    })
    return (
        <>
            <ToastContainer />
            <section>
                {spinner && <Spinner />}
                <div className="m-t-3  cus-padd cusheight">
                    <header>
                        <h2 className='page-tittle'> <i className="fa-solid fa-angle-left cusgap cursor" onClick={() => navigate(-1)}></i>Rush Hours</h2>
                        <hr />
                    </header>
                    {
                        intialRole({ permission: "insights", userData: userInfo, section: 'viewRushHour' }) ?
                            <>
                                <div className="d-flex justify-content-between listpadding mb-3">
                                    <div className="dropdown">
                                        <input type="date" className='form-control' value={date} onChange={(e) => { setDate(e.target.value) }} />
                                    </div>
                                    <div className="dropdown">
                                        <select className="form-control" aria-label="form-select-sm example" style={{ height: '34px' }}
                                            disabled value={branchId} onChange={(e) => { setBranchId(e.target.value) }}
                                        >
                                            <option hidden>All</option>
                                            <option value="">All</option>
                                            {branchList?.map((item) => (
                                                <option value={item._id} key={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <ResponsiveContainer width="98%" height="70%">
                                    <BarChart
                                        width={600}
                                        height={500}
                                        data={rush}
                                        margin={{ top: 20, left: 0 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="_id" style={{ fontSize: '13px' }} />
                                        <YAxis style={{ fontSize: '13px' }} />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="Total_Visit" fill="#f47d3a" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </>
                            : <div className='d-flex justify-content-center mt-5 me-5'><h3>You don't have permission to access</h3></div>
                    }
                </div>
            </section>
        </>
    )
}

export default RushHours