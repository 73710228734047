import { useContext, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { offerValidation, zeroOfferValidation, normalOfferValidation } from '../../../Context/validation'
import { createOfferApi, offerListApi, multipleServicesListApi, imgFolder, offerViewApi, editOfferApi, deleteOffer, logOutApi } from '../../../Context/api'
import axios from 'axios'
import { toast } from 'react-toastify'
import { statusCodes, commonMessage, roleNames } from '../../../Constants/generalConfig'
import { useEffect } from 'react'
import moment from 'moment/moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { Notifications } from '../../../App'

const UseOffer = () => {
    const { userInfo } = useContext(Notifications)
    const [value, setValue] = useState([18, 40])
    const [imageData, setImageData] = useState('')
    const [serviceList, setServiceList] = useState([])
    const [inActive, setInActive] = useState(false)
    const [offerListData, serOfferListData] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [offerId, setOfferId] = useState('')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const [show, setShow] = useState(false)
    const [showOffer, setShowOffer] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [zeroOffer, setZeroOffer] = useState(false)
    const [rewardOffer, setRewardOffer] = useState(false)
    const [filter, setFilter] = useState({ searchText: '', offerFilter: 'ALL' })
    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1)
    const PER_PAGE = 10
    const serviceData = []
    const [showDelete, setShowDelete] = useState(false);
    const navigate = useNavigate()
    const [male, setMale] = useState(false)
    const [female, setFemale] = useState(false)
    const [other, setOther] = useState(false)
    const date = moment(new Date()).format('YYYY-MM-DD')
    const fileInputRef = useRef(null);
    const loaction = useLocation()
    useEffect(() => {
        if (userData == null) {
            navigate('/')
        }
        else {
            offerList()
            setSpinner(true)
        }
    }, [page, filter?.offerFilter])

    const logOutFun = () => {
        axios.post(logOutApi(), { deviceId: userData?.deviceId, userId: userData.userId, type: 'WEBPROVIDER' }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                localStorage.removeItem('userData')
                navigate('/')
            }
        })
    }
    const valuetext = (value) => {
        return value
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const offerList = () => {
        const data = {
            userId: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
            isDeleted: false,
            searchText: filter?.searchText,
            page: page,
            count: loaction.pathname === '/layout/reward' ? 100 : PER_PAGE,
            branchId: userData?.locationId,
            offerType: loaction.pathname === '/layout/reward' ? 'REWARDS' : filter?.offerFilter
        }
        setSpinner(true)
        axios.post(offerListApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    serOfferListData(resp.data.data)
                    setSpinner(false)
                    setCount(resp.data.totalCount)
                    if (!resp.data.data?.length) {
                        setPage(1)
                    }
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    setSpinner(false)
                    serOfferListData([])
                }
            })
            .catch(() => {
                setSpinner(false)
                toast.error(commonMessage.networkError)
            })
    }
    const offerView = (id) => {
        axios.post(offerViewApi(), { loggedInUserId: userData?.userId, offersId: id }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                setZeroOffer(resp?.data?.data?.ZeroOffer)
                setOfferId(id)
                setImageData(resp.data.data.image)
                editOffer.setValues(resp.data.data)
                setSelectedService(resp.data.data.serviceData)
                setValue([resp.data.data.audience?.age?.from, resp.data.data.audience?.age?.to])
                setInActive(resp.data?.data?.audience?.inActive)
                resp?.data?.data?.audience?.gender?.find((item) => item === 'MALE' ? setMale(true) : item === 'FEMALE' ? setFemale(true) : item === 'OTHER' ? setOther(true) : null)
                setShow(true)
                servicesList()

            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
        })
    }
    const servicesList = () => {
        axios
            .post(multipleServicesListApi(), { userId: userData?.userId, branchId: userData?.locationId }, { headers: { Authorization: `Bearer ${userData?.token}` } })
            .then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    if (serviceData.length === 0) {
                        resp.data.data?.map((item) => {
                            item.servicedetails?.map((data) => {
                                serviceData.push({ name: data.serviceName, _id: data.serviceId, categoryName: item.categoryName })
                            })
                            setServiceList(serviceData)
                        })
                    }
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
            })
    }
    const createOffer = useFormik({
        initialValues: {
            name: '',
            audience: '',
            startDate: '',
            endDate: '',
            service: [],
            description: '',
            terms_condition: '',
            discountType: '',
            discountValue: '',
            maximumValue: '',
            probability: '5'
        },
        validationSchema: !rewardOffer ? normalOfferValidation : zeroOffer ? zeroOfferValidation : offerValidation,
        onSubmit: (values) => {
            const data = {
                name: values.name.trim(),
                image: imageData,
                description: values.description.trim(),
                terms_condition: values.terms_condition,
                startDate: values.startDate,
                endDate: values.endDate,
                serviceId: values.service.map((item) => item._id),
                createdby_id: userData?.roleTitle === roleNames.SERVICE_PROVIDER ? userData?.userId : userData?.createdbyId,
                discountType: values.discountType,
                discountValue: values.discountValue,
                maximumValue: values.maximumValue,
                probability: +values.probability,
                offerType: rewardOffer ? "REWARDS" : "OFFER",
                ZeroOffer: zeroOffer,
                audience: {
                    type: 'CUSTOM',
                    gender: [],
                    inActive: inActive,
                    age: {
                        from: value[0],
                        to: value[1],
                    },
                },
            }

            if (zeroOffer) {
                delete data.startDate
                delete data.endDate
                delete data.discountType
                delete data.discountValue
                delete data.maximumValue
                delete data.audience
                delete data.serviceId
            }
            if (!rewardOffer) {
                delete data.probability
                delete data.audience
            }
            if (!zeroOffer) {
                if (male) {
                    data?.audience?.gender?.push('MALE');
                }
                if (female) {
                    data.audience.gender.push('FEMALE');
                }
                if (other) {
                    data.audience.gender.push('OTHER');
                }
                if (values.startDate > values.endDate) {
                    return toast.warn('Start date cannot be greater than end date');
                }
                if (rewardOffer) {
                    if (!data.audience.gender.length) {
                        return toast.warn('Please select audience');
                    }
                }
            }
            if (!imageData) return toast.warn('Please upload image')
            // console.log(data,'llllll')
            // return
            setSpinner(true)
            axios.post(createOfferApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setShowOffer(false)
                    createOffer.resetForm()
                    setImageData('')
                    offerList()
                    setSpinner(false)
                    setMale(false); setFemale(false); setOther(false)
                    setZeroOffer(false)
                    setRewardOffer(false)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                })
        },
    })
    const editOffer = useFormik({
        initialValues: {
            name: '',
            description: '',
            serviceData: '',
            endDate: '',
            startDate: '',
            terms_condition: '',
            discountType: '',
            discountValue: '',
            maximumValue: '',
            probability: ''
        },
        validationSchema: !rewardOffer ? normalOfferValidation : zeroOffer ? zeroOfferValidation : offerValidation,
        onSubmit: (values) => {
            const data = {
                offerId: offerId,
                name: values.name.trim(),
                image: imageData,
                description: values.description.trim(),
                terms_condition: values.terms_condition,
                startDate: values.startDate,
                endDate: values.endDate,
                serviceId: values.serviceData.map((item) => item._id),
                loggedInUserId: userData?.userId,
                discountType: values.discountType,
                discountValue: values.discountValue,
                maximumValue: values.maximumValue,
                probability: +values.probability,
                offerType: rewardOffer ? "REWARDS" : "OFFER",
                ZeroOffer: zeroOffer,
                audience: {
                    type: 'CUSTOM',
                    gender: [],
                    inActive: inActive,
                    age: {
                        from: value[0],
                        to: value[1],
                    },
                },
            }
            if (zeroOffer) {
                delete data.startDate
                delete data.endDate
                delete data.discountType
                delete data.discountValue
                delete data.maximumValue
                delete data.audience
            }
            if (!rewardOffer) {
                delete data.probability
                delete data.audience

            }
            // console.log(zeroOffer,'zeroOffer')
            if (!zeroOffer) {
                if (male) {
                    data.audience.gender.push('MALE');
                }
                if (female) {
                    data.audience.gender.push('FEMALE');
                }
                if (other) {
                    data.audience.gender.push('OTHER');
                }
                if (values.startDate > values.endDate) {
                    return toast.warn('Start date cannot be greater than end date');
                }
                if (rewardOffer) {
                    if (!data.audience.gender.length) {
                        return toast.warn('Please select audience');
                    }
                }
            }
            // console.log(data,'data')
            // return
            setSpinner(true)
            axios.post(editOfferApi(), { ...data }, { headers: { Authorization: `Bearer ${userData?.token}` } }).then((resp) => {
                if (resp.data.code === statusCodes.ok) {
                    toast.success(resp.data.message)
                    setImageData('')
                    offerList()
                    setShow(false)
                    setSpinner(false)
                    setMale(false); setFemale(false); setOther(false)
                    setZeroOffer(false)
                }
                else if (resp.data.code === statusCodes.tokenExpire) {
                    logOutFun()
                }
                else {
                    toast.error(resp.data.message)
                    setSpinner(false)
                }
            })
                .catch(() => {
                    toast.error(commonMessage.networkError)
                    setSpinner(false)
                })
        },
    })
    const offerDelete = () => {
        axios.post(deleteOffer(), { id: offerId, isDeleted: true }).then((resp) => {
            if (resp.data.code === statusCodes.ok) {
                toast.success(resp.data.message)
                offerList()
                setShowDelete(false)
            }
            else if (resp.data.code === statusCodes.tokenExpire) {
                logOutFun()
            }
            else {
                toast.error(resp.data.message)
            }
        })
    }

    return {
        logOutFun,
        valuetext,
        handleChange,
        offerList,
        offerView,
        servicesList,
        offerDelete,
        setValue,
        setImageData,
        setServiceList,
        setInActive,
        serOfferListData,
        setSelectedService,
        setOfferId,
        setShow,
        setShowOffer,
        setSpinner,
        setFilter,
        setCount,
        setPage,
        navigate,
        setMale,
        setFemale,
        setOther,
        setShowDelete,
        setZeroOffer,
        setRewardOffer,
        showDelete,
        zeroOffer,
        createOffer,
        editOffer,
        value,
        imageData,
        serviceList,
        inActive,
        offerListData,
        selectedService,
        offerId,
        userData,
        show,
        showOffer,
        spinner,
        filter,
        count,
        page,
        PER_PAGE,
        serviceData,
        male,
        female,
        other,
        date,
        imgFolder,
        fileInputRef,
        userInfo,
        rewardOffer
    }
}

export default UseOffer